import { BaseStyledTableCell } from '@ui/base';
import { ThemedTableCellProps, UIHTMLComponentProps } from '@ui/model';
import { ComponentType, forwardRef, useContext } from 'react';
import { TableContext } from './Table';

interface TableCellProps
  extends UIHTMLComponentProps<HTMLTableCellElement>,
    ThemedTableCellProps {
  name?: string;
  colSpan?: number;
  rowSpan?: number;
}

export const TableCell: ComponentType<TableCellProps> = forwardRef<
  HTMLTableCellElement,
  TableCellProps
>(
  (
    {
      className,
      width,
      minWidth,
      height,
      variant,
      color = 'black.87',
      bgColor,
      align,
      whiteSpace,
      ...props
    },
    ref
  ) => {
    const ctxValue = useContext(TableContext);
    let component: 'th' | undefined;

    if (ctxValue && ctxValue.isHead) {
      component = 'th';
    }

    return (
      <BaseStyledTableCell
        {...props}
        as={component}
        ref={ref}
        className={className}
        $color={color}
        $bgColor={bgColor}
        $variant={variant}
        $align={align}
        $minWidth={minWidth}
        $width={width}
        $height={height}
        $whiteSpace={whiteSpace}
      />
    );
  }
);

TableCell.displayName = 'TableCell';
TableCell.defaultProps = {
  align: 'center',
  whiteSpace: 'pre',
  py: '16',
  px: '8',
};
