/**
 * Date가 오늘 날짜와 동일한지 확인한다.
 * @param {Date} date - 오늘인지 확인할 Date 객체를 넘긴다.
 * @returns {boolean} 오늘인지의 여부를 반환한다.
 * @group Utils
 *
 * @example
 * const someday = new Date(...);
 * isToday(someday) => true | false;
 */
export const isToday = (date: Date) => {
  return new Date().toDateString() === date.toDateString();
};

/**
 * Date 두개를 받아서 두 Date가 같은 날짜인지 반환한다. (시간은 상관없음)
 * @param {Date} date - 비교할 Date 1
 * @param {Date} targetDate - date와 같은 날짜인지 확인할 Date 객체
 * @returns {boolean} 두 파라미터가 동일한 날짜인지의 여부를 반환한다.
 * @group Utils
 *
 * @example
 * const date1 = new Date(...);
 * const date2 = new Date(...);
 * isSameDate(date1, date2) => true | false;
 */
export const isSameDate = (date: Date, targetDate: Date) => {
  return date.toDateString() === targetDate.toDateString();
};

/**
 * Date가 Start, End 사이에 위치한 날짜인지 확인한다. (start < date <= end)
 * @param {Date} start - Range의 시작 일자
 * @param {Date} end - Range의 종료 일자
 * @param {Date} date - date가 Range안에 위치한 날짜인지의 여부를 반환한다.
 * @returns {boolean}
 * @group Utils
 *
 * @example
 * const startDate = new Date(2022, 0, 1); // 2022년 1월 1일
 * const endDate = new Date(2022, 0, 31); // 2022년 1월 31일
 * const date = new Date(2022, 0, 15); // 2022년 1월 15일
 * const outOfRange = new Date(2022, 5, 1); // 2022년 6월 1일
 *
 * isRageInDate(startDate, endDate, date) => true;
 * isRageInDate(startDate, endDate, outOfRange) => false;
 */
export const isRageInDate = (start: Date, end: Date, date: Date) => {
  const dateTime = date.getTime();
  const startTime = start.getTime() - 24 * 60 * 60 * 1000;
  const endTime = end.getTime();
  if (dateTime > startTime && dateTime <= endTime) {
    return true;
  }

  return false;
};

/**
 * n년 전의 Date를 반환한다.
 * @param {number} n - N 년전의 N에 해당하는 수
 * @param {Date} targetDate - 기준이 되는 Date 객체
 * @returns {Date} 기준 날짜에서 N년 전의 Date 객체를 반환한다.
 * @group Utils
 *
 * @example
 * const someoneBirthday = new Date(2022, 5, 28);
 * getBeforeYear(30, someoneBirthday) => Date(1992, 5, 28)
 */
export const getBeforeYear = (
  n: number,
  targetDate: Date = new Date()
): Date => {
  const beforeYear = targetDate.getFullYear() - n;
  const resTime = targetDate.setFullYear(beforeYear);
  return new Date(resTime);
};

/**
 * n개월 전의 Date를 반환한다.
 * @param {number} index - N 개월전의 N에 해당하는 수
 * @param {Date} targetDate - 기준이 되는 Date 객체
 * @returns {Date} 기준 날짜에서 N개월 전의 Date 객체를 반환한다.
 * @group Utils
 *
 * @example
 * const date = new Date(2022, 0, 1);
 * getBeforeMonth(6, date) => // 2021.07.01에 해당하는 Date 객체
 */
export const getBeforeMonth = (n: number, targetDate: Date = new Date()) => {
  const beforeMonth = targetDate.getMonth() - n;
  const resTime = targetDate.setMonth(beforeMonth);
  return new Date(resTime);
};

/**
 * YYYY.MM.DD 형태의 String을 Date로 변환한다.
 * @param {param_type} param_name - 파라미터의 설명을 적으세요.
 * @returns {Date} - YYYY.MM.DD가 변환된 Date 객체
 * @group Utils
 *
 * @example
 * getDateByFormatString("1992.06.28") => Date(1992, 5, 28)
 */
export const getDateByFormatString = (date: string): Date => {
  // TODO: string에 원하는 Format이 들어오지 않을 경우에 대한 예외처리가 필요하다면 코드 작성하기
  const dateSplitArr = date.split('.');

  const year = +dateSplitArr[0];
  const month = +dateSplitArr[1] - 1;
  const day = +dateSplitArr[2];

  return new Date(year, month, day);
};

type DateFormat =
  | 'YYYYmmdd'
  | 'YYYY.mm.dd'
  | 'YYYY-mm-dd'
  | 'YYYY/mm/dd'
  | 'YYYY/mm'
  | 'YY/mm/dd';

/**
 * UNIX Time Stamp를 받아서 정해진 Format 형식의 문자를 반환한다.
 * @param {number} time Unix Time Stamp
 * @returns {string} Format 형식의 문자열을 반환한다.
 * @group Utils
 *
 * @example
 * const time = new Date(1992, 5, 28).getTime();
 * getDateByUnixTimeStamp(date, 'YYYY-mm-dd') => // "1992-06-28"
 */
export const getDateFormattedString = (time: number, format: DateFormat) => {
  const targetDate = new Date(time);

  const year = targetDate.getFullYear();
  const substrYear = year.toString().substring(2, 4);
  const month = `${targetDate.getMonth() + 1}`.padStart(2, '0');
  const date = `${targetDate.getDate()}`.padStart(2, '0');

  switch (format) {
    case 'YYYY/mm':
      return `${year}/${month}`;
    case 'YYYY/mm/dd':
      return `${year}/${month}/${date}`;
    case 'YYYY-mm-dd':
      return `${year}-${month}-${date}`;
    case 'YYYY.mm.dd':
      return `${year}.${month}.${date}`;
    case 'YY/mm/dd':
      return `${substrYear}/${month}/${date}`;
    default:
      return `${year}${month}${date}`;
  }
};

/**
 * UNIX Time Stamp 데이터를 받아서 해당 날짜의 시간을 자정으로 재설정하여 반환한다.
 * @param {number} ts Unix Time Stamp
 * @returns {Date}
 * @group Utils
 */
export const getDateInitSecond = (ts: number) => {
  return new Date(new Date(ts).setHours(0, 0, 0, 0));
};

/**
 * UNIX Time Stamp 형식의 start, end 데이터를 받아서 그 기간의 문자열을 반환한다.
 * @param {number} startTs Unix Time Stamp
 * @param {number} endTs Unix Time Stamp
 * @returns {string} diff 기간의 string을 반환한다. ex: 2개월 , 1년 2개월, 20일
 * @group Utils
 *
 * @example
 * const startTs = new Date(2022, 11, 1).getTime();
 * const endTs = new Date(2023, 1, 20).getTime();
 * const diff = getDateDiff(startTs, endTs) => 3개월
 */
export const getDateDiffStr = (
  startTs: number,
  endTs: number,
  variant?: 'year' | 'month' | 'day',
  initSecond?: boolean,
  additionDay?: number
) => {
  let _endTs = endTs;
  let _startTs = startTs;

  if (initSecond) {
    _endTs = +getDateInitSecond(_endTs);
    _startTs = +getDateInitSecond(_startTs);
  }

  const diffTs = Math.round(_endTs - _startTs);
  const dayTs = 86400000;
  const monthTs = 2629743000;
  const yearTs = 31556926000;
  // https://www.unixtimestamp.com/ 참조해서 month, year 나눔

  let calcTs = diffTs;

  const periodArr = [];

  if (calcTs >= yearTs) {
    const year = Math.floor(calcTs / yearTs);
    periodArr.push(`${year}년`);
    calcTs = calcTs - year * yearTs;
  }

  if (calcTs >= monthTs) {
    const month = Math.floor(calcTs / monthTs);
    if (variant !== 'year') {
      periodArr.push(`${month}개월`);
    }
    calcTs = calcTs - month * monthTs;
  }

  if (calcTs >= dayTs) {
    const day = Math.floor(calcTs / dayTs);
    if (variant === 'day') {
      periodArr.push(`${day + (additionDay || 0)}일`);
    }
  }

  return periodArr.join(' ');
};

/**
 * year, month 등의 파라미터를 받아 iOS 환경에서 변환 가능한 방식으로 날짜를 변환 후 반환한다.
 * @param {number} year - 타겟 Date의 Year
 * @param {number} month - 타겟 Date Month
 * @param {number} date - 타겟 Date Date. 생략 가능.
 * @returns {number} - 날짜를 MicroSecond 형태로 반환
 * @group Utils
 *
 * @example
 * const startDate = getDateCompatibleIOS(new Date().getYer(), new Date().getMonth());
 */
export const getDateCompatibleIOS = (
  year: number,
  month: number,
  date?: number
): number => {
  const patchPad = (num: number) => String(num).padStart(2, '0');
  const dateArray = [year, patchPad(month)];
  if (date) dateArray.push(patchPad(date));
  return +new Date(dateArray.join('-'));
};
