import {
  IntervalEnum,
  MyStrategyOrderType,
  PeriodEnum,
  StrategyOptimizationEnum,
  StrategyOrderType as StrategyOrderEnum,
  StrategyOrderMethod,
  StrategyOrderPeriodReturn,
  StrategyRebalancingOptionEnum,
  ThemeSrcEnum,
  UniverseEnum,
  UniversePresetEnum,
} from '@graphql/generates';
import { TagColor } from '@models/common';
import {
  IntervalType,
  OptimizationType,
  PeriodType,
  RebalancingOptionType,
  StrategyOrderMethodType,
  StrategyOrderPeriodReturnType,
  StrategyOrderType,
  ThemeSrcType,
  UniversePresetType,
  UniverseType,
} from '@models/strategy';

export const INTERVAL_ENUM = [
  IntervalEnum.Day,
  IntervalEnum.Half,
  IntervalEnum.Month,
  IntervalEnum.Quarter,
  IntervalEnum.Week,
  IntervalEnum.Year,
] as const;

export const PERIOD_ENUM = [
  PeriodEnum.Month,
  PeriodEnum.Quarter,
  PeriodEnum.Half,
  PeriodEnum.Year,
  PeriodEnum.ThreeYears,
  'ALL',
] as const;

export const UNIVERSE_ENUM_US = [UniverseEnum.Nasdaq100, UniverseEnum.Snp500];
export const UNIVERSE_ENUM_KR = [
  UniverseEnum.Krx300,
  UniverseEnum.Kosdaq,
  UniverseEnum.Kosdaq,
  UniverseEnum.Kosdaq150,
  UniverseEnum.Kospi,
  UniverseEnum.Kospi200,
];

export const UNIVERSE_ENUM = [
  ...UNIVERSE_ENUM_KR,
  ...UNIVERSE_ENUM_US,
] as const;

/** 상장기업 */
export const UNIVERSE_PUBLIC_ENUM = [
  UniversePresetEnum.Kosdaq,
  UniversePresetEnum.Kospi,
  UniversePresetEnum.KospiKosdaq,
];

/** 대표기업 */
export const UNIVERSE_REPRESENT_ENUM = [
  UniversePresetEnum.Kosdaq150,
  UniversePresetEnum.Kospi200,
  UniversePresetEnum.Kospi200Kosdaq150Krx300,
  UniversePresetEnum.Krx300,
];

/** 미국 기업 */
export const UNIVERSE_US_ENUM = [
  UniversePresetEnum.Nasdaq100,
  UniversePresetEnum.Snp500,
  UniversePresetEnum.Snp500Nasdaq100,
];

export const UNIVERSE_PRESET_ENUM = [
  ...UNIVERSE_PUBLIC_ENUM,
  ...UNIVERSE_REPRESENT_ENUM,
  ...UNIVERSE_US_ENUM,
];

export const REBALANCING_OPTION_ENUM = [
  StrategyRebalancingOptionEnum.Month,
  StrategyRebalancingOptionEnum.Quarter,
  StrategyRebalancingOptionEnum.Half,
  'NONE',
] as const;

export const OPTIMIZATION_ENUM = [
  StrategyOptimizationEnum.EqualWeight,
  StrategyOptimizationEnum.MarketWeight,
  StrategyOptimizationEnum.RiskParity,
] as const;

export const STRATEGY_ORDER_THEME_ENUM = [
  StrategyOrderEnum.CumulativeAsc,
  StrategyOrderEnum.Cumulative,
  StrategyOrderEnum.Name,
] as const;

export const STRATEGY_ORDER_METHOD_ENUM = [
  StrategyOrderMethod.Cumulative,
  StrategyOrderMethod.CumulativeAsc,
  StrategyOrderMethod.Name,
] as const;

export const STRATEGY_ORDER_PERIOD_RETURN_ENUM = [
  StrategyOrderPeriodReturn.Month_1,
  StrategyOrderPeriodReturn.Month_3,
  StrategyOrderPeriodReturn.Month_6,
] as const;

export const STRATEGY_ORDER_MASTER_ENUM = [StrategyOrderEnum.Name] as const;

/** 테마 출처 */
export const THEME_SRC_COMMON_ENUM = [
  ThemeSrcEnum.KbAm,
  ThemeSrcEnum.KbSec,
] as const;

export const THEME_SRC_ENUM = [
  ...THEME_SRC_COMMON_ENUM,
  ThemeSrcEnum.Quantit,
] as const;

export const INTERVAL_MAPPER: Record<IntervalType, IntervalType> = {
  DAY: IntervalEnum.Day,
  HALF: IntervalEnum.Half,
  MONTH: IntervalEnum.Month,
  QUARTER: IntervalEnum.Quarter,
  WEEK: IntervalEnum.Week,
  YEAR: IntervalEnum.Year,
};

export const PERIOD_MAPPER: Record<PeriodType, PeriodType> = {
  MONTH: PeriodEnum.Month,
  HALF: PeriodEnum.Half,
  QUARTER: PeriodEnum.Quarter,
  THREE_YEARS: PeriodEnum.ThreeYears,
  YEAR: PeriodEnum.Year,
  ALL: 'ALL',
};

export const REBALANCING_OPTION_MAPPER: Record<
  RebalancingOptionType,
  RebalancingOptionType
> = {
  HALF: StrategyRebalancingOptionEnum.Half,
  MONTH: StrategyRebalancingOptionEnum.Month,
  QUARTER: StrategyRebalancingOptionEnum.Quarter,
  NONE: 'NONE',
};

export const REBALANCING_OPTION_TEXT: Record<RebalancingOptionType, string> = {
  HALF: '6개월',
  MONTH: '1개월',
  QUARTER: '3개월',
  NONE: '없음',
};

export const OPTIMIZATION_MAPPER: Record<OptimizationType, OptimizationType> = {
  EQUAL_WEIGHT: StrategyOptimizationEnum.EqualWeight,
  MARKET_WEIGHT: StrategyOptimizationEnum.MarketWeight,
  RISK_PARITY: StrategyOptimizationEnum.RiskParity,
};

export const UNIVERSE_MAPPER: Record<UniverseType, UniverseEnum> = {
  KRX300: UniverseEnum.Krx300,
  KOSDAQ: UniverseEnum.Kosdaq,
  KOSDAQ150: UniverseEnum.Kosdaq150,
  KOSPI: UniverseEnum.Kospi,
  KOSPI200: UniverseEnum.Kospi200,
  NASDAQ100: UniverseEnum.Nasdaq100,
  SNP500: UniverseEnum.Snp500,
};

export const UNIVERSE_PRESET_MAPPER: Record<
  UniversePresetType,
  UniversePresetEnum
> = {
  KOSDAQ: UniversePresetEnum.Kosdaq,
  KOSDAQ150: UniversePresetEnum.Kosdaq150,
  KOSPI: UniversePresetEnum.Kospi,
  KOSPI200: UniversePresetEnum.Kospi200,
  KOSPI_KOSDAQ: UniversePresetEnum.KospiKosdaq,
  KOSPI200_KOSDAQ150_KRX300: UniversePresetEnum.Kospi200Kosdaq150Krx300,
  KRX300: UniversePresetEnum.Krx300,
  NASDAQ100: UniversePresetEnum.Nasdaq100,
  SNP500: UniversePresetEnum.Snp500,
  SNP500_NASDAQ100: UniversePresetEnum.Snp500Nasdaq100,
};

export const STRATEGY_ORDER_MAPPER: Record<
  StrategyOrderType,
  StrategyOrderType
> = {
  CUMULATIVE_ASC: StrategyOrderEnum.CumulativeAsc,
  CUMULATIVE: StrategyOrderEnum.Cumulative,
  NAME: StrategyOrderEnum.Name,
};

export const STRATEGY_ORDER_METHOD_MAPPER: Record<
  StrategyOrderMethodType,
  StrategyOrderMethodType
> = {
  CUMULATIVE: StrategyOrderMethod.Cumulative,
  CUMULATIVE_ASC: StrategyOrderMethod.CumulativeAsc,
  NAME: StrategyOrderMethod.Name,
};

export const STRATEGY_ORDER_PERIOD_MAPPER: Record<
  StrategyOrderPeriodReturnType,
  StrategyOrderPeriodReturnType
> = {
  MONTH_1: StrategyOrderPeriodReturn.Month_1,
  MONTH_3: StrategyOrderPeriodReturn.Month_3,
  MONTH_6: StrategyOrderPeriodReturn.Month_6,
};

export const MY_STRATEGY_ORDER_MAPPER: Record<
  MyStrategyOrderType,
  MyStrategyOrderType
> = {
  BACKTEST_CUMULATIVE: MyStrategyOrderType.BacktestCumulative,
  BENCHMARK_OR_BASE_STRATEGY_CUMULATIVE:
    MyStrategyOrderType.BenchmarkOrBaseStrategyCumulative,
  FORWARD_CUMULATIVE: MyStrategyOrderType.ForwardCumulative,
  FORWARD_START_DATE: MyStrategyOrderType.ForwardStartDate,
};

export const PERIOD_LOCALE: Record<PeriodType, string> = {
  MONTH: '최근 1개월',
  HALF: '최근 6개월',
  QUARTER: '최근 3개월',
  THREE_YEARS: '최근 3년',
  YEAR: '최근 1년',
  ALL: '전체기간',
};

export const UNIVERSE_LOCALE: Record<UniverseType, string> = {
  KRX300: 'KRX 300',
  KOSDAQ: 'KOSDAQ',
  KOSDAQ150: 'KOSDAQ 150',
  KOSPI: 'KOSPI',
  KOSPI200: 'KOSPI 200',
  NASDAQ100: 'NASDAQ 100',
  SNP500: 'S&P 500',
};

export const REBALANCING_OPTION_LOCALE: Record<RebalancingOptionType, string> =
  {
    HALF: '6개월에 한 번',
    MONTH: '1개월에 한 번',
    QUARTER: '3개월에 한 번',
    NONE: '리밸런싱 없음',
  };
export const REBALANCING_OPTION_LOCALE_SENTENCE: Record<
  RebalancingOptionType,
  string
> = {
  HALF: `설정된 리밸런싱 주기는 ${REBALANCING_OPTION_TEXT.HALF}마다 한 번 입니다.`,
  MONTH: `설정된 리밸런싱 주기는 ${REBALANCING_OPTION_TEXT.MONTH}마다 한 번 입니다.`,
  QUARTER: `설정된 리밸런싱 주기는 ${REBALANCING_OPTION_TEXT.QUARTER}마다 한 번 입니다.`,
  NONE: '리밸런싱을 하지 않는 전략입니다.',
};

export const OPTIMIZATION_LOCALE: Record<OptimizationType, string> = {
  EQUAL_WEIGHT: '동일비중',
  MARKET_WEIGHT: '시가총액',
  RISK_PARITY: '위험균형',
};

export const STRATEGY_ORDER_LOCALE: Record<StrategyOrderType, string> = {
  CUMULATIVE_ASC: '수익률 낮은 순',
  CUMULATIVE: '수익률 높은 순',
  NAME: '가나다 순',
} as const;

export const STRATEGY_ORDER_PERIOD_LOCALE: Record<
  StrategyOrderPeriodReturnType,
  string
> = {
  MONTH_1: '1개월',
  MONTH_3: '3개월',
  MONTH_6: '6개월',
} as const;

export const MY_STRATEGY_ORDER_LOCALE: Record<MyStrategyOrderType, string> = {
  BACKTEST_CUMULATIVE: '백테스트 누적수익률 높은 순',
  BENCHMARK_OR_BASE_STRATEGY_CUMULATIVE: '프리셋 누적수익률 높은 순',
  FORWARD_CUMULATIVE: '모의투자 누적수익률 높은 순',
  FORWARD_START_DATE: '전략생성 최신순',
} as const;

export const THEME_SRC_MAPPER: Record<ThemeSrcType, ThemeSrcType> = {
  KB_AM: ThemeSrcEnum.KbAm,
  KB_SEC: ThemeSrcEnum.KbSec,
  QUANTIT: ThemeSrcEnum.Quantit,
};

export const THEME_SRC_COLOR_MAPPER: Record<ThemeSrcType, TagColor> = {
  KB_AM: 'green',
  KB_SEC: 'orange',
  QUANTIT: 'blue',
};

export const THEME_SRC_LOCALE: Record<ThemeSrcType, string> = {
  KB_AM: 'KB자산운용 테마',
  KB_SEC: 'KB증권 테마',
  QUANTIT: 'SNS 테마',
};
