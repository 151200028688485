import { EditHeadTab } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useEditController } from '@hooks/edit/useEditController';
import { CountryCodeType } from '@models/common';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { getFloaterModelByStep } from '@utils/strategy';
import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { EditCancelBtn } from '../DetailEditPageContainer';
import { StockSelectorContainer } from '../StockSelectorContainer';
import { StrategySettingContainer } from '../StrategySettingContainer';

interface LevelTwoContainerProps {
  countryCode?: CountryCodeType;
  reEdit?: boolean;
  renew?: boolean;
  prevStrategyId?: string;
}

/**
 * Beginner Level 2단계 컨테이너
 */
export const LevelTwoContainer: FC<LevelTwoContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
  reEdit = false,
  renew = false,
  prevStrategyId = '',
}) => {
  const {
    holdings,
    fetchStyleFactors,
    fetchDefaultOptions,
    fetchMySimulationOption,
    updateRenewAndReEdit,
    setPrevSimulationOptionScreening,
  } = useStrategyEditStore();
  const { strategyId = '' } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const editStep = searchParams.get('step') || 1;

  const { handleClickComplete, handleCancel } = useEditController({
    isSimple: true,
    countryCode,
    renew,
    reEdit,
    prevStrategyId,
    investmentStrategyLevel: INVESTMENT_STRATEGY_LEVEL_MAPPER.NOVICE_LEVEL3,
  });

  const handleClickNext = () => {
    // Check 된 카드가 없으면 PopUp 띄우기
    // step 2번째로 이동시키기
    setSearchParams(`step=${+editStep + 1}`, { replace: true });
  };

  const handleClickPrev = () => {
    setSearchParams(`step=${+editStep - 1}`, { replace: true });
  };

  const handleClickTabButton = (index: number) => () => {
    setSearchParams(`step=${index + 1}`, { replace: true });
  };

  useEffect(() => {
    if (renew || reEdit) {
      Promise.resolve(fetchMySimulationOption(strategyId)).then(() => {
        updateRenewAndReEdit(renew, reEdit);
        setPrevSimulationOptionScreening(true);
      });
      return;
    }

    fetchDefaultOptions(strategyId);
    fetchStyleFactors({ country: countryCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/2단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 2,
        okDisabled: +editStep === 2 && holdings.length === 0, // 종목구성 데이터 없는 경우 버튼 비활성화
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleClickComplete,
      })}
      optionalButton={EditCancelBtn(handleCancel)}
      onClickPrev={handleCancel}
    >
      <EditHeadTab
        step={+editStep}
        type="beginner"
        onClickTab={handleClickTabButton}
        enableDesc={holdings.length !== 0}
      />
      {+editStep === 1 && (
        <StrategySettingContainer countryCode={countryCode} />
      )}
      {+editStep === 2 && <StockSelectorContainer simpleMode />}
    </LayoutContainer>
  );
};
