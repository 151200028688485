import { COUNTRY_MAPPER } from '@constants/common';
import { SimpleEditPageContainer } from '@containers/strategy-edit/SimpleEditPageContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const MyUsStrategySimpleEditPage: FC = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <SimpleEditPageContainer
      renew
      prevStrategyId={prevStrategyId}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
