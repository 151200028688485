import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';
import { StockListEmptyBottomsheetContentModel } from '@models/feedback';
import { Typography } from '@ui/components';

interface StockEmptyBottomSheetProps
  extends StockListEmptyBottomsheetContentModel,
    ContentWidthModel {}

const StockEmptyBottomSheetWrap = styled(BottomsheetTemplate)``;

export const StockEmptyBottomSheet: FC<StockEmptyBottomSheetProps> = ({
  contentWidth,
  onOk,
  type,
  isActiveKbContract,
}) => {
  return (
    <StockEmptyBottomSheetWrap
      type="stockListEmpty"
      title="전략을 다시 편집 해주세요."
      contentWidth={contentWidth}
      button={isActiveKbContract ? 'single' : 'double'}
      okText={isActiveKbContract ? '닫기' : '전략 편집하기'}
      secondText="닫기"
      onClickOk={onOk}
    >
      <Typography variant="body1_title" color="black.47">
        최소 5개 종목 이상으로 포트폴리오를 다시 구성해 주세요. 포트폴리오는
        기초 전략의 구성종목*이 3개 이상 포함되어야 합니다.
        <br />
        <br />* 기초 전략의 투자 기준을 만족하는 종목 중 사용자가 추가 또는
        비중을 조정하지 않은 종목
      </Typography>
    </StockEmptyBottomSheetWrap>
  );
};
