import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../Flexbox';

interface ToggleButtonProps {
  checked?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  initChecked?: boolean;
  labelPosition?: 'left' | 'right';
  onChange?: (checked: boolean) => void;
  onClickDisabled?: () => void;
}

const ToggleButtonWrap = styled(FlexBox)``;

export const Label = styled.label<{ disabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};

  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colorPalette.divider.appbar_tab};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 12px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.colorPalette.kb.yellow};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

//TODO: 넘겨주는 값에 따라서 checked값을 변경하고 disabled일때는 아무런 콜백하무도 동작하지 않는게 좋지 않을까 생각이 듭니다.
export const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  disabled = false,
  initChecked = false,
  labelPosition = 'right',
  onChange,
  onClickDisabled,
}) => {
  const [_checked, setChecked] = useState(initChecked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (disabled) {
      if (onClickDisabled) onClickDisabled();
      return;
    }
    setChecked((v) => !v);
    if (onChange) onChange(e.target.checked);
  };

  useEffect(() => {
    setChecked(initChecked);
  }, [initChecked]);

  return (
    <ToggleButtonWrap alignItems="center" spacing={6}>
      {labelPosition === 'left' && children}
      <Label disabled={disabled}>
        <input type="checkbox" checked={_checked} onChange={handleChange} />
        <span className="slider" />
      </Label>
      {labelPosition === 'right' && children}
    </ToggleButtonWrap>
  );
};
