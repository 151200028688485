import { SimulationTypeEnum } from '@graphql/generates';
import { SimulationType } from '@models/strategy';

export const SIMULATION_TYPE_ENUM = [
  SimulationTypeEnum.All,
  SimulationTypeEnum.Backtest,
  SimulationTypeEnum.Forwardtest,
] as const;

export const SIMULATION_TYPE_MAPPER: Record<SimulationType, SimulationType> = {
  ALL: SimulationTypeEnum.All,
  BACKTEST: SimulationTypeEnum.Backtest,
  FORWARDTEST: SimulationTypeEnum.Forwardtest,
};
