import { theme } from '@styles/theme';
import { numberToPercent } from '@utils/common';

export const RebalanceTick = (props: any) => {
  const { x, y, payload, visibleTicksCount, index } = props;

  let customX = 0;

  if (visibleTicksCount > 2) {
    if (index === 0 || index === visibleTicksCount - 1) {
      customX = index === 0 ? 12 : -16;
    } else {
      return null;
    }
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={customX}
        y={0}
        dy={16}
        textAnchor="middle"
        fontSize={12}
        fontWeight={300}
        fill={theme.colorPalette.black[40]}
      >
        {`${payload.value}`.slice(2)}
      </text>
    </g>
  );
};

export const DateTickComp = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fontSize={12}
        fontWeight={300}
        fill={theme.colorPalette.black[40]}
      >
        {`${payload.value}`.slice(2, 7)}
      </text>
    </g>
  );
};

export const PercentTickFormat = (props: any) => {
  const { x, y, payload, decimal } = props;

  let resultStr = '';
  resultStr += numberToPercent(payload.value);
  if (!decimal) {
    resultStr = `${parseInt(resultStr)}`;
  }
  resultStr += '%';

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        dx={-28}
        textAnchor="start"
        fontSize={10}
        fontWeight={300}
        fill={theme.colorPalette.black[40]}
      >
        {resultStr}
      </text>
    </g>
  );
};

export const RadarTick = (props: any) => {
  const { index, x, y, cx, cy, orientation, payload, radius, textAnchor } =
    props;
  const exceptionIndex = new Set([4, 5]).has(index);
  const values: string[] = exceptionIndex
    ? [payload.value]
    : payload.value.split(' ');
  const initDy = exceptionIndex ? 0.5 : 0;

  return (
    <text
      x={x}
      y={y}
      cx={cx}
      cy={cy}
      orientation={orientation}
      textAnchor={textAnchor}
      radius={radius}
      fill={theme.colorPalette.black[87]}
      fontSize={12}
      fontWeight={300}
    >
      {values.map((value, vIndex) => {
        const _dy = initDy || vIndex * 1.5;
        return (
          <tspan key={`radar_tspan_${vIndex}`} x={x} dy={`${_dy}em`}>
            {value}
          </tspan>
        );
      })}
    </text>
  );
};
