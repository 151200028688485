import {
  GetFactorsQueryVariables,
  GetStyleFactorsQueryVariables,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import { HistogramModel } from '@models/common';
import {
  FactorInfoItemModel,
  FactorInputDto,
  RawFactorModel,
  StrategyFactorType,
  StyleFactorInfoItemModel,
  StyleFactorPresetType,
  TopPercentType,
} from '@models/strategy';
import { StateCreator } from 'zustand';
import { StrategyEditState } from '../strategy-edit.store';
import {
  convertFactorstoList,
  convertHistograms,
  convertMyFactorsToList,
  convertMyStyleFactors,
  convertStrategyToSimulationOption,
  convertStyleFactors,
} from './factor-edit.convert';
import { createTickRange } from './factor-edit.helper';
import { createInitFactorEditState } from './factor-edit.init';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';

export interface FactorEditSliceState {
  /** (상세 편집) 팩터 선택 단계 */
  /** 팩터 불러오기 */
  factors: FactorInfoItemModel[];
  /** 하위 팩터 선택여부 */
  factorCheckObj: Record<string, boolean>;
  /** 팩터 토글 여부 객체 */
  factorToggleObj: Record<StrategyFactorType, boolean>;
  /** (상세 편집) 팩터 조정 단계 */
  /** SimulationOptionScreeningInput "factors" 파라미터 객체 */
  inputFactors: FactorInputDto[];
  /** Backend 팩터 Raw 데이터 */
  rawFactors: RawFactorModel[];
  /** 팩터 조정 Histograms 렌더링 객체 */
  histograms: HistogramModel[];
  /** (간편 편집) 간편 팩터 관련 */
  styleFactors: StyleFactorInfoItemModel[];
  stylePresetObj: StyleFactorPresetType;
}

export interface FactorEditSlice extends FactorEditSliceState {
  setInputFactors: (factorCheckObj: Record<string, boolean>) => void;
  updateInputFactor: (
    queryObj: FactorInputDto,
    topPercent?: TopPercentType
  ) => void;
  fetchFactors: (queryObj: GetFactorsQueryVariables) => void;
  setFactorCheckObj: (cmName: string) => void;
  setToggleFactorObj: (factor: StrategyFactorType) => void;
  fetchMyFactors: (myStrategyId: string) => {};
  updateStyleFactors: (item: StyleFactorInfoItemModel) => void;
  fetchStyleFactors: (queryObj: GetStyleFactorsQueryVariables) => void;
  fetchMyStyleFactors: (myStrategyId: string) => {};
  fetchDefaultOptions: (strategyId: string) => void;
}

export const createFactorEditSlice: StateCreator<
  StrategyEditState,
  [],
  [],
  FactorEditSlice
> = (set, get) => ({
  /** (상세 편집) 팩터 선택 단계 */

  ...createInitFactorEditState(),

  setInputFactors: (factorCheckObj) => {
    const { rawFactors, inputFactors, histograms } = get();
    const { histograms: _histograms, inputFactors: _inputFactors } =
      convertHistograms(factorCheckObj, rawFactors, inputFactors, histograms);
    set(() => ({ histograms: _histograms, inputFactors: _inputFactors }));
  },
  updateInputFactor: ({ cmName, max, min }, topPercent) => {
    const { histograms, inputFactors } = get();
    const _histograms = histograms.map((histogram) => {
      if (histogram.cmName === cmName) {
        const initRange = createTickRange(
          { min, max },
          histogram.rawHistograms
        );
        return {
          ...histogram,
          max,
          min,
          initRange,
          // TODO: 상위n% 버튼 재활성화 시 원복
          // selectedTopPercent: topPercent,
        };
      }
      return histogram;
    });
    const _inputFactors = inputFactors.map((inputFactor) => {
      if (inputFactor.cmName === cmName) {
        return {
          ...inputFactor,
          min,
          max,
        };
      }
      return inputFactor;
    });
    set(() => ({
      histograms: _histograms,
      inputFactors: _inputFactors,
    }));
  },

  updateStyleFactors: (factor) => {
    const { styleFactors } = get();
    const newStyleFactors = [...styleFactors];
    const index = newStyleFactors.findIndex(
      (styleFactor) => styleFactor.cmName === factor.cmName
    );

    if (index >= 0) {
      newStyleFactors[index].type = factor.type;
    }
    set(() => ({
      styleFactors: newStyleFactors,
      whitelistObj: {},
    }));
  },
  fetchStyleFactors: async (queryObj) => {
    const response = await repo.getStyleFactors(queryObj);
    const { styleFactors, stylePresetObj } = convertStyleFactors(response);
    set(() => ({
      styleFactors,
      stylePresetObj,
      beforeMount: false,
    }));
  },
  fetchMyStyleFactors: async (myStrategyId) => {
    const response = await repo.getMyStyleFactors({ myStrategyId });
    const { styleFactors, stylePresetObj } = convertMyStyleFactors(response);
    set(() => ({
      styleFactors,
      stylePresetObj,
    }));
  },
  fetchFactors: async (queryObj) => {
    const response = await repo.getFactors(queryObj);
    const factorsInfo = convertFactorstoList(response);
    if (factorsInfo) {
      const { factorList, checkObj, rawFactors } = factorsInfo;
      set(() => ({
        factors: factorList,
        factorCheckObj: checkObj,
        rawFactors,
        beforeMount: false,
      }));
    }
  },
  fetchMyFactors: async (myStrategyId) => {
    const response = await repo.getMyFactors({ myStrategyId });
    const factorsInfo = convertMyFactorsToList(response);
    if (factorsInfo) {
      const { factorList, checkObj, rawFactors, inputFactors } = factorsInfo;
      const { histograms: _histograms } = convertHistograms(
        checkObj,
        rawFactors,
        inputFactors,
        []
      );
      set(() => ({
        factors: factorList,
        factorCheckObj: checkObj,
        rawFactors,
        inputFactors,
        histograms: _histograms,
      }));
    }
  },

  setFactorCheckObj: (cmName) => {
    const newFactorCheckObj = {
      ...get().factorCheckObj,
      [cmName]: !get().factorCheckObj[cmName],
    };
    get().setInputFactors(newFactorCheckObj);
    set(() => ({
      factorCheckObj: newFactorCheckObj,
    }));
  },

  setToggleFactorObj: (factor) => {
    const newToggleObj = {
      ...get().factorToggleObj,
      [factor]: !get().factorToggleObj[factor],
    };

    set(() => ({ factorToggleObj: newToggleObj }));
  },
  fetchDefaultOptions: async (strategyId) => {
    const { setOptimization, setRebalancing } = get();
    const response = await useReadymadeStrategyStore
      .getState()
      .fetchStrategy(strategyId, false);
    const { optimization, rebalancing } =
      convertStrategyToSimulationOption(response);
    setOptimization(optimization);
    setRebalancing(rebalancing);
  },
});
