import { FlexBox, Typography } from '@ui/components';
import React, { FC } from 'react';

interface NoMatchPageProps {}

export const NoMatchPage: FC<NoMatchPageProps> = () => {
  return (
    <div style={{ height: '100dvh' }}>
      <FlexBox
        direction="column"
        justify="center"
        alignItems="center"
        height="100%"
      >
        <img src={`/icon/file-permission.svg`} alt="file permission" />
        <Typography variant="body1_title" mt={14}>
          잘못된 접근 경로입니다.
        </Typography>
        <FlexBox direction="column" justify="center" alignItems="center">
          <Typography variant="caption_medium" color="gray.blue">
            페이지가 없거나 잘못된 경로입니다.
          </Typography>
          <Typography variant="caption_medium" color="gray.blue">
            경로를 다시 확인하시고 이용해 주시기 바랍니다.
          </Typography>
        </FlexBox>
      </FlexBox>
    </div>
  );
};
