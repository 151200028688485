import { ScThemedTextProps } from '@ui/model/ui.scType';
import {
  getFontColorFromProps,
  getFontStyleByVariantProps,
  getFontWeightFromProps,
  getLineHeightFromProps,
  getPaddingMarginFromProps,
  getTextAlignFromProps,
  getTextOverflowFromProps,
  getWhiteSpaceFromProps,
  getWordBreakFromProps,
} from '@ui/util';
import styled from 'styled-components';

export const BaseStyledText = styled.p<ScThemedTextProps>`
  ${getFontColorFromProps}
  ${getLineHeightFromProps}
  ${getFontStyleByVariantProps}
  ${getFontWeightFromProps}
  ${getTextAlignFromProps}
  ${getTextOverflowFromProps}
  ${getWhiteSpaceFromProps}
  ${getWordBreakFromProps}
  ${getPaddingMarginFromProps}
`;
