import {
  FactorsTypeInfoFragment,
  HistogramItemInfoFragment,
} from '@graphql/generates';
import {
  HistogramChartDataModel,
  HistogramModel,
  RawHistogramModel,
} from '@models/common';
import {
  FactorInfoItemModel,
  FactorInputDto,
  RawFactorModel,
  StrategyFactorType,
} from '@models/strategy';
import { filterNonDataOfArray } from '@utils/common';

/**
 * 팩터 관련 데이터 생성 함수.
 * @param {Object[]} factors - factors | myFactors 응답 데이터.
 * @param {Object} factors[].style - 팩터 스타일 정보.
 * @param {Object[]} factors[].factors - 팩터 정보.
 * @returns {Object}
 */
export const createFactors = (
  factors: Array<FactorsTypeInfoFragment | null>
) => {
  let checkObj: Record<string, boolean> = {};
  /** ViewModel Factors 데이터 */
  const factorList: FactorInfoItemModel[] = [];
  /** DB Entity Factors 데이터 */
  const rawFactors: RawFactorModel[] = [];
  /** 이전에 선택한 InputFactors */
  const inputFactors: FactorInputDto[] = [];
  /** 이전에 선택한 Histograms */
  const histograms: HistogramModel[] = [];

  if (factors) {
    factors.forEach((factor) => {
      /** 상위 팩터 */
      if (factor?.style && factor.style.style) {
        const factorStyle = factor.style;
        let newFactorItem: FactorInfoItemModel = {
          desc: factorStyle.description || '',
          name: factorStyle.style as StrategyFactorType,
          text: factorStyle.name || '-',
          subs: [],
        };

        if (factor.factors && factor.factors.length > 0) {
          factor.factors.forEach((subFactor) => {
            if (subFactor) {
              const commentList: string[] = [];
              const {
                cmName,
                displayName,
                description,
                defaultValueInfo,
                histogramData,
                ignoreNegative,
                min,
                max,
                img,
                comments,
              } = subFactor;

              if (comments) {
                comments.forEach((comment) => {
                  if (comment) {
                    commentList.push(comment);
                  }
                });
              }

              newFactorItem.subs.push({
                desc: description || '',
                min,
                max,
                name: displayName,
                cmName,
                img,
                comments: commentList,
              });

              const _histogramData = parseRawHistogram(histogramData);
              const rawFactor: RawFactorModel = {
                cmName,
                desc: description || '',
                name: displayName,
                max,
                min,
                histogramData: _histogramData,
                ignoreNegative,
                img,
                comments: commentList,
              };
              rawFactors.push(rawFactor);

              checkObj = Object.assign(checkObj, {
                [cmName]: defaultValueInfo?.isChecked || false,
              });

              if (defaultValueInfo && defaultValueInfo.isChecked) {
                // myFactors Query의 경우 defaultValueInfo 존재
                // 사용자가 전략 생성 당시 특정 팩터 값을 선택했다면
                // defaultValueInfo.isChecked === true
                const { max, min } = defaultValueInfo;
                if (typeof max === 'number' && typeof min === 'number') {
                  const prevInputFactor: FactorInputDto = {
                    cmName,
                    max,
                    min,
                  };
                  const prevHistogram = createHistogram(
                    rawFactor,
                    prevInputFactor
                  );
                  inputFactors.push(prevInputFactor);
                  histograms.push(prevHistogram);
                }
              }
            }
          });
        }

        factorList.push(newFactorItem);
      }
    });
  }

  return { factorList, checkObj, rawFactors, inputFactors, histograms };
};

/**
 * histogram 데이터 생성 함수.
 * @param {Object} rawFactor - 백엔드 팩터 Raw 데이터.
 * @param {string} rawFactor.cmName - 팩터 CM명. 팩터 식별에 활용.
 * @param {Object} rawFactor.histogramData - 히스토그램 Raw 데이터.
 * @param {Object} rawFactor.histogramData.factorRange - 히스토그램 Raw 데이터.
 * @param {Object} rawFactor.histogramData.range - 히스토그램 Winsorized 데이터.
 * @param {Object} rawFactor.histogramData.high - 히스토그램 상위 Winsorized 데이터.
 * @param {number[]} rawFactor.histogramData.stickCounts - 히스토그램 종목 분포 데이터. 차트에 활용.
 * @param {number} rawFactor.max - 전체 유니버스 범위에 대한 팩터 Max 값.
 * @param {number} rawFactor.min - 전체 유니버스 범위에 대한 팩터 Min 값.
 * @param {string} rawFactor.name - 팩터명.
 * @param {string} rawFactor.desc - 팩터 설명글. Info 모달에 표시됨.
 * @param {boolean} rawFactor.ignoreNegative - 팩터 음수값 무시 여부.
 * @param {number} rawFactor.img - 팩터 설명 이미지 Path No. Public의 이미지 경로에 활용됨.
 * @param {string[]} rawFactor.comments - 팩터 부연설명글. Info 모달에 표시됨.
 * @param {Object} prevInputFactor - SimulationOptionScreeningInput "factors" 데이터.(이전에 선택한 팩터 데이터.)
 * @param {string} prevInputFactor.cmName - 선택된 팩터 CM명. 팩터 식별에 활용.
 * @param {number} prevInputFactor.max - 선택된 팩터의 Max 값.
 * @param {number} prevInputFactor.min - 선택된 팩터의 Min 값.
 * @returns {Object}
 */
export const createHistogram = (
  rawFactor: RawFactorModel,
  prevInputFactor?: FactorInputDto
) => {
  const {
    cmName,
    histogramData,
    max,
    min,
    name,
    desc,
    ignoreNegative,
    img,
    comments,
  } = rawFactor;
  let histogramChartData: HistogramChartDataModel[] = [];
  let maxLimit = max;
  let minLimit = min;
  let highValue = { min, max };
  let middleValue = { min, max };
  let lowValue = { min, max };
  let highRange: number[] = [];
  let middleRange: number[] = [];
  let lowRange: number[] = [];
  let initRange: number[] = [];
  let initMax = max;
  let initMin = min;
  // let selectedTopPercent: TopPercentType | undefined = undefined;

  /**
   * Histogram Chart Range(initRange)에 매핑되는 데이터
   *
   * min, max 값을 제외한 나머지는 winsorized 데이터에 기반.
   * [factorRange.min, ...winsorized, factorRange.max]
   * */
  let rawHistograms: number[] = [];

  if (histogramData) {
    const { stickCounts, range, factorRange, low, middle, high } =
      histogramData;
    const { min: rangeMin, max: rangeMax } = range;
    const commonDifference = (rangeMax - rangeMin) / (100 - 1);
    rawHistograms = Array(100)
      .fill(undefined)
      .map((_, index) => rangeMin + commonDifference * index);

    /** 연산 중 오차가 발생할 수 있어서 최소/최댓값을 직접 매칭 */
    rawHistograms.shift();
    rawHistograms.unshift(factorRange.min);
    rawHistograms.pop();
    rawHistograms.push(factorRange.max);

    maxLimit = rangeMax;
    minLimit = rangeMin;
    highValue = { ...high };
    middleValue = { ...middle };
    lowValue = { ...low };
    highRange = createTickRange(high, rawHistograms);
    middleRange = createTickRange(middle, rawHistograms);
    lowRange = createTickRange(low, rawHistograms);

    histogramChartData = createHistogramChartData(stickCounts);

    /** 초기값 세팅: 상위20%(high) */
    initRange = highRange;
    initMax = high.max;
    initMin = high.min;
    // selectedTopPercent = 'high';
  }

  if (prevInputFactor) {
    const { max: prevMax, min: prevMin } = prevInputFactor;
    const initValue = { max: prevMax, min: prevMin };
    initMax = prevMax;
    initMin = prevMin;
    // selectedTopPercent = createSelectedTopPercent(initValue, {
    //   highValue,
    //   middleValue,
    //   lowValue,
    // });
    initRange = createTickRange(initValue, rawHistograms);
  }

  const histogram: HistogramModel = {
    cmName,
    displayName: name,
    description: desc,
    rawHistograms,
    minLimit,
    maxLimit,
    lowRange,
    lowValue,
    middleRange,
    middleValue,
    highRange,
    highValue,
    initRange,
    max: initMax,
    min: initMin,
    // selectedTopPercent,
    histogramChartData,
    ignoreNegative,
    comments,
    img,
  };
  return histogram;
};

/**
 * HistogramItemInfoFragment 데이터를 RawHistogramModel로 파싱 함수.
 * @param {Object} data - HistogramItemInfoFragment 데이터
 * @param {Object} rawFactor.histogramData.factorRange - 히스토그램 Raw 데이터.
 * @param {Object} rawFactor.histogramData.range - 히스토그램 Winsorized 데이터.
 * @param {Object} rawFactor.histogramData.high - 히스토그램 상위 Winsorized 데이터.
 * @param {Object} rawFactor.histogramData.middle - 히스토그램 중위 Winsorized 데이터.
 * @param {Object} rawFactor.histogramData.low - 히스토그램 하위 Winsorized 데이터.
 * @param {number[]} rawFactor.histogramData.stickCounts - 히스토그램 종목 분포 데이터. 차트에 활용.
 * @returns {Object}
 */
export const parseRawHistogram = (
  data: HistogramItemInfoFragment | null | undefined
): RawHistogramModel | null => {
  if (!data) return null;
  const { stickCounts, range, factorRange, low, middle, high } = data;
  return {
    range: { min: range ? range.min || 0 : 0, max: range ? range.max || 0 : 0 },
    factorRange: {
      min: factorRange ? factorRange.min || 0 : 0,
      max: factorRange ? factorRange.max || 0 : 0,
    },
    low: { min: low ? low.min || 0 : 0, max: low ? low.max || 0 : 0 },
    middle: {
      min: middle ? middle.min || 0 : 0,
      max: middle ? middle.max || 0 : 0,
    },
    high: { min: high ? high.min || 0 : 0, max: high ? high.max || 0 : 0 },
    stickCounts: filterNonDataOfArray(stickCounts),
  };
};

/**
 * 이전 인덱스의 값과 비교하여 타겟 값과의 차이가 더 적은 값의 인덱스를 반환
 * @param {number[]} rawData - rawHistograms 데이터.
 * @param {number} index - 현재 인덱스.
 * @param {number} difference - 타겟 값.
 * @returns
 */
export const compareWithPrev = (
  rawData: number[],
  index: number,
  difference: number
) => {
  // 비교 대상이 없는 경우
  if (index - 1 < 0) return index;
  const result =
    Math.abs(rawData[index] - difference) -
    Math.abs(rawData[index - 1] - difference);
  if (result <= 0) {
    // 타겟값과의 차이가
    // 이전 인덱스 값에 비해 현재 값이 더 작거나(더 가까운 경우) 서로 같은 경우
    // 현재 인덱스 반환
    return index;
  }
  return index - 1;
};

/**
 * 팩터의 min, max 값을 토대로 팩터 슬라이더 범위 구하는 함수.
 * min과 max 값을 배열로 묶어 반환.
 * @param {Object} range - 팩터 min, max 값.
 * @param {number[]} rawData - rawHistograms 데이터.
 * @returns {number[]}
 */
export const createTickRange = (
  range: { min: number; max: number },
  rawData: number[] // 오름차순 배열
) => {
  const { min, max } = range;
  let minIndex = 0;
  let maxIndex = 99;
  const rawDataLength = rawData.length;

  for (let i = 0; i < rawDataLength; i++) {
    if (rawData[i] === min) {
      minIndex = i;
      break;
    } else if (rawData[i] > min) {
      minIndex = compareWithPrev(rawData, i, min);
      break;
    }
  }
  for (let i = minIndex; i < rawDataLength; i++) {
    if (rawData[i] === max) {
      maxIndex = i;
      break;
    } else if (rawData[i] > max) {
      maxIndex = compareWithPrev(rawData, i, max);
      break;
    }
  }

  return [minIndex, maxIndex];
};

// TODO: 상위n% 버튼 재활성화 시 원복
// export const createSelectedTopPercent = (
//   value: FactorValueDto,
//   setValues: {
//     highValue: FactorValueDto;
//     middleValue: FactorValueDto;
//     lowValue: FactorValueDto;
//   }
// ): TopPercentType | undefined => {
//   const { highValue, middleValue, lowValue } = setValues;
//   const compare = (value1: FactorValueDto, value2: FactorValueDto) => {
//     return value1.max === value2.max && value1.min === value2.min;
//   };
//   if (compare(value, highValue)) return 'high';
//   else if (compare(value, middleValue)) return 'middle';
//   else if (compare(value, lowValue)) return 'low';
// };

/**
 * 히스토그램 차트 데이터 생성 함수.
 * @param {number[]} data - 히스토그램 차트 데이터.
 * @returns {Object[]}
 */
export const createHistogramChartData = (data: number[]) => {
  const max = Math.max(...data);
  const newData: HistogramChartDataModel[] = data.map((histogram) => {
    let height = Math.floor((histogram * 100) / max);
    if (height < 4 && histogram !== 0) {
      height = 4; // 최소 높이
    }
    return {
      height,
      volume: histogram,
    };
  });
  return newData;
};
