import { MAX_WIDTH, MIN_WIDTH } from '@constants/layout.const';
import {
  COLOR_PALETTE,
  FONT_SIZE_DICTIONARY,
  FONT_WEIGHT_DICTIONARY,
  LINE_HEIGHT_DICTIONARY,
  SPACING_SIZE_DICTIONARY,
  Z_INDEX_DICTIONARY,
  DARK_COLOR_PALETTE,
} from '@ui/constants';
import { isDarkMode } from '@utils/common';

const baseTheme = {
  size: FONT_SIZE_DICTIONARY,
  lineHeight: LINE_HEIGHT_DICTIONARY,
  spacing: SPACING_SIZE_DICTIONARY,
  zIndex: Z_INDEX_DICTIONARY,
  fontWeight: FONT_WEIGHT_DICTIONARY,
  layout: { minWidth: `${MIN_WIDTH}px`, maxWidth: `${MAX_WIDTH}px` },
};

export const lightTheme = {
  ...baseTheme,
  colorPalette: COLOR_PALETTE,
};

export const darkTheme = {
  ...baseTheme,
  colorPalette: DARK_COLOR_PALETTE,
};

export const theme = isDarkMode() ? darkTheme : lightTheme;

export type ThemeType = typeof theme;
