import { KB_ERROR_MAPPER } from '@constants/error.const';
import {
  BACKTEST_CREATION_ERROR,
  KB_API_ERROR,
  SYSTEM_ERROR,
} from '@constants/feedback';
import { ExceptionType } from '@models/error.model';
import { useConfirmStore } from '@stores/feedback';

/**
 * 에러 객체를 넘겨받아 에러 타입을 반환하는 함수.
 * @param {Object} error - 에러 객체.
 * @returns {string}
 */
export const createErrorType = (error: Error) => {
  let errorType: ExceptionType = 'system';

  if (error.message === KB_ERROR_MAPPER.KB_API_REQ_FAIL) {
    errorType = 'kb-api';
  } else if (error.message === KB_ERROR_MAPPER.DUPLICATED) {
    /** 동일명 에러의 경우 컴포넌트 단에서 에러 캐치 */
    errorType = 'kb-duplicated';
  } else if (error.message === 'Network request failed') {
    errorType = 'network';
  }

  return errorType;
};

/**
 * 기본 예외처리 콜백함수.
 * @param {Object} error - 에러 객체.
 * @returns {void}
 */
export const callbackException = (error: Error) => {
  const { closeConfirm, openConfirm } = useConfirmStore.getState();
  closeConfirm('loading');

  switch (createErrorType(error)) {
    case 'kb-api': {
      openConfirm('alert')({ ...KB_API_ERROR });
      break;
    }
    case 'kb-duplicated': {
      // confirm 호출하지 않음
      break;
    }
    case 'network': {
      openConfirm('alert')({ ...BACKTEST_CREATION_ERROR });
      break;
    }
    default: {
      openConfirm('alert')({ ...SYSTEM_ERROR });
    }
  }
};

/**
 * 콜백함수를 실행하고, 에러를 throw 하는 예외처리 함수.
 * @param {Object} error - 에러 객체.
 * @callback replaceCallback - 에러 발생시 실행할 콜백함수.
 * @param {Object} err - 에러 객체.
 * @returns {void}
 */
export const handleException = (
  error: Error,
  replaceCallback?: (err?: Error) => void
) => {
  const callback = replaceCallback || callbackException;
  callback(error);

  throw new Error(error.message);
};
