import { BaseStyledTableBody } from '@ui/base';
import { ThemedTableBodyProps, UIHTMLComponentProps } from '@ui/model';
import { ComponentType, forwardRef } from 'react';

interface TableBodyProps
  extends UIHTMLComponentProps<HTMLTableSectionElement>,
    ThemedTableBodyProps {}

export const TableBody: ComponentType<TableBodyProps> = forwardRef<
  HTMLTableSectionElement,
  TableBodyProps
>(({ ...props }, ref) => <BaseStyledTableBody {...props} ref={ref} />);

TableBody.displayName = 'TableBody';
