import { COUNTRY_MAPPER } from '@constants/common';
import { LevelTwoContainer } from '@containers/strategy-edit/beginner/LevelTwoContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const MyUsStrategyBeginner2EditPage: FC = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <LevelTwoContainer
      renew
      prevStrategyId={prevStrategyId}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
