import { GetHandleProps, SliderItem } from 'react-compound-slider';
import styled, { css } from 'styled-components';
import { Icon } from '@ui/components';

interface HandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disabled?: boolean;
}

interface HandleTabProps {
  percent: number;
}

const HandleBackground = styled.div<HandleTabProps>`
  ${({ percent }) => {
    return css`
      left: ${percent}%;
    `;
  }}
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 26px;
  height: 36px;
  cursor: pointer;
  background-color: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const HandleTap = styled.div<HandleTabProps>`
  ${({ percent }) => {
    if (percent) {
      return css`
        left: ${percent}%;
      `;
    }
  }}
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 26px;
  height: 36px;

  & > img {
    width: 26px;
    height: 36px;
  }
`;

export const Handle: React.FC<HandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => {
  return (
    <>
      <HandleBackground percent={percent} {...getHandleProps(id)} />
      <HandleTap
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        percent={percent}
      >
        <Icon type="slider-handle" />
      </HandleTap>
    </>
  );
};
