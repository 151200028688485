import { ThemedComponentProps } from '@ui/model';
import styled, { css, keyframes } from 'styled-components';

interface HistogramHeightProps {
  height?: number;
}

interface HistogramSelectedProps {
  selected?: boolean;
}

interface HistogramChartItemProps
  extends HistogramHeightProps,
    HistogramSelectedProps {}

const animation = (height: number) => keyframes`
  from {
    height: 0%
  }
  to {
    height: ${height}%
  }
`;

/** 히스토그램 바 높이 */
const histogramHeight = ({ height }: HistogramHeightProps) => {
  if (height === 0) {
    return css`
      height: 100%;
    `;
  }
  if (height) {
    return css`
      height: ${height}%;
      animation: ${animation(height)} 0.5s ease;
    `;
  }
};

/** 히스토그램 바 색상 */
const histogramBarColor = ({
  height,
  selected,
  theme,
}: HistogramHeightProps & HistogramSelectedProps & ThemedComponentProps) => {
  if (height === 0) {
    return css`
      background-color: transparent;
      border: 1px dashed ${theme.colorPalette.divider.title};
    `;
  }
  if (selected) {
    return css`
      background-color: ${theme.colorPalette.kb.yellow};
    `;
  }
};

export const HistogramChartItem = styled.div<HistogramChartItemProps>`
  background-color: ${({ theme }) => theme.colorPalette.divider.title};
  width: 24px;
  @media (min-width: 361px) {
    flex: 1;
    width: auto;
  }

  ${histogramHeight}
  ${histogramBarColor}
`;

export const HistogramSingleChartItem = styled.div<HistogramChartItemProps>`
  background-color: ${({ theme }) => theme.colorPalette.divider.title};
  flex: 1;
  width: auto;

  ${histogramHeight}
  ${histogramBarColor}
`;
