import { filterNonDataOfArray } from '@utils/common';
import { createHistogramChartData } from '../factor-edit/factor-edit.helper';
import {
  GetFactorsQuery,
  GetMyFactorsQuery,
  GetMyStrategyQuery,
} from '@graphql/generates';
import { MasterHistogramModel } from '@models/common';
import { MasterInfoModel } from '@models/strategy';

/**
 * GetMyStrategyQuery 응답 데이터를 대가들의전략 기본 정보 state 데이터로 변환
 * @param data GetMyStrategyQuery 응답 데이터
 * @returns {masterInfo, universePreset}
 */
export const convertMyMasterInfo = (data: GetMyStrategyQuery) => {
  // 대가들의전략 기본 정보
  const masterInfo: MasterInfoModel = {
    desc: '',
    shortDesc: '',
    factors: [],
    id: '',
    name: '',
    src: [],
    img: '',
  };
  // 선택된 유니버스 정보
  let universePreset;

  if (data) {
    if (data.myStrategy?.myStrategy) {
      const myStrategy = data.myStrategy.myStrategy;
      const { strategy, universePreset: universeObj } = myStrategy;
      if (universeObj.universePreset) {
        universePreset = universeObj.universePreset;
      }

      masterInfo.shortDesc = strategy?.shortDescription || '';
      masterInfo.desc = strategy?.description || '';
      masterInfo.name = strategy?.name || '';
      masterInfo.id = strategy?.id || '';

      if (strategy?.__typename === 'MasterStrategyType') {
        masterInfo.img = strategy?.img || '';
        masterInfo.factors = [...filterNonDataOfArray(strategy.masterFactors)];
        masterInfo.src = [...filterNonDataOfArray(strategy.masterSrc)];
      }
    }
  }

  return {
    masterInfo,
    universePreset,
  };
};

/**
 * 선택된 유니버스와 전략ID에 해당하는 GetFactorsQuery 응답 데이터를
 * state 데이터로 변환
 * @param data GetFactorsQuery 응답 데이터
 * @returns {masterFactor, masterFactorIndex}
 */
export const convertMasterFactor = (data: GetFactorsQuery) => {
  const resObj: MasterHistogramModel = {
    cmName: '',
    histogramChartData: [],
    range: [],
  };
  let masterFactorIndex = 0;
  const factors = data.factors?.factors;
  if (!factors)
    return {
      masterFactor: resObj,
      masterFactorIndex,
    };

  if (factors.length === 1) {
    const factor = factors[0];
    if (factor?.factors && factor.style) {
      const masterFactor = factor.factors[0];
      const min = masterFactor?.min || 0;
      const max = masterFactor?.max || 0;
      resObj.cmName = masterFactor?.cmName || '';
      resObj.range = [min, max];
      const stickCounts = filterNonDataOfArray(
        masterFactor?.histogramData?.stickCounts
      );
      const histogramChartData = createHistogramChartData(stickCounts);
      resObj.histogramChartData = histogramChartData;

      masterFactorIndex = Math.floor((max + min) / 2);
      if ((max + min) % 2 === 0) {
        masterFactorIndex -= 1;
      }
    }
  }
  return {
    masterFactor: resObj,
    masterFactorIndex,
  };
};

/**
 * 선택된 유니버스와 내 전략ID에 해당하는 GetMyFactorsQuery 응답 데이터를
 * state 데이터로 변환
 * @param data GetMyFactorsQuery 응답 데이터
 * @returns {masterFactor, masterFactorIndex}
 */
export const convertMyMasterFactor = (data: GetMyFactorsQuery) => {
  const resObj: MasterHistogramModel = {
    cmName: '',
    histogramChartData: [],
    range: [],
  };
  let masterFactorIndex = 0;
  const factors = data.myFactors?.myFactors;
  if (!factors) {
    return {
      masterFactor: resObj,
      masterFactorIndex,
    };
  }

  if (factors.length === 1) {
    const factor = factors[0];
    if (factor?.factors && factor.style) {
      const masterFactor = factor.factors[0];
      const min = masterFactor?.min || 0;
      const max = masterFactor?.max || 0;
      resObj.cmName = masterFactor?.cmName || '';
      resObj.range = [min, max];

      masterFactorIndex = Math.floor((max + min) / 2);
      if ((max + min) % 2 === 0) {
        masterFactorIndex -= 1;
      }
      if (
        masterFactor?.defaultValueInfo?.isChecked &&
        masterFactor?.defaultValueInfo?.min
      ) {
        masterFactorIndex = masterFactor.defaultValueInfo.min - 1 || 0;
      }

      const stickCounts = filterNonDataOfArray(
        masterFactor?.histogramData?.stickCounts
      );
      const histogramChartData = createHistogramChartData(stickCounts);
      resObj.histogramChartData = histogramChartData;
    }
  }
  return {
    masterFactor: resObj,
    masterFactorIndex,
  };
};
