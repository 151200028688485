import { MasterFactors } from '@components/strategy';
import { MIN_WIDTH } from '@constants/layout.const';
import { MasterStrategyContent } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FlexBox } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { PopupTemplate } from './PopupTemplate';

interface MasterStrategyPopupProps
  extends MasterStrategyContent,
    ContentWidthModel {}

const PopupWrap = styled(PopupTemplate)``;

export const MasterStrategyPopup: FC<MasterStrategyPopupProps> = ({
  contentWidth = MIN_WIDTH,
  factors,
  title = '상세 투자 전략',
}) => {
  return (
    <PopupWrap contentWidth={contentWidth} title={title} type="masterStrategy">
      <FlexBox py={20}>
        <MasterFactors factors={factors} />
      </FlexBox>
    </PopupWrap>
  );
};
