import { ConfirmType } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { useConfirmStore } from '@stores/feedback';
import { theme } from '@styles/theme';
import { Button, ButtonGroup, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { ButtonModeType } from '@ui/model';
import _ from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Dimmed, FeedbackTemplate } from '../FeedbackTemplate';

interface ConfirmTemplateProps extends ContentWidthModel {
  type: ConfirmType;
  button?: ButtonModeType;
  children?: React.ReactNode;
  disabled?: boolean;
  cancelText?: string;
  okText?: string;
  okDebounceMs?: number;
  title?: string;
  onCancel?: () => void;
  onOk?: () => void;
  /** Ok 버튼 클릭 시 모달 자동 닫힘 Flag */
  autoClose?: boolean;
  zIndex?: number;
}

const ConfirmTemplateWrap = styled(FlexBox)`
  height: 100%;
  width: 100%;
  padding: 39px;
  position: absolute;
`;

const ConfirmInnerWrap = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.white};
  border-radius: 4px;
  width: 100%;
  padding: 32px 16px 16px;
  border: 1px solid ${({ theme }) => theme.colorPalette.black[3]};
`;

const LoadingWrap = styled.div`
  border-radius: 4px;
  width: 100%;
`;

const ConfirmMain = styled.div`
  padding: 0 8px;
`;

const ConfirmFooter = styled.div`
  margin-top: 24px;
`;

export const ConfirmTemplate: FC<ConfirmTemplateProps> = ({
  type,
  button = 'double',
  children,
  contentWidth,
  disabled,
  cancelText = '취소',
  okText = '확인',
  okDebounceMs = 0,
  title,
  onCancel,
  onOk,
  autoClose = true,
  zIndex,
}) => {
  const isDouble = button === 'double';
  const closeConfirm = useConfirmStore((state) => state.closeConfirm);

  const handleBlock = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    closeConfirm(type);
  };

  const handleOk = _.debounce(() => {
    if (onOk) {
      onOk();
    }
    if (autoClose) {
      closeConfirm(type);
    }
  }, okDebounceMs);

  if (type === 'loading') {
    return (
      <FeedbackTemplate
        contentWidth={contentWidth}
        dimmedBackgroundColor="rgba(0,0,0,0.7)"
        dimmedOpacity={0.7}
        zIndex={theme.zIndex.loading}
        onClose={() => closeConfirm(type)}
      >
        <Dimmed dimmedBackgroundColor="rgba(0,0,0,0.7)" dimmedOpacity={0.7} />
        <ConfirmTemplateWrap
          alignItems="center"
          justify="center"
          onClick={handleBlock}
        >
          <LoadingWrap>{children}</LoadingWrap>
        </ConfirmTemplateWrap>
      </FeedbackTemplate>
    );
  }

  return (
    <FeedbackTemplate
      contentWidth={contentWidth}
      zIndex={zIndex}
      onClose={() => closeConfirm(type)}
    >
      <ConfirmTemplateWrap
        alignItems="center"
        justify="center"
        onClick={handleBlock}
      >
        <ConfirmInnerWrap>
          <ConfirmMain>
            {title && (
              <Typography variant="subTitle1_title" color="black.87" mb={16}>
                {title}
              </Typography>
            )}
            {children}
          </ConfirmMain>
          <ConfirmFooter>
            <ButtonGroup>
              {isDouble && (
                <Button size="big" variant="plain" onClick={handleCancel}>
                  {cancelText}
                </Button>
              )}
              <Button
                color="fixedblack"
                size="big"
                disabled={disabled}
                onClick={handleOk}
              >
                {okText}
              </Button>
            </ButtonGroup>
          </ConfirmFooter>
        </ConfirmInnerWrap>
      </ConfirmTemplateWrap>
    </FeedbackTemplate>
  );
};
