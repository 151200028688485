import { MyStrategyCardEventHandlers } from '@models/my-strategy/my-strategy.model';
import { MyStrategyCardDto } from '@models/strategy';
import { Button, FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import { MyStrategyCard } from './MyStrategyCard';
import { EmptyList } from '@components/myStrategy-list/EmptyList';
import styled from 'styled-components';
import { SelectButtonWrap } from '@styles/common';
import { MY_STRATEGY_ORDER_LOCALE } from '@constants/strategy';
import { MyStrategyOrderType } from '@graphql/generates';

const MyStrategyListWrap = styled.div``;

const MakeStrategyButton = styled(Button)`
  border-color: ${({ theme }) => theme.colorPalette.border.chip};
`;

interface MyStrategyListProps {
  orderType: MyStrategyOrderType;
  myStrategyOrder: boolean;
  myStrategyList: MyStrategyCardDto[];
  onCreateStrategy: () => void;
  onChangeOrderType: () => void;
  eventHandlers: (strategy: MyStrategyCardDto) => MyStrategyCardEventHandlers;
}

export const MyStrategyList: FC<MyStrategyListProps> = ({
  orderType,
  myStrategyOrder,
  myStrategyList,
  onCreateStrategy,
  onChangeOrderType,
  eventHandlers,
}) => {
  if (myStrategyList.length === 0) {
    return <EmptyList onClickCreate={onCreateStrategy} />;
  }

  return (
    <MyStrategyListWrap>
      <FlexBox mb={24} spacing={12} mt={24}>
        <SelectButtonWrap
          variant="white"
          open={myStrategyOrder}
          selectedValue={MY_STRATEGY_ORDER_LOCALE[orderType]}
          placeholder="정렬 방식"
          onClick={onChangeOrderType}
          height={36}
        />
        <MakeStrategyButton
          variant="plain"
          height={36}
          onClick={onCreateStrategy}
        >
          <Typography color="black.87" variant="caption_medium">
            전략만들기
          </Typography>
        </MakeStrategyButton>
      </FlexBox>
      <FlexBox direction="column" spacing={16}>
        {myStrategyList.map((item) => {
          return (
            <MyStrategyCard
              key={item.id}
              strategy={item}
              {...eventHandlers(item)}
            />
          );
        })}
      </FlexBox>
    </MyStrategyListWrap>
  );
};
