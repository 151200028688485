/**
 * 객체를 문자열 형태로 직렬화 한다.
 * 만약 값이 문자열이면 그 값 그대로 돌려준다.
 * @param {any} value - 보통 객체 또는 배열이 들어간다.
 * @returns {string} JavaScript Object Notation (JSON) string 반환한다.
 * @group Utils
 */
export const marshalJson = (value: any) => {
  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

/**
 * 특정 문자열의 내용을 확인하여 Object 로 만든다.
 * 첫문자와 마지막 문자를 비교하여 JSON 형태가 아니라면 string을 반환하며,
 * JSON 변환 시 문제가 발생된다면 null을 반환 한다.
 * 그 외 값이 유효하지 않을 경우, 들어온 raw 값을 그대로 반환 한다.
 * @param raw
 * @group Utils
 */
export const unmarshalJson = (raw: string | null): unknown => {
  if (raw) {
    if (/(^\{.*\}$|^\[.*\]$)/.test(raw)) {
      try {
        return JSON.parse(raw) as unknown;
      } catch (error) {
        return null;
      }
    }
  }

  return raw;
};

/**
 * 객체를 복사(Deep Clone)하여 반환한다.
 * 값이 문자열이면 그 값 그래도 반환한다.
 * @param data
 * @group Utils
 */
export const deepClone = <T>(data: T): T => {
  if (typeof data === 'string') {
    return data;
  }

  return JSON.parse(JSON.stringify(data));
};

/**
 * 파라미터 값을 예외처리하여 배열을 반환한다.
 * 파라미터 값이 배열인 경우, 원소값이 null인 데이터를 제외하여 반환한다.
 * 파라미터 값이 null 또는 undefined 인 경우 빈 배열을 반환한다.
 * @param {Array<T | null> | null | undefined} array - 배열(원소가 T 또는 null) 또는 null 또는 undefined
 * @returns {T[]} 배열을 반환한다.
 */
export const filterNonDataOfArray = <T>(
  array: Array<T | null> | null | undefined
) => {
  return (
    (array?.filter((item) => {
      if (typeof item === 'number') return true;
      if (item) return true;
      return false;
    }) as T[]) || []
  );
};

/**
 * 배열을 반환한다.
 * 파라미터 값이 단일값인 경우 배열로 감싸서 반환한다.
 * 배열인 경우 그대로 반환한다.
 * @param {T | Array<T>} data
 * @returns {Array<T>} 를 반환한다.
 */
export const convertSingleValueToArray = <T>(data: T | Array<T>) => {
  return data instanceof Array ? data : [data];
};
