import {
  ScThemedInputProps,
  ScThemedInputWrapperProps,
  ThemedComponentProps,
} from '@ui/model';
import { css } from 'styled-components';

export const getCssInput = () => {
  return css`
    border: 0;
    background-color: transparent;
    caret-color: ${({ theme }) => theme.colorPalette.kb.yellow};
    color: ${({ theme }) => theme.colorPalette.black[100]};

    &::placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colorPalette.black[87]};
      opacity: 0.25;
    }

    &:disabled {
      color: ${({ theme }) => theme.colorPalette.black[87]};
      opacity: 0.25;
    }
  `;
};

export const getCssInputDisabled = ({ theme }: ThemedComponentProps) => {
  return css`
    &:disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }
  `;
};

export const getTextFieldFocusProps = ({
  theme,
  variant,
  isFocus,
}: ThemedComponentProps & ScThemedInputWrapperProps) => {
  if (isFocus) {
    if (variant === 'white') {
      return css`
        border-color: ${theme.colorPalette.kb.gray};
      `;
    }

    return css`
      border-radius: 4px 4px 0px 0px;
    `;
  }
};

export const getCssTextFieldVariantProps = ({
  theme,
  variant,
}: ThemedComponentProps & ScThemedInputWrapperProps) => {
  if (variant === 'white') {
    return css`
      background-color: ${theme.colorPalette.background.input_box};
      border: 1px solid ${theme.colorPalette.border.input};
    `;
  }
};

export const getCssInputFontSize = ({ fontScale }: ScThemedInputProps) => {
  const scale = `scale(${fontScale || 0.9})`;
  return css`
    font-size: 16px;
    transform: ${scale};
    transform-origin: left;
    &::placeholder {
      font-size: 16px !important;
      transform-origin: left;
    }
  `;
};
