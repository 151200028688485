import { FC } from 'react';
import { GetRailProps } from 'react-compound-slider';
import styled from 'styled-components';

interface SliderRailProps {
  getRailProps: GetRailProps;
}

const RailOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: 2px;
  cursor: pointer;
`;

const RailInner = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  transform: translate(0%, -50%);
  border-radius: 2px;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colorPalette.black[13]};
`;

export const SliderRail: FC<SliderRailProps> = ({ getRailProps }) => {
  return (
    <>
      <RailOuter {...getRailProps()} />
      <RailInner />
    </>
  );
};
