import { PerformanceGap } from '@components/feedback/confirm/PerformanceGap';
import { COUNTRY_MAPPER } from '@constants/common';
import {
  BACKTEST_ACCESS_CHECK,
  EXCEEDED_STRATEGY_ALERT_CONTENT,
  NOT_AVAILABLE_VIEW_FORWARD_TEST,
} from '@constants/feedback';
import { MyStrategyCardDto } from '@models/strategy';
import { useAuthStore } from '@stores/auth';
import { useBottomsheetStore, useConfirmStore } from '@stores/feedback';
import { useMyStrategyListStore } from '@stores/my-strategy-list';
import {
  move_back_menu,
  move_my_di_account,
  move_rebalancing,
  tab_selected,
} from '@utils/bridge';
import { handleBlockPopupState } from '@utils/common';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRouterMove } from '..';

export const useMyStrategyListSelector = () => {
  const location = useLocation();
  const nav = useNavigate();
  const {
    pageStatus,
    orderType,
    nationIndex,
    fetchIsBacktestData,
    syncMyStrategy,
    checkIsActiveMyStrategy,
    deleteMyStrategy,
    updateMyStrategyName,
    duplicateMyStrategy,
    changeOrder,
    initState,
    fetchMyStrategies,
    changeNationIndex,
  } = useMyStrategyListStore();

  const {
    moveToBackTest,
    moveToUsBackTest,
    moveToForwardTest,
    moveToUsForwardTest,
    moveToReadyMades,
    moveToUSReadyMades,

    simpleEditRoutes,
    detailEditRoutes,
  } = useRouterMove();

  const { closeConfirm, openConfirm } = useConfirmStore();
  const { isStrategyCreationPossible, isStrategyCreationPossibleUs, fetchMe } =
    useAuthStore();
  const { closeBottomsheet, openBottomsheet } = useBottomsheetStore();

  useEffect(() => {
    if (pageStatus === 'success' || pageStatus === 'error') {
      closeConfirm('loading');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageStatus]);

  useLayoutEffect(() => {
    fetchMe();

    const handlePopState = handleBlockPopupState(move_back_menu);
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopState);

    const urlParams = new URLSearchParams(location.search);
    const tabIndex = urlParams.get('tab');

    if (tabIndex && +tabIndex !== nationIndex) {
      changeNationIndex(+tabIndex);
    } else {
      fetchMyStrategies({
        orderType,
      });
    }

    return () => {
      initState();
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 전략 보관함 국가 탭 클릭 시 실행되는 함수 */
  const handleSwitchCountry = (index: number) => () => {
    if (index === 0 || 1) {
      tab_selected(`${index}`);
      changeNationIndex(index);
      nav(`?tab=${index}`, { replace: true });
    }
  };

  const handleOrder = () => {
    openBottomsheet('myStrategyOrder')({
      order: orderType,
      onClickOption(name) {
        changeOrder(name);
        closeBottomsheet('myStrategyOrder');
      },
    });
  };

  /**
   * '백테스트 결과' 버튼 클릭 시 실행되는 함수
   * 백테스트 진입 가능 여부 조회 후
   * - 가능하면 백테스트로 이동
   * - 불가능하면 알림창 띄움
   */
  const handleClickBackTest =
    (id: string, nationIndex: number) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const pageMove = nationIndex === 0 ? moveToBackTest : moveToUsBackTest;
      e.stopPropagation();
      fetchIsBacktestData(id)
        .then((result) => {
          if (result) {
            pageMove(id);
            return;
          }
          openConfirm('alert')({
            ...BACKTEST_ACCESS_CHECK,
          });
        })
        .catch();
    };

  /**
   * '모의투자' 버튼 클릭 시 실행되는 함수
   */
  const handleClickForward =
    (strategy: MyStrategyCardDto, nationIndex: number) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { id, isForwardAvailable, isActiveKbContract } = strategy;
      const pageMove =
        nationIndex === 0 ? moveToForwardTest : moveToUsForwardTest;
      e.stopPropagation();
      if (isForwardAvailable) {
        if (isActiveKbContract) {
          openConfirm('alert')({
            title: '모의투자와 실제 성과 간의 차이',
            button: 'single',
            bottomContent: <PerformanceGap />,
            onOk: () => {
              pageMove(id);
            },
          });
        } else {
          pageMove(id);
        }
        return;
      }
      openConfirm('alert')({
        ...NOT_AVAILABLE_VIEW_FORWARD_TEST,
      });
    };

  /** '계좌 현황보기' 버튼 클릭 시 실해되는 함수 */
  const handleClickContract = (strategy: MyStrategyCardDto) => () => {
    if (strategy.isActiveKbContract) {
      openConfirm('alert')({
        title: '모의투자와 실제 성과 간의 차이',
        button: 'single',
        bottomContent: <PerformanceGap />,
        onOk: () => {
          move_my_di_account(strategy.portfolioId);
        },
      });
    }
  };

  /** '리밸런싱 하기' 버튼 클릭시 실행되는 함수 */
  const handleClickRebalancing = (strategy: MyStrategyCardDto) => () => {
    move_rebalancing(strategy.portfolioId);
  };

  /**
   * 내 전략의 정보에 맞게 편집 라우트를 처리하는 함수.
   *
   * @param strategy - 내 전략 정보
   * @param nationIndex - 선택된 현재 국가 인덱스
   * @returns {void}
   */
  const editRouteCallback = (
    strategy: MyStrategyCardDto,
    nationIndex: number
  ) => {
    const { id, category, isSimple, investmentStrategyLevel } = strategy;
    const countryCode =
      nationIndex === 0 ? COUNTRY_MAPPER.DOMESTIC : COUNTRY_MAPPER.USA;

    if (isSimple) {
      simpleEditRoutes({
        strategyId: id,
        prevStrategyId: id,
        reEdit: true,
        countryCode,
        investmentStrategyLevel,
      });
      return;
    }

    detailEditRoutes({
      strategyId: id,
      prevStrategyId: id,
      category: category || 'THEME',
      countryCode,
    });
  };

  /** '투자 시작하기' 버튼 클릭 시 실행되는 함수 */
  const handleClickStartContract = (strategy: MyStrategyCardDto) => () => {
    checkIsActiveMyStrategy(strategy.id).then((result) => {
      const { id } = strategy;
      if (!result) {
        openBottomsheet('stockListEmpty')({
          type: 'stockEmpty',
          onOk() {
            editRouteCallback(strategy, nationIndex);
          },
          isActiveKbContract: strategy.isActiveKbContract,
        });
        return;
      }

      syncMyStrategy(id, strategy.portfolioId);
    });
  };

  /** 더보기 버튼 클릭 시 */
  const handleClickOption =
    (strategy: MyStrategyCardDto, nationIndex: number) => () => {
      const { id, title, isActiveKbContract } = strategy;
      openBottomsheet('myStrategyOption')({
        isActiveKbContract,
        onClickOption(name) {
          // 전략 복제
          if (name === 'duplicate') {
            handleDuplicate(id, title);
          }
          // 전략 편집
          if (name === 'edit') {
            handleEdit(strategy, nationIndex);
            closeBottomsheet('myStrategyOption');
          }
          // 전략명 변경
          if (name === 'updateName') {
            handleUpdateName(id, title);
          }
          // 전략 삭제
          if (name === 'delete') {
            handleDelete(id, title);
          }
        },
      });
    };

  /** 더보기 -> '전략 복제' 버튼 클릭시 실행되는 함수 */
  const handleDuplicate = (id: string, title: string) => {
    if (
      (nationIndex === 0 && !isStrategyCreationPossible) ||
      (nationIndex === 1 && !isStrategyCreationPossibleUs)
    ) {
      openConfirm('alert')({
        ...EXCEEDED_STRATEGY_ALERT_CONTENT,
      });
      return;
    }

    openConfirm('textInput')({
      autoClose: false,
      description: '복제할 전략명을 입력해주세요.',
      errorMsg: '동일한 전략명이 있습니다.',
      errorEmptyMsg: '전략명을 입력해주세요.',
      textLimit: 50,
      okDebounceMs: 200,
      title: '전략 복제',
      initValue: title + (title.length > 46 ? '' : '(복제)'),
      onOkPromise: async (name) =>
        await duplicateMyStrategy(id, name).then((myStrategyId) => {
          if (myStrategyId) {
            fetchMe();
            closeConfirm('textInput');
            closeBottomsheet('myStrategyOption');
          }
        }),
    });
  };

  /** 더보기 -> '전략명 변경' 버튼 클릭시 실행되는 함수 */
  const handleUpdateName = (id: string, title: string) => {
    openConfirm('textInput')({
      autoClose: false,
      description: '변경할 전략명을 입력해주세요.',
      errorMsg: '동일한 전략명이 있습니다.',
      errorEmptyMsg: '전략명을 입력해주세요.',
      title: '전략명 변경',
      textLimit: 50,
      initValue: title,
      onOkPromise: async (name) => {
        return await updateMyStrategyName(id, name).then((result) => {
          if (result) {
            closeConfirm('textInput');
            closeBottomsheet('myStrategyOption');
          }
        });
      },
    });
  };

  /** 더보기 -> '전략 삭제' 버튼 클릭시 실행되는 함수 */
  const handleDelete = (id: string, title: string) => {
    openConfirm('alert')({
      descriptions: [
        `[${title}]전략을 삭제하시겠습니까?`,
        '삭제하시면 투자기록을 더 이상 확인하실 수 없습니다.',
      ],
      title: '전략 삭제',
      onOk: async () => {
        deleteMyStrategy(id).then((result) => {
          if (result) {
            fetchMe();
            closeBottomsheet('myStrategyOption');
          }
        });
      },
    });
  };

  /** 더보기 -> '전략 편집' 버튼 클릭시 실행되는 함수*/
  const handleEdit = (strategy: MyStrategyCardDto, nationIndex: number) => {
    editRouteCallback(strategy, nationIndex);
  };

  /** '전략 만들기' 버튼 클릭시 실행되는 함수 */
  const handleCreateStrategy = () => {
    if (nationIndex === 0) {
      moveToReadyMades();
    } else if (nationIndex === 1) {
      moveToUSReadyMades();
    }
  };

  /** Nav바의 '<' 버튼 클릭시 실행되는 함수 */
  const handleClickPrev = () => {
    move_back_menu();
  };

  const myStrategyCardHandler = (strategy: MyStrategyCardDto) => {
    return {
      onClickBackTest: handleClickBackTest(strategy.id, nationIndex),
      onClickForward: handleClickForward(strategy, nationIndex),
      onClickContract: handleClickContract(strategy),
      onClickRebalancing: handleClickRebalancing(strategy),
      onClickStartContract: handleClickStartContract(strategy),
      onClickOption: handleClickOption(strategy, nationIndex),
    };
  };

  return {
    handleSwitchCountry,
    handleClickPrev,
    handleCreateStrategy,
    handleOrder,
    myStrategyCardHandler,
  };
};
