import { SelectOption } from '@components/common';
import { OPTIMIZATION_OPTIONS } from '@constants/common';
import { OptimizationBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface OptimizationBottomsheetProps
  extends OptimizationBottomsheetContentModel,
    ContentWidthModel {}

const OptimizationBottomsheetWrap = styled(BottomsheetTemplate)``;

export const OptimizationBottomsheet: FC<OptimizationBottomsheetProps> = ({
  contentWidth,
  optimization,
  onClickOption,
}) => {
  return (
    <OptimizationBottomsheetWrap
      type="optimization"
      description="투자 전략에 따라 추출된 종목들의 비중을 설정하는 방법을 선택해주세요."
      title="최적화 옵션"
      contentWidth={contentWidth}
    >
      <SelectOption
        options={OPTIMIZATION_OPTIONS}
        selected={optimization}
        onClick={onClickOption}
      />
    </OptimizationBottomsheetWrap>
  );
};
