import { EditHeadTab } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import {
  LESS_THEN_FIVE_STOCKS_ALERT,
  NO_MATCHED_HOLDINGS_ALERT_CONTENT,
} from '@constants/feedback';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useEditController } from '@hooks/edit/useEditController';
import { CountryCodeType } from '@models/common';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { getFloaterModelByStep } from '@utils/strategy';
import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BooksizeOptionContainer } from '../BooksizeOptionContainer';
import { EditCancelBtn } from '../DetailEditPageContainer';
import { SimpleFactorContainer } from '../SimpleFactorContainer';

interface LevelProContainerProps {
  countryCode?: CountryCodeType;
  reEdit?: boolean;
  renew?: boolean;
  prevStrategyId?: string;
}

/**
 * Pro Level 1단계 컨테이너
 */
export const LevelProContainer: FC<LevelProContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
  reEdit = false,
  renew = false,
  prevStrategyId = '',
}) => {
  const {
    holdings,
    fetchStyleFactors,
    fetchDefaultOptions,
    checkHoldingsValidate,
    fetchMyStyleFactors,
    fetchMySimulationOption,
    updateRenewAndReEdit,
    setPrevSimulationOptionScreening,
  } = useStrategyEditStore();
  const { strategyId = '' } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const editStep = searchParams.get('step') || 1;
  const { openConfirm } = useConfirmStore();

  const { handleCreateMyStrategy, handleCancel } = useEditController({
    isSimple: true,
    countryCode,
    reEdit,
    renew,
    prevStrategyId,
    investmentStrategyLevel:
      INVESTMENT_STRATEGY_LEVEL_MAPPER.INTERMEDIATE_LEVEL1,
  });

  const handleComplete = () => {
    checkHoldingsValidate(strategyId, true).then((holdingsCnt) => {
      if (holdingsCnt >= 5) {
        handleCreateMyStrategy();
      } else if (holdingsCnt === 0) {
        openConfirm('alert')({
          ...NO_MATCHED_HOLDINGS_ALERT_CONTENT,
          onOk: handleClickPrev,
        });
      } else {
        openConfirm('alert')({
          ...LESS_THEN_FIVE_STOCKS_ALERT,
          onOk: handleClickPrev,
        });
      }
    });
  };

  const handleClickNext = () => {
    // Check 된 카드가 없으면 PopUp 띄우기
    // step 2번째로 이동시키기
    setSearchParams(`step=${+editStep + 1}`, { replace: true });
  };

  const handleClickPrev = () => {
    setSearchParams(`step=${+editStep - 1}`, { replace: true });
  };

  const handleClickTabButton = (index: number) => () => {
    setSearchParams(`step=${index + 1}`, { replace: true });
  };

  useEffect(() => {
    if (renew || reEdit) {
      Promise.all([
        fetchMyStyleFactors(strategyId),
        fetchMySimulationOption(strategyId),
      ]).then(() => {
        setPrevSimulationOptionScreening(true);
        updateRenewAndReEdit(renew, reEdit);
      });
      return;
    }
    fetchDefaultOptions(strategyId);
    fetchStyleFactors({ country: countryCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/2단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 2,
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleComplete,
      })}
      optionalButton={EditCancelBtn(handleCancel)}
      onClickPrev={handleCancel}
    >
      <EditHeadTab
        step={+editStep}
        type="pro"
        onClickTab={handleClickTabButton}
        enableDesc={holdings.length !== 0}
      />
      {+editStep === 1 && <SimpleFactorContainer />}
      {+editStep === 2 && <BooksizeOptionContainer />}
    </LayoutContainer>
  );
};
