import { BottomsheetCommonModel, BottomsheetType } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { useBottomsheetStore } from '@stores/feedback';
import { Button, ButtonGroup, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { ButtonModeType } from '@ui/model';
import { getCssScroll, getTextOverflow } from '@ui/util';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FeedbackTemplate } from '../FeedbackTemplate';

interface BottomSheetContentProps {
  maxHeight?: number;
}

interface BottomsheetFooterProps {
  buttonMargin?: number;
}

interface BottomsheetTemplateProps
  extends BottomsheetCommonModel,
    BottomSheetContentProps,
    BottomsheetFooterProps,
    ContentWidthModel {
  button?: ButtonModeType;
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  type: BottomsheetType;
}

const BottomSheetInnerWrap = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.white};
  bottom: 0;
  border-radius: 8px 8px 0 0;
  width: 100%;
  position: absolute;
  padding: 36px 24px 24px;
`;

const BottomsheetClosebar = styled(FlexBox)`
  width: 100%;
  position: absolute;
  left: 0;
  top: -20px;
`;

const BottomsheetMain = styled.div`
  padding: 0 8px;
`;

const BottomsheetHeader = styled(FlexBox)``;

const BottomsheetDescription = styled(Typography)`
  ${getTextOverflow(2)}
`;

const BottomsheetContent = styled.div<BottomSheetContentProps>`
  ${({ maxHeight }) => {
    if (maxHeight)
      return css`
        max-height: ${maxHeight}px;
      `;
  }}

  ${getCssScroll()}
`;

const BottomsheetFooter = styled.div<BottomsheetFooterProps>`
  ${({ buttonMargin }) => {
    if (buttonMargin) {
      return css`
        margin-top: ${buttonMargin}px;
      `;
    }
    return css`
      margin-top: 20px;
    `;
  }}
`;

export const BottomsheetTemplate: FC<BottomsheetTemplateProps> = ({
  type,
  button,
  buttonMargin,
  contentWidth,
  children,
  description,
  headerContent,
  okText = '확인',
  secondText = '취소',
  title,
  onClickOk,
  onClickSecond,
}) => {
  const [maxHeight, setMaxHeight] = React.useState<number>(456);
  const closeBottomsheet = useBottomsheetStore(
    (state) => state.closeBottomsheet
  );
  const handleBlock = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleCancel = () => {
    if (onClickSecond) {
      onClickSecond();
    }
    closeBottomsheet(type);
  };

  const handleOk = () => {
    if (onClickOk) {
      onClickOk();
    }
    closeBottomsheet(type);
  };

  React.useLayoutEffect(() => {
    let sub = 0;
    if (description) sub += 38;
    if (button) sub += 84;
    if (sub) {
      setMaxHeight((h) => h - sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeedbackTemplate
      contentWidth={contentWidth}
      onClose={() => closeBottomsheet(type)}
    >
      <BottomSheetInnerWrap onClick={handleBlock}>
        <BottomsheetClosebar justify="center">
          <img src="/icon/close-bar.svg" alt="close" />
        </BottomsheetClosebar>
        <BottomsheetMain>
          {(title || description) && (
            <BottomsheetHeader direction="column" mb={description ? 8 : 20}>
              <FlexBox justify="space-between">
                {title && (
                  <Typography as="h1" variant="h7_title">
                    {title}
                  </Typography>
                )}
                {headerContent}
              </FlexBox>

              {type === 'booksize' && description && (
                <Typography variant="caption_medium" color="state.red" mt={8}>
                  {description}
                </Typography>
              )}

              {type !== 'booksize' && description && (
                <>
                  <BottomsheetDescription
                    variant="body2_content"
                    color="black.47"
                    mt={8}
                  >
                    {description}
                  </BottomsheetDescription>
                  {type === 'optimization' && (
                    <Typography variant="tag" color="gray.blue" mt={4}>
                      *일부 전략의 프리셋 수익률은 동일비중 최적화를 바탕으로
                      제공됩니다.
                    </Typography>
                  )}
                </>
              )}
            </BottomsheetHeader>
          )}
          <BottomsheetContent maxHeight={maxHeight}>
            {children}
          </BottomsheetContent>
        </BottomsheetMain>
        {button && (
          <BottomsheetFooter buttonMargin={buttonMargin}>
            <ButtonGroup>
              {button === 'double' && (
                <Button
                  variant="plain"
                  size="big"
                  color="black.60"
                  onClick={handleCancel}
                >
                  {secondText}
                </Button>
              )}
              <Button size="big" color="fixedblack" onClick={handleOk}>
                {okText}
              </Button>
            </ButtonGroup>
          </BottomsheetFooter>
        )}
      </BottomSheetInnerWrap>
    </FeedbackTemplate>
  );
};
