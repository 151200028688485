import { InfoButton } from '@components/common';
import { ANNUAL_CONVERSION_TOOLTIP } from '@constants/common';
import {
  RiskAssessmentIndexDataType,
  RiskAssessmentIndexModel,
} from '@models/strategy';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  Typography,
} from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled from 'styled-components';

interface RiskAssessmentIndexProps extends RiskAssessmentIndexModel {}

const RiskAssessmentIndexWrap = styled.div``;

const Caption = styled(FlexBox)``;

export const RiskAssessmentIndex: FC<RiskAssessmentIndexProps> = ({
  baseDate,
  head,
  data,
}) => {
  const [active, setActive] = React.useState(false);

  const type: RiskAssessmentIndexDataType = active ? 'year' : 'standard';

  const handleClick = (checked: boolean) => {
    setActive(checked);
  };

  return (
    <RiskAssessmentIndexWrap>
      <Caption alignItems="center" mb={16} spacing={6}>
        <ToggleButton onChange={handleClick}>
          <Typography variant="body2_content" color="black.60">
            연환산 하기
          </Typography>
        </ToggleButton>
        <InfoButton
          title="연환산 하기"
          description={ANNUAL_CONVERSION_TOOLTIP}
        />
      </Caption>
      {baseDate && (
        <Typography variant="caption_medium" color="gray.blue" mb={16}>
          {baseDate}
        </Typography>
      )}
      <Table>
        <TableHead bgColor="background.table">
          <TableRow>
            {head.map(({ text, width }, index) => (
              <TableCell
                key={`risk-assessment-index-head-cell-${index}`}
                variant="caption_medium"
                width={width}
                py={6}
              >
                {text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data[type].map((row, rowIndex) => {
            const topTwo = rowIndex < 2;
            return (
              <TableRow key={`risk-assessment-index-row-${rowIndex}`}>
                {row.map((item, index) => {
                  let bgColor:
                    | 'background.table'
                    | 'background.table_highlight'
                    | undefined = undefined;
                  const isFirst = index === 0;
                  if (isFirst) {
                    bgColor = 'background.table';
                  } else if (!isFirst && topTwo && active) {
                    bgColor = 'background.table_highlight';
                  }
                  return (
                    <TableCell
                      key={`risk-assessment-index-cell-${index}`}
                      bgColor={bgColor}
                      variant={isFirst ? 'caption_medium' : 'body2_content'}
                      py={12}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </RiskAssessmentIndexWrap>
  );
};
