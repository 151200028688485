import { COUNTRY_MAPPER } from '@constants/common';
import { MasterEditPageContainer } from '@containers/strategy-edit/MasterEditPageContainer';
import { FC } from 'react';

interface StrategyMasterEditPageProps {
  reEdit?: boolean;
}
export const UsStrategyMasterEditPage: FC<StrategyMasterEditPageProps> = ({
  reEdit,
}) => {
  return (
    <MasterEditPageContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />
  );
};
