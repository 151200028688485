import {
  EXTERNAL_PAGE_INFO,
  PAGE_INFO,
  PAGE_KR_INFO,
  PAGE_US_INFO,
} from './router.const';

export const GNB_FLAG_MENU = {
  [`/${PAGE_KR_INFO.readymades.page}`]: PAGE_KR_INFO.readymades.name,
  [`/${PAGE_US_INFO['us-readymades'].page}`]:
    PAGE_US_INFO['us-readymades'].name,
};

export const GNB_FLAG_MAPPER = {
  [`/${PAGE_KR_INFO.readymades.page}`]: '/image/nation_kor.svg',
  [`/${PAGE_US_INFO['us-readymades'].page}`]: '/image/nation_usa.svg',
};

export const GNB_MENU = [
  EXTERNAL_PAGE_INFO['direct-indexing'],
  PAGE_KR_INFO.readymades,
  PAGE_US_INFO['us-readymades'],
  PAGE_INFO['my-strategies'],
];

export const GNB_LOCALE = {
  [`/${PAGE_KR_INFO.readymades.page}`]: PAGE_KR_INFO.readymades.name,
  [`/${PAGE_US_INFO['us-readymades'].page}`]:
    PAGE_US_INFO['us-readymades'].name,
  [`/${PAGE_INFO['my-strategies'].page}`]: PAGE_INFO['my-strategies'].name,
};

export const MAX_WIDTH = 430;

export const MIN_WIDTH = 320;
