import {
  STACK_BAR_CHART_BLUE_COLOR,
  STACK_BAR_CHART_ORANGE_COLOR,
} from '@constants/common';
import { ChartColorType, ChartWeightDto } from '@models/common';
import { theme } from '@styles/theme';
import { FC } from 'react';
import { Cell, Pie, PieChart as RechartPieChart } from 'recharts';

interface PieChartProps {
  chartData: ChartWeightDto[];
  color: ChartColorType;
}

export const PieChart: FC<PieChartProps> = ({ chartData, color }) => {
  const colors =
    color === 'blue'
      ? STACK_BAR_CHART_BLUE_COLOR
      : STACK_BAR_CHART_ORANGE_COLOR;
  return (
    <RechartPieChart width={140} height={140}>
      <Pie
        data={chartData}
        dataKey="weight"
        nameKey="name"
        outerRadius={70}
        innerRadius={30}
        startAngle={90}
        endAngle={450}
        stroke={theme.colorPalette.white}
      >
        {chartData.map((_, index) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Pie>
    </RechartPieChart>
  );
};
