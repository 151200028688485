import { HistogramChartModel } from '@models/common';
import { FlexBox } from '@ui/components/Flexbox';
import { FC, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { HistogramChartItem } from './slider-units/HistogramChartItem';

interface HistogramChartProps extends HistogramChartModel {}

const HistogramChartWrap = styled(FlexBox)`
  height: 50px;
`;

const calcIndex = (range: readonly number[]) => {
  return range.map((item) => Math.floor(item / 2));
};

export const HistogramChart: FC<HistogramChartProps> = ({
  histogramChartData = [],
  range = [0, 99],
}) => {
  const [indexRange, setIndexRange] = useState<number[]>([0, 49]);

  useLayoutEffect(() => {
    setIndexRange(calcIndex(range));
  }, [range]);

  return (
    <HistogramChartWrap spacing={1} alignItems="flex-end">
      {histogramChartData.map((item, index) => {
        const [start, end] = indexRange;
        const selected = index >= start && index <= end;
        return (
          <HistogramChartItem
            key={`histogram_chart_${index}`}
            height={item.height}
            selected={selected}
          />
        );
      })}
    </HistogramChartWrap>
  );
};
