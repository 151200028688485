/**
 * 브라우저 뒤로가기 버튼 클릭 시 URL 변경을 막기위한 함수
 * popState 되고 난 이후에 이벤트 리스너가 실행되기 때문에
 * pushState를 먼저 한번 실행해준다.
 * @param e PopStateEvent
 */
export const handleBlockPopupState = (func: Function) => (e: PopStateEvent) => {
  window.history.pushState(null, '', document.URL);
  func();
};
