import { ScThemedButtonProps } from '@ui/model';
import {
  getCssButtonDisabled,
  getCssButtonPressed,
  getCssButtonSizeProps,
  getCssButtonVariantProps,
  getFontColorFromProps,
  getHeightFromProps,
} from '@ui/util';
import styled from 'styled-components';

export const BaseStyledButton = styled.button<ScThemedButtonProps>`
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  ${getCssButtonDisabled}
  ${getCssButtonPressed}
  ${getCssButtonVariantProps}
  ${getCssButtonSizeProps}
  ${getFontColorFromProps}
  ${getHeightFromProps}
`;
