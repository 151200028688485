import { useSearchHoldingData } from '@hooks/edit/stock-selector/useSearchHoldingData';
import { StockSearchContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { useCountryCode } from '@stores/common/common.store';
import { FlexBox } from '@ui/components';
import { TextField } from '@ui/components/TextField';
import { getCssScroll } from '@ui/util';
import { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { PopupTemplate } from './PopupTemplate';
import { StockSearchItem } from './StockSearchItem';

interface StockSearchPopupProps
  extends ContentWidthModel,
    StockSearchContentModel {}

const StockSearchPopupWrap = styled(PopupTemplate)``;

const Items = styled.ul`
  width: 100%;
  height: calc(100vh - 132px);
  padding: 8px 0;

  ${getCssScroll()}
`;

export const StockSearchPopup: FC<StockSearchPopupProps> = ({
  contentWidth,
  onClickItem,
}) => {
  const [keyword, setKeyword] = useState('');
  const countryCode = useCountryCode();
  const searchedHoldings = useSearchHoldingData({ countryCode, keyword });

  const handleChange = (value: string | number) => {
    const _keyword = String(value);
    setKeyword(_keyword);
  };

  const handleItem: (ccid: string) => MouseEventHandler<HTMLLIElement> =
    (ccid) => () => {
      if (onClickItem) onClickItem(ccid);
    };

  return (
    <StockSearchPopupWrap
      contentWidth={contentWidth}
      title="종목검색"
      type="stockSearch"
    >
      <FlexBox mt={28}>
        <TextField
          height={32}
          fontScale={1}
          placeholder="종목명을 입력하세요."
          search="left"
          onChangeValue={handleChange}
        />
      </FlexBox>
      <Items>
        {searchedHoldings.map((item) => (
          <StockSearchItem
            countryCode={countryCode}
            item={item}
            keyword={keyword}
            onClickItem={handleItem}
            key={item.ccid}
          />
        ))}
      </Items>
    </StockSearchPopupWrap>
  );
};
