import { UNIVERSE_LOCALE } from '@constants/strategy';
import { ReturnChartItem } from '@graphql/generates';
import { SelectOptionDto, TickDto } from '@models/common';
import { UniverseType } from '@models/strategy';
import { getDateFormattedString } from '@utils/common';

/**
 * 셀렉트박스 UI에 들어갈 벤치마크 옵션 리스트를 생성한다.
 * @param {string[]} benchmarks - UniverseEnum 배열.
 * @returns {Object[]}
 */
export const createBenchmarkOptions = (benchmarks: UniverseType[]) => {
  return benchmarks.map((benchmark) => {
    const option: SelectOptionDto<UniverseType> = {
      name: benchmark,
      text: UNIVERSE_LOCALE[benchmark],
    };
    return option;
  });
};

/**
 * 누적수익률 차트 데이터를 생성한다.
 * @param {Object[]} chart - GetCumulativeReturnQuery 차트 데이터.
 * @param {Object} chart[].strategy - 프리셋전략 정보.
 * @param {Object} chart[].benchmark - 벤치마크 정보.
 * @returns {Object}
 */
export const createChartData = (chart: Array<ReturnChartItem>) => {
  let benchmarkName = '';
  let strategyName = '프리셋(Pre-set)';

  if (chart[0]) {
    benchmarkName = chart[0].benchmark?.name || '';
    benchmarkName = !!benchmarkName
      ? UNIVERSE_LOCALE[benchmarkName as UniverseType]
      : '';
  }

  const _chart = chart.map((chartItem) => {
    const benchmarkValue = chartItem?.benchmark?.value || 0;
    const strategyValue = chartItem?.strategy?.value || 0;
    const name = getDateFormattedString(chartItem?.ts, 'YYYY/mm/dd');

    const tick: TickDto = {
      base: strategyValue,
      benchmark: benchmarkValue,
      name,
    };

    return tick;
  });

  return {
    benchmarkName,
    strategyName,
    data: _chart,
  };
};
