import { FloaterModel } from '@models/layout.model';
import { FloaterModelGenDto } from '@models/strategy';

/**
 * 전략 편집에서 사용되는 FloaterModel을 반환합니다.
 * @param step {number} 현재 단계
 * @param len {number} 전체 단계
 * @param okDisabled {boolean} 확인 버튼 비활성화 여부
 * @param onClickCancel {() => void} 취소 버튼 클릭 시 실행되는 함수
 * @param onClickOk {() => void} 확인 버튼 클릭 시 실행되는 함수
 * @param onClickComplete {() => void} 완료 버튼 클릭 시 실행되는 함수
 */
export const getFloaterModelByStep = ({
  step,
  len,
  okDisabled,
  onClickCancel,
  onClickOk,
  onClickComplete,
}: FloaterModelGenDto): FloaterModel => {
  if (step === 1) {
    return {
      variant: 'single',
      okText: '다음',
      okDisabled,
      onClickOk,
    };
  }

  if (step === len) {
    return {
      variant: 'double',
      okText: '완료',
      okDisabled,
      cancelText: '이전',
      onClickOk: onClickComplete,
      onClickCancel,
    };
  }

  return {
    variant: 'double',
    okText: '다음',
    cancelText: '이전',
    onClickOk,
    onClickCancel,
  };
};
