import {
  STACK_BARS_KEY_LIST,
  STACK_BAR_CHART_BLUE_COLOR,
  STACK_BAR_CHART_ORANGE_COLOR,
} from '@constants/common';
import {
  ChartColorType,
  HistoryInfoItemDto,
  StackBarChartItemDto,
  StockIndexKeys,
} from '@models/common';
import { theme } from '@styles/theme';
import { isNumber } from '@utils/common';
import { FC, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  CategoricalChartFunc,
  CategoricalChartState,
} from 'recharts/types/chart/generateCategoricalChart';
import { TooltipPositionType, renderBarChartTooltip } from './ChartTooltip';
import { RebalanceTick } from './ChartTick';

interface StackBarChartProps {
  data: HistoryInfoItemDto[];
  color?: ChartColorType;

  onClickBar: (tooltipIndex: number) => void;
}

const convertToStackBarChartFormat = (
  originList: HistoryInfoItemDto[]
): StackBarChartItemDto[] => {
  const resList: StackBarChartItemDto[] = [];

  originList.forEach((item) => {
    const barChartItem: StackBarChartItemDto = item.stockList.reduce(
      (prev, cur, index) => {
        const stockKeys = index as unknown as StockIndexKeys;
        prev[stockKeys] = Math.round(cur.weight * 10000);
        return prev;
      },
      {
        date: item.date,
        cash: Math.round(item.cash * 10000),
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      }
    );

    resList.push(barChartItem);
  });

  return resList;
};

export const StackBarChart: FC<StackBarChartProps> = ({
  data,
  color = 'blue',
  onClickBar,
}) => {
  const [chartX, setChartX] = useState(-1);
  const [pos, setPos] = useState<TooltipPositionType>(undefined);

  const chartData = convertToStackBarChartFormat(data);
  const colors =
    color === 'blue'
      ? STACK_BAR_CHART_BLUE_COLOR
      : STACK_BAR_CHART_ORANGE_COLOR;

  const handleMouseMove: CategoricalChartFunc = (
    nextState: CategoricalChartState
  ) => {
    const { activeCoordinate, chartX, activeTooltipIndex } = nextState;

    let newChartX = activeCoordinate?.x || chartX;
    if (!!newChartX) {
      if (activeTooltipIndex === 0) {
        setPos('first');
        newChartX += 24;
      } else if (activeTooltipIndex === 11) {
        setPos('last');
        newChartX -= 24;
      } else {
        setPos(undefined);
      }

      setChartX(newChartX);
    }
    if (
      isNumber(nextState.activeTooltipIndex) &&
      nextState.activeTooltipIndex > -1
    ) {
      onClickBar(nextState.activeTooltipIndex);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setChartX(-1);
    }, 400);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          left: -18,
        }}
        barCategoryGap={1}
        onMouseMove={handleMouseMove}
      >
        <XAxis
          dataKey="date"
          padding={{ left: 2 }}
          strokeWidth={1}
          axisLine={false}
          tickLine={false}
          fontSize={12}
          fontWeight={300}
          color={theme.colorPalette.black[40]}
          tickFormatter={() => ''}
          tick={<RebalanceTick />}
        />
        <YAxis
          strokeWidth={1}
          tickCount={6}
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => `${value / 100}%`}
          padding={{ bottom: 2 }}
          fontSize={12}
          fontWeight={300}
          color={theme.colorPalette.black[40]}
        />
        <Tooltip
          position={{ x: chartX - 41, y: -14 }}
          content={renderBarChartTooltip(pos)}
          cursor={false}
        />
        <Bar
          key={`stack-bar-cash`}
          dataKey="cash"
          stackId="history"
          fill={theme.colorPalette.divider.appbar_tab}
        />
        {STACK_BARS_KEY_LIST.map((item) => {
          return (
            <Bar
              key={`stack-bar-${item}`}
              dataKey={item}
              stackId="history"
              fill={colors[item]}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
