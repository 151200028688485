import { CashDto, MyStockCompositionDto } from '@models/strategy';
import { usePopupStore } from '@stores/feedback/popup.store';
import { MoreBtn } from '@styles/common';
import { Icon, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { StockCompositionTable } from './StockCompositionTable';

interface HoldingPositionInfoProps {
  cash?: CashDto;
  stockList: MyStockCompositionDto[];
}

const HoldingPositionInfoWrap = styled.div``;

export const HoldingPositionInfo: FC<HoldingPositionInfoProps> = ({
  cash,
  stockList,
}) => {
  const openPopup = usePopupStore((state) => state.openPopup);

  const handleStockPopup = () => {
    openPopup('stockComposition')({
      cash,
      stockList,
    });
  };

  return (
    <HoldingPositionInfoWrap>
      <StockCompositionTable
        cash={stockList.length <= 5 ? cash : undefined}
        stockList={stockList.slice(0, 5)}
      />
      {stockList.length > 5 && (
        <MoreBtn onClick={handleStockPopup}>
          <Typography variant="caption" color="black.60">
            종목 구성 더보기
          </Typography>
          <Icon type="chevron-right" size={16} />
        </MoreBtn>
      )}
    </HoldingPositionInfoWrap>
  );
};
