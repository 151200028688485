import {
  OPTIMIZATION_MAPPER,
  REBALANCING_OPTION_MAPPER,
} from '@constants/strategy';
import {
  SIMPLE_FACTOR_GRADE_VIEW_MAPPER,
  TOP_PERCENT_ENUM,
} from '@constants/strategy/strategy-edit.const';
import {
  GetFactorsQuery,
  GetMyFactorsQuery,
  GetMyStyleFactorsQuery,
  GetStrategyQuery,
  GetStyleFactorsQuery,
} from '@graphql/generates';
import { HistogramModel } from '@models/common';
import {
  FactorInputDto,
  OptimizationType,
  RawFactorModel,
  RebalancingOptionType,
  StrategyFactorType,
  StyleFactorInfoItemModel,
  StyleFactorPresetType,
} from '@models/strategy';
import { createFactors, createHistogram } from './factor-edit.helper';
import { STRATEGY_PRESET_INIT } from './factor-edit.init';

/**
 * GetStyleFactorsQuery 응답 데이터를 간편 편집 스타일 팩터 state 데이터로 변환
 * @param data GetStyleFactorsQuery 응답 데이터
 * @returns {styleFactors, stylePresetObj}
 */
export const convertStyleFactors = (data: GetStyleFactorsQuery) => {
  const resList: StyleFactorInfoItemModel[] = [];
  const stylePresetObj: StyleFactorPresetType = STRATEGY_PRESET_INIT;
  const { styleFactors } = data;

  if (styleFactors?.styleFactors?.styleFactors) {
    if (styleFactors.styleFactors.simplePrest) {
      const simpleObj = styleFactors.styleFactors.simplePrest;
      TOP_PERCENT_ENUM.map((preset) => [
        (stylePresetObj[preset].max = simpleObj[preset]?.max || 0),
        (stylePresetObj[preset].min = simpleObj[preset]?.min || 0),
      ]);
    }
    const factorList = styleFactors.styleFactors.styleFactors;
    factorList.forEach((factor) => {
      if (factor && factor.style?.style) {
        resList.push({
          name: factor.style.style as StrategyFactorType,
          cmName: factor.cmName,
          desc: factor.style?.description || '',
          text: factor.style.name || '',
        });
      }
    });
  }

  return { styleFactors: resList, stylePresetObj };
};

/**
 * GetMyStyleFactorsQuery 응답 데이터를 간편 편집 스타일 팩터 state 데이터로 변환
 * @param data GetMyStyleFactorsQuery 응답 데이터
 * @returns {styleFactors, stylePresetObj}
 */
export const convertMyStyleFactors = (data: GetMyStyleFactorsQuery) => {
  const resList: StyleFactorInfoItemModel[] = [];
  const stylePresetObj: StyleFactorPresetType = STRATEGY_PRESET_INIT;
  const { myStyleFactors } = data;

  if (myStyleFactors?.myStyleFactors?.styleFactors) {
    if (myStyleFactors.myStyleFactors.simplePrest) {
      const simpleObj = myStyleFactors.myStyleFactors.simplePrest;
      TOP_PERCENT_ENUM.map((preset) => [
        (stylePresetObj[preset].max = simpleObj[preset]?.max || 0),
        (stylePresetObj[preset].min = simpleObj[preset]?.min || 0),
      ]);
    }
    const factorList = myStyleFactors.myStyleFactors.styleFactors;
    factorList.forEach((factor) => {
      if (factor && factor.style?.style) {
        resList.push({
          name: factor.style.style as StrategyFactorType,
          cmName: factor.cmName,
          desc: factor.style?.description || '',
          text: factor.style.name || '',
          type: factor.defaultGrade
            ? SIMPLE_FACTOR_GRADE_VIEW_MAPPER[factor.defaultGrade]
            : undefined,
        });
      }
    });
  }

  return { styleFactors: resList, stylePresetObj };
};

/**
 * 팩터 체크박스, 백엔드 팩터 Raw 데이터와
 * 이전에 선택된 input, histograms 데이터를 기반으로
 * histograms, inputFactors 데이터 업데이트하여 반환
 * @param factorCheckObj 팩터 체크박스 데이터
 * @param rawFactors Backend 팩터 Raw 데이터
 * @param inputFactors SimulationOptionScreeningInput "factors" 데이터
 * @param histograms 이전 선택된 팩터의 histograms 데이터
 * @returns {histograms, inputFactors}
 */
export const convertHistograms = (
  factorCheckObj: Record<string, boolean>,
  rawFactors: RawFactorModel[],
  inputFactors: FactorInputDto[],
  histograms: HistogramModel[]
) => {
  const _histograms: HistogramModel[] = []; // 업데이트될 histograms 데이터
  const _inputFactors: FactorInputDto[] = []; // 업데이트될 inputFactors 데이터
  rawFactors.forEach((factor) => {
    if (factorCheckObj[factor.cmName]) {
      const prevInputFactor = inputFactors.find(
        (f) => f.cmName === factor.cmName
      );
      const prevHistogram = histograms.find((h) => h.cmName === factor.cmName);
      const histogram = prevHistogram
        ? { ...prevHistogram }
        : createHistogram(factor, prevInputFactor);
      _histograms.push(histogram);
      _inputFactors.push(
        prevInputFactor
          ? { ...prevInputFactor }
          : {
              cmName: factor.cmName,
              max: histogram.max,
              min: histogram.min,
            }
      );
    }
  });
  return {
    histograms: _histograms,
    inputFactors: _inputFactors,
  };
};

/**
 * GetFactorsQuery 응답 데이터를 state 데이터로 변환
 * @param data GetFactorsQuery 응답 데이터
 * @returns {factorList, checkObj, rawFactors};
 */
export const convertFactorstoList = (data: GetFactorsQuery) => {
  const factors = data.factors?.factors;

  if (!factors) return;

  const { checkObj, factorList, rawFactors } = createFactors(factors);

  return { factorList, checkObj, rawFactors };
};

/**
 * GetMyFactorsQuery 응답 데이터를 state 데이터로 변환
 * @param data GetMyFactorsQuery 응답 데이터
 * @returns {factorList, checkObj, rawFactors, inputFactors, histograms}
 */
export const convertMyFactorsToList = (data: GetMyFactorsQuery) => {
  const factors = data.myFactors?.myFactors;

  if (!factors) return;

  const { checkObj, factorList, rawFactors, inputFactors, histograms } =
    createFactors(factors);

  return { factorList, checkObj, rawFactors, inputFactors, histograms };
};

/**
 * GetStrategyQuery 응답 데이터 중 최적화옵션, 리밸런싱옵션 데이터를 state 데이터로 변환
 * @param data GetStrategyQuery 응답 데이터
 * @returns {optimization, rebalancing}
 */
export const convertStrategyToSimulationOption = (data: GetStrategyQuery) => {
  let optimization: OptimizationType = OPTIMIZATION_MAPPER.MARKET_WEIGHT;
  let rebalancing: RebalancingOptionType = REBALANCING_OPTION_MAPPER.MONTH;
  if (data && data.strategy?.strategy?.simulationOption) {
    const simulationOption = data.strategy.strategy.simulationOption;
    optimization = simulationOption.optimization;
    rebalancing = simulationOption.rebalancing || 'NONE';
  }

  return {
    optimization,
    rebalancing,
  };
};
