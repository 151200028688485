import { MyStrategyEditResultContainer } from '@containers/myStrategy/MyStrategyEditResultContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface MyStrategyEditResultPageProps {}

export const MyStrategyEditResultPage: FC<
  MyStrategyEditResultPageProps
> = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <MyStrategyEditResultContainer renew prevStrategyId={prevStrategyId} />
  );
};
