import { SimulationOptionScreeningInput } from '@graphql/generates';
import { repo } from '@graphql/repo';
import {
  OptimizationType,
  PresetType,
  RebalancingOptionType,
  StrategySettingDto,
} from '@models/strategy';
import { convertMyStrategy, useMyStrategyStore } from '@stores/my-strategy';
import { StateCreator } from 'zustand';
import { StrategyEditState } from '../strategy-edit.store';
import { createSimulationOptionState } from './simulation-option.init';

export interface SimulationOptionSliceState {
  /** 선택된 투자기준 */
  strategySettingObj: StrategySettingDto;
  /** 현재 선택 항목 */
  simulationOptionScreening?: SimulationOptionScreeningInput;
  /** 이전 선택 항목 */
  prevSimulationOptionScreening?: SimulationOptionScreeningInput;
}

export interface SimulationOptionSlice extends SimulationOptionSliceState {
  setBooksize: (booksize: number) => void;
  setOptimization: (optimization: OptimizationType) => void;
  setRebalancing: (rebalancing: RebalancingOptionType) => void;
  setStartDate: (startDate: number) => void;
  fetchMySimulationOption: (myStrategyId: string) => {};
  setPrevSimulationOptionScreening: (isSimple: boolean) => void;
}

export const createSimulationOptionSlice: StateCreator<
  StrategyEditState,
  [],
  [],
  SimulationOptionSlice
> = (set, get) => ({
  ...createSimulationOptionState(),
  setBooksize: (booksize) => {
    set(() => ({
      strategySettingObj: { ...get().strategySettingObj, booksize },
    }));
  },
  setOptimization: (optimization) => {
    set(() => ({
      strategySettingObj: { ...get().strategySettingObj, optimization },
    }));
  },
  setRebalancing: (rebalancing) => {
    set(() => ({
      strategySettingObj: {
        ...get().strategySettingObj,
        rebalancing: rebalancing === 'NONE' ? undefined : rebalancing,
      },
    }));
  },
  setStartDate: (startDate) => {
    set(() => ({
      strategySettingObj: { ...get().strategySettingObj, startDate },
    }));
  },

  fetchMySimulationOption: async (myStrategyId) => {
    const response = await repo.getMyStrategy({ id: myStrategyId });
    const myStrategy = convertMyStrategy(response);
    if (myStrategy) {
      set(() => ({
        strategySettingObj: {
          ...myStrategy.strategySettingObj,
        },
        whitelistObj: myStrategy.whitelistObj,
      }));

      // 다시 편집하기 or 내전략 편집 > 전략 카테고리 세팅
      useMyStrategyStore.setState({
        category: myStrategy.myStrategyInfo.category,
      });
    }
  },

  setPrevSimulationOptionScreening: (isSimple) => {
    const {
      industries,
      inputFactors,
      styleFactors,
      stylePresetObj,
      strategySettingObj,
      universePreset,
    } = get();

    const { booksize, optimization, startDate, rebalancing } =
      strategySettingObj;
    let simulationOptionScreening: SimulationOptionScreeningInput = {
      industries,
      booksize,
      optimization,
      startDate,
      rebalancing: rebalancing === 'NONE' ? null : rebalancing,
      universePreset,
      blacklist: [],
      whitelist: [],
      factors: inputFactors.map((factor) => ({
        cmName: factor.cmName,
        max: factor.max,
        min: factor.min,
      })),
    };
    if (isSimple) {
      simulationOptionScreening = {
        ...simulationOptionScreening,
        factors: styleFactors
          .filter((factor) => !!factor.type)
          .map((factor) => ({
            cmName: factor.cmName,
            ...stylePresetObj[factor.type as PresetType],
          })),
      };
    }
    set(() => ({ prevSimulationOptionScreening: simulationOptionScreening }));
  },
});
