import { DetailEditPageContainer } from '@containers/strategy-edit/DetailEditPageContainer';
import { FC } from 'react';

interface StrategyDetailEditPageProps {
  reEdit?: boolean;
}
export const StrategyDetailEditPage: FC<StrategyDetailEditPageProps> = ({
  reEdit = false,
}) => {
  return <DetailEditPageContainer reEdit={reEdit} />;
};
