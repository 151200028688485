import { Button, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled from 'styled-components';

interface EmptyListProps {
  onClickCreate: () => void;
}

const EmptyListWrap = styled.div`
  width: 210px;
  margin: auto;
  margin-top: 170px;
`;

export const EmptyList: FC<EmptyListProps> = ({ onClickCreate }) => {
  return (
    <EmptyListWrap>
      <FlexBox direction="column" spacing={24} alignItems="center">
        <Typography align="center" variant="subTitle2_medium">
          저장된 전략이 없습니다. <br /> 전략을 선택하여 편집해보세요!
        </Typography>
        <FlexBox width={160}>
          <Button onClick={onClickCreate}>
            <Typography color="fixedblack" variant="subTitle2_medium">
              전략 만들기
            </Typography>
          </Button>
        </FlexBox>
      </FlexBox>
    </EmptyListWrap>
  );
};
