/* eslint-disable react-hooks/exhaustive-deps */
import { EmptyContent } from '@components/strategy/EmptyContent';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';
import { FactorEditCardContainer } from './FactorEditCardContainer';

interface FactorEditContainerProps {}

const FactorEditContainerWrap = styled(FlexBox)``;

const Header = styled.div``;

export const FactorEditContainer: FC<FactorEditContainerProps> = () => {
  const { histograms, updateInputFactor, setFactorCheckObj } =
    useStrategyEditStore();

  return (
    <FactorEditContainerWrap direction="column" spacing={8}>
      <Header>
        <Typography as="span" color="secondary.text" variant="caption_medium">
          {histograms.length}
        </Typography>
        <Typography as="span" color="black.87" variant="caption_medium">
          개 선택됨
        </Typography>
      </Header>
      <FlexBox direction="column" spacing={16}>
        {histograms.map((histogram) => {
          return (
            <FactorEditCardContainer
              {...histogram}
              onChange={updateInputFactor}
              onClose={setFactorCheckObj}
              key={histogram.cmName}
            />
          );
        })}
        {histograms.length === 0 && (
          <EmptyContent
            desc="선택된 팩터가 없습니다."
            descVariant="body1_title"
            subDesc="이전 단계에서 선택할 수 있습니다."
            paddingTop={150}
          />
        )}
      </FlexBox>
    </FactorEditContainerWrap>
  );
};
