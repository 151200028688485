import { EditOptionType, InfoBottomsheetContentModel } from '@models/feedback';

export const BOTTOMSHEET_ENUM = [
  'info',
  'factorInfo',
  'optionSelection',
  'profitSelector',
  'edit',
  'booksize',
  'optimization',
  'rebalancing',
  'backtestDate',
  'strategyOrder',
  'myStrategyOrder',
  'myStrategyOption',
  'themeGroups',
  'stockListEmpty',
] as const;

/**
 * 투자 방법 탭 타입
 */
export const BOTTOMSHEET_EDIT_TAB = ['beginner', 'pro'] as const;

/**
 * 비기너 Type
 */
export const BOTTOMSHEET_EDIT_SIMPLE_ENUM = [
  'beginner-1',
  'beginner-2',
] as const;

/**
 * 프로 투자 Type
 */
export const BOTTOMSHEET_EDIT_PRO_ENUM = ['pro-1', 'pro-2', 'pro-3'] as const;

export const BOTTOMSHEET_TYPE_ENUM = [
  ...BOTTOMSHEET_EDIT_SIMPLE_ENUM,
  ...BOTTOMSHEET_EDIT_PRO_ENUM,
] as const;

export const BOTTOMSHEET_DESC: Record<
  EditOptionType,
  { title: string; desc: string }
> = {
  'beginner-1': {
    title: 'Level 1',
    desc: '금액만 설정하여 빠르게 투자합니다.',
  },
  'beginner-2': {
    title: 'Level 2',
    desc: '4가지 투자기준과 종목을 선택하여 투자합니다.',
  },
  'pro-1': {
    title: 'Level 1',
    desc: '나만의 투자스타일과 금액만 설정하여 투자합니다.',
  },
  'pro-2': {
    title: 'Level 2',
    desc: '투자스타일부터 종목까지 선택하여 투자합니다.',
  },
  'pro-3': {
    title: 'Level Pro',
    desc: '모든 과정을 편집하여 전문가 전략을 만듭니다.',
  },
};

export const KB_PICK_BOTTOMSHEET_INFO: InfoBottomsheetContentModel = {
  button: 'single',
  descriptions: [
    'KB증권이 현재 시황에 적합하여 선별한 투자 테마 전략을 의미합니다.',
    'Active 전략은 시황과 전략 변화에 따라 유연하게 포트폴리오 내 종목과 비중을 조정하여 운용합니다.',
  ],
  descriptionVariant: 'list',
  title: `‘KB's Pick’ 프리셋이란?`,
};
