import { PieChart, PieChartLegendList } from '@components/common';
import { ExposureDto } from '@models/strategy';
import { Typography } from '@ui/components';
import { SwitchButton } from '@ui/components/button/SwitchButton';
import { FlexBox } from '@ui/components/Flexbox';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

interface SectorIndustryCompProps {
  variant: 'single' | 'double';
  data: ExposureDto[];
}

const Section = styled.div`
  & + & {
    &::before {
      background-color: ${({ theme }) => theme.colorPalette.divider.contents};
      content: '';
      display: block;
      height: 1px;
      margin: 24px 0;
    }
  }
`;

const SectorIndustryWrap = styled(FlexBox)`
  @media screen and (max-width: 359px) {
    gap: 8px;
  }
`;

export const SectorIndustryComp: FC<SectorIndustryCompProps> = ({
  variant,
  data,
}) => {
  const [switchIndex, setSwitchIndex] = useState<'sectors' | 'industries'>(
    'sectors'
  );
  const buttonList = useMemo(
    () => [
      {
        index: 0,
        name: '섹터',
        onClickBtn: () => {
          setSwitchIndex('sectors');
        },
      },
      {
        index: 1,
        name: '업종',
        onClickBtn: () => {
          setSwitchIndex('industries');
        },
      },
    ],
    []
  );
  const activeIndex = switchIndex === 'sectors' ? 0 : 1;

  if (variant === 'single' && data.length > 0) {
    const chartData = data[0][switchIndex];
    const color = 'blue';
    return (
      <FlexBox justify="center" direction="column">
        <FlexBox justify="center" mb={32}>
          <SwitchButton
            variant="oval"
            buttonList={buttonList}
            activeIndex={activeIndex}
          />
        </FlexBox>
        <SectorIndustryWrap spacing={32} justify="center">
          <PieChart chartData={chartData} color={color} />
          <PieChartLegendList color={color} legendList={chartData} />
        </SectorIndustryWrap>
      </FlexBox>
    );
  }

  return (
    <FlexBox justify="center" direction="column">
      <FlexBox justify="center" mb={32}>
        <SwitchButton
          variant="oval"
          buttonList={buttonList}
          activeIndex={activeIndex}
        />
      </FlexBox>
      {data.map((chart, index) => {
        const color = index === 0 ? 'orange' : 'blue';
        const titlePrefix = index === 0 ? '나의 전략' : '프리셋(Pre-set)';
        const chartData = chart[switchIndex];
        return (
          <Section key={`exposure_chart_${index}`}>
            <Typography mb={16} variant="body2_title">
              {titlePrefix}
            </Typography>
            <SectorIndustryWrap spacing={32} justify="center">
              <PieChart chartData={chartData} color={color} />
              <PieChartLegendList color={color} legendList={chartData} />
            </SectorIndustryWrap>
          </Section>
        );
      })}
    </FlexBox>
  );
};
