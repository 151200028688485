import {
  ScThemedTableBodyProps,
  ScThemedTableCellProps,
  ScThemedTableHeadProps,
  ScThemedTableProps,
  ScThemedTableRowProps,
} from '@ui/model';
import {
  getBackgroundColorFromProps,
  getFontColorFromProps,
  getFontStyleByVariantProps,
  getHeightFromProps,
  getMinWidthFromProps,
  getPaddingMarginFromProps,
  getTableCellDefaultCss,
  getTableDefaultCss,
  getTableRowBorder,
  getTableRowDefaultCss,
  getTableScrollCss,
  getTextAlignFromProps,
  getWhiteSpaceFromProps,
  getWidthFromProps,
} from '@ui/util';
import styled from 'styled-components';

export const BaseStyledTable = styled.table<ScThemedTableProps>`
  ${getTableDefaultCss}
  ${getWidthFromProps}
  ${getTableScrollCss}
`;

export const BaseStyledTableHead = styled.thead<ScThemedTableHeadProps>`
  ${getBackgroundColorFromProps}
`;

export const BaseStyledTableBody = styled.tbody<ScThemedTableBodyProps>``;

export const BaseStyledTableRow = styled.tr<ScThemedTableRowProps>`
  ${getTableRowDefaultCss}
  ${getTableRowBorder}
`;

export const BaseStyledTableCell = styled.td<ScThemedTableCellProps>`
  ${getWidthFromProps}
  ${getMinWidthFromProps}
  ${getWhiteSpaceFromProps}
  ${getFontStyleByVariantProps}
  ${getTextAlignFromProps}
  ${getHeightFromProps}
  ${getPaddingMarginFromProps}
  ${getBackgroundColorFromProps}
  ${getTableCellDefaultCss}
  ${getFontColorFromProps}
`;
