import { COUNTRY_MAPPER } from '@constants/common';
import { LevelProContainer } from '@containers/strategy-edit/expert/LevelProContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const MyUsStrategyPro1EditPage: FC = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <LevelProContainer
      renew
      prevStrategyId={prevStrategyId}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
