import { SelectButton } from '@components/common';
import {
  STRATEGY_ORDER_LOCALE,
  STRATEGY_ORDER_PERIOD_LOCALE,
} from '@constants/strategy';
import {
  StrategyCategoryType,
  StrategyOrderMethodType,
  StrategyOrderPeriodReturnType,
} from '@models/strategy';
import { FlexBox } from '@ui/components';
import { TextField } from '@ui/components/TextField';
import { FC } from 'react';
import styled from 'styled-components';

interface SearchConditionProps {
  category: StrategyCategoryType;
  strategyOrder: boolean;
  orderTypes: StrategyOrderMethodType;
  periodType: StrategyOrderPeriodReturnType;
  keyword: string;
  placeholder: string;
  changeOrder: () => void;
  onReset: () => void;
  onChange: (keywordStr: string | number) => void;
  onSearch: () => void;
}

const SelectButtonWrap = styled(SelectButton)`
  white-space: nowrap;
`;

export const SearchCondition: FC<SearchConditionProps> = ({
  category,
  strategyOrder,
  orderTypes,
  periodType,
  keyword,
  placeholder,
  changeOrder,
  onReset,
  onChange,
  onSearch,
}) => {
  if (category === 'EMP') return null;

  if (category === 'MASTER') {
    return (
      <TextField
        variant="white"
        search="right"
        value={keyword}
        placeholder={placeholder}
        onClickReset={onReset}
        onChangeValue={onChange}
        onClickSearch={onSearch}
      />
    );
  }

  const orderText = orderTypes ? STRATEGY_ORDER_LOCALE[orderTypes] : '';
  const periodText = periodType
    ? `최근 ${STRATEGY_ORDER_PERIOD_LOCALE[periodType]}`
    : '';

  return (
    <FlexBox spacing={8}>
      <SelectButtonWrap
        variant="white"
        open={strategyOrder}
        selectedValue={`${orderText}·${periodText}`}
        placeholder="정렬 방식"
        onClick={changeOrder}
      />
      <TextField
        variant="white"
        search="right"
        value={keyword}
        placeholder={placeholder}
        onClickReset={onReset}
        onChangeValue={onChange}
        onClickSearch={onSearch}
      />
    </FlexBox>
  );
};
