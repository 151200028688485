import { FactorEditCard } from '@components/strategy-edit';
import { HistogramModel } from '@models/common';
import { FactorInputDto } from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { createTickRange } from '@stores/strategy-edit';
import { numberFixedWithLimit } from '@utils/common';
import { ChangeEvent, FC, useState } from 'react';

interface FactorEditCardProps extends HistogramModel {
  onChange: (factorInput: FactorInputDto, topPercent?: TopPercentType) => void;
  onClose: (cmName: string) => void;
}

type TopPercentType = 'high' | 'middle' | 'low';

const numberLimitUntil4 = (num: number) => {
  return +numberFixedWithLimit(num, 4);
};

const inputValidator =
  (type: 'min' | 'max', toBeComparedValue: number) =>
  (value: number | string | readonly string[]) => {
    const _value = +value;
    if (isNaN(_value)) {
      if (/^-$/.test(String(value))) {
        return true;
      }
      return false;
    }
    if (type === 'min') {
      return _value < toBeComparedValue;
    } else if (type === 'max') {
      return _value > toBeComparedValue;
    }
    return false;
  };

export const FactorEditCardContainer: FC<FactorEditCardProps> = ({
  cmName,
  histogramChartData,
  displayName,
  description,
  max,
  min,
  rawHistograms,
  initRange,
  // TODO: 상위n% 버튼 재활성화 시 원복
  // selectedTopPercent,
  // ignoreNegative,
  comments,
  img,
  onChange,
  onClose,
}) => {
  const openConfirm = useConfirmStore((state) => state.openConfirm);

  const [minInput, setMinInput] = useState<number>(numberLimitUntil4(min));
  const [maxInput, setMaxInput] = useState<number>(numberLimitUntil4(max));
  const [minInputText, setMinInputText] = useState<string>(
    String(numberLimitUntil4(min))
  );
  const [maxInputText, setMaxInputText] = useState<string>(
    String(numberLimitUntil4(max))
  );
  const [range, setRange] = useState<number[]>(initRange);
  const [minTextViewable, setMinTextViewable] = useState<boolean>(true);
  const [maxTextViewable, setMaxTextViewable] = useState<boolean>(true);

  const [startRange, endRange] = range;
  const isStartRangeExtreme: boolean =
    startRange !== undefined && min === rawHistograms?.[0];
  const isEndRangeExtreme: boolean =
    endRange !== undefined && max === rawHistograms?.[99];
  const minValue: string =
    minTextViewable && isStartRangeExtreme ? 'Min' : minInputText;
  const maxValue: string =
    maxTextViewable && isEndRangeExtreme ? 'Max' : maxInputText;

  const handleChangeInput =
    (type: 'min' | 'max') => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (type === 'min') {
        setMinInputText(value);
        return;
      }
      setMaxInputText(value);
    };

  const handleFocus = (type: 'min' | 'max') => () => {
    if (type === 'min') {
      setMinTextViewable(false);
      return;
    }
    setMaxTextViewable(false);
  };

  const handleBlurForce = (type: 'min' | 'max') => {
    if (type === 'min') {
      setMinTextViewable(true);
      return;
    }
    setMaxTextViewable(true);
  };

  const handleBlur =
    (type: 'min' | 'max') => (e: React.FocusEvent<HTMLInputElement>) => {
      handleBlurForce(type);

      if (type === 'min') {
        let _min = +minInputText;
        if (_min === minInput) return;
        if (!inputValidator('min', maxInput)(_min)) {
          setMinInputText(minInput.toString());
          return;
        }
        _min = _min < -100000 ? -100000 : _min;
        _min = +_min.toFixed(8);

        const range = createTickRange({ min: _min, max }, rawHistograms);
        setRange(range);
        setMinInput(_min);
        setMinInputText(_min.toString());
        setMinTextViewable(true);
        onChange({ cmName, min: _min, max });
      } else if (type === 'max') {
        let _max = +maxInputText;
        if (_max === maxInput) return;
        if (!inputValidator('max', minInput)(_max)) {
          setMaxInputText(maxInput.toString());
          return;
        }
        _max = _max > 100000 ? 100000 : _max;
        _max = +_max.toFixed(8);

        const range = createTickRange({ min, max: _max }, rawHistograms);
        setRange(range);
        setMaxInput(_max);
        setMaxInputText(_max.toString());
        setMinTextViewable(true);
        onChange({ cmName, min, max: _max });
      }
    };

  const handleChange = (values: readonly number[], type?: 'min' | 'max') => {
    const _range = values as number[];
    if (type === 'min') {
      const _min = rawHistograms[_range[0]];
      setRange(_range);
      setMinInput(numberLimitUntil4(_min));
      setMinInputText(numberLimitUntil4(_min).toString());
      onChange({ cmName, min: _min, max });
      return;
    }
    const _max = rawHistograms[_range[1]];
    setRange(_range);
    setMaxInput(numberLimitUntil4(_max));
    setMaxInputText(numberLimitUntil4(_max).toString());
    onChange({ cmName, min, max: _max });
  };

  const handleUpdate = (values: readonly number[], type?: 'min' | 'max') => {
    if (type === 'min') {
      const _min = rawHistograms[values[0]];
      setMinInputText(numberLimitUntil4(_min).toString());
      return;
    }
    const _max = rawHistograms[values[1]];
    setMaxInputText(numberLimitUntil4(_max).toString());
  };

  const handleCloseCard = (cmName: string) => () => {
    onClose(cmName);
  };

  const handleBlockerCallback = () => {
    openConfirm('alert')({
      button: 'single',
      title: '대상 종목이 없습니다.',
      description: '(최근 30 영업일 내 해당 실적 발표)',
    });
  };

  const headerCommonProps = {
    cmName,
    displayName,
    description,
    comments,
    img,
    handleCloseCard,
  };
  const inputCommonProps = {
    minValue,
    maxValue,
  };
  const sliderCommonProps = {
    histogramChartData,
    range,
  };

  if (
    histogramChartData.length === 0 ||
    rawHistograms.length === 0 ||
    initRange.length === 0
  ) {
    return (
      <FactorEditCard
        header={headerCommonProps}
        input={inputCommonProps}
        slider={sliderCommonProps}
        onBlockerCallback={handleBlockerCallback}
      />
    );
  }

  return (
    <FactorEditCard
      header={headerCommonProps}
      input={{
        ...inputCommonProps,
        handleBlur,
        handleChangeInput,
        handleFocus,
      }}
      slider={{
        ...sliderCommonProps,
        handleChange,
        handleUpdate,
      }}
    />
  );
};
