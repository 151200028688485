import { UniverseCountryEnum } from '@graphql/generates';
import { CountryCodeType } from '@models/common';

export const STATUS_ENUM = ['init', 'loading', 'error', 'success'] as const;

export const COUNTRY_CODE_ENUM = [
  UniverseCountryEnum.Domestic,
  UniverseCountryEnum.Usa,
] as const;

export const COUNTRY_MAPPER: Record<CountryCodeType, CountryCodeType> = {
  DOMESTIC: UniverseCountryEnum.Domestic,
  USA: UniverseCountryEnum.Usa,
};

export const COUNTRY_MAPPER_TEXT: Record<CountryCodeType, string> = {
  DOMESTIC: 'KR',
  USA: 'US',
};
