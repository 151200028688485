import { theme, ThemeType } from '@styles/theme';
import {
  BoxSpacingType,
  DefMarginProps,
  DefPaddingProps,
  MarginChangeProps,
  PaddingAndMarginChangeProps,
  PaddingChangeProps,
  ThemeColorType,
  ThemedComponentProps,
} from '@ui/model/ui.type';
import { css } from 'styled-components';
import { isNumber } from 'util';

export function findColorByType(
  color?: ThemeColorType | 'transparent',
  def = 'inherit'
): string {
  if (color) {
    const colorVal = color.split('.');

    if (color === 'transparent' || color === undefined) {
      return color;
    }

    if (color === 'white' || color === 'fixedwhite' || color === 'fixedblack') {
      return theme.colorPalette[color];
    }

    if (colorVal.length === 2) {
      const palette = theme.colorPalette as any;
      return palette[colorVal[0]][colorVal[1]];
    }
  }

  return def;
}

export const getPaddingMarginFromProps = (
  props: ThemedComponentProps & PaddingAndMarginChangeProps
) => {
  const paddingStyle = getPaddingFromProps(props);
  const marginStyle = getMarginFromProps(props);

  return css`
    ${paddingStyle}
    ${marginStyle}
  `;
};

export const getPaddingFromProps = ({
  theme,
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
  defP,
}: ThemedComponentProps & PaddingChangeProps & DefPaddingProps) => {
  const paddingStyle = getCssPaddingMargin(
    theme.spacing,
    'padding',
    {
      top: py || pt || p,
      left: px || pl || p,
      right: px || pr || p,
      bottom: py || pb || p,
    },
    defP
  );

  return css`
    ${paddingStyle}
  `;
};

export const getMarginFromProps = ({
  theme,
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
  defM,
}: ThemedComponentProps & MarginChangeProps & DefMarginProps) => {
  const marginStyle = getCssPaddingMargin(
    theme.spacing,
    'margin',
    {
      top: my || mt || m,
      left: mx || ml || m,
      right: mx || mr || m,
      bottom: my || mb || m,
    },
    defM
  );

  return css`
    ${marginStyle}
  `;
};

interface SpacingDirectionDto {
  top?: BoxSpacingType;
  left?: BoxSpacingType;
  right?: BoxSpacingType;
  bottom?: BoxSpacingType;
}

function makeSpacingStyles(
  spacing: ThemeType['spacing'],
  key: 'margin' | 'padding',
  direction: keyof SpacingDirectionDto,
  value?: BoxSpacingType
) {
  if (!value) {
    return '';
  }
  return `${key}-${direction}: ${getSpacing(spacing, value)};`;
}

function getCssPaddingMargin(
  spacing: ThemeType['spacing'],
  key: 'margin' | 'padding',
  val: SpacingDirectionDto,
  def?: string
) {
  const defStyle = def ? `${key}: ${def};` : '';

  if (val.top && val.left && val.right && val.bottom) {
    return `${defStyle}${key}: ${getSpacing(spacing, val.top)} ${getSpacing(
      spacing,
      val.right
    )} ${getSpacing(spacing, val.bottom)} ${getSpacing(spacing, val.left)};`;
  }

  let result = defStyle;

  result += makeSpacingStyles(spacing, key, 'top', val.top);
  result += makeSpacingStyles(spacing, key, 'left', val.left);
  result += makeSpacingStyles(spacing, key, 'right', val.right);
  result += makeSpacingStyles(spacing, key, 'bottom', val.bottom);

  return result;
}

function getSpacing(spacing: ThemeType['spacing'], value: BoxSpacingType) {
  if (isNumber(value)) {
    return `${value}px`;
  }
  return spacing[value];
}

export function getCssScroll() {
  return css`
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  `;
}
