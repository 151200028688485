import { KbError } from '@graphql/generates';
import { KbErrorType } from '@models/error.model';

export const KB_ERROR_ENUM = [
  KbError.ApiReqFail,
  KbError.CanNotDelete,
  KbError.CanNotUpdate,
  KbError.DoesNotExist,
  KbError.Duplicated,
  KbError.ExceededMaxCnt,
  KbError.InvalidParameter,
  KbError.InvalidPermission,
  KbError.InvalidState,
  KbError.KbApiReqFail,
] as const;

export const KB_ERROR_MAPPER: Record<KbErrorType, KbErrorType> = {
  API_REQ_FAIL: KbError.ApiReqFail,
  CAN_NOT_DELETE: KbError.CanNotDelete,
  CAN_NOT_UPDATE: KbError.CanNotUpdate,
  DOES_NOT_EXIST: KbError.DoesNotExist,
  DUPLICATED: KbError.Duplicated,
  EXCEEDED_MAX_CNT: KbError.ExceededMaxCnt,
  INVALID_PARAMETER: KbError.InvalidParameter,
  INVALID_PERMISSION: KbError.InvalidPermission,
  INVALID_STATE: KbError.InvalidState,
  KB_API_REQ_FAIL: KbError.KbApiReqFail,
};
