import { GNB_FLAG_MAPPER } from '@constants/layout.const';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface SwitchButtonProps {
  activeIndex: number;
  onClickBtn: (index: number) => () => void;
}

const SwitchButtonWrap = styled.button`
  background-color: ${({ theme }) => theme.colorPalette.white};
  border-style: solid;
  border-color: ${({ theme }) => theme.colorPalette.divider.title};
  border-width: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 100%;
  height: 40px;
  margin-left: -1px;

  &:first-child {
    border-radius: 3px 0px 0px 3px;
  }

  &:last-child {
    border-radius: 0px 3px 3px 0px;
  }

  &.active {
    border: 1px solid ${({ theme }) => theme.colorPalette.secondary.default};
    background-color: ${({ theme }) => theme.colorPalette.secondary.default};
  }
`;

export const CountrySwitchButton: FC<SwitchButtonProps> = ({
  activeIndex = 0,
  onClickBtn,
}) => {
  return (
    <FlexBox width="100%">
      <SwitchButtonWrap
        className={activeIndex === 0 ? 'active' : 'default'}
        onClick={onClickBtn(0)}
      >
        <img src={GNB_FLAG_MAPPER['/readymades']} alt="한국 국기" width={16} />
        <Typography
          color={activeIndex === 0 ? 'fixedwhite' : 'black.60'}
          variant="overline_title"
        >
          한국 전략
        </Typography>
      </SwitchButtonWrap>
      <SwitchButtonWrap
        className={activeIndex === 1 ? 'active' : 'default'}
        onClick={onClickBtn(1)}
      >
        <img
          src={GNB_FLAG_MAPPER['/us-readymades']}
          alt="미국 국기"
          width={16}
        />
        <Typography
          color={activeIndex === 1 ? 'fixedwhite' : 'black.60'}
          variant="overline_title"
        >
          미국 전략
        </Typography>
      </SwitchButtonWrap>
    </FlexBox>
  );
};
