import {
  OPTIMIZATION_MAPPER,
  REBALANCING_OPTION_MAPPER,
} from '@constants/strategy';
import { StrategySettingDto } from '@models/strategy';
import { SimulationOptionSliceState } from './simulation-option.slice';
import { getBeforeYear, getDateCompatibleIOS } from '@utils/common';

export const STRATEGY_SETTING_INIT: StrategySettingDto = {
  booksize: 5000000,
  optimization: OPTIMIZATION_MAPPER.MARKET_WEIGHT,
  rebalancing: REBALANCING_OPTION_MAPPER.MONTH,
  startDate: 0,
};

export const createInitStartDate = () => {
  const today = new Date(
    getDateCompatibleIOS(new Date().getFullYear(), new Date().getMonth() + 1)
  );
  return +getBeforeYear(3, today);
};

export const createSimulationOptionState = (): SimulationOptionSliceState => ({
  strategySettingObj: {
    ...STRATEGY_SETTING_INIT,
    startDate: createInitStartDate(),
  },
  simulationOptionScreening: undefined,
  prevSimulationOptionScreening: undefined,
});
