import {
  createScrollLockCss,
  popDimmedStack,
  pushDimmedStack,
} from '@utils/layout';
import React from 'react';

/**
 * document.body 스크롤을 고정시키기 위한 커스텀 훅
 * @param {boolean} position - document.body의 position을 fixed로 설정할지 여부
 */
export const useScrollBlocker = (position = true) => {
  const scrollPosition = window.pageYOffset;

  React.useEffect(() => {
    pushDimmedStack();
    document.body.style.cssText = createScrollLockCss(scrollPosition, position);

    return () => {
      if (popDimmedStack() <= 0) {
        document.body.style.cssText = '';
      }
      if (position) {
        window.scrollTo({ top: scrollPosition });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
