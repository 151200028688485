import { COUNTRY_MAPPER } from '@constants/common';
import { DetailEditPageContainer } from '@containers/strategy-edit/DetailEditPageContainer';
import { FC } from 'react';

interface StrategyDetailEditPageProps {
  reEdit?: boolean;
}
export const UsStrategyDetailEditPage: FC<StrategyDetailEditPageProps> = ({
  reEdit = false,
}) => {
  return (
    <DetailEditPageContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />
  );
};
