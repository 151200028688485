import { LineChart, SelectButton } from '@components/common';
import { DescInfo } from '@components/common/DescInfo';
import {
  HoldingPositionInfo,
  RebalancingHistory,
  ReturnRateInfo,
  RiskAssessmentIndex,
  SectionTemplate,
  SectorIndustryComp,
  StrategyStyleInfo,
} from '@components/strategy';
import {
  COUNTRY_MAPPER,
  CUMULATIVE_RETURN_TOOLTIP,
  ORGANIZING_STOCKS_TOOLTIP,
  REBALANCING_HISTORY_TOOLTIP,
  REVENUE_ANALYTICS_TOOLTIP,
  SECTOR_INDUSTRY_TOOLTIP,
  STRATEGY_STYLE_TOOLTIP,
} from '@constants/common';
import {
  REBALANCING_OPTION_LOCALE_SENTENCE,
  SIMULATION_TYPE_MAPPER,
} from '@constants/strategy';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { EditCancelBtn } from '@containers/strategy-edit/DetailEditPageContainer';
import { useEditResultController } from '@hooks/edit/useEditResultController';
import { useMyStrategyLoader } from '@hooks/useMyStrategyLoader';
import { useProfitSelector } from '@hooks/useProfitSelector';
import { CountryCodeType } from '@models/common';
import { useMyStrategyStore } from '@stores/my-strategy';
import { FlexBox } from '@ui/components';
import { FC, useMemo } from 'react';

interface MyStrategyEditPageResultContainerProps {
  renew?: boolean;
  prevStrategyId?: string;
  countryCode?: CountryCodeType;
}

export const MyStrategyEditResultContainer: FC<
  MyStrategyEditPageResultContainerProps
> = ({
  renew = false,
  prevStrategyId = '',
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const { strategyId } = useMyStrategyLoader('ALL');
  const {
    changeBenchmarkOption,
    category,
    detailedStrategyInfo,
    benchmarkOption,
    benchmarkOptions,
    strategyStyle,
    cumulativeReturn,
    riskAssessmentIndex,
    exposure,
    holdingPosition,
    rebalancingHistory,
  } = useMyStrategyStore();
  const { bottomsheet, selectedBenchmark, handleSelect } = useProfitSelector({
    strategyId,
    benchmarkOption,
    benchmarkOptions,
    simulationType: SIMULATION_TYPE_MAPPER.ALL,
    changeBenchmarkOption,
  });

  const { handleCancel, handleCancelFlotter, handleOkFlotter } =
    useEditResultController({
      strategyId,
      prevStrategyId,
      renew,
      countryCode,
    });

  const isDIY = category === 'DIY';
  const isMaster = category === 'MASTER';
  const myStrategyValue =
    detailedStrategyInfo.myCumulativeReturnRate?.cumulative;
  const secondLabel = useMemo(() => {
    if (!detailedStrategyInfo.myCumulativeReturnRate || !category) return '-';
    return category === 'DIY' || category === 'MASTER'
      ? selectedBenchmark
      : '프리셋(Pre-set)';
  }, [
    detailedStrategyInfo.myCumulativeReturnRate,
    category,
    selectedBenchmark,
  ]);
  const secondValue =
    isDIY || isMaster
      ? detailedStrategyInfo.benchmarkCumulativeReturnRate?.cumulative
      : detailedStrategyInfo.baseCumulativeReturnRate?.cumulative;
  const thirdLabel =
    category === 'SECTOR' || category === 'THEME'
      ? selectedBenchmark
      : undefined;
  const thirdValue =
    detailedStrategyInfo.benchmarkCumulativeReturnRate?.cumulative;

  return (
    <LayoutContainer
      extended
      tagColor="orange"
      tagName="백테스트 결과"
      title={detailedStrategyInfo.name}
      floater={{
        variant: 'double',
        okText: '전략저장',
        cancelText: '다시 편집하기',
        onClickCancel: handleCancelFlotter,
        onClickOk: handleOkFlotter,
      }}
      optionalButton={EditCancelBtn(handleCancel)}
      onClickPrev={handleCancel}
    >
      <SectionTemplate
        description={detailedStrategyInfo.baseDate}
        minHeight={138}
        title="누적수익률"
        infoDescription={CUMULATIVE_RETURN_TOOLTIP}
      >
        <FlexBox direction="column">
          <ReturnRateInfo
            type="double"
            label="나의 전략"
            value={myStrategyValue}
            secondLabel={secondLabel}
            secondValue={secondValue}
            thirdLabel={thirdLabel}
            thirdValue={thirdValue}
          />
          <DescInfo priceInfo />
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={strategyStyle.baseDate}
        minHeight={415}
        infoDescription={STRATEGY_STYLE_TOOLTIP}
        title="전략스타일 분석"
      >
        <StrategyStyleInfo strategyStyle={strategyStyle} />
      </SectionTemplate>

      <SectionTemplate
        description={cumulativeReturn.baseDate}
        infoDescription={REVENUE_ANALYTICS_TOOLTIP}
        minHeight={716}
        title="수익 분석"
      >
        <FlexBox direction="column" spacing={32}>
          <FlexBox direction="column" spacing={24}>
            <FlexBox>
              <SelectButton
                variant="white"
                open={bottomsheet}
                placeholder="벤치마크"
                selectedValue={selectedBenchmark}
                onClick={handleSelect('benchmark')}
              />
            </FlexBox>
            <FlexBox direction="column" height={284}>
              {cumulativeReturn.data.length > 0 && (
                <LineChart data={cumulativeReturn} />
              )}
            </FlexBox>
            {riskAssessmentIndex && (
              <FlexBox direction="column">
                <RiskAssessmentIndex {...riskAssessmentIndex} />
                <DescInfo />
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={exposure.baseDate}
        minHeight={294}
        title="섹터/업종 구성"
        infoDescription={SECTOR_INDUSTRY_TOOLTIP}
      >
        <SectorIndustryComp variant="double" data={exposure.data} />
      </SectionTemplate>
      <SectionTemplate
        description={holdingPosition.baseDate}
        minHeight={364}
        title="종목구성(Top5)"
        infoDescription={ORGANIZING_STOCKS_TOOLTIP}
      >
        <HoldingPositionInfo
          cash={holdingPosition.cash}
          stockList={holdingPosition.data}
        />
      </SectionTemplate>

      <SectionTemplate
        description={rebalancingHistory.baseDate}
        minHeight={620}
        subDescription={rebalancingHistory.rebalancing}
        title="리밸런싱 내역"
        infoDescription={REBALANCING_HISTORY_TOOLTIP}
      >
        <RebalancingHistory
          color="orange"
          barChartData={rebalancingHistory.data}
          disableChart={
            rebalancingHistory.rebalancing ===
            REBALANCING_OPTION_LOCALE_SENTENCE.NONE
          }
        />
        <DescInfo />
      </SectionTemplate>
    </LayoutContainer>
  );
};
