import { COUNTRY_MAPPER } from '@constants/common';
import { MasterEditPageContainer } from '@containers/strategy-edit/MasterEditPageContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const MyUsStrategyMasterEditPage: FC = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <MasterEditPageContainer
      renew
      prevStrategyId={prevStrategyId}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
