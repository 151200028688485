import { iosRegex, mobileRegex, windowRegex } from '@constants/common';
/**
 * AOS인지 아닌지 판단하는 함수
 * @returns {boolean} Android인지 아닌지 판단하는 함수
 */
export const isAndroid = () => {
  const userAgentLow = navigator.userAgent.toLocaleLowerCase();
  return userAgentLow.search('android') > -1;
};

/**
 * IOS인지 아닌지 판단하는 함수
 * @returns {boolean} IOS인지 아닌지 판단하는 함수
 */
export const isIOS = () => {
  return navigator.userAgent.match(iosRegex) == null ? false : true;
};

/**
 * Windows Phone인지 아닌지 판단하는 함수
 * @returns {boolean} Windows Phone인지 아닌지 판단하는 함수
 */
export const isWindowPhone = () => {
  return navigator.userAgent.match(windowRegex) == null ? false : true;
};

/**
 * Mobile Device인지 아닌지 판단하는 함수
 * @returns {boolean} Mobile Device인지 아닌지 판단하는 함수
 */
export const isMobile = () => {
  const userAgentLow = navigator.userAgent.toLocaleLowerCase();
  return userAgentLow.match(mobileRegex) == null ? false : true;
};

/**
 * DarkMode 인지 아닌지 판단하는 함수
 * @returns {boolean} DarkMode 인지 아닌지 판단하는 함수
 */
export const isDarkMode = () => {
  return window.navigator.userAgent.includes('m-able_dark');
};

/**
 * userAgent가 m-able인지 확인하는 함수
 * @returns {boolean} userAgent가 m-able인지 확인하는 함수
 */
export const isMable = () => {
  return window.navigator.userAgent.includes('m-able');
};
