import { RadarChart } from '@components/common';
import { MyStrategyStyleDetailInfo } from '@components/myStrategy';
import { StrategyStyleModel } from '@models/strategy';
import { FlexBox } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface StrategyStyleInfoProps {
  strategyStyle: StrategyStyleModel;
}

const StrategyStyleInfoWrap = styled(FlexBox)``;

const StrategyStyleChartWrap = styled.div`
  height: 287px;
`;

export const StrategyStyleInfo: FC<StrategyStyleInfoProps> = ({
  strategyStyle: { chartData, detailInfo },
}) => {
  const hasStyleData = chartData.data.length > 0;
  return (
    <StrategyStyleInfoWrap direction="column" spacing={16}>
      <StrategyStyleChartWrap>
        {hasStyleData && <RadarChart chartData={chartData} hasLegend />}
      </StrategyStyleChartWrap>
      {hasStyleData && detailInfo && (
        <MyStrategyStyleDetailInfo strategyStyleDetailInfo={detailInfo} />
      )}
    </StrategyStyleInfoWrap>
  );
};
