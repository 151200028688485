import { initChart } from '@constants/common';
import { InvestmentStrategyLevelEnum } from '@graphql/generates';
import {
  MyStrategyBasicInfoModel,
  RiskAssessmentIndexModel,
  StrategyStyleModel,
} from '@models/strategy';

export const initMyRiskAssessmentIndex: RiskAssessmentIndexModel = {
  head: [
    { width: 80, text: '구분' },
    { width: 80, text: '나의 전략' },
    { width: 80, text: '' },
  ],
  data: {
    standard: [
      ['수익률', '-', '-'],
      ['변동성', '-', '-'],
      ['샤프지수', '-', '-'],
      ['MDD', '-', '-'],
    ],
    year: [
      ['수익률', '-', '-'],
      ['변동성', '-', '-'],
      ['샤프지수', '-', '-'],
      ['MDD', '-', '-'],
    ],
  },
};

export const initMyStrategyBasicInfo: MyStrategyBasicInfoModel = {
  createdAt: '',
  baseStrategyName: '',
  universePresetName: '',
  selectedCount: 0,
  sectorIndustrySummary: {
    selectedCount: 0,
    sectors: [],
    industries: [],
  },
};

export const initStrategyStyle: StrategyStyleModel = {
  baseDate: '',
  chartData: { ...initChart },
  detailInfo: [],
};

export const createInitForwardSummary = () => ({
  investmentPeriod: ['----/--/--'],
  investmentAmount: '- 원',
  valuationAmount: '- 원',
  valuationProfit: '- 원',
  valuationExchange: '- 원',
});

export const createInitMyStrategyState = () => ({
  portfolioId: undefined,
  name: '',
  category: undefined,
  createdAt: +new Date(),
  isSimple: false,
  isActiveKbContract: false,
  isForwardAvailable: false,
  detailedStrategyInfo: {
    name: '',
    forwardSummary: createInitForwardSummary(),
    forwardSummaryExchange: createInitForwardSummary(),
    exchangeRateInfo: '',
    createdExchangeRateInfo: '',
  },
  benchmarkOption: undefined,
  benchmarkOptions: [],
  strategyStyle: { ...initStrategyStyle },
  cumulativeReturn: { ...initChart },
  riskAssessmentIndex: { ...initMyRiskAssessmentIndex },
  exposure: { ...initChart },
  holdingPosition: { ...initChart },
  rebalancingHistory: { ...initChart, legendList: [], rebalancing: '' },
  universePreset: undefined,

  myStrategyBasicInfo: { ...initMyStrategyBasicInfo },
  mySimulationOptionInfo: [],
  myStrategyBlacklistInfo: [],

  investmentStrategyLevel: InvestmentStrategyLevelEnum.ExpertLevelPro,
});
