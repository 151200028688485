import { REBALANCING_OPTION_LOCALE_SENTENCE } from '@constants/strategy';
import {
  BaseDate,
  PositionInfoFragment,
  RebalancingHistoryType,
  StrategyRebalancingOptionEnum,
} from '@graphql/generates';
import {
  ChartWeightDto,
  HistoryInfoItemDto,
  RebalancingHistoryModel,
} from '@models/common';
import { MyStockCompositionDto } from '@models/strategy';
import {
  getDateDiffStr,
  getDateFormattedString,
  numberToPercent,
  numberWithCommas,
} from '@utils/common';

/**
 * 리밸런싱 옵션을 넘겨받아 REBALANCING_OPTION_LOCALE_SENTENCE 상수로 변환하는 함수.
 * @param {string} optionData - 리밸런싱 옵션.
 * @returns {string}
 */
export const createRebalancingOption = (
  optionData: StrategyRebalancingOptionEnum | null | undefined
) => {
  let result = REBALANCING_OPTION_LOCALE_SENTENCE.NONE;

  if (optionData) {
    result = REBALANCING_OPTION_LOCALE_SENTENCE[optionData];
  }

  return result;
};

/**
 * baseDate 객체를 넘겨받아 period(또는 [period, diffString])을 반환하는 함수.
 * @param {Object} baseDate - 기준일 정보.
 * @param {number} start - 기준일 시작일자.
 * @param {number} end - 기준일 마지막일자.
 * @param {boolean} diff start와 end 기간의 간격(일수) 반환 여부.
 * @returns {string | string[]}
 */
export const createPeriod = (
  baseDate?: BaseDate | null | undefined,
  diff?: boolean
) => {
  let _baseDate = [];
  let diffString = '';

  if (baseDate) {
    const { start, end } = baseDate;
    if (start) {
      _baseDate.push(getDateFormattedString(start, 'YYYY/mm/dd'));
      if (diff && end) {
        const startDate = Math.floor(start);
        const endDate = Math.floor(end);
        const _diffString = getDateDiffStr(startDate, endDate, 'day', true, 1);
        if (_diffString) {
          diffString = `(${_diffString || 0})`;
        }
      }
    }
    if (end) {
      _baseDate.push(getDateFormattedString(end, 'YYYY/mm/dd'));
    }
  }

  const period = _baseDate.join('~');

  if (diff) return [period, diffString];

  return period;
};

/**
 * baseDate 객체를 넘겨받아 기준일자 문자열을 반환하는 함수.
 * @param {Object} baseDate - 기준일 정보.
 * @param {number} baseDate.start - 기준일 시작일자.
 * @param {number} baseDate.end - 기준일 마지막일자.
 * @returns {string}
 */
export const createBaseDate = (baseDate?: BaseDate | null | undefined) => {
  if (baseDate) {
    const { start, end } = baseDate;
    if (start || end) {
      return `*기준일자:${createPeriod(baseDate)}`;
    }
  }

  return '';
};

/**
 * 리밸런싱 이력에서 공용으로 사용되는 변환 작업 로직 함수로 구성.
 * @param {Object} data - RebalancingHistoryType 데이터.
 * @param {Object} data.baseDate - 기준일 정보.
 * @param {Object} data.info - 리밸런싱 이력 정보.
 * @param {string} data.rebalancing - 리밸런싱 주기 옵션.
 * @return {RebalancingHistoryModel}
 */
export const getRebalancingHistoryModel = (data: RebalancingHistoryType) => {
  const { baseDate, info, rebalancing } = data;
  const _baseDate = createBaseDate(baseDate);
  const historyList: HistoryInfoItemDto[] = [];
  const legendList: ChartWeightDto[] = [];
  const _rebalancing = createRebalancingOption(rebalancing);

  if (info && info.length > 0) {
    info.forEach((item: any, index: number) => {
      if (item?.ts && item.holdings) {
        const chartItemObj: HistoryInfoItemDto = {
          date: getDateFormattedString(item.ts, 'YYYY/mm/dd'),
          cash: item.cash?.weight || 0,
          stockList: [],
        };
        const isLastIndex = info.length - 1 === index;
        item.holdings.forEach((item: any) => {
          if (item?.name && item.weight) {
            chartItemObj.stockList.push({
              name: item.name,
              weight: item.weight,
            });
            if (isLastIndex) {
              legendList.push({
                name: item.name,
                weight: item.weight,
              });
            }
          }
        });
        historyList.push(chartItemObj);
      }
    });
  }

  const result: RebalancingHistoryModel = {
    baseDate: _baseDate,
    data: historyList,
    legendList,
    rebalancing: _rebalancing,
  };

  return result;
};

/**
 * float number를 넘겨받아 소수점 2자리까지 표현하는 함수.
 * @param {number} float 변환할 float number.
 * @returns {string}
 */
export const transFloatToPercentage = (float: number) => {
  return `${numberToPercent(float)}%`;
};

/**
 * PositionInfoFragment[]를 넘겨받아 MyStockCompositionDto[]로 반환하는 함수.
 * @param {Object[]} data - 종목구성 섹션의 종목 정보.
 * @param {string} data[].name - 종목명.
 * @param {number} data[].weight - 종목 비중.
 * @param {number} data[].volume - 종목 수량.
 * @param {number} data[].price - 종목 가격.
 * @param {boolean} extended 추가 정보 반환 여부.
 * @returns {Object[]}
 */
export const createHoldingPositions = (
  data: PositionInfoFragment[],
  extended?: boolean
) => {
  const positions: MyStockCompositionDto[] = [];
  for (const positionItem of data) {
    if (!positionItem) continue;
    const position: MyStockCompositionDto = {
      name: positionItem.name,
      percent: positionItem.weight
        ? transFloatToPercentage(positionItem.weight)
        : '-',
    };
    if (extended) {
      position.volume = positionItem.volume
        ? numberWithCommas(positionItem.volume)
        : '-';
      position.price =
        positionItem.price && positionItem.volume
          ? numberWithCommas(positionItem.price * positionItem.volume)
          : '-';
    }
    positions.push(position);
  }
  return positions;
};
