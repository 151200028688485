import {
  GNB_FLAG_MAPPER,
  GNB_FLAG_MENU,
  GNB_LOCALE,
  GNB_MENU,
  MIN_WIDTH,
} from '@constants/layout.const';
import { ContentWidthModel, NavigationModel } from '@models/layout.model';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { createScrollLockCss } from '@utils/layout';
import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';

interface NavigationProps extends NavigationModel {}

interface DimmedProps extends ContentWidthModel {}

const NavigationWrap = styled.nav`
  position: relative;
  width: 100%;
`;

const Bar = styled(FlexBox)``;

const Menu = styled(FlexBox)`
  background-color: ${({ theme }) => theme.colorPalette.white};
  position: absolute;
  width: 100%;
  top: 36.5px;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

const MenuItem = styled.li<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 10.5px 0;
  text-align: center;
  width: 100%;
  &:active {
    background-color: ${({ theme }) => theme.colorPalette.black[3]};
  }

  ${({ selected, theme }) => {
    if (selected) {
      return css`
        background-color: ${theme.colorPalette.black[3]};
      `;
    }
  }}
`;

const Dimmed = styled.div<DimmedProps>`
  background-color: ${({ theme }) => theme.colorPalette.background.dimmed};
  height: calc(100vh - 48px);
  opacity: 0.5;
  bottom: 0;
  top: 48px;
  position: fixed;
  ${({ contentWidth }) => {
    return css`
      width: ${contentWidth}px;
    `;
  }}
`;

export const Navigation: FC<NavigationProps> = ({
  contentWidth = MIN_WIDTH,
  pathname = '',
  onNavigation,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setOpen((v) => !v);
  };

  const handleDimmed = () => {
    setOpen((v) => !v);
  };

  const handleMenu = (page: string) => {
    setOpen((v) => !v);
    if (onNavigation) {
      onNavigation(page);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.cssText = createScrollLockCss();
    } else {
      document.body.style.cssText = '';
    }
  }, [open]);

  return (
    <NavigationWrap>
      <Bar role="button" justify="center" spacing={6} onClick={handleClick}>
        {GNB_FLAG_MENU[pathname] && (
          <img src={GNB_FLAG_MAPPER[pathname]} alt="Flag" width={22} />
        )}
        <Typography as="h1" variant="h7_title" color="black.87">
          {GNB_LOCALE[pathname]}
        </Typography>
        <Icon type={`arrow-${open ? 'up' : 'down'}`} size={24} />
      </Bar>
      {open && <Dimmed contentWidth={contentWidth} onClick={handleDimmed} />}
      {open && (
        <Menu direction="column" alignItems="center">
          {GNB_MENU.map(({ name, page }, index) => {
            const _page = `/${page}`;
            const selected = pathname === _page;
            return (
              <MenuItem
                key={`nav_menu_${index}`}
                selected={selected}
                onClick={() => handleMenu(_page)}
              >
                {GNB_FLAG_MENU[_page] && (
                  <img src={GNB_FLAG_MAPPER[_page]} alt="Flag" width={22} />
                )}
                <Typography
                  variant="body1_title"
                  color={selected ? 'secondary.text' : 'black.87'}
                >
                  {name}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </NavigationWrap>
  );
};
