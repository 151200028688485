interface AppEnvironment {
  production: boolean;
  development: boolean;
  /**
   * 현재 앱에서 기본적으로 쓰이는 API URL
   */
  apiBaseUrl: string;
  /**
   * Access Token을 관리하기 위한 Cookie의 Key 값
   */
  authTokenKey: string;
  /**
   * KB CI를 관리하기 위한 LocalStorage의 Key 값
   */
  ciKey: string;
}

const env: AppEnvironment = {
  production: process.env.REACT_APP_ENV === 'production',
  development: process.env.REACT_APP_ENV === 'development',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  authTokenKey: process.env.REACT_APP_AUTH_TOKEN_KEY || '',
  ciKey: process.env.REACT_APP_CI_STORAGE_KEY || '',
};

export default env;
