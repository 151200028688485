import { Tag } from '@components/common';
import { FactorInfoButton } from '@components/strategy-edit/FactorInfoButton';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { svgPath } from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

const FactorBox = styled.button`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;

  &.open-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
  }

  .sector-content {
    border-bottom: 1px solid
      ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  }

  &.open {
    border-radius: 8px 8px 0px 0px;
  }
`;

const SubFactorWrap = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.black[1]};
  padding-bottom: 16px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-width: 0px 1px 1px 1px;
`;

const SubFactorBox = styled(FlexBox)`
  margin: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.column};
`;

const SubFactorBtn = styled.div`
  padding: 16px 0;
  text-align: left;

  & > div {
    & > img {
      align-self: baseline;
    }
  }
`;

const SubFactorDescBox = styled.div`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
  border-radius: 8px;
  min-height: 42px;
`;

const SubFactorTitle = styled.div`
  display: inline;
  & > p {
    display: inline;
  }
`;

export const FactorSelectContainer: FC = () => {
  const {
    factors,
    factorCheckObj,
    factorToggleObj,
    setToggleFactorObj,
    setFactorCheckObj,
  } = useStrategyEditStore();

  const factorCheckedCnt = Object.values(factorCheckObj).filter(
    (value) => !!value
  ).length;

  const handleClickSubFactorChecked = (cmName: string) => () => {
    setFactorCheckObj(cmName);
  };

  return (
    <FlexBox direction="column" spacing={16}>
      <FlexBox>
        <Typography variant="caption_medium" color="secondary.text">
          {factorCheckedCnt}
        </Typography>
        <Typography variant="caption_medium" color="black.87">
          개 선택됨
        </Typography>
      </FlexBox>
      {factors.map((factor) => {
        const enableToggleFactor = factor.subs.length > 1;
        const selectedLength = factor.subs.filter(
          ({ cmName }) => factorCheckObj[cmName]
        ).length;
        return (
          <FlexBox direction="column" key={factor.name}>
            <FactorBox
              className={factorToggleObj[factor.name] ? 'open' : ''}
              onClick={() => {
                setToggleFactorObj(factor.name);
              }}
            >
              <FlexBox justify="space-between">
                <FlexBox spacing={4} alignItems="center">
                  <Typography variant="body1_title" color="black.100">
                    {factor.text}
                  </Typography>
                  {enableToggleFactor && <Tag>{factor.subs.length}</Tag>}
                  {selectedLength > 0 && (
                    <Tag color="intensiveBlue">{selectedLength}개 선택</Tag>
                  )}
                </FlexBox>
                {enableToggleFactor && (
                  <Icon
                    type={
                      factorToggleObj[factor.name] ? 'arrow-up' : 'arrow-down'
                    }
                  />
                )}
              </FlexBox>
              <Typography
                variant="caption"
                color="black.60"
                mt={8}
                align="left"
              >
                {factor.desc}
              </Typography>
            </FactorBox>
            {factorToggleObj[factor.name] && enableToggleFactor && (
              <SubFactorWrap>
                {factor.subs.map((subFactor) => (
                  <SubFactorBox key={subFactor.name} direction="column">
                    <SubFactorBtn
                      role="button"
                      onClick={handleClickSubFactorChecked(subFactor.cmName)}
                    >
                      <FlexBox spacing={8} alignItems="center">
                        <Icon
                          type={
                            factorCheckObj[subFactor.cmName]
                              ? 'checkbox-selected'
                              : 'checkbox-default'
                          }
                        />
                        <SubFactorTitle>
                          <Typography
                            variant="body1_title"
                            color="black.100"
                            mr={4}
                          >
                            {subFactor.name}
                          </Typography>
                          <FactorInfoButton
                            title={subFactor.name}
                            description={subFactor.desc}
                            img={subFactor.img}
                            comments={subFactor.comments}
                          />
                        </SubFactorTitle>
                      </FlexBox>
                      <SubFactorDescBox>
                        <img
                          src={svgPath('/image/factor', subFactor.img)}
                          alt={`${subFactor.name} Icon`}
                          width="100%"
                        />
                      </SubFactorDescBox>
                    </SubFactorBtn>
                  </SubFactorBox>
                ))}
              </SubFactorWrap>
            )}
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
