import { MasterEditPageContainer } from '@containers/strategy-edit/MasterEditPageContainer';
import { FC } from 'react';

interface StrategyMasterEditPageProps {
  reEdit?: boolean;
}
export const StrategyMasterEditPage: FC<StrategyMasterEditPageProps> = ({
  reEdit,
}) => {
  return <MasterEditPageContainer reEdit={reEdit} />;
};
