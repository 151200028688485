const BACKGROUND_COLOR_PALETTE_DICTIONARY = {
  dimmed: 'rgba(0, 0, 0, 0.5)',
  table: '#F7F9FC',
  table_highlight: 'rgba(255, 204, 0, 0.08)',
  box: 'rgba(239, 239, 239, 0.25)',
  input_box: '#ffffff',
  general_button_2: 'rgba(239, 239, 239, 0.3)',
  general_button_2_pressed: 'rgba(239, 239, 239, 0.7)',
  error: '#FFF1F1',
} as const;

const BLACK_COLOR_PALETTE_DICTIONARY = {
  1: '#FCFCFC',
  3: '#F8F8F8',
  7: '#EEEEEE',
  13: '#DDDDDD',
  40: '#999999',
  47: '#888888',
  60: '#666666',
  73: '#444444',
  87: '#222222',
  100: '#000000',
} as const;

const BORDER_COLOR_PALETTE_DICTIONARY = {
  default: '#d9d9d9',
  button: '#CCCCCC',
  chip: '#E8E8E8',
  input: '#E5E5E5',
  select: '#E5E5E5',
  chartgrid: '#CCCCCC',
} as const;

const DIVIDER_COLOR_PALETTE_DICTIONARY = {
  appbar_tab: '#DDDDDD',
  title: '#EEEEEE',
  sub_filter: '#F4F4F4',
  list: '#F8F8F8',
  column: '#EEEEEE',
  contents: '#F8F8F8',
  tables: '#F0F0F0',
  benchmark: '#A1A0B4',
} as const;

const GRAY_COLOR_PALETTE_DICTIONARY = {
  blue: '#959CAB',
} as const;

const KB_COLOR_PALETTE_DICTIONARY = {
  yellow: '#FFDC00',
  yellow_pressed: '#FFCC00',
  gray: '#60584C',
} as const;

const SECONDARY_COLOR_PALETTE_DICTIONARY = {
  default: '#5B86D4',
  bright: '#5B86D4',
  text: '#5177BC',
  chip: 'rgba(91, 134, 212, 0.1)',
};

const STATE_COLOR_PALETTE_DICTIONARY = {
  red: '#DE092F',
  blue: '#2859D1',
  green: '#009989',
  purple: '#625BD4',
  snackbar: 'rgba(34, 45, 74, 0.85)', // #222D4A,
} as const;

/**
 * Color 키워드 이름으로 구성된 배열
 */
export const COLOR_PALETTE_KEYWORD_LIST = [
  'background.dimmed',
  'background.table',
  'background.table_highlight',
  'background.error',

  'black.1',
  'black.3',
  'black.7',
  'black.13',
  'black.40',
  'black.47',
  'black.60',
  'black.73',
  'black.87',
  'black.100',

  'border.default',
  'border.button',
  'border.chip',

  'divider.appbar_tab',
  'divider.title',
  'divider.sub_filter',
  'divider.list',
  'divider.column',
  'divider.contents',
  'divider.tables',

  'gray.blue',

  'kb.yellow',
  'kb.yellow_pressed',
  'kb.gray',

  'state.red',
  'state.blue',
  'state.green',
  'state.purple',

  'secondary.default',
  'secondary.text',
  'secondary.bright',

  'white',
  'fixedblack',
  'fixedwhite',
] as const;

export const COLOR_PALETTE_LIST = [...COLOR_PALETTE_KEYWORD_LIST] as const;

export const COLOR_PALETTE = {
  background: BACKGROUND_COLOR_PALETTE_DICTIONARY,
  border: BORDER_COLOR_PALETTE_DICTIONARY,
  divider: DIVIDER_COLOR_PALETTE_DICTIONARY,
  secondary: SECONDARY_COLOR_PALETTE_DICTIONARY,
  black: BLACK_COLOR_PALETTE_DICTIONARY,
  gray: GRAY_COLOR_PALETTE_DICTIONARY,
  kb: KB_COLOR_PALETTE_DICTIONARY,
  state: STATE_COLOR_PALETTE_DICTIONARY,
  white: '#FFFFFF',
  fixedblack: '#222222',
  fixedwhite: '#FFFFFF',
} as const;
