import { create } from 'zustand';
import {
  StrategyEditCommonSlice,
  createStrategyEditCommonSlice,
} from './common';
import { FactorEditSlice, createFactorEditSlice } from './factor-edit';
import {
  MasterStrategySlice,
  createMasterStrategySlice,
} from './master-strategy';
import {
  SectorIndustrySlice,
  createSectorIndustrySlice,
} from './sector-industry';
import {
  SimulationOptionSlice,
  createSimulationOptionSlice,
} from './simulation-option';
import { StockSelectorSlice, createStockSelectorSlice } from './stock-selector';
import { createInitStrategyEditState } from './strategy-edit.init';

export interface StrategyEditState
  extends StrategyEditCommonSlice,
    SectorIndustrySlice,
    FactorEditSlice,
    SimulationOptionSlice,
    StockSelectorSlice,
    MasterStrategySlice {}

export const useStrategyEditStore = create<StrategyEditState>()((...a) => ({
  ...createStrategyEditCommonSlice(...a),
  ...createSectorIndustrySlice(...a),
  ...createFactorEditSlice(...a),
  ...createSimulationOptionSlice(...a),
  ...createStockSelectorSlice(...a),
  ...createMasterStrategySlice(...a),
  ...createInitStrategyEditState(),
}));

export const useStockSelectorAction = () =>
  useStrategyEditStore((state) => state.stockSelectorAction);

export const useStockSelectorState = () =>
  useStrategyEditStore((state) => {
    return {
      holdings: state.holdings,
      excludeHoldings: state.excludeHoldings,
      totalWeight: state.totalWeight,
    };
  });
