import { LOADING_MESSAGES } from '@constants/feedback';
import { WhitelistInputItem } from '@graphql/auth';
import {
  GetVolumePositionQueryVariables,
  SimulationOptionScreeningInput,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import {
  HoldingModel,
  PresetType,
  StockItemModel,
  StrategyEditHoldingNotiType,
  WhiteListStockItemModel,
  WhiteListStockObjectType,
} from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { usePopupStore } from '@stores/feedback/popup.store';
import { useToastStore } from '@stores/feedback/toast.store';
import { useMyStrategyStore } from '@stores/my-strategy';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import _ from 'lodash';
import { StateCreator } from 'zustand';
import { StrategyEditState } from '../strategy-edit.store';
import {
  convertHoldings,
  convertMyHoldings,
  convertMyVolumePositions,
  convertVolumePositions,
} from './stock-selector.convert';
import {
  checkEnableAddStock,
  checkIsAlreadyAdded,
  getErrorCodeLessThanMinWeight,
  getWhitelistInput,
  initWhiteListLastEdit,
  updateScreeningIfSectorIndustry,
  whiteListObjFilteredAdd,
} from './stock-selector.helper';
import { createStockSelectorState } from './stock-selector.init';

export interface StockSelectorSliceState {
  baseHoldings?: string[];
  /**
   * 처음 화면 집입시의 종목 리스트
   */
  originHoldings: StockItemModel[];
  /**
   * 종목 리스트 (변동)
   */
  holdings: StockItemModel[];
  /**
   * 종목의 갯수 / volume(갯수)가 0인 항목을 제외하고서 카운팅
   */
  holdingsCnt: number;
  /**
   * 제외 종목 리스트
   */
  excludeHoldings: StockItemModel[];
  /**
   * 종목 비중 합계
   */
  totalWeight: number;
  /**
   * 종목 검색 리스트
   */
  searchedHoldings: HoldingModel[];
  /**
   *  추가된 종목 리스트
   */
  whitelistObj: WhiteListStockObjectType;
}

export interface StockSelectorAction {
  /**
   * 종목 추가
   */
  addStockItem: () => void;

  /** 원래 비중대로 */
  resetStockList: () => void;

  /**
   * 제외된 종목 제거 (다시 종목에 포함)
   */
  includeStockItem: (stock: StockItemModel) => void;

  /**
   *
   */
  updateWhiteList: (ccid: string) => WhitelistInputItem[];
}

export interface StockSelectorSlice extends StockSelectorSliceState {
  stockSelectorAction: StockSelectorAction;

  /**
   * 종목 호출
   * @param {string} strategyId 전략 ID
   * @param {boolean} isSimple 간편 편집 여부
   * @param {boolean} isRenew 재편집 여부
   */
  fetchHoldings: (
    strategyId: string,
    isSimple: boolean,
    option: SimulationOptionScreeningInput
  ) => Promise<number>;

  /** 초기화면 진입시 Holdings Fetch */
  fetchInitHoldings: (strategyId: string, isSimple: boolean) => void;

  /** 종목 편/출입 */
  fetchVolumeHoldings: (
    queryObj: GetVolumePositionQueryVariables
  ) => Promise<void>;

  /** WhiteList에 따른 종목 Fetch */
  fetchVolumeHoldingsOptional: (
    query: Partial<GetVolumePositionQueryVariables>
  ) => Promise<void>;

  /** 종목 초기화 함수 */
  initHoldings: () => void;

  /** 종목 검색 초기화 */
  initSearchedHoldings: () => void;

  /**
   *  종목의 비중 버튼 클릭시
   */
  changeWeight: () => void;

  /**
   * 종목 초기화
   */
  resetStockItem: (stock: StockItemModel) => void;

  /**
   * 종목 제거 (비중고정인 항목을 제외했을 경우)
   */
  removeStockItem: (stock: StockItemModel) => void;

  /**
   * 종목 비중 변경 ( '+' | '-' 버튼을 통한)
   */
  changeStockWeight: (whiteListItem: WhiteListStockItemModel) => void;

  /**
   * 종목 비중 변경 (input 입력을 통한)
   */
  changeStockWeightByInput: (whiteListItem: WhiteListStockItemModel) => void;

  /**
   * 백테스팅 생성 전 종목 유효성 검사
   */
  validateWhiteList: () => boolean;

  /**
   * '금액만 선택' 시 종목 유효성 검사
   */
  checkHoldingsValidate: (
    strategyId: string,
    isSimple: boolean
  ) => Promise<number>;
}

export const createStockSelectorSlice: StateCreator<
  StrategyEditState,
  [],
  [],
  StockSelectorSlice
> = (set, get) => ({
  ...createStockSelectorState(),
  stockSelectorAction: {
    addStockItem: () => {
      const { openPopup } = usePopupStore.getState();
      const { stockSelectorAction, fetchVolumeHoldingsOptional } = get();
      const { closePopup } = usePopupStore.getState();

      if (!checkEnableAddStock()) return;

      openPopup('stockSearch')({
        onClickItem(ccid) {
          if (checkIsAlreadyAdded(ccid)) return;
          const whitelist = stockSelectorAction.updateWhiteList(ccid);
          fetchVolumeHoldingsOptional({ whitelist });
          closePopup('stockSearch');
        },
      });
    },
    resetStockList: () => {
      const {
        holdings,
        originHoldings,
        excludeHoldings,
        whitelistObj,
        fetchVolumeHoldingsOptional,
      } = get();
      const { openToast } = useToastStore.getState();

      // 기존 종목이랑 같다면 호출 하지 않음
      if (_.isEqual(holdings, originHoldings)) return;

      const newWhitelistObj = whiteListObjFilteredAdd(whitelistObj);
      const whitelist = getWhitelistInput(newWhitelistObj);

      set(() => ({
        whitelistObj: newWhitelistObj,
      }));

      fetchVolumeHoldingsOptional({ whitelist }).then(() => {
        // 제외종목 & 추가종목이 있다면 Toast메시지 띄우기
        if (excludeHoldings.length > 0 || whitelist.length > 0) {
          openToast(`제외 종목과 추가 종목을 유지하고
  비중 조건을 초기화를 하였습니다. `);
        }
      });
    },
    includeStockItem: (stock) => {
      const { excludeHoldings, fetchVolumeHoldingsOptional } = get();
      const newExcludeHoldings = excludeHoldings.filter(
        (stockItem) => stockItem.ccid !== stock.ccid
      );

      fetchVolumeHoldingsOptional({
        blacklist: newExcludeHoldings.map((stock) => stock.ccid),
      });
    },
    updateWhiteList: (ccid: string) => {
      const { whitelistObj } = get();
      const newWhiteListObj = initWhiteListLastEdit(whitelistObj);
      newWhiteListObj[ccid] = {
        ccid,
        weight: 0,
        isAdd: true,
        isLastEdit: true,
      };
      set(() => ({ whitelistObj: newWhiteListObj, loading: true }));
      const whitelist = getWhitelistInput(newWhiteListObj);

      return whitelist;
    },
  },

  fetchInitHoldings: async (strategyId, isSimple) => {
    const { universePreset, industries, inputFactors, strategySettingObj } =
      get();
    const { booksize, optimization, startDate, rebalancing } =
      strategySettingObj;

    let simulationOptionScreening: SimulationOptionScreeningInput = {
      booksize,
      optimization,
      startDate,
      rebalancing: rebalancing === 'NONE' ? null : rebalancing,
      universePreset,
      industries,
      blacklist: [],
      whitelist: [],
      factors: inputFactors.map((factor) => ({
        cmName: factor.cmName,
        max: factor.max,
        min: factor.min,
      })),
    };

    get().fetchHoldings(strategyId, isSimple, simulationOptionScreening);
  },

  fetchHoldings: async (strategyId, isSimple, option) => {
    const { closeConfirm, openConfirm } = useConfirmStore.getState();
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.loadHoldings,
    });

    const {
      renew,
      reEdit,
      prevSimulationOptionScreening,
      originHoldings,
      styleFactors,
      stylePresetObj,
    } = get();

    let result = {};
    let holdingsCnt = 0;
    let simulationOptionScreening: SimulationOptionScreeningInput = {
      ...option,
    };

    if (isSimple) {
      simulationOptionScreening = {
        ...simulationOptionScreening,
        factors: styleFactors
          .filter((factor) => !!factor.type)
          .map((factor) => ({
            cmName: factor.cmName,
            ...stylePresetObj[factor.type as PresetType],
          })),
      };
    }

    if (renew || reEdit) {
      let _simulationOptionScreening = undefined;
      const isEqual = _.isEqual(
        simulationOptionScreening,
        prevSimulationOptionScreening
      );
      if (!isEqual) {
        _simulationOptionScreening = { ...simulationOptionScreening };
        _simulationOptionScreening = updateScreeningIfSectorIndustry(
          // fetchMySimulationOption 호출 시점에 category 세팅됨
          useMyStrategyStore.getState().category || 'THEME',
          _simulationOptionScreening
        );
      }
      const response = await repo.getMyHoldings({
        id: strategyId,
        simulationOptionScreening: _simulationOptionScreening,
      });
      const {
        excludeHoldings,
        holdings,
        totalWeight,
        whitelistObj,
        baseHoldings,
      } = convertMyHoldings({
        data: response,
        origin: originHoldings,
        whitelistObj: get().whitelistObj,
      });
      result = {
        baseHoldings,
        excludeHoldings,
        holdings,
        totalWeight,
        originHoldings: _.cloneDeep(holdings),
        whitelistObj,
      };
      holdingsCnt = holdings.length;
    } else {
      simulationOptionScreening = updateScreeningIfSectorIndustry(
        // Lv1,2,3,4: fetchDefaultOptions(내부에서 fetchStrategy 호출) 호출 시점에 category 세팅됨
        // LvPro: fetchStrategy 호출 시점에 category 세팅됨
        useReadymadeStrategyStore.getState().category,
        simulationOptionScreening
      );
      const response = await repo.getHoldings({
        strategyId,
        simulationOptionScreening,
      });
      const {
        excludeHoldings,
        holdings,
        totalWeight,
        whitelistObj,
        baseHoldings,
      } = convertHoldings({
        data: response,
        origin: originHoldings,
        whitelistObj: get().whitelistObj,
      });
      result = {
        excludeHoldings,
        holdings,
        totalWeight,
        originHoldings: _.cloneDeep(holdings),
        whitelistObj,
        baseHoldings,
      };
      holdingsCnt = holdings.length;
    }
    closeConfirm('loading');
    set(() => ({
      ...result,
      holdingsCnt,
      loading: false,
    }));

    return holdingsCnt;
  },

  fetchVolumeHoldings: async (queryObj) => {
    const { closeConfirm, openConfirm } = useConfirmStore.getState();
    const { renew, reEdit, originHoldings, whitelistObj } = get();
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.loadHoldings,
    });
    let result = {};
    if (renew || reEdit) {
      const response = await repo.getMyVolumePosition({
        myStrategyId: queryObj.strategyId,
        ...queryObj,
      });
      result = convertMyVolumePositions({
        data: response,
        origin: originHoldings,
        whitelistObj,
      });
    } else {
      const response = await repo.getVolumePosition(queryObj);
      result = convertVolumePositions({
        data: response,
        origin: originHoldings,
        whitelistObj,
      });
    }

    closeConfirm('loading');
    set(() => ({
      ...result,
      loading: false,
    }));

    return Promise.resolve();
  },

  fetchVolumeHoldingsOptional: async (queryObj) => {
    const {
      strategyId,
      strategySettingObj,
      excludeHoldings,
      whitelistObj,
      originHoldings,
      baseHoldings,
      universePreset,
      fetchVolumeHoldings,
    } = get();

    const {
      booksize,
      optimization,
      rebalancing: _rebalancing,
      startDate,
    } = strategySettingObj;

    const newQueryObj = {
      ...queryObj,
      booksize,
      optimization,
      rebalancing: _rebalancing === 'NONE' ? null : _rebalancing,
      startDate,
    };

    if (!queryObj.strategyId) {
      newQueryObj.strategyId = strategyId;
    }

    if (!queryObj.blacklist) {
      newQueryObj.blacklist = excludeHoldings.map((stock) => stock.ccid);
    }

    if (!queryObj.firstHoldings) {
      newQueryObj.firstHoldings = originHoldings.map((stock) => stock.ccid);
    }

    if (!queryObj.whitelist) {
      newQueryObj.whitelist = getWhitelistInput(whitelistObj);
    }

    if (!queryObj.baseHoldings) {
      newQueryObj.baseHoldings = baseHoldings || [];
    }

    if (!queryObj.universePreset) {
      // 대가들의 전략에서는 universePreset 필수값
      // 다른 전략의 경우 universePreset 값이 undefined라
      // payload에 전달 안됨
      newQueryObj.universePreset = universePreset;
    }

    return fetchVolumeHoldings(newQueryObj as GetVolumePositionQueryVariables);
  },

  initHoldings: () => {
    set(() => ({
      ...createStockSelectorState(),
    }));
  },

  searchedHoldings: [],
  initSearchedHoldings: () => {
    set(() => ({ searchedHoldings: [] }));
  },
  changeWeight: () => {
    const { holdings, totalWeight } = get();
    const newHoldings = holdings.map((holding) => {
      return {
        ...holding,
        weight: totalWeight / holdings.length,
      };
    });
    set(() => ({ holdings: newHoldings }));
  },

  resetStockItem: (stock) => {
    const { whitelistObj, changeStockWeight, fetchVolumeHoldingsOptional } =
      get();

    if (stock.isAdd) {
      changeStockWeight({
        ccid: stock.ccid,
        weight: 0,
        isAdd: true,
        isLastEdit: false,
      });
    } else {
      const newWhiteListObj = _.cloneDeep(whitelistObj);
      if (newWhiteListObj[stock.ccid]) {
        delete newWhiteListObj[stock.ccid];
      }
      const whitelist = getWhitelistInput(newWhiteListObj);

      set(() => ({ whitelistObj: newWhiteListObj, loading: true }));

      fetchVolumeHoldingsOptional({
        whitelist,
      });
    }
  },

  removeStockItem: (stock) => {
    const { ccid, isAdd } = stock;
    const { whitelistObj, excludeHoldings, fetchVolumeHoldingsOptional } =
      get();
    const newWhiteListObj = _.cloneDeep(whitelistObj);
    if (newWhiteListObj[ccid]) {
      delete newWhiteListObj[ccid];
    }
    const whitelist = getWhitelistInput(newWhiteListObj);
    const newExcludeHoldings = [...excludeHoldings];
    if (!isAdd) {
      newExcludeHoldings.push(stock);
    }

    set(() => ({ whitelistObj: newWhiteListObj, loading: true }));

    fetchVolumeHoldingsOptional({
      blacklist: newExcludeHoldings.map((stock) => stock.ccid),
      whitelist,
    });
  },

  changeStockWeight: (whitelistItem) => {
    const { whitelistObj, fetchVolumeHoldingsOptional } = get();
    const newWhiteListObj = initWhiteListLastEdit(whitelistObj);
    newWhiteListObj[whitelistItem.ccid] = {
      ...whitelistItem,
    };
    const whitelist = getWhitelistInput(newWhiteListObj);

    set(() => ({ whitelistObj: newWhiteListObj, loading: true }));

    fetchVolumeHoldingsOptional({
      whitelist,
    });
  },

  changeStockWeightByInput: (whiteListItem) => {
    const { whitelistObj, fetchVolumeHoldingsOptional } = get();
    const newWhiteListObj = initWhiteListLastEdit(whitelistObj);
    newWhiteListObj[whiteListItem.ccid] = {
      ...whiteListItem,
    };
    const whitelist = getWhitelistInput(newWhiteListObj);

    set(() => ({ whitelistObj: newWhiteListObj, loading: true }));

    fetchVolumeHoldingsOptional({
      whitelist,
    });
  },

  validateWhiteList: () => {
    let retBool = true;

    const newWhiteList = Object.values(get().whitelistObj);
    const errorIdObj: Record<string, StrategyEditHoldingNotiType> = {};
    let newHoldings = _.cloneDeep(get().holdings);

    newWhiteList.forEach((item) => {
      // 비중이 0.1% 미만인 경우
      const minimumErrorCode = getErrorCodeLessThanMinWeight(item);
      if (minimumErrorCode) {
        errorIdObj[item.ccid] = minimumErrorCode;
        retBool = false;
      }
    });

    if (!retBool) {
      newHoldings = _.cloneDeep(get().holdings).map((stock: StockItemModel) => {
        const infoCode = errorIdObj[stock.ccid];
        if (infoCode) {
          return {
            ...stock,
            isError: true,
            infoCode,
          };
        }

        return stock;
      });
      set(() => ({ holdings: newHoldings }));
    }
    return retBool;
  },

  checkHoldingsValidate: async (strategyId, isSimple) => {
    const { openConfirm } = useConfirmStore.getState();
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.loadHoldings,
    });

    const { universePreset, industries, inputFactors, strategySettingObj } =
      get();

    const { booksize, optimization, startDate, rebalancing } =
      strategySettingObj;

    let simulationOptionScreening: SimulationOptionScreeningInput = {
      industries,
      booksize,
      optimization,
      startDate,
      rebalancing: rebalancing === 'NONE' ? null : rebalancing,
      universePreset,
      blacklist: [],
      whitelist: [],
      factors: inputFactors.map((factor) => ({
        cmName: factor.cmName,
        max: factor.max,
        min: factor.min,
      })),
    };

    return get().fetchHoldings(strategyId, isSimple, simulationOptionScreening);
  },
});
