import { UniverseItemModel, UniversePresetType } from '@models/strategy';
import { FlexBox, Icon, Typography } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UniverseListProps {
  renew?: boolean;
  activeUniverse?: UniversePresetType;
  universeList: UniverseItemModel[];
  onClickUniverse: (name: UniversePresetType) => () => void;
}

const UniverseBox = styled.button`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const UniverseList: FC<UniverseListProps> = ({
  renew,
  activeUniverse,
  universeList,
  onClickUniverse,
}) => {
  return (
    <FlexBox direction="column" spacing={16}>
      {universeList.map((universe, index) => (
        <UniverseBox
          key={`universe-${index}`}
          onClick={onClickUniverse(universe.name)}
          disabled={renew}
        >
          <FlexBox alignItems="center" spacing={6}>
            <Icon
              size={24}
              type={
                universe.name === activeUniverse
                  ? 'radiobox-selected'
                  : 'radiobox-default'
              }
            />
            <Typography variant="body1_title">{universe.text}</Typography>
          </FlexBox>
          <Typography variant="caption" color="black.60" align="left">
            {universe.desc}
          </Typography>
        </UniverseBox>
      ))}
    </FlexBox>
  );
};
