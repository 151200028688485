import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import styled from 'styled-components';

const LegendIcon = styled.div<{ bgColor: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
`;

const LegendText = styled(Typography)`
  word-break: break-all;
`;

export const LineChartLegendRender = (props: any) => {
  const { payload } = props;
  return (
    <FlexBox spacing={12} ml={24}>
      {payload.map((entry: any, index: number) => {
        return (
          <FlexBox key={`item-${index}`} alignItems="center" spacing={4}>
            <LegendIcon bgColor={entry.color} />
            <Typography variant="overline_content" color="black.87">
              {entry.value}
            </Typography>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export const RadarChartLegendRender = (props: any) => {
  const { payload } = props;
  return (
    <FlexBox spacing={12}>
      {payload.map((entry: any, index: number) => {
        return (
          <FlexBox key={`item-${index}`} alignItems="center" spacing={4}>
            <LegendIcon bgColor={entry.color} />
            <LegendText variant="overline_content" color="black.87">
              {entry.value}
            </LegendText>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
