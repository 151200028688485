import { COUNTRY_MAPPER } from '@constants/common';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';
import { CountryCodeType } from '@models/common';
import { InvestmentStrategyLevelType } from '@models/strategy';
import { useUpdateCountryCode } from '@stores/common/common.store';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { handleBlockPopupState } from '@utils/common';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRouterMove } from '..';

interface useEditControllerProps {
  isSimple?: boolean;
  renew: boolean;
  reEdit: boolean;
  prevStrategyId: string;
  countryCode: CountryCodeType;
  investmentStrategyLevel?: InvestmentStrategyLevelType;
}

export const useEditController = ({
  isSimple = false,
  renew,
  reEdit,
  prevStrategyId,
  countryCode,
  investmentStrategyLevel = INVESTMENT_STRATEGY_LEVEL_MAPPER.EXPERT_LEVEL_PRO,
}: useEditControllerProps) => {
  const { strategyId = '' } = useParams<string>();

  const {
    holdingsCnt,
    editCancel,
    initEditState,
    createMyStrategy,
    setStrategyId,
  } = useStrategyEditStore();
  const updateCountryCode = useUpdateCountryCode();
  const { openConfirm } = useConfirmStore();

  const {
    moveToReadyMades,
    moveToUSReadyMades,
    moveToMyStrategies,
    moveToEditResult,
    moveToUsEditResult,
    moveToMyEditResult,
    moveToMyUsEditResult,
  } = useRouterMove();

  useEffect(() => {
    setStrategyId(strategyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyId]);

  useEffect(() => {
    updateCountryCode(countryCode);
    const handlePopState = handleBlockPopupState(handleCancel);
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopState);

    return () => {
      initEditState();
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** '편집 취소' 버튼 클릭시 실행되는 함수 */
  const handleCancel = () => {
    let moveMyStrategies =
      countryCode === COUNTRY_MAPPER.USA
        ? () => moveToMyStrategies(1)
        : () => moveToMyStrategies(0);
    let moveReadyMade =
      countryCode === COUNTRY_MAPPER.USA
        ? moveToUSReadyMades
        : moveToReadyMades;
    editCancel(moveMyStrategies, moveReadyMade);
  };

  const handleClickComplete = () => {
    if (holdingsCnt < 5) {
      openConfirm('alert')({
        title: '종목 구성',
        descriptions: [
          '기본 종목을 포함해 총 5개 이상의 종목을 구성해 주세요.',
        ],
        button: 'single',
      });
      return;
    }

    handleCreateMyStrategy();
  };

  const handleCreateMyStrategy = () => {
    createMyStrategy(
      strategyId,
      isSimple,
      renew || reEdit,
      investmentStrategyLevel
    ).then((myStrategyId) => {
      if (myStrategyId) {
        if (renew) {
          if (countryCode === COUNTRY_MAPPER.USA) {
            moveToMyUsEditResult(myStrategyId, prevStrategyId || strategyId);
            return;
          } else {
            moveToMyEditResult(myStrategyId, prevStrategyId || strategyId);
            return;
          }
        }
        if (countryCode === COUNTRY_MAPPER.USA) {
          moveToUsEditResult(myStrategyId);
          return;
        } else {
          moveToEditResult(myStrategyId);
          return;
        }
      }
    });
  };

  return { handleClickComplete, handleCancel, handleCreateMyStrategy };
};
