/* eslint-disable react-hooks/exhaustive-deps */
import { EditHeadTab } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useMasterEditController } from '@hooks/edit/useMasterEditController';
import { CountryCodeType } from '@models/common';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { getFloaterModelByStep } from '@utils/strategy';
import { FC } from 'react';
import styled from 'styled-components';
import { EditCancelBtn } from './DetailEditPageContainer';
import { MasterFactorContainer } from './MasterFactorContainer';
import { StockSelectorContainer } from './StockSelectorContainer';
import { StrategySettingContainer } from './StrategySettingContainer';
import { UniverseSelectorContainer } from './UniverseSelectorContainer';

export interface MasterEditPageContainerProps {
  renew?: boolean;
  reEdit?: boolean;
  prevStrategyId?: string;
  countryCode?: CountryCodeType;
}

const MasterSummaryBox = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  padding: 20px;
  margin-bottom: 28px;

  .master-img {
    flex-shrink: 0;
  }
`;

export const MasterEditPageContainer: FC<MasterEditPageContainerProps> = ({
  renew = false,
  reEdit = false,
  prevStrategyId = '',
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const { holdings } = useStrategyEditStore();
  const { masterInfo } = useReadymadeStrategyStore();
  const {
    strategyId,
    editStep,
    handleCancel,
    handleClickNext,
    handleClickComplete,
    handleClickPrev,
    handleClickShowDetail,
    handleClickTabButton,
  } = useMasterEditController({
    renew,
    reEdit,
    prevStrategyId,
    countryCode,
  });

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/4단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 4,
        okDisabled: +editStep === 4 && holdings.length === 0, // 종목구성 데이터 없는 경우 버튼 비활성화
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleClickComplete,
      })}
      optionalButton={EditCancelBtn(handleCancel)}
      onClickPrev={handleCancel}
    >
      <MasterSummaryBox>
        <FlexBox>
          <FlexBox width="60px" className="master-img" mr={16}>
            <img
              src={`/image/master/${masterInfo.img}.png`}
              alt={`${masterInfo.img} Icon`}
              width={60}
              height={60}
            />
          </FlexBox>
          <FlexBox direction="column" spacing={4}>
            <Typography variant="subTitle1_title">{masterInfo.name}</Typography>
            <Typography variant="caption" color="black.47">
              {masterInfo.shortDesc}
            </Typography>
            <FlexBox width={112} mt={4}>
              <button onClick={handleClickShowDetail}>
                <Typography color="secondary.text" variant="overline_title">
                  상세 투자 전략 보기
                </Typography>
              </button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </MasterSummaryBox>
      <EditHeadTab
        step={+editStep}
        type="master"
        enableDesc={holdings.length !== 0}
        onClickTab={handleClickTabButton}
      />
      <FlexBox direction="column" pb={+editStep === 3 ? 240 : 0}>
        {+editStep === 1 && (
          <UniverseSelectorContainer
            strategyId={strategyId}
            renew={renew}
            reEdit={reEdit}
            countryCode={countryCode}
          />
        )}
        {+editStep === 2 && <MasterFactorContainer />}
        {+editStep === 3 && (
          <StrategySettingContainer countryCode={countryCode} />
        )}
        {+editStep === 4 && <StockSelectorContainer />}
      </FlexBox>
    </LayoutContainer>
  );
};
