import { exceptSpecialTextRegex } from '@constants/common';
import { KB_ERROR_MAPPER } from '@constants/error.const';
import { TextInputContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FlexBox, Typography } from '@ui/components';
import { TextField } from '@ui/components/TextField';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmTemplate } from './ConfirmTemplate';

interface TextInputConfirmProps
  extends TextInputContentModel,
    ContentWidthModel {}

const TextInputConfirmWrap = styled(ConfirmTemplate)``;

const Left = styled.div``;

const Right = styled.div``;

function validator(value: number | string | readonly string[]) {
  if (typeof value === 'number' || typeof value === 'object') return false;
  if (exceptSpecialTextRegex.test(value)) {
    return true;
  }
  return false;
}

export const TextInputConfirm: FC<TextInputConfirmProps> = ({
  contentWidth,
  autoClose = true,
  children,
  description,
  errorMsg,
  errorEmptyMsg,
  initValue = '',
  placeholder,
  textLimit,
  title,
  onOk,
  onOkPromise,
}) => {
  const [error, setError] = useState(false);
  const [text, setText] = useState<string>(initValue);

  const isLimited = textLimit && text.length >= textLimit;

  const handleChange = (value: string | number) => {
    setError(false);
    setText(String(value));
  };

  const handleOk = () => {
    if (text.trim() === '' && errorEmptyMsg) {
      setError(true);
      return;
    }
    let _text = text || initValue;
    _text = _text.trim();
    if (onOk) {
      onOk(_text);
    } else if (onOkPromise) {
      onOkPromise(_text).catch((err) => {
        if (err.message === KB_ERROR_MAPPER.DUPLICATED) {
          setError(true);
        }
      });
    }
  };

  const handleReset = () => {
    setText('');
  };

  return (
    <TextInputConfirmWrap
      type="textInput"
      autoClose={autoClose}
      contentWidth={contentWidth}
      title={title}
      okDebounceMs={200}
      okText="저장"
      onOk={handleOk}
    >
      <FlexBox direction="column" spacing={16}>
        {children}
        <div>
          <TextField
            type="text"
            value={text}
            placeholder={placeholder}
            error={error}
            maxLength={textLimit}
            onChangeValue={handleChange}
            onChangeValidator={validator}
            onClickReset={handleReset}
          />
          <FlexBox mt={4} justify="space-between">
            <Left>
              {!error && description && (
                <Typography variant="tag" color="gray.blue">
                  {isLimited ? '최대 50자까지 입력 가능합니다.' : description}
                </Typography>
              )}
              {error && text.trim() !== '' && errorMsg && (
                <Typography variant="tag" color="state.red">
                  {errorMsg}
                </Typography>
              )}
              {error && text.trim() === '' && errorEmptyMsg && (
                <Typography variant="tag" color="state.red">
                  {errorEmptyMsg}
                </Typography>
              )}
            </Left>
            <Right>
              <Typography
                variant="tag"
                color={error ? 'state.red' : 'gray.blue'}
              >
                {text.length}/{textLimit}
              </Typography>
            </Right>
          </FlexBox>
        </div>
      </FlexBox>
    </TextInputConfirmWrap>
  );
};
