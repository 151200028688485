import { LevelOneContainer } from '@containers/strategy-edit/beginner/LevelOneContainer';
import { FC } from 'react';

interface StrategyBeginner1EditPageProps {
  reEdit?: boolean;
}

export const StrategyBeginner1EditPage: FC<StrategyBeginner1EditPageProps> = ({
  reEdit = false,
}) => {
  return <LevelOneContainer reEdit={reEdit} />;
};
