import { DatePicker, SelectOption } from '@components/common';
import { BACKTEST_START_DATE_OPTIONS } from '@constants/common';
import { BacktestDateBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { getBeforeYear, getDateCompatibleIOS } from '@utils/common';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface BacktestDateBottomsheetProps
  extends BacktestDateBottomsheetContentModel,
    ContentWidthModel {}

const BacktestDateBottomsheetWrap = styled(BottomsheetTemplate)``;

export const BacktestDateBottomsheet: FC<BacktestDateBottomsheetProps> = ({
  contentWidth,
  defaultDate = new Date(),
  onOk,
}) => {
  const [month, setMonth] = useState(9);
  const [year, setYear] = useState(2019);

  const handleMonth = (_month: number) => {
    setMonth(_month);
  };
  const handleYear = (_year: number) => {
    setYear(_year);
  };

  const handleOk = () => {
    if (onOk) {
      const date = getDateCompatibleIOS(year, month);
      onOk(date);
    }
  };

  useEffect(() => {
    let date = defaultDate;
    if (typeof date === 'number') date = new Date(date);
    setMonth(date.getMonth() + 1);
    setYear(date.getFullYear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BacktestDateBottomsheetWrap
      type="backtestDate"
      title="백테스트 시작일"
      contentWidth={contentWidth}
      button="double"
      buttonMargin={24}
      onClickOk={handleOk}
    >
      <DatePicker
        defaultDate={defaultDate}
        onScrollMonth={handleMonth}
        onScrollYear={handleYear}
      />
    </BacktestDateBottomsheetWrap>
  );
};

export const BacktestDateBottomsheet2: FC<BacktestDateBottomsheetProps> = ({
  contentWidth,
  onOk,
}) => {
  const handleOk = (diffYear: number) => {
    if (onOk) {
      const prevDate = getBeforeYear(diffYear);
      const prevYear = prevDate.getFullYear();
      const prevMonth = prevDate.getMonth() + 1;
      const date = getDateCompatibleIOS(prevYear, prevMonth);
      onOk(date);
    }
  };

  return (
    <BacktestDateBottomsheetWrap
      type="backtestDate"
      title="백테스트 시작일"
      contentWidth={contentWidth}
    >
      <SelectOption options={BACKTEST_START_DATE_OPTIONS} onClick={handleOk} />
    </BacktestDateBottomsheetWrap>
  );
};
