import {
  SIMPLE_PRESET_LOCALE,
  TOP_PERCENT_ENUM,
} from '@constants/strategy/strategy-edit.const';
import { PresetType, StyleFactorInfoItemModel } from '@models/strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface SimpleFactorContainerProps {}

const FactorBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;

  &.middle {
    & > button {
      &:first-child {
        border-radius: 4px 0px 0px 4px;
        border-width: 1px 0px 1px 1px;
      }
      &:nth-child(2) {
        border-width: 1px 1px 1px 1px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
        border-width: 1px 1px 1px 0px;
      }
    }
  }

  & > button {
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-child(2) {
      border-width: 1px 0px 1px 0px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

const DegreeButton = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colorPalette.border.chip};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colorPalette.black[60]};

  &:active {
    background-color: ${({ theme }) => theme.colorPalette.black[3]};
  }

  &.active {
    border-color: ${({ theme }) => theme.colorPalette.secondary.text};
  }
`;

const ButtonText = styled(Typography)`
  @media (max-width: 359px) {
    font-size: 13px;
  }
`;

export const SimpleFactorContainer: FC<SimpleFactorContainerProps> = () => {
  const { styleFactors, updateStyleFactors } = useStrategyEditStore();
  const handleClickPreset =
    (type: PresetType, factor: StyleFactorInfoItemModel) => () => {
      let newType: PresetType | undefined = type;
      if (type === factor.type) {
        newType = undefined;
      }
      updateStyleFactors({
        ...factor,
        type: newType,
      });
    };
  const checkFactorCnt = styleFactors.filter((factor) => !!factor.type).length;

  return (
    <>
      <FlexBox mb={8}>
        <Typography variant="caption" color="secondary.bright">
          {checkFactorCnt}
        </Typography>
        <Typography variant="caption" color="black.87">
          개 선택됨
        </Typography>
      </FlexBox>
      <FlexBox direction="column" spacing={16}>
        {styleFactors.map((factor) => (
          <FactorBox key={factor.name}>
            <FlexBox justify="space-between">
              <Typography variant="body1_title" mb={8}>
                {factor.text}
              </Typography>
            </FlexBox>
            <Typography variant="caption">{factor.desc}</Typography>
            <ButtonGroup className={factor.type ? factor.type : ''}>
              {TOP_PERCENT_ENUM.map((value) => (
                <DegreeButton
                  key={`degree_button_${value}`}
                  onClick={handleClickPreset(value, factor)}
                  className={factor.type === value ? 'active' : ''}
                >
                  <ButtonText
                    color={
                      factor.type === value ? 'secondary.text' : 'black.60'
                    }
                    variant="body2_title"
                  >
                    {SIMPLE_PRESET_LOCALE[value]}
                  </ButtonText>
                </DegreeButton>
              ))}
            </ButtonGroup>
          </FactorBox>
        ))}
      </FlexBox>
    </>
  );
};
