export const SPACING_SIZE_DICTIONARY = {
  '0': '0px',
  '4': '4px',
  '8': '8px',
  '12': '12px',
  '16': '16px',
  '20': '20px',
  '24': '24px',
  '28': '28px',
  '32': '32px',
  '36': '36px',
  '40': '40px',
  '48': '48px',
  '64': '64px',
} as const;

export const FLEX_DIRECTION_LIST = [
  'row',
  'row-reverse',
  'column',
  'column-reverse',
] as const;

export const FLEX_JUSTIFY_LIST = [
  'flex-start',
  'center',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly',
] as const;

export const FLEX_ALIGN_ITEMS_LIST = [
  'flex-start',
  'center',
  'flex-end',
  'stretch',
  'baseline',
] as const;

export const BOX_SHADOW_DICTIONARY = {
  drop: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  elevation:
    '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
};

/**
 *  **Z-Index Level 관련**
 *
 *  Content 관련 (100 ~ 999)
 *
 *  Layout 관련 (1000 ~ 1999)
 *
 *  Modal 관련 (2000 ~ 2999)
 *
 *  Confirm 관련 (3000 ~ 3999)
 */
export const Z_INDEX_DICTIONARY = {
  content: 100,
  layout: 1000,
  header: 1100,
  bottomsheet: 2000,
  popup: 2100,
  loading: 3000,
  alert: 3100,
} as const;

export const POSITION_LIST = [
  'absolute',
  'fixed',
  'relative',
  'sticky',
] as const;
