import { COUNTRY_MAPPER } from '@constants/common';
import { MyStrategyEditResultContainer } from '@containers/myStrategy/MyStrategyEditResultContainer';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface MyUsStrategyEditResultPageProps {}

export const MyUsStrategyEditResultPage: FC<
  MyUsStrategyEditResultPageProps
> = () => {
  const { prevStrategyId = '' } = useParams<string>();
  return (
    <MyStrategyEditResultContainer
      renew
      prevStrategyId={prevStrategyId}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
