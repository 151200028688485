import { SelectOption } from '@components/common';
import { MY_STRATEGY_ORDER_OPTIONS } from '@constants/common';
import { MyStrategyOrderBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface MyStrategyOrderBottomsheetProps
  extends MyStrategyOrderBottomsheetContentModel,
    ContentWidthModel {}

const MyStrategyOrderBottomsheetWrap = styled(BottomsheetTemplate)``;

export const MyStrategyOrderBottomsheet: FC<
  MyStrategyOrderBottomsheetProps
> = ({
  contentWidth,
  order,
  options = MY_STRATEGY_ORDER_OPTIONS,
  onClickOption,
}) => {
  return (
    <MyStrategyOrderBottomsheetWrap
      type="myStrategyOrder"
      contentWidth={contentWidth}
    >
      <SelectOption
        options={options}
        selected={order}
        onClick={onClickOption}
      />
    </MyStrategyOrderBottomsheetWrap>
  );
};
