import { ReturnRateText } from '@components/common';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface ReturnRateInfoProps {
  type?: 'single' | 'double';
  label: string;
  value?: number;
  secondLabel?: string;
  secondValue?: number;
  thirdLabel?: string;
  thirdValue?: number;
}

const ReturnRateInfoWrap = styled(FlexBox)`
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
  padding: 20px;
`;

export const ReturnRateInfo: FC<ReturnRateInfoProps> = ({
  type = 'single',
  label,
  value,
  secondLabel = '',
  secondValue,
  thirdLabel,
  thirdValue,
}) => {
  const returnRateType = thirdLabel ? 'subTitle2' : 'h5';

  if (type === 'double') {
    return (
      <ReturnRateInfoWrap alignItems="center" justify="space-around">
        <FlexBox direction="column" alignItems="center">
          <ReturnRateText value={value} type={returnRateType} />
          <Typography variant="overline_content" color="black.60">
            {label}
          </Typography>
        </FlexBox>
        <FlexBox direction="column" alignItems="center">
          <ReturnRateText value={secondValue} type={returnRateType} />
          <Typography variant="overline_content" color="black.60">
            {secondLabel}
          </Typography>
        </FlexBox>
        {thirdLabel && (
          <FlexBox direction="column" alignItems="center">
            <ReturnRateText value={thirdValue} type={returnRateType} />
            <Typography variant="overline_content" color="black.60">
              {thirdLabel}
            </Typography>
          </FlexBox>
        )}
      </ReturnRateInfoWrap>
    );
  }

  return (
    <ReturnRateInfoWrap alignItems="center" justify="space-between">
      <Typography variant="overline_content" color="black.60">
        {label}
      </Typography>
      <ReturnRateText value={value} />
    </ReturnRateInfoWrap>
  );
};
