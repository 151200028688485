import { FC, useEffect } from 'react';
import { UniverseList } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import { UNIVERSE_PRESET_MAPPER, UNIVERSE_PUBLIC_ENUM } from '@constants/strategy';
import { StrategyCategoryType, UniversePresetType } from '@models/strategy';
import { useCountryCode } from '@stores/common/common.store';
import { useStrategyListStore } from '@stores/readymade-list';
import { FlexBox, SwitchButton, Typography } from '@ui/components';
import { useQueryState } from '@hooks/useQueryState';

interface CategorySectorContainerProps {
  createSearchCondition: (category: StrategyCategoryType) => JSX.Element;
}

export const CategorySectorContainer: FC<CategorySectorContainerProps> = ({
  createSearchCondition,
}) => {
  const {
    category,
    universe,
    universeIndex,
    universePresets,
    updateCountryCode,
    changeUniverse,
    setUniverseIndex,
  } = useStrategyListStore();
  const countryCode = useCountryCode();
  const [urlUniverse] = useQueryState<string>({
    key: 'presets',
    defaultValue: '',
  });

  const handleClickUniverse = (name: UniversePresetType) => () => {
    changeUniverse(name);
  };

  const handleClickUniverseIndex = (index: number) => () => {
    if (index === 0 || index === 1) {
      const universePreset =
        index === 0
          ? UNIVERSE_PRESET_MAPPER.KOSPI200_KOSDAQ150_KRX300
          : UNIVERSE_PRESET_MAPPER.KOSPI_KOSDAQ;
      setUniverseIndex(index);
      changeUniverse(universePreset);
    }
  };

  useEffect(() => {
    updateCountryCode(countryCode);
  }, [countryCode, updateCountryCode]);


  useEffect(() => {
    const IS_UNIVERSE_PUBLIC_ENUM = UNIVERSE_PUBLIC_ENUM.findIndex((item) => item === urlUniverse.toLocaleUpperCase()) !== -1;
    if (urlUniverse) {
      setUniverseIndex(IS_UNIVERSE_PUBLIC_ENUM ? 1 : 0)
      changeUniverse(urlUniverse as UniversePresetType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlUniverse]);

  if (countryCode === COUNTRY_MAPPER.USA) {
    return (
      <>
        <div>
          <Typography mb={8} variant="caption_medium" color="black.60">
            유니버스 선택 (한가지만 선택가능)
          </Typography>
          <UniverseList
            activeName={universe}
            universeList={universePresets.USA}
            onClickUniverse={handleClickUniverse}
          />
        </div>
        <FlexBox height={8} my={32} mx={-20} bgColor="black.3" />
        <div>
          <Typography mb={8} variant="caption_medium" color="black.60">
            업종 전략 선택
          </Typography>
          {createSearchCondition(category)}
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Typography mb={8} variant="caption_medium" color="black.60">
          유니버스 선택 (한가지만 선택가능)
        </Typography>
        <SwitchButton
          variant="full"
          activeIndex={universeIndex}
          buttonList={[
            {
              index: 0,
              name: '대표기업',
              onClickBtn: handleClickUniverseIndex(0),
            },
            {
              index: 1,
              name: '상장기업',
              onClickBtn: handleClickUniverseIndex(1),
            },
          ]}
        />
        <FlexBox mt={24}>
          <UniverseList
            activeName={universe}
            universeList={
              universeIndex === 0
                ? universePresets.REPRESENT
                : universePresets.PUBLIC
            }
            onClickUniverse={handleClickUniverse}
          />
        </FlexBox>
      </div>
      <FlexBox height={8} my={32} mx={-20} bgColor="black.3" />
      <div>
        <Typography mb={8} variant="caption_medium" color="black.60">
          업종 전략 선택
        </Typography>
        {createSearchCondition(category)}
      </div>
    </>
  );
};
