import { ListTableVariantProps } from '@ui/model';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../Flexbox';
import { Typography } from '../Typography';

interface ListTableProps extends ListTableVariantProps {
  tableContent: Record<string, string | ReactNode>;
}

const ListTableWrap = styled.div``;
const ListItemWrap = styled(FlexBox)`
  &.line {
    border-bottom: 1px solid
      ${({ theme }) => theme.colorPalette.divider.contents};
  }
`;

export const ListTable: FC<ListTableProps> = ({
  tableContent,
  variant = 'list',
}) => {
  const rowContents = [];
  for (const [key, value] of Object.entries(tableContent)) {
    rowContents.push(
      <ListItemWrap
        key={`list_table_${key}`}
        justify="space-between"
        className={variant === 'list-line' ? 'line' : ''}
      >
        <FlexBox width="40%" py={6}>
          <Typography variant="caption" color="black.60">
            {key}
          </Typography>
        </FlexBox>
        <FlexBox width="60%" justify="flex-end" pl={12} py={6}>
          <Typography as="div" variant="caption_medium" color="black.87">
            {value}
          </Typography>
        </FlexBox>
      </ListItemWrap>
    );
  }

  return <ListTableWrap>{rowContents}</ListTableWrap>;
};
