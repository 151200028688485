import { HistogramChartModel, HistogramEventModel } from '@models/common';
import { FlexBox } from '@ui/components/Flexbox';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HistogramChart } from './HistogramChart';
import { HistogramSlider } from './HistogramSlider';

interface HistogramProps extends HistogramChartModel, HistogramEventModel {}

const HistogramWrap = styled(FlexBox)``;

export const Histogram: FC<HistogramProps> = ({
  histogramChartData,
  range,
  onChange,
  onUpdate,
}) => {
  const [renderingRange, setRenderingRange] =
    useState<readonly number[]>(range);

  const handleChange = (values: readonly number[], type?: 'min' | 'max') => {
    if (onChange) onChange(values, type);
  };

  const handleUpdate = (values: readonly number[], type?: 'min' | 'max') => {
    setRenderingRange(values);
    if (onUpdate) onUpdate(values, type);
  };

  useEffect(() => {
    setRenderingRange(range);
  }, [range]);

  return (
    <HistogramWrap direction="column" spacing={16}>
      <HistogramChart
        histogramChartData={histogramChartData}
        range={renderingRange}
      />
      <HistogramSlider
        range={renderingRange}
        onUpdate={handleUpdate}
        onChange={handleChange}
      />
    </HistogramWrap>
  );
};
