import { GetMySectorsQuery, GetSectorsQuery } from '@graphql/generates';
import {
  SectorInfoItemModel,
  StrategyIndustryType,
  StrategySectorType,
} from '@models/strategy';
import { filterNonDataOfArray } from '@utils/common';
import { createInitSectors } from './sector-industry.init';

/**
 * GetSectorsQuery 응답 데이터를 섹터 정보 state 데이터로 변환
 * @param data GetSectorsQuery 응답 데이터
 * @returns {SectorInfoItemModel[]}
 */
export const convertSectors = (data: GetSectorsQuery) => {
  const resList: SectorInfoItemModel[] = [];

  if (data.sectors && data.sectors.sectors) {
    const sectorList = filterNonDataOfArray(data.sectors.sectors);
    sectorList.forEach((sector) => {
      const sectorItem: SectorInfoItemModel = {
        industries: [],
        name: sector.sector as StrategySectorType,
        text: sector.name,
      };
      if (sector.industries) {
        sector.industries.forEach((industry) => {
          if (industry) {
            sectorItem.industries.push({
              name: industry.industry as StrategyIndustryType,
              text: industry.name,
            });
          }
        });
      }
      resList.push(sectorItem);
    });
  }

  return resList;
};

/**
 * GetMySectorsQuery 응답 데이터를 내 섹터 정보 state 데이터로 변환
 * @param data GetMySectorsQuery 응답 데이터
 * @returns {sectorInfoList, sectorCheckObj}
 */
export const convertMySectors = (data: GetMySectorsQuery) => {
  const mySectors = data.mySectors?.mySectors;
  const sectorInfoList: SectorInfoItemModel[] = [];
  const sectorCheckObj = createInitSectors(false);

  if (!mySectors) return { sectorInfoList, sectorCheckObj };

  let sectorCount = 0;

  for (const mySector of mySectors) {
    if (!mySector) continue;

    const { sector, name, industries } = mySector;

    if (!industries) continue;

    let industryCount = 0;
    const sectorItem: SectorInfoItemModel = {
      industries: [],
      name: sector as StrategySectorType,
      text: name,
    };

    for (const myIndustry of industries) {
      if (!myIndustry) continue;

      const { industry, name, isChecked } = myIndustry;
      const _industry = industry as StrategyIndustryType;
      sectorItem.industries.push({
        name: industry as StrategyIndustryType,
        text: name,
      });

      if (isChecked) {
        industryCount += 1;
        sectorCheckObj.industry[_industry] = true;
      }
    }

    if (industries.length === industryCount) {
      const _sector = sector as StrategySectorType;
      sectorCheckObj.sector[_sector] = true;
      sectorCount += 1;
    }

    sectorInfoList.push(sectorItem);
  }

  if (mySectors.length === sectorCount) {
    sectorCheckObj.all = true;
  }

  return { sectorInfoList, sectorCheckObj };
};
