import { Tag } from '@components/common';
import { MyStrategyCardDto } from '@models/strategy';
import { Button, Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BackResultBox, ForwardResultBox } from '../myStrategy/card';
import { MyStrategyCardEventHandlers } from '@models/my-strategy/my-strategy.model';

interface MyStrategyCardProps extends MyStrategyCardEventHandlers {
  strategy: MyStrategyCardDto;
}

const MyStrategyCardWrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;
`;

const MoreBtn = styled.button`
  width: 16px;
  height: 16px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colorPalette.divider.column};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const MyStrategyCard: FC<MyStrategyCardProps> = ({
  strategy,
  onClickOption,
  onClickBackTest,
  onClickForward,
  onClickContract,
  onClickRebalancing,
  onClickStartContract,
}) => {
  return (
    <MyStrategyCardWrap>
      <FlexBox alignItems="baseline" justify="space-between" spacing={8} mb={6}>
        <FlexBox width="100%" direction="column" spacing={8}>
          {strategy.isActiveKbContract && (
            <FlexBox>
              <Tag color="purple">자문계약</Tag>
            </FlexBox>
          )}
          <FlexBox spacing={4}>
            <Typography color="black.40" variant="overline_title">
              전략생성일 {strategy.createdAt}
            </Typography>
          </FlexBox>
        </FlexBox>
        <MoreBtn onClick={onClickOption}>
          <Icon type="more" size={16} />
        </MoreBtn>
      </FlexBox>
      <Typography
        variant="body1_title"
        wordBreak="break-all"
        style={{ lineBreak: 'anywhere' }}
        mb={16}
      >
        {strategy.title}
      </Typography>
      <Divider />
      <ForwardResultBox strategy={strategy} />
      <Divider />
      <BackResultBox strategy={strategy} />
      <FlexBox mt={6} spacing={6}>
        <Button variant="general2" height={32} onClick={onClickBackTest}>
          <Typography variant="overline_title" color="black.60">
            백테스트 결과
          </Typography>
        </Button>
        <Button variant="general2" height={32} onClick={onClickForward}>
          <Typography variant="overline_title" color="black.60">
            모의투자 결과
          </Typography>
        </Button>
      </FlexBox>
      {strategy.isActiveKbContract ? (
        <FlexBox mt={6} spacing={6}>
          <Button variant="general2" height={32} onClick={onClickContract}>
            <Typography variant="overline_title" color="secondary.text">
              계좌 현황 보기
            </Typography>
          </Button>
          <Button variant="general2" height={32} onClick={onClickRebalancing}>
            <Typography variant="overline_title" color="secondary.text">
              리밸런싱 하기
            </Typography>
          </Button>
        </FlexBox>
      ) : (
        <FlexBox mt={6} spacing={6}>
          <Button variant="general2" height={32} onClick={onClickStartContract}>
            <Typography variant="overline_title" color="secondary.text">
              투자 시작하기
            </Typography>
          </Button>
        </FlexBox>
      )}
    </MyStrategyCardWrap>
  );
};
