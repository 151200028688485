import { HistogramChartModel, HistogramEventModel } from '@models/common';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';
import { HistogramSingleChart } from './HistogramSingleChart';
import { HistogramSingleSlider } from './HistogramSingleSlider';

interface HistogramProps extends HistogramChartModel, HistogramEventModel {
  factorIndex: number;
}

const HistogramWrap = styled(FlexBox)``;

export const HistogramSingle: FC<HistogramProps> = ({
  histogramChartData = [],
  range,
  factorIndex,
  onChange,
  onUpdate,
}) => {
  const handleChange = (values: readonly number[]) => {
    if (onChange) {
      const [, end] = values;
      const maxLen = histogramChartData.length;
      if (end !== maxLen) {
        onChange([end, maxLen]);
      } else {
        onChange(values);
      }
    }
  };

  const handleUpdate = (values: readonly number[]) => {
    if (onUpdate) {
      const [, end] = values;
      const maxLen = histogramChartData.length;
      if (end !== maxLen) {
        onUpdate([end, maxLen]);
      } else {
        onUpdate(values);
      }
    }
  };

  if (histogramChartData.length === 0) return null;

  return (
    <HistogramWrap direction="column" spacing={16}>
      <HistogramSingleChart
        histogramChartData={histogramChartData}
        range={[factorIndex, histogramChartData.length]}
      />
      <HistogramSingleSlider
        rangeIndex={factorIndex}
        domain={[0, histogramChartData.length]}
        range={range}
        onUpdate={handleUpdate}
        onChange={handleChange}
      />
    </HistogramWrap>
  );
};
