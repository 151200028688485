import { IndustryEnum } from '@graphql/generates';
import {
  SectorIndustryCheckDto,
  SectorInfoItemModel,
  StrategySectorType,
} from '@models/strategy';
import { StateCreator } from 'zustand';
import { StrategyEditState } from '../strategy-edit.store';
import { convertMySectors, convertSectors } from './sector-industry.convert';
import { createInitSectorIndustryState } from './sector-industry.init';
import { repo } from '@graphql/repo';

export interface SectorIndustrySliceState {
  /** 선택된 업종 갯수 */
  checkedCnt: number;
  /** 섹터/업종 선택여부 */
  sectorCheckObj: SectorIndustryCheckDto;
  /** 섹터 토글 여부 객체 */
  sectorToggleObj: Record<StrategySectorType, boolean>;
  /** SimulationOptionScreeningInput "industries" 파라미터 객체 */
  industries: IndustryEnum[];
}

export interface SectorIndustrySlice extends SectorIndustrySliceState {
  // TODO: 해당 부분 init 에 추가 검토하기
  sectorInfoList: SectorInfoItemModel[];

  setCheckedCnt: (cnt: number) => void;
  fetchSectors: () => void;
  fetchMySectors: (myStrategyId: string) => {};
  setCheckObj: (obj: SectorIndustryCheckDto) => void;
  setToggleObj: (sector: StrategySectorType) => void;
  setIndustries: (list: IndustryEnum[]) => void;
}

export const createSectorIndustrySlice: StateCreator<
  StrategyEditState,
  [],
  [],
  SectorIndustrySlice
> = (set, get) => ({
  sectorInfoList: [],
  ...createInitSectorIndustryState(),
  setCheckedCnt: (cnt: number) => {
    set(() => ({ checkedCnt: cnt }));
  },
  fetchSectors: async () => {
    const response = await repo.getSectors({});
    const sectors = convertSectors(response);
    set(() => ({
      sectorInfoList: sectors,
    }));
  },
  fetchMySectors: async (myStrategyId) => {
    const response = await repo.getMySectors({ myStrategyId });
    const result = convertMySectors(response);
    set(() => ({ ...result }));
  },
  setCheckObj: (obj) => {
    set(() => ({ sectorCheckObj: obj }));
  },
  setToggleObj: (sector) => {
    const newToggleObj = {
      ...get().sectorToggleObj,
      [sector]: !get().sectorToggleObj[sector],
    };

    set(() => ({ sectorToggleObj: newToggleObj }));
  },
  setIndustries: (list) => {
    set(() => ({ industries: list }));
  },
});
