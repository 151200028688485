import { BaseStyledButton } from '@ui/base';
import { ButtonUIHTMLComponentProps, ThemedButtonProps } from '@ui/model';
import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../Flexbox';

interface ButtonProps extends ButtonUIHTMLComponentProps, ThemedButtonProps {
  ref?: React.Ref<HTMLButtonElement>;
}

export const ButtonGroup = styled(FlexBox)`
  gap: 8px;
`;

export const Button: React.ComponentType<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      variant = 'primary',
      color = 'black.87',
      size = 'primary',
      height,
      ...props
    },
    ref
  ) => {
    return (
      <BaseStyledButton
        {...props}
        ref={ref as never}
        $variant={variant}
        $color={color}
        $size={size}
        $height={height}
      />
    );
  }
);
