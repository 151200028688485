import { COUNTRY_MAPPER } from '@constants/common';
import { PAGE_INFO, PAGE_KR_INFO, PAGE_US_INFO } from '@constants/router.const';
import {
  InvestmentStrategyLevelEnum,
  UniverseCountryEnum,
} from '@graphql/generates';
import { CountryCodeType } from '@models/common';
import { PageType } from '@models/router.model';
import { StrategyCategoryType } from '@models/strategy';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface PathChangeProps {
  pageType: PageType;
  id: string;
  prevId?: string;
}

/**
 * countryCode가 USA 인지 판별하는 함수
 * @param countryCode - 국가 코드
 * @returns {boolean}
 */
const isCountryCodeUs = (countryCode: UniverseCountryEnum) => {
  if (countryCode === COUNTRY_MAPPER.USA) return true;
  return false;
};

const createQueryString = (
  prevString: string,
  queryStrings: string[] | undefined
) => {
  let newString = queryStrings ? queryStrings.join('&') : '';
  if (prevString && newString) {
    newString = prevString + '&' + newString;
  }
  return newString ? `?${newString}` : '';
};

/**
 * 페이지 이동을 위한 커스텀 훅.
 * useNavigate함수에 기능을 추가하고 이를 커스텀 훅으로 분리하여 재사용성을 높임.
 * @returns {object} returnObject - 이동 함수 모음
 * - moveToReadyMades: 전략 마켓 리스트로 이동하는 함수
 * - ...
 * - moveToMyEditResult: 백테스트 결과 (내 전략 편집 후 보이는 화면)로 이동하는 함수
 * @group Hooks
 *
 * @example
 * const { moveToMasterDetail } = useRouterMove();
 * moveToMasterDetail(abc123); // 전략상세(대가) 페이지로 이동
 */
export const useRouterMove = () => {
  const nav = useNavigate();
  let [searchParams] = useSearchParams();

  const getReplacePath = ({ id, prevId, pageType }: PathChangeProps) => {
    const path =
      '/' +
      PAGE_INFO[pageType].page
        .replace(':strategyId', id)
        .replace(':prevStrategyId', prevId || '');
    return path;
  };

  /**
   * 전략 상세 페이지로 이동하는 함수
   */
  const moveToReadymadeDetail = (id: string) => {
    const path = getReplacePath({ id, pageType: 'readymade-detail' });
    nav(path);
  };

  /**
   * 전략 상세 페이지(US)로 이동하는 함수
   */
  const moveToUSReadymadeDetail = (id: string) => {
    const path = getReplacePath({ id, pageType: 'us-readymade-detail' });
    nav(path);
  };

  /**
   * 전략 마켓 리스트로 이동하는 함수
   */
  const moveToReadyMades = () => {
    nav('/' + PAGE_KR_INFO.readymades.page);
  };

  /**
   * 전략 마켓 리스트(미국)로 이동하는 함수
   */
  const moveToUSReadyMades = () => {
    nav('/' + PAGE_US_INFO['us-readymades'].page);
  };

  /**
   * 전략상세(대가)로 이동하는 함수
   */
  const moveToMasterDetail = (id: string) => {
    const path = getReplacePath({ id, pageType: 'readymade-detail-master' });
    nav(path);
  };

  /**
   * 전략상세(대가)로 이동하는 함수
   */
  const moveToUSMasterDetail = (id: string) => {
    const path = getReplacePath({ id, pageType: 'us-readymade-detail-master' });
    nav(path);
  };

  /**
   * 투자 금액 선택 페이지로 이동하는 함수
   */
  const moveToBeginner1Edit = (
    id: string,
    countryCode: CountryCodeType,
    reEdit: boolean = false
  ) => {
    const path = getReplacePath({
      id,
      pageType: `${
        isCountryCodeUs(countryCode) ? 'us-' : ''
      }strategy-beginner-1-${reEdit ? 're-' : ''}edit`,
    });
    nav(path);
  };
  const moveToMyBeginner1Edit = (
    id: string,
    countryCode: CountryCodeType,
    prevId: string
  ) => {
    const path = getReplacePath({
      id,
      pageType: `my-${
        isCountryCodeUs(countryCode) ? 'us-' : ''
      }strategy-beginner-1-edit`,
      prevId,
    });
    nav(path);
  };

  /**
   * 전략편집(비기너 레벨2)로 이동하는 함수
   */
  const moveToBeginner2Edit = (
    id: string,
    countryCode: CountryCodeType,
    reEdit: boolean = false
  ) => {
    const path = getReplacePath({
      id,
      pageType: `${
        isCountryCodeUs(countryCode) ? 'us-' : ''
      }strategy-beginner-2-${reEdit ? 're-' : ''}edit`,
    });
    nav(path);
  };
  const moveToMyBeginner2Edit = (
    id: string,
    countryCode: CountryCodeType,
    prevId: string
  ) => {
    const path = getReplacePath({
      id,
      pageType: `my-${
        isCountryCodeUs(countryCode) ? 'us-' : ''
      }strategy-beginner-2-edit`,
      prevId,
    });
    nav(path);
  };

  /**
   * 전략편집(프로 레벨1)로 이동하는 함수
   */
  const moveToPro1Edit = (
    id: string,
    countryCode: CountryCodeType,
    reEdit: boolean = false
  ) => {
    const path = getReplacePath({
      id,
      pageType: `${isCountryCodeUs(countryCode) ? 'us-' : ''}strategy-pro-1-${
        reEdit ? 're-' : ''
      }edit`,
    });
    nav(path);
  };
  const moveToMyPro1Edit = (
    id: string,
    countryCode: CountryCodeType,
    prevId: string
  ) => {
    const path = getReplacePath({
      id,
      pageType: `my-${
        isCountryCodeUs(countryCode) ? 'us-' : ''
      }strategy-pro-1-edit`,
      prevId,
    });
    nav(path);
  };

  /**
   * 전략편집(간편)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToSimpleEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `strategy-simple${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(간편)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToUsSimpleEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `us-strategy-simple${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(상세)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToDetailEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `strategy-detail${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(상세)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToUsDetailEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `us-strategy-detail${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(상세 - DIY)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToDiyEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `strategy-diy${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(상세 - DIY)으로 이동하는 함수
   * @param id 전략 ID값
   * @param queryStrings 추가로 넣을 쿼리스트링
   */
  const moveToUsDiyEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `us-strategy-diy${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(대가들의 전략)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMasterEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `strategy-master${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 전략편집(대가들의 전략)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToUsMasterEdit = (
    id: string,
    reEdit: boolean = false,
    queryStrings: string[] | undefined = undefined
  ) => {
    const prevStrings = searchParams.toString();
    const queryString = createQueryString(prevStrings, queryStrings);
    const path =
      getReplacePath({
        id,
        pageType: `us-strategy-master${reEdit ? '-re' : ''}-edit`,
      }) + queryString;
    nav(path);
  };

  /**
   * 백테스트 결과 (편집 후 보이는 화면)로 이동하는 함수
   */
  const moveToEditResult = (id: string) => {
    const path = getReplacePath({ id, pageType: 'strategy-edit-result' });
    nav(path);
  };

  /**
   * 백테스트 결과 (편집 후 보이는 화면)로 이동하는 함수
   */
  const moveToUsEditResult = (id: string) => {
    const path = getReplacePath({ id, pageType: 'us-strategy-edit-result' });
    nav(path);
  };

  /**
   * 전략 보관함 리스트로 이동하는 함수
   */
  const moveToMyStrategies = (tabIndex?: number) => {
    let path = '/' + PAGE_INFO['my-strategies'].page;
    if (tabIndex !== undefined) {
      path += `?tab=${tabIndex}`;
    }
    nav(path);
  };

  /**
   * 백테스트 결과로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToBackTest = (id: string) => {
    const path = getReplacePath({ id, pageType: 'my-strategy-back-test' });
    nav(path);
  };

  /**
   * 백테스트 결과(미국)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToUsBackTest = (id: string) => {
    const path = getReplacePath({ id, pageType: 'my-us-strategy-back-test' });
    nav(path);
  };

  /**
   * 모의투자 결과로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToForwardTest = (id: string) => {
    const path = getReplacePath({ id, pageType: 'my-strategy-forward-test' });
    nav(path);
  };

  /**
   * 모의투자 결과(미국)로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToUsForwardTest = (id: string) => {
    const path = getReplacePath({
      id,
      pageType: 'my-us-strategy-forward-test',
    });
    nav(path);
  };

  /**
   * 내 전략편집(대가)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyMasterEdit = (id: string, prevId: string) => {
    const path =
      getReplacePath({
        id,
        prevId,
        pageType: 'my-strategy-master-edit',
      }) + '?step=2';
    nav(path);
  };

  /**
   * 내 전략편집(대가)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyUsMasterEdit = (id: string, prevId: string) => {
    const path =
      getReplacePath({
        id,
        prevId,
        pageType: 'my-us-strategy-master-edit',
      }) + '?step=2';
    nav(path);
  };

  /**
   * 내 전략편집(간편)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMySimpleEdit = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-strategy-simple-edit',
    });
    nav(path);
  };

  /**
   * 내 전략편집(간편)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyUsSimpleEdit = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-us-strategy-simple-edit',
    });
    nav(path);
  };

  /**
   * 내 전략편집(상세)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyDetailEdit = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-strategy-detail-edit',
    });
    nav(path);
  };

  /**
   * 내 전략편집(상세)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyUsDetailEdit = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-us-strategy-detail-edit',
    });
    nav(path);
  };

  /**
   * 내 전략편집(상세 - DIY)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyDetailEditDiy = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-strategy-diy-edit',
    });
    nav(path);
  };

  /**
   * 내 전략편집(상세 - DIY)으로 이동하는 함수
   * @param id 전략 ID값
   */
  const moveToMyUsDetailEditDiy = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-us-strategy-detail-edit',
    });
    nav(path);
  };

  /**
   * 백테스트 결과 (내 전략 편집 후 보이는 화면)로 이동하는 함수
   */
  const moveToMyEditResult = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-strategy-edit-result',
    });
    nav(path);
  };

  /**
   * 백테스트 결과 (내 전략 편집 후 보이는 화면)로 이동하는 함수
   */
  const moveToMyUsEditResult = (id: string, prevId: string) => {
    const path = getReplacePath({
      id,
      prevId,
      pageType: 'my-us-strategy-edit-result',
    });
    nav(path);
  };

  const simpleEditRoutes = (routeObj: {
    strategyId: string;
    prevStrategyId?: string;
    reEdit?: boolean;
    /**
     * 국가 코드
     * @default COUNTRY_MAPPER.DOMESTIC (국내)
     */
    countryCode?: UniverseCountryEnum;
    investmentStrategyLevel?: InvestmentStrategyLevelEnum;
  }) => {
    const {
      strategyId,
      prevStrategyId,
      reEdit,
      countryCode = COUNTRY_MAPPER.DOMESTIC,
      investmentStrategyLevel,
    } = routeObj;
    switch (investmentStrategyLevel) {
      case InvestmentStrategyLevelEnum.NoviceLevel1: {
        if (prevStrategyId) {
          moveToMyBeginner1Edit(strategyId, countryCode, prevStrategyId);
          return;
        }
        moveToBeginner1Edit(strategyId, countryCode, reEdit);
        break;
      }
      case InvestmentStrategyLevelEnum.NoviceLevel3: {
        if (prevStrategyId) {
          moveToMyBeginner2Edit(strategyId, countryCode, prevStrategyId);
          return;
        }
        moveToBeginner2Edit(strategyId, countryCode, reEdit);
        break;
      }
      case InvestmentStrategyLevelEnum.IntermediateLevel1: {
        if (prevStrategyId) {
          moveToMyPro1Edit(strategyId, countryCode, prevStrategyId);
          return;
        }
        moveToPro1Edit(strategyId, countryCode, reEdit);
        break;
      }
      case InvestmentStrategyLevelEnum.IntermediateLevel3: {
        const isUs = isCountryCodeUs(countryCode);

        if (prevStrategyId && isUs) {
          moveToMyUsSimpleEdit(strategyId, prevStrategyId);
          return;
        }
        if (prevStrategyId) {
          moveToMySimpleEdit(strategyId, prevStrategyId);
          return;
        }
        if (isUs) {
          moveToUsSimpleEdit(strategyId, reEdit);
          return;
        }
        moveToSimpleEdit(strategyId, reEdit);
        break;
      }
    }
  };

  const detailEditRoutes = (routeObj: {
    strategyId: string;
    prevStrategyId?: string;
    reEdit?: boolean;
    /**
     * 국가 코드
     * @default COUNTRY_MAPPER.DOMESTIC (국내)
     */
    countryCode?: UniverseCountryEnum;
    category?: StrategyCategoryType;
  }) => {
    const {
      strategyId,
      prevStrategyId,
      reEdit,
      countryCode = COUNTRY_MAPPER.DOMESTIC,
      category,
    } = routeObj;

    const isUs = isCountryCodeUs(countryCode);

    switch (category) {
      case 'DIY': {
        if (prevStrategyId && isUs) {
          moveToMyUsDetailEditDiy(strategyId, prevStrategyId);
          return;
        }
        if (prevStrategyId) {
          moveToMyDetailEditDiy(strategyId, prevStrategyId);
          return;
        }
        if (isUs) {
          moveToUsDiyEdit(strategyId, reEdit);
          return;
        }
        moveToDiyEdit(strategyId, reEdit);
        break;
      }
      case 'MASTER': {
        if (prevStrategyId && isUs) {
          moveToMyUsMasterEdit(strategyId, prevStrategyId);
          return;
        }
        if (prevStrategyId) {
          moveToMyMasterEdit(strategyId, prevStrategyId);
          return;
        }
        if (isUs) {
          moveToUsMasterEdit(strategyId, reEdit);
          return;
        }
        moveToMasterEdit(strategyId, reEdit);
        break;
      }
      default: {
        if (prevStrategyId && isUs) {
          moveToMyUsDetailEdit(strategyId, prevStrategyId);
          return;
        }
        if (prevStrategyId) {
          moveToMyDetailEdit(strategyId, prevStrategyId);
          return;
        }
        if (isUs) {
          moveToUsDetailEdit(strategyId, reEdit);
          return;
        }
        moveToDetailEdit(strategyId, reEdit);
      }
    }
  };

  return {
    moveToReadyMades,
    moveToUSReadyMades,

    moveToReadymadeDetail,
    moveToUSReadymadeDetail,

    moveToMasterDetail,
    moveToUSMasterDetail,

    moveToBeginner1Edit,
    moveToBeginner2Edit,
    moveToPro1Edit,

    moveToSimpleEdit,
    moveToUsSimpleEdit,

    moveToDiyEdit,
    moveToUsDiyEdit,

    moveToMasterEdit,
    moveToUsMasterEdit,

    moveToDetailEdit,
    moveToUsDetailEdit,

    moveToBackTest,
    moveToUsBackTest,

    moveToForwardTest,
    moveToUsForwardTest,

    moveToEditResult,
    moveToUsEditResult,

    moveToMyEditResult,
    moveToMyUsEditResult,

    moveToMyMasterEdit,
    moveToMyUsMasterEdit,

    moveToMySimpleEdit,
    moveToMyUsSimpleEdit,

    moveToMyDetailEdit,
    moveToMyUsDetailEdit,

    moveToMyDetailEditDiy,
    moveToMyUsDetailEditDiy,

    moveToMyStrategies,

    simpleEditRoutes,
    detailEditRoutes,
  };
};
