import { DetailedStrategyInfoModel } from '@models/strategy';
import { FlexBox, Typography } from '@ui/components';
import { getTextOverflow } from '@ui/util';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ReturnRateInfo } from './ReturnRateInfo';

interface DetailedStrategyInfoProps extends DetailedStrategyInfoModel {}

const DetailedStrategyInfoWrap = styled.div``;

export const Script = styled(Typography)<{ extended: boolean }>`
  ${({ extended }) => {
    if (!extended) {
      return getTextOverflow(2);
    }
    return css`
      display: block;
    `;
  }}
`;

export const DetailedStrategyInfo: FC<DetailedStrategyInfoProps> = ({
  baseDate,
  description,
  // name, /** FIXME: Header 정해지면 수정 */
  cumulativeReturnRate,
  benchmarkName,
  benchmarkCumulativeReturnRate,
}) => {
  const [extended, setExtended] = React.useState(false);
  const [isOverflow, setIsOverflow] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setExtended((prev) => !prev);
  };

  React.useLayoutEffect(() => {
    const trigger = () => {
      if (ref.current) {
        const { scrollHeight, clientHeight } = ref.current;

        if (scrollHeight && clientHeight) {
          setIsOverflow(scrollHeight > clientHeight);
        }
      }
    };

    if (ref.current) {
      trigger();
    }
  }, [description]);

  const type: 'single' | 'double' = benchmarkCumulativeReturnRate
    ? 'double'
    : 'single';
  const isDouble = type === 'double';

  return (
    <DetailedStrategyInfoWrap>
      <Script
        variant="body2_content"
        color="black.87"
        extended={extended}
        ref={ref}
      >
        {description}
      </Script>
      {description && isOverflow && (
        <button onClick={handleClick}>
          <Typography variant="body2_title" color="secondary.text" mt={8}>
            전략소개 {extended ? '접기' : '더보기'}
          </Typography>
        </button>
      )}

      <FlexBox mt={24} mb={8} spacing={8} direction="column">
        {isDouble && (
          <FlexBox spacing={6}>
            <Typography variant="subTitle1_title">최근 3개월 수익률</Typography>
            {/* TODO: 인포버튼 내용 결정 전까지 숨김처리 */}
            {/* <InfoButton title="최근 3개월 수익률" /> */}
          </FlexBox>
        )}

        <Typography variant="caption_medium" color="gray.blue">
          {baseDate || '*기준일자:'}
        </Typography>
      </FlexBox>

      <ReturnRateInfo
        type={type}
        label={isDouble ? '프리셋(Pre-set)' : '최근 3개월 수익률'}
        value={cumulativeReturnRate}
        secondLabel={benchmarkName}
        secondValue={benchmarkCumulativeReturnRate}
      />
    </DetailedStrategyInfoWrap>
  );
};
