import { createInitCommonEditState } from './common';
import { createInitFactorEditState } from './factor-edit';
import { createMasterStrategyState } from './master-strategy';
import { createInitSectorIndustryState } from './sector-industry';
import { createSimulationOptionState } from './simulation-option';
import { createStockSelectorState } from './stock-selector';

export const createInitStrategyEditState = () => ({
  ...createInitCommonEditState(),
  ...createInitSectorIndustryState(),
  ...createInitFactorEditState(),
  ...createStockSelectorState(),
  ...createSimulationOptionState(),
  ...createMasterStrategyState(),
});
