import { useMyStrategyStore } from '@stores/my-strategy';
import { useRouterMove } from '..';
import { CountryCodeType } from '@models/common';
import { COUNTRY_MAPPER } from '@constants/common';
import { useMyStrategyListStore } from '@stores/my-strategy-list';
import { SIMULATION_TYPE_MAPPER } from '@constants/strategy';
import { useBottomsheetStore } from '@stores/feedback';
import { useEffect } from 'react';
import { move_back_menu, move_rebalancing } from '@utils/bridge';

interface MyStrategyResultControllerProps {
  apiStatus: string;
  enableContract: boolean;
  countryCode: CountryCodeType;
  strategyId: string;
  encryptedId: string;
  portfolioId: string | undefined;
}

export const useMyStrategyResultController = ({
  apiStatus,
  enableContract,
  countryCode,
  strategyId,
  encryptedId,
  portfolioId,
}: MyStrategyResultControllerProps) => {
  const { openBottomsheet, closeBottomsheet } = useBottomsheetStore();
  const { category, isSimple, isActiveKbContract, fetchHoldingPositionAll } =
    useMyStrategyStore();
  const { syncMyStrategy, checkIsActiveMyStrategy } = useMyStrategyListStore();

  const {
    moveToMySimpleEdit,
    moveToMyUsSimpleEdit,
    moveToMyDetailEdit,
    moveToMyUsDetailEdit,
    moveToMyDetailEditDiy,
    moveToMyUsDetailEditDiy,
    moveToMyMasterEdit,
    moveToMyUsMasterEdit,
    moveToMyStrategies,
  } = useRouterMove();

  /**
   * 전략 편집 페이지로 이동하는 함수
   */
  const handleMoveToEditPage = () => {
    if (isSimple) {
      if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
        moveToMySimpleEdit(strategyId, strategyId);
      } else {
        moveToMyUsSimpleEdit(strategyId, strategyId);
      }
    } else {
      if (category === 'DIY') {
        if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
          moveToMyDetailEditDiy(strategyId, strategyId);
        } else {
          moveToMyUsDetailEditDiy(strategyId, strategyId);
        }
      } else if (category === 'MASTER') {
        if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
          moveToMyMasterEdit(strategyId, strategyId);
        } else {
          moveToMyUsMasterEdit(strategyId, strategyId);
        }
      } else {
        if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
          moveToMyDetailEdit(strategyId, strategyId);
        } else {
          moveToMyUsDetailEdit(strategyId, strategyId);
        }
      }
    }
  };

  /**
   * 투자 시작하기 버튼 클릭시 실행되는 함수
   */
  const handleStartContract = () => {
    const syncId = +strategyId > 0 ? encryptedId : strategyId;
    checkIsActiveMyStrategy(syncId).then((result) => {
      if (!result) {
        fetchHoldingPositionAll({
          id: syncId,
          simulationType: SIMULATION_TYPE_MAPPER.FORWARDTEST,
        });
        handleFailBottomsheet('checkEmpty');
        return;
      }

      syncMyStrategy(syncId, portfolioId);
    });
  };

  // 종목의 갯수가 부족하거 없을때 바텀 시트 띄우기
  const handleFailBottomsheet = (
    bottomSheetType: 'stockEmpty' | 'checkEmpty' = 'stockEmpty'
  ) => {
    openBottomsheet('stockListEmpty')({
      type: bottomSheetType,
      onOk: () =>
        isActiveKbContract
          ? closeBottomsheet('stockListEmpty')
          : handleMoveToEditPage,
      isActiveKbContract,
    });
  };

  // 하단의 메인(노락색) 버튼 클릭시 실행되는 함수
  const handleClickOk = () => {
    if (!enableContract && !isActiveKbContract) {
      handleMoveToEditPage();
    } else {
      if (portfolioId) {
        isActiveKbContract
          ? move_rebalancing(portfolioId)
          : handleStartContract();
      }
    }
  };

  const handlePrev = (ci: string) => {
    if (ci) {
      move_back_menu();
    } else {
      const myStrategyTabIndex = countryCode === COUNTRY_MAPPER.USA ? 1 : 0;
      moveToMyStrategies(myStrategyTabIndex);
    }
  };

  // 페이지 접근 했을 때 현금 100%로 투자 시작을 할 수 없을때 바텀 시트 띄우기
  useEffect(() => {
    if (!enableContract && apiStatus === 'success') {
      handleFailBottomsheet('stockEmpty');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus, enableContract]);

  return {
    handlePrev,
    handleClickOk,
    handleMoveToEditPage,
    handleStartContract,
  };
};
