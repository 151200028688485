import { UniverseItemModel, UniversePresetType } from '@models/strategy';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UniverseModel {
  name: string;
  isActive?: boolean;
  onClick: () => void;
}

interface UniverseListProps {
  activeName: UniversePresetType;
  universeList: UniverseItemModel[];
  onClickUniverse: (type: UniversePresetType) => () => void;
}

const UniverseTagWrap = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 34px;
  border-radius: 20px;
  border: 1.5px solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.colorPalette.black[73]
        : theme.colorPalette.divider.appbar_tab};
`;

const UniverseListWrap = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 8px;
`;

export const UniverseTag: FC<UniverseModel> = ({
  name,
  isActive = false,
  onClick,
}) => {
  return (
    <UniverseTagWrap isActive={isActive} onClick={onClick}>
      <Typography
        variant="caption_medium"
        color={isActive ? 'black.87' : 'black.40'}
      >
        {name}
      </Typography>
    </UniverseTagWrap>
  );
};

export const UniverseList: FC<UniverseListProps> = ({
  onClickUniverse,
  universeList,
  activeName,
}) => {
  return (
    <UniverseListWrap>
      {universeList.map((universe) => (
        <UniverseTag
          key={universe.name}
          name={universe.text}
          isActive={universe.name === activeName}
          onClick={onClickUniverse(universe.name)}
        />
      ))}
    </UniverseListWrap>
  );
};
