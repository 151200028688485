import { ScThemedIconProps, ThemedIconProps } from '@ui/model';
import { getMarginFromProps } from '@ui/util';
import { svgPath } from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

const IconWrap = styled.img<ScThemedIconProps>`
  ${getMarginFromProps}
  align-self: center;
  flex-shrink: 0;
`;

export const Icon: FC<ThemedIconProps> = ({
  type = 'search',
  size = '24',
  ...props
}) => {
  return (
    <IconWrap
      {...props}
      src={svgPath('/icon', type)}
      width={size}
      height={size}
      alt={`icon-${type}`}
    />
  );
};

Icon.displayName = 'Icon';
