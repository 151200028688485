import { LevelTwoContainer } from '@containers/strategy-edit/beginner/LevelTwoContainer';
import { FC } from 'react';

interface StrategyBeginner2EditPageProps {
  reEdit?: boolean;
}

export const StrategyBeginner2EditPage: FC<StrategyBeginner2EditPageProps> = ({
  reEdit = false,
}) => {
  return <LevelTwoContainer reEdit={reEdit} />;
};
