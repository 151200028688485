/* eslint-disable react-hooks/exhaustive-deps */
import { HistogramSingle } from '@components/common/histogram/single/HistogramSingle';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface MasterFactorContainerProps {}

const MasterFactorContainerWrap = styled.div``;

const CountWarp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
`;

const Controller = styled.div`
  padding: 0 15px;
  height: 80px;
`;

const StrategyBox = styled(FlexBox)`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
`;

export const MasterFactorContainer: FC<MasterFactorContainerProps> = () => {
  const { masterInfo } = useReadymadeStrategyStore();
  const { masterFactor, masterFactorIndex, setMasterFactorIndex } =
    useStrategyEditStore();

  const handleChangeMasterFactor = (values: readonly number[]) => {
    setMasterFactorIndex(values[0]);
  };

  const handleUpdateMasterFactor = (values: readonly number[]) => {
    setMasterFactorIndex(values[0]);
  };

  return (
    <MasterFactorContainerWrap>
      <StrategyBox p={20} direction="column" spacing={40}>
        <FlexBox justify="space-between" spacing={12}>
          <FlexBox direction="column">
            <Typography variant="body1_title" color="black.100">
              {masterInfo.name}
            </Typography>
            <Typography variant="caption_medium" color="secondary.text">
              종목 수 :{' '}
              {masterFactor.histogramChartData
                .slice(masterFactorIndex)
                .reduce((acc, cur) => acc + cur.volume, 0)}
            </Typography>
          </FlexBox>
          <CountWarp>
            <Typography variant="caption_medium">
              {masterFactorIndex + 1}
            </Typography>
          </CountWarp>
        </FlexBox>
        <Controller>
          <HistogramSingle
            histogramChartData={masterFactor.histogramChartData}
            range={masterFactor.range}
            factorIndex={masterFactorIndex}
            onChange={handleChangeMasterFactor}
            onUpdate={handleUpdateMasterFactor}
          />
        </Controller>
      </StrategyBox>
    </MasterFactorContainerWrap>
  );
};
