import {
  PRICE_INFO_DESCRIPTIONS,
  RATE_INFO_DESCRIPTION,
} from '@constants/common';
import { Typography } from '@ui/components';
import styled from 'styled-components';

interface DescInfoProps {
  priceInfo?: boolean;
}

const DescInfoWrap = styled.div`
  margin-top: 4px;
  & > p {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.8px;
  }
`;

export const DescInfo: React.FC<DescInfoProps> = ({ priceInfo }) => {
  return (
    <DescInfoWrap>
      {RATE_INFO_DESCRIPTION.map((item, index) => {
        return (
          <Typography key={index} color="gray.blue">
            {item}
          </Typography>
        );
      })}
      {priceInfo &&
        PRICE_INFO_DESCRIPTIONS.map((item, index) => {
          return (
            <Typography key={index} color="state.red">
              {item}
            </Typography>
          );
        })}
    </DescInfoWrap>
  );
};
