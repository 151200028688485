import {
  ScColorProps,
  ScLineHeightProps,
  ScTextAlignProps,
  ScTextBoldProps,
  ScTextOverflowProps,
  ScTextSizeProps,
  ScTextVariantProps,
  ScWhiteSpaceProps,
  ScWordBreakProps,
} from '@ui/model/ui.scType';
import { ThemedComponentProps } from '@ui/model/ui.type';
import { isNumberLike } from '@utils/common';
import { css } from 'styled-components';
import { findColorByType } from './common.util';

export const getFontColorFromProps = ({ $color }: ScColorProps) => {
  if (!$color) {
    return null;
  }
  return css`
    color: ${findColorByType($color)};
  `;
};

export const getFontStyleByVariantProps = ({
  theme,
  $variant,
}: ThemedComponentProps & ScTextVariantProps) => {
  if (!$variant) {
    return null;
  }

  return css`
    font-size: ${theme.size[$variant]};
    line-height: ${theme.lineHeight[$variant]};
    font-weight: ${theme.fontWeight[$variant]};
  `;
};

export const getFontSizeFromProps = ({
  $size,
}: ThemedComponentProps & ScTextSizeProps) => {
  if (!$size) {
    return null;
  }

  if (isNumberLike($size)) {
    return css`
      font-size: ${$size}px;
    `;
  }
  return css`
    font-size: ${$size};
  `;
};

export const getFontWeightFromProps = ({
  $fontWeight,
}: ThemedComponentProps & ScTextBoldProps) => {
  if ($fontWeight === 'medium') {
    return css`
      font-weight: 500;
    `;
  }
  if ($fontWeight === 'light') {
    return css`
      font-weight: 300;
    `;
  }
  if ($fontWeight === 'semibold') {
    return css`
      font-weight: 600;
    `;
  }
  if ($fontWeight === 'bold') {
    return css`
      font-weight: 700;
    `;
  }
};

export const getLineHeightFromProps = ({
  $lineHeight,
}: ThemedComponentProps & ScLineHeightProps) => {
  if (!$lineHeight) {
    return null;
  }
  return css`
    line-height: ${$lineHeight};
  `;
};

export const getTextAlignFromProps = ({ $align }: ScTextAlignProps) => {
  if (!$align) {
    return null;
  }
  return css`
    text-align: ${$align};
  `;
};

export const getTextOverflowFromProps = ({
  $textOverflow,
}: ScTextOverflowProps) => {
  if (!$textOverflow) {
    return null;
  }
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
};

export const getWhiteSpaceFromProps = ({ $whiteSpace }: ScWhiteSpaceProps) => {
  if (!$whiteSpace) {
    return null;
  }
  return css`
    white-space: ${$whiteSpace};
  `;
};

export const getWordBreakFromProps = ({ $wordBreak }: ScWordBreakProps) => {
  if (!$wordBreak) {
    return null;
  }
  return css`
    word-break: ${$wordBreak};
  `;
};
