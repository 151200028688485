import { TagColor, TagSize } from '@models/common';
import { theme } from '@styles/theme';
import { Typography } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TagProps {
  size?: TagSize;
  color?: TagColor;
  children?: React.ReactNode;
}

interface tagColorModel {
  text: string;
  bg: string;
}

const tagColorObj: Record<TagColor, tagColorModel> = {
  green: {
    text: '#42701F',
    bg: 'rgba(111, 191, 55, 0.1);',
  },
  orange: {
    text: '#DB7804',
    bg: 'rgba(255, 192, 28, 0.1);',
  },
  blue: {
    text: theme.colorPalette.secondary.text,
    bg: 'rgba(81, 119, 188, 0.1);',
  },
  red: {
    text: '#FF4C4C',
    bg: 'rgba(231, 93, 90, 0.1)',
  },
  purple: {
    text: theme.colorPalette.white,
    bg: '#8B40F9',
  },
  intensiveBlue: {
    text: theme.colorPalette.fixedwhite,
    bg: theme.colorPalette.secondary.default,
  },
};

const TabBox = styled.div<{ color: TagColor }>`
  display: inline-block;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${({ color }) => tagColorObj[color].bg};

  & > p {
    color: ${({ color }) => tagColorObj[color].text};
  }
`;

export const Tag: FC<TagProps> = ({
  size = 'small',
  color = 'blue',
  children,
}) => {
  return (
    <TabBox color={color}>
      <Typography variant={size === 'big' ? 'overline_title' : 'tag'}>
        {children}
      </Typography>
    </TabBox>
  );
};
