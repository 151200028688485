/* eslint-disable react-hooks/exhaustive-deps */
import { Tag } from '@components/common';
import { STRATEGY_INDUSTRY_ENUM } from '@constants/common';
import { useSectorIndustrySelector } from '@hooks/useSectorIndustrySelector';
import { StrategySectorType } from '@models/strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { CheckBoxButton, Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

const SectorBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;

  &.open-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
  }

  .sector-content {
    border-bottom: 1px solid
      ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  }

  &.open {
    border-radius: 8px 8px 0px 0px;
  }
`;

const IndustryWrap = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.black[1]};
  padding-bottom: 16px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-width: 0px 1px 1px 1px;
`;

const IndustryBox = styled(FlexBox)`
  padding: 16px 0;
  margin: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.column};
`;

export const SectorIndustryListContainer: FC = () => {
  const {
    setCheckedCnt,
    sectorInfoList,
    setIndustries,
    sectorCheckObj,
    setCheckObj,
    sectorToggleObj,
    setToggleObj,
  } = useStrategyEditStore();

  const {
    checkObj,
    checkedCnt,
    handleCheckAll,
    handleCheckIndustry,
    handleCheckSector,
  } = useSectorIndustrySelector(sectorCheckObj);

  const handleToggleSector = (sector: StrategySectorType) => () => {
    setToggleObj(sector);
  };

  useEffect(() => {
    setCheckObj(checkObj);
    setCheckedCnt(checkedCnt);
    const industryList = STRATEGY_INDUSTRY_ENUM.filter(
      (industry) => checkObj.industry[industry]
    );
    setIndustries(industryList);
  }, [checkObj]);

  return (
    <FlexBox direction="column" spacing={16}>
      <FlexBox>
        <Typography variant="caption_medium" color="secondary.text">
          {checkedCnt}
        </Typography>
        <Typography variant="caption_medium" color="black.87">
          개 선택됨
        </Typography>
      </FlexBox>
      <SectorBox onClick={handleCheckAll}>
        <CheckBoxButton variant={checkObj.all ? 'select' : 'default'}>
          <Typography variant="body1_title" color="black.100">
            섹터 전체선택
          </Typography>
          <Tag>{STRATEGY_INDUSTRY_ENUM.length}</Tag>
        </CheckBoxButton>
      </SectorBox>
      {sectorInfoList.map((sector) => {
        const enableToggleSector = sector.industries.length > 1;
        const isSectorChecked = sectorCheckObj.sector[sector.name];
        const selectedLength = isSectorChecked
          ? 0
          : sector.industries.filter(
              ({ name }) => sectorCheckObj.industry[name]
            ).length;
        return (
          <FlexBox direction="column" key={sector.name}>
            <SectorBox className={sectorToggleObj[sector.name] ? 'open' : ''}>
              <FlexBox justify="space-between">
                <CheckBoxButton
                  variant={checkObj.sector[sector.name] ? 'select' : 'default'}
                  onClick={handleCheckSector(sector.name)}
                >
                  <Typography variant="body2_title">{sector.text}</Typography>
                  {enableToggleSector && <Tag>{sector.industries.length}</Tag>}
                  {selectedLength > 0 && (
                    <Tag color="intensiveBlue">{selectedLength}개 선택</Tag>
                  )}
                </CheckBoxButton>
                {enableToggleSector && (
                  <button onClick={handleToggleSector(sector.name)}>
                    <Icon
                      type={
                        sectorToggleObj[sector.name] ? 'arrow-up' : 'arrow-down'
                      }
                    />
                  </button>
                )}
              </FlexBox>
            </SectorBox>

            {sectorToggleObj[sector.name] && enableToggleSector && (
              <IndustryWrap>
                {sector.industries.map((industry) => (
                  <IndustryBox key={industry.name}>
                    <CheckBoxButton
                      variant={
                        checkObj.industry[industry.name] ? 'select' : 'default'
                      }
                      onClick={handleCheckIndustry(sector.name, industry.name)}
                    >
                      <Typography variant="body2_title">
                        {industry.text}
                      </Typography>
                    </CheckBoxButton>
                  </IndustryBox>
                ))}
              </IndustryWrap>
            )}
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
