import { DetailEditPageContainer } from '@containers/strategy-edit/DetailEditPageContainer';
import { FC } from 'react';

interface StrategyDetailEditDiyPageProps {
  reEdit?: boolean;
}
export const StrategyDetailEditDiyPage: FC<StrategyDetailEditDiyPageProps> = ({
  reEdit = false,
}) => {
  return <DetailEditPageContainer category="DIY" reEdit={reEdit} />;
};
