import {
  BOOKSIZE_OPTIONS,
  BOOKSIZE_OPTIONS2,
  COUNTRY_MAPPER,
} from '@constants/common';
import { useCountryCode } from '@stores/common/common.store';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { FlexBox, Icon, Typography } from '@ui/components';
import { replaceNumberByTenThousandUnit } from '@utils/common';
import React, { FC, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

const Option = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 15px;
`;

const Text = styled.div`
  max-width: 234px;
`;

const filterOptions = new Set(
  Array.from({ length: 10 }, (_, i) => (i + 1) * 100)
);

export const BooksizeOptionContainer: FC = () => {
  const { strategySettingObj, setBooksize } = useStrategyEditStore();
  const countryCode = useCountryCode();
  const { openConfirm } = useConfirmStore();

  const [selectOption, setSelectOption] = useState<number>(500);
  const [customSelected, setCustomSelected] = useState<boolean>(false);

  const options =
    countryCode === COUNTRY_MAPPER.DOMESTIC
      ? BOOKSIZE_OPTIONS
      : BOOKSIZE_OPTIONS2;

  const handleClickInputOption = () => {
    openConfirm('booksize')({
      countryCode,
      onOk: (booksizeInput: number) => {
        setCustomSelected(true);
        setSelectOption(-1);
        setBooksize(booksizeInput * 10000); // 투자금액 : 1만 -> 1원 단위로 치환
      },
    });
  };

  const handleClick: (name: number) => React.MouseEventHandler<HTMLElement> =
    (name) => (e) => {
      e.stopPropagation();
      setCustomSelected(false);
      setSelectOption(name);
      setBooksize(name * 10000);
    };

  useLayoutEffect(() => {
    if (strategySettingObj.booksize) {
      const _bookSize = strategySettingObj.booksize / 10000;
      setCustomSelected(!filterOptions.has(_bookSize));
      setSelectOption(_bookSize);
    }
  }, [strategySettingObj.booksize]);

  return (
    <>
      <FlexBox spacing={8} direction="column" mb={8}>
        <Typography color="black.87" variant="subTitle1_title">
          투자 금액
        </Typography>
        <FlexBox>
          <Typography color="state.red" variant="caption_medium">
            *
          </Typography>
          <Typography color="state.red" variant="caption_medium">
            설정한 투자금액이 적으면 전략이 온전히 반영되지 않습니다.
            프리셋(Pre-set)전략은 1억원 기준입니다.
          </Typography>
        </FlexBox>
      </FlexBox>
      <ul>
        <Option onClick={handleClickInputOption}>
          <Typography
            variant="subTitle2"
            fontWeight={customSelected ? 'medium' : 'light'}
            color={customSelected ? 'secondary.text' : 'black.87'}
          >
            직접입력
          </Typography>
          <FlexBox>
            {customSelected && (
              <Typography color="secondary.text" variant="subTitle2_medium">
                {replaceNumberByTenThousandUnit(strategySettingObj.booksize)}
              </Typography>
            )}
            <Icon type="chevron-right" />
          </FlexBox>
        </Option>
        {options.map(({ name, text, subText, variant }) => {
          const isSelected = selectOption === name;
          return (
            <Option
              key={`option_${name}`}
              role="button"
              onClick={handleClick(name)}
            >
              <Text>
                <Typography
                  variant="subTitle2"
                  fontWeight={isSelected ? 'medium' : 'light'}
                  color={isSelected ? 'secondary.text' : 'black.87'}
                >
                  {text}
                </Typography>
                {subText && (
                  <Typography
                    variant="caption"
                    color={isSelected ? 'secondary.text' : 'black.47'}
                    mt={8}
                  >
                    {subText}
                  </Typography>
                )}
              </Text>
              {isSelected && <Icon type="check" />}
            </Option>
          );
        })}
      </ul>
    </>
  );
};
