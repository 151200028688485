import {
  BOTTOMSHEET_DESC,
  BOTTOMSHEET_EDIT_PRO_ENUM,
  BOTTOMSHEET_EDIT_SIMPLE_ENUM,
} from '@constants/feedback';
import {
  EditBottomsheetContentModel,
  EditOptionType,
  editTabType,
} from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { StrategyCategoryType } from '@models/strategy';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EditProgressTag } from './EditProgressTag';
import { EditSheetTemplate } from './EditSheetTemplate';
import { EditTab } from './EditTab';
import { isDarkMode } from '@utils/common';

interface EditBottomsheetProps
  extends EditBottomsheetContentModel,
    ContentWidthModel {
  onClickOk?: (
    option: EditOptionType,
    id: string,
    category: StrategyCategoryType
  ) => void;
  onClickCancel?: () => void;
}

const EditBottomsheetWrap = styled(EditSheetTemplate)`
  position: relative;
`;
const LevelInfoWrap = styled(FlexBox)`
  background-color: ${({ theme }) => theme.colorPalette.white};
  position: absolute;
  left: 28px;
  right: 0;
  bottom: 82px;
`;

const Item = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
`;

const Title = styled(Typography)``;

const Description = styled(Typography)``;

const EditOptionList = styled(FlexBox)``;

export const EditBottomsheet: FC<EditBottomsheetProps> = ({
  contentWidth,
  category,
  strategyId,
  onClickOk,
  onClickCancel,
}) => {
  const [editTab, setEditTab] = useState<editTabType>('beginner');
  const [editOption, setEditOption] = useState<EditOptionType>('beginner-1');
  const EditTypeList = useMemo(() => {
    if (editTab === 'beginner') return BOTTOMSHEET_EDIT_SIMPLE_ENUM;
    else return BOTTOMSHEET_EDIT_PRO_ENUM;
  }, [editTab]);
  const editContentWidth = contentWidth && contentWidth < 360 ? 230 : undefined;

  const handleSelect: (
    _option: EditOptionType
  ) => React.MouseEventHandler<HTMLButtonElement> = (_option) => (e) => {
    e.stopPropagation();
    setEditOption(_option);
  };

  const handleClickTab = (tab: editTabType) => () => {
    setEditTab(tab);
  };

  const handleClick = () => {
    if (onClickOk) {
      onClickOk(editOption, strategyId, category);
    }
  };

  useEffect(() => {
    if (editTab === 'pro') {
      setEditOption('pro-1');
    }

    if (editTab === 'beginner') {
      setEditOption('beginner-1');
    }
  }, [editTab]);

  return (
    <EditBottomsheetWrap
      type="edit"
      title="투자 방법 찾기"
      button="double"
      okText="다음"
      contentWidth={contentWidth}
      onClickOk={handleClick}
      onClickSecond={onClickCancel}
    >
      <EditTab activeTab={editTab} onClickTab={handleClickTab} />
      <EditOptionList direction="column" pt={62}>
        {EditTypeList.map((type) => {
          const optionChecked = type === editOption;
          return (
            <Item
              spacing={4}
              py={20}
              onClick={handleSelect(type)}
              key={type}
              alignItems="center"
              justify="space-between"
            >
              <FlexBox direction="column" width={editContentWidth}>
                <Title
                  variant={optionChecked ? 'subTitle2_medium' : 'subTitle2'}
                  color={optionChecked ? 'secondary.text' : 'black.87'}
                >
                  {BOTTOMSHEET_DESC[type].title}
                </Title>
                <EditProgressTag type={type} category={category} />
                <Description
                  variant="caption"
                  color={
                    optionChecked && !isDarkMode()
                      ? 'secondary.text'
                      : 'black.47'
                  }
                  wordBreak="keep-all"
                >
                  {BOTTOMSHEET_DESC[type].desc}
                </Description>
              </FlexBox>
              <FlexBox justify="space-between" height={24}>
                {optionChecked && <Icon type="check" />}
              </FlexBox>
            </Item>
          );
        })}
      </EditOptionList>
      <LevelInfoWrap>
        <Typography color="gray.blue" variant="overline_content" mt={12}>
          * Level은 투자 난이도를 의미합니다.
        </Typography>
      </LevelInfoWrap>
    </EditBottomsheetWrap>
  );
};
