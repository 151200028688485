/** 특수문자에 해당하는 입력값을 안받도록 하기위한 정규식 */
export const exceptSpecialTextRegex =
  /^[0-9|a-z|A-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣ぁ-ゔァ-ヴー々〆〤一-龥\s\-/:;()₩&@“.,?!'[\]{}#%^*+=_\\|~<>$£¥ㆍᆢ•]*$/;

/** IOS  */
export const iosRegex = /iPhone|iPad|iPod/i;

/** AOS */
export const aosRegex = /Android/i;

/** Windows Phone */
export const windowRegex = /windows phone/i;

/** Mobile Device */
export const mobileRegex = /iPhone|iPad|iPod|Android|windows phone/i;
