import { EditHeadTab } from '@components/strategy-edit';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { getFloaterModelByStep } from '@utils/strategy/strategy-edit.util';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { EditCancelBtn } from './DetailEditPageContainer';
import { FactorEditContainer } from './FactorEditContainer';
import { FactorSelectContainer } from './FactorSelectContainer';
import { SectorIndustryListContainer } from './SectorIndustryListContainer';
import { StockSelectorContainer } from './StockSelectorContainer';
import { StrategySettingContainer } from './StrategySettingContainer';
import { CountryCodeType } from '@models/common';

interface DIYEditContainerProps {
  countryCode: CountryCodeType;
  onReplaceStep: (index: number) => void;
  onClose: () => void;
  onOk: () => void;
}

export const DIYEditContainer: FC<DIYEditContainerProps> = ({
  countryCode,
  onReplaceStep,
  onClose,
  onOk,
}) => {
  const location = useLocation();
  const { closeConfirm, openConfirm } = useConfirmStore();
  const urlParams = new URLSearchParams(location.search);
  const editStep = urlParams.get('step') || 1;
  const { checkedCnt, holdings } = useStrategyEditStore();

  const handleClickNext = () => {
    if (+editStep === 1) {
      if (checkedCnt === 0) {
        openConfirm('alert')({
          button: 'single',
          description: '최소 1개 이상의 업종을 선택해주세요.',
          title: '섹터/업종',
          onOk: () => closeConfirm('alert'),
        });
      } else {
        onReplaceStep(+editStep + 1);
      }
    } else {
      onReplaceStep(+editStep + 1);
    }
  };

  const handleClickPrev = () => {
    onReplaceStep(+editStep - 1);
  };

  const handleClickComplete = () => {
    onOk();
  };

  const handleClickTabButton = (index: number) => () => {
    const step = index + 1;
    if (+editStep === 1) {
      if (checkedCnt === 0) {
        openConfirm('alert')({
          button: 'single',
          description: '최소 1개 이상의 업종을 선택해주세요.',
          title: '섹터/업종',
          onOk: () => closeConfirm('alert'),
        });
      } else {
        onReplaceStep(step);
      }
    } else {
      onReplaceStep(step);
    }
  };

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/5단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 5,
        okDisabled:
          (+editStep === 1 && checkedCnt === 0) ||
          (+editStep === 5 && holdings.length === 0), // 종목구성 데이터 없는 경우 버튼 비활성화
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleClickComplete,
      })}
      optionalButton={EditCancelBtn(onClose)}
      onClickPrev={onClose}
    >
      <EditHeadTab
        type="diy"
        step={+editStep}
        enableDesc={holdings.length !== 0}
        onClickTab={handleClickTabButton}
      />
      {+editStep === 1 && <SectorIndustryListContainer />}
      {+editStep === 2 && <FactorSelectContainer />}
      {+editStep === 3 && <FactorEditContainer />}
      {+editStep === 4 && (
        <StrategySettingContainer countryCode={countryCode} />
      )}
      {+editStep === 5 && <StockSelectorContainer />}
    </LayoutContainer>
  );
};
