import {
  UNIVERSE_PUBLIC_ENUM,
  UNIVERSE_REPRESENT_ENUM,
  UNIVERSE_US_ENUM,
} from '@constants/strategy';
import {
  GetStrategiesQuery,
  GetThemeGroupsQuery,
  GetUniversePresetsQuery,
} from '@graphql/generates';
import {
  StrategyCardModel,
  StrategyPaginationModel,
  StrategyUniverseType,
  ThemeGroupDto,
  UniverseItemModel,
} from '@models/strategy';
import { filterNonDataOfArray, getDateFormattedString } from '@utils/common';

export const convertStrategiesByCategory = (data: GetStrategiesQuery) => {
  const edges = data?.strategies?.edges;
  const pageInfo = data.strategies?.pageInfo;
  const resList: StrategyCardModel[] = [];
  const pageObj: StrategyPaginationModel = {
    hasNextPage: false,
    endCursor: '',
  };

  if (!edges)
    return {
      list: resList,
      pageObj,
    };

  edges.forEach((edge) => {
    const strategy: StrategyCardModel = {
      id: '',
      description: '',
      title: '',
      isKbPick: false,
    };
    const node = edge?.node;

    if (!node) return strategy;

    strategy.id = node.id;
    strategy.description = node.shortDescription;
    strategy.title = node.name;
    strategy.returnSummary = {};

    if (node.__typename === 'MasterStrategyType') {
      strategy.img = node.img;
      // nothing to do
    } else if (node.__typename === 'SectorIndustryStrategyType') {
      strategy.img = node.img;
      strategy.returnSummary.month1 = node.returnSummary?.month1 || undefined;
      strategy.returnSummary.month3 = node.returnSummary?.month3 || undefined;
      strategy.returnSummary.month6 = node.returnSummary?.month6 || undefined;
    } else {
      strategy.returnSummary.month1 = node.returnSummary?.month1 || undefined;
      strategy.returnSummary.month3 = node.returnSummary?.month3 || undefined;
      strategy.returnSummary.month6 = node.returnSummary?.month6 || undefined;
      strategy.returnSummary.mdd = node.returnSummary?.mdd || undefined;
      strategy.returnSummary.annualAvgVolatility =
        node.returnSummary?.annualAvgVolatility || undefined;

      strategy.returnSummary.kbPick = node.returnSummaryV2?.strategy?.kbPick;
      strategy.returnSummary.kbPickCreatedTs = getDateFormattedString(
        node.returnSummaryV2?.strategy?.kbPickCreatedTs || 0,
        'YY/mm/dd'
      );

      strategy.kbPickDescription = node.kbPickShortDescription || undefined;
    }
    resList.push(strategy);
  });

  if (pageInfo) {
    pageObj.hasNextPage = pageInfo.hasNextPage;
    pageObj.endCursor = pageInfo.endCursor || '';
  }

  return {
    list: resList,
    pageObj,
  };
};

export const convertThemeGroups = (
  data: GetThemeGroupsQuery
): ThemeGroupDto[] | undefined => {
  const infos = data.themeGroups?.themeGroups;
  if (!infos) return undefined;
  const themeGroups: ThemeGroupDto[] = [];
  for (const info of infos) {
    if (!info) continue;
    themeGroups.push({
      id: info.id,
      name: info.name || '',
    });
  }
  return themeGroups;
};

export const convertUniverseList = (data: GetUniversePresetsQuery) => {
  const { universePresets } = data;

  const universeListObj: Record<StrategyUniverseType, UniverseItemModel[]> = {
    PUBLIC: [],
    REPRESENT: [],
    USA: [],
  };

  if (universePresets && universePresets.universePresets) {
    universePresets.universePresets.forEach((item) => {
      if (item?.name) {
        const universeItem: UniverseItemModel = {
          desc: item?.description || '',
          name: item.name || '',
          text: item?.displayName || '',
          universes: filterNonDataOfArray(item?.universes),
        };

        if (UNIVERSE_US_ENUM.includes(item.name)) {
          universeListObj.USA.push(universeItem);
        }

        if (UNIVERSE_PUBLIC_ENUM.includes(item.name)) {
          universeListObj.PUBLIC.push(universeItem);
        }

        if (UNIVERSE_REPRESENT_ENUM.includes(item.name)) {
          universeListObj.REPRESENT.push(universeItem);
        }
      }
    });
  }

  return universeListObj;
};
