import {
  SECTOR_INDUSTRY_STRUCTURE,
  STRATEGY_INDUSTRY_ENUM,
  STRATEGY_SECTOR,
} from '@constants/common';
import {
  SectorIndustryCheckDto,
  StrategyIndustryType,
  StrategySectorType,
} from '@models/strategy';
import {
  SECTOR_CHECK_INIT_FALSE,
  createInitSectors,
} from '@stores/strategy-edit/sector-industry';

import { useLayoutEffect, useState } from 'react';

/**
 *
 * @param {object} initSectors - 초기 선택된 섹터/업종 정보
 * @returns {object} returnObject
 * - checkObj: 선택된 섹터/업종 정보
 * - checkedCnt: 선택된 업종 개수
 * - handleCheckAll: 전체 선택/해제 함수
 * - handleCheckSector: 섹터 선택/해제 함수
 * - handleCheckIndustry: 업종 선택/해제 함수
 *  @group Hooks
 *  @example
 * const {
 *  checkObj,
 *  checkedCnt,
 *  handleCheckAll,
 *  handleCheckSector,
 *  handleCheckIndustry,
 * } = useSectorIndustrySelector();
 * handleCheckAll(); // 전체 선택/해제
 */
export const useSectorIndustrySelector = (
  initSectors = createInitSectors(true)
) => {
  const [checkObj, setCheckObj] = useState<SectorIndustryCheckDto>(initSectors);

  const handleCheckAll = () => {
    // All - True
    if (!checkObj.all) {
      setCheckObj(createInitSectors(true));
    }

    // All - False
    if (checkObj.all) {
      setCheckObj(SECTOR_CHECK_INIT_FALSE);
    }
  };

  const isSectorAllChecked = (
    sectorObj: Record<StrategySectorType, boolean>
  ) => {
    let isAllCheck = true;
    STRATEGY_SECTOR.forEach((sector) => {
      if (!sectorObj[sector]) isAllCheck = false;
    });
    return isAllCheck;
  };

  const handleCheckSector = (sector: StrategySectorType) => () => {
    const newSectorObj = { ...checkObj.sector };
    const newIndustryObj = { ...checkObj.industry };

    const booleanValue = !checkObj.sector[sector];
    newSectorObj[sector] = booleanValue;

    if (SECTOR_INDUSTRY_STRUCTURE[sector].length === 0) {
      const sectorItselfIndustry = sector as unknown as StrategyIndustryType;
      newIndustryObj[sectorItselfIndustry] = booleanValue;
    } else {
      SECTOR_INDUSTRY_STRUCTURE[sector].forEach((industry) => {
        newIndustryObj[industry] = booleanValue;
      });
    }

    const newCheckObj: SectorIndustryCheckDto = {
      all: isSectorAllChecked(newSectorObj),
      sector: { ...newSectorObj },
      industry: { ...newIndustryObj },
    };
    setCheckObj(newCheckObj);
  };

  const handleCheckIndustry =
    (sector: StrategySectorType, industry: StrategyIndustryType) => () => {
      const newSectorObj = { ...checkObj.sector };
      const newIndustryObj = { ...checkObj.industry };
      newIndustryObj[industry] = !checkObj.industry[industry];

      let sectorChecked = true;
      SECTOR_INDUSTRY_STRUCTURE[sector].forEach((industry) => {
        if (sectorChecked && !newIndustryObj[industry]) sectorChecked = false;
      });
      newSectorObj[sector] = sectorChecked;

      const newCheckObj: SectorIndustryCheckDto = {
        all: isSectorAllChecked(newSectorObj),
        sector: { ...newSectorObj },
        industry: { ...newIndustryObj },
      };
      setCheckObj(newCheckObj);
    };

  useLayoutEffect(() => {
    setCheckObj(initSectors);
  }, [initSectors]);

  const checkedCnt = STRATEGY_INDUSTRY_ENUM.filter(
    (ind) => checkObj.industry[ind]
  ).length;

  return {
    checkObj,
    checkedCnt,
    handleCheckAll,
    handleCheckSector,
    handleCheckIndustry,
  };
};
