import { MasterFactors, Script, SectionTemplate } from '@components/strategy';
import { COUNTRY_MAPPER } from '@constants/common';
import { EXCEEDED_STRATEGY_ALERT_CONTENT } from '@constants/feedback';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useRouterMove } from '@hooks/useRouterMove';
import { CountryCodeType } from '@models/common';
import { useAuthStore } from '@stores/auth';
import { useConfirmStore } from '@stores/feedback';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { FlexBox, Typography } from '@ui/components';
import { check_from_mable_menu, move_back_menu } from '@utils/bridge';
import { FC, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface MasterDetailContainerProps {
  countryCode?: CountryCodeType;
}

const MasterInfoWrap = styled(FlexBox)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  padding: 20px;

  & > img {
    width: 60px;
    height: 60px;
  }
`;

const MasterSourceWrap = styled.ul`
  & > [aria-label='source'] {
    margin-left: 20px;
    list-style-type: disc;
  }
`;

export const MasterDetailContainer: FC<MasterDetailContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const {
    moveToReadyMades,
    moveToUSReadyMades,
    moveToMasterEdit,
    moveToUsMasterEdit,
  } = useRouterMove();
  const { strategyId = '' } = useParams<string>();

  const { initState, masterInfo, fetchMasterInfo } =
    useReadymadeStrategyStore();
  const { isStrategyCreationPossibleUs, isStrategyCreationPossible, fetchMe } =
    useAuthStore();

  const openConfirm = useConfirmStore((state) => state.openConfirm);

  useLayoutEffect(() => {
    fetchMe();
    fetchMasterInfo(strategyId);

    return () => {
      initState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** '전략 선택' 버튼 클릭 했을 때 실행되는 함수 */
  const handleClickStrategySelect = () => {
    if (
      (countryCode === COUNTRY_MAPPER.DOMESTIC &&
        !isStrategyCreationPossible) ||
      (countryCode === COUNTRY_MAPPER.USA && !isStrategyCreationPossibleUs)
    ) {
      openConfirm('alert')({
        ...EXCEEDED_STRATEGY_ALERT_CONTENT,
      });
      return;
    }

    if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
      moveToMasterEdit(strategyId);
    } else {
      moveToUsMasterEdit(strategyId);
    }
  };

  const handleCancel = () => {
    if (check_from_mable_menu()) {
      move_back_menu();
      return;
    }

    if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
      moveToReadyMades();
    } else {
      moveToUSReadyMades();
    }
  };

  return (
    <LayoutContainer
      floater={{
        variant: 'double',
        okText: '전략 선택',
        cancelText: '이전',
        onClickCancel: handleCancel,
        onClickOk: handleClickStrategySelect,
      }}
      onClickPrev={handleCancel}
    >
      <SectionTemplate variant="custom">
        <MasterInfoWrap spacing={16} alignItems="flex-start" mb={24}>
          <img
            src={`/image/master/${masterInfo.img}.png`}
            alt={`${masterInfo.img} Icon`}
            width={60}
            height={60}
          />
          <div>
            <Typography
              as="h1"
              variant="subTitle1_title"
              color="black.87"
              mb={4}
            >
              {masterInfo.name}
            </Typography>
            <Typography
              variant="caption"
              color="black.47"
              style={{ wordBreak: 'keep-all' }}
            >
              {masterInfo.shortDesc}
            </Typography>
          </div>
        </MasterInfoWrap>
        <Script variant="body2_content" color="black.87" extended={true}>
          {masterInfo.desc}
        </Script>
      </SectionTemplate>
      <SectionTemplate title="상세 투자 전략">
        <MasterFactors factors={masterInfo.factors} />
      </SectionTemplate>
      {masterInfo.src.length > 0 && (
        <FlexBox mt={24} direction="column" spacing={4}>
          <Typography variant="caption_medium" color="gray.blue">
            {masterInfo.name + ' 출처'}
          </Typography>
          <MasterSourceWrap>
            {masterInfo.src.map((source, index) => (
              <Typography
                aria-label="source"
                variant="overline_content"
                color="gray.blue"
                key={`master-source-${index}`}
                as="li"
              >
                {source}
              </Typography>
            ))}
          </MasterSourceWrap>
        </FlexBox>
      )}
    </LayoutContainer>
  );
};
