import React, { FC } from 'react';
import styled from 'styled-components';

interface StrategyBaseInfoProps {}

const StrategyBaseInfoWrap = styled.div``;

export const StrategyBaseInfo: FC<StrategyBaseInfoProps> = () => {
  return <StrategyBaseInfoWrap></StrategyBaseInfoWrap>;
};
