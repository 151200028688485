// Dependency
import env from '@core/env';
import {
  GQLVariables,
  createAuthHeader,
  gqlErrorResToData,
  gqlSuccessErrorHandler,
} from '@core/network';
import { GraphQLClient, RequestDocument } from 'graphql-request';

export const baseClient = new GraphQLClient(env.apiBaseUrl);
export const authClient = new GraphQLClient(env.apiBaseUrl);

/**
 * graphqlBaseClient
 * @description graphql Client
 * @param {string | DocumentNode} document Gql
 * @param {Object} variables variables
 * @returns {Object}
 */
export function graphqlBaseClient<T, V = GQLVariables>(
  document: RequestDocument,
  variables?: V
) {
  return async (): Promise<T> => {
    try {
      return await baseClient
        .request(document, variables)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

/**
 * graphqlAuthClient
 * @description graphql Client
 * @param {string | DocumentNode} document Gql
 * @param {Object} variables variables
 * @returns {Object}
 */
export function graphqlAuthClient<T, V = GQLVariables>(
  document: RequestDocument,
  variables?: V
) {
  return async (): Promise<T> => {
    authClient.setHeaders(createAuthHeader());
    try {
      return await authClient
        .request(document, variables)
        .then((res) => {
          gqlSuccessErrorHandler(res);
          return res;
        })
        .catch(gqlErrorResToData);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
