import {
  MY_STRATEGY_ORDER_LOCALE,
  MY_STRATEGY_ORDER_MAPPER,
  OPTIMIZATION_LOCALE,
  OPTIMIZATION_MAPPER,
  PERIOD_LOCALE,
  PERIOD_MAPPER,
  REBALANCING_OPTION_LOCALE,
  REBALANCING_OPTION_MAPPER,
  STRATEGY_ORDER_LOCALE,
  STRATEGY_ORDER_METHOD_MAPPER,
  STRATEGY_ORDER_PERIOD_MAPPER,
  UNIVERSE_LOCALE,
  UNIVERSE_MAPPER,
} from '@constants/strategy';
import { MyStrategyOrderType } from '@graphql/generates';
import { SelectOptionDto } from '@models/common';
import {
  OptimizationType,
  PeriodType,
  RebalancingOptionType,
  StrategyOrderMethodType,
  StrategyOrderPeriodReturnType,
  UniverseType,
} from '@models/strategy';

export const BENCHMARK_OPTIONS: SelectOptionDto<UniverseType>[] = [
  {
    name: UNIVERSE_MAPPER.KRX300,
    text: UNIVERSE_LOCALE[UNIVERSE_MAPPER.KRX300],
  },
  {
    name: UNIVERSE_MAPPER.KOSPI,
    text: UNIVERSE_LOCALE[UNIVERSE_MAPPER.KOSPI],
  },
  {
    name: UNIVERSE_MAPPER.KOSPI200,
    text: UNIVERSE_LOCALE[UNIVERSE_MAPPER.KOSPI200],
  },
  {
    name: UNIVERSE_MAPPER.KOSDAQ,
    text: UNIVERSE_LOCALE[UNIVERSE_MAPPER.KOSDAQ],
  },
  {
    name: UNIVERSE_MAPPER.KOSDAQ150,
    text: UNIVERSE_LOCALE[UNIVERSE_MAPPER.KOSDAQ150],
  },
];

export const PERIOD_OPTIONS: SelectOptionDto<PeriodType>[] = [
  {
    name: PERIOD_MAPPER.MONTH,
    text: PERIOD_LOCALE[PERIOD_MAPPER.MONTH],
  },
  {
    name: PERIOD_MAPPER.QUARTER,
    text: PERIOD_LOCALE[PERIOD_MAPPER.QUARTER],
  },
  {
    name: PERIOD_MAPPER.HALF,
    text: PERIOD_LOCALE[PERIOD_MAPPER.HALF],
  },
  {
    name: PERIOD_MAPPER.YEAR,
    text: PERIOD_LOCALE[PERIOD_MAPPER.YEAR],
  },
  {
    name: PERIOD_MAPPER.THREE_YEARS,
    text: PERIOD_LOCALE[PERIOD_MAPPER.THREE_YEARS],
  },
  {
    name: PERIOD_MAPPER.ALL,
    text: PERIOD_LOCALE[PERIOD_MAPPER.ALL],
  },
];

export const BOOKSIZE_OPTIONS2: SelectOptionDto<number>[] = [
  {
    name: 500,
    text: '500만원',
  },
  {
    name: 600,
    text: '600만원',
  },
  {
    name: 700,
    text: '700만원',
  },
  {
    name: 800,
    text: '800만원',
  },
  {
    name: 900,
    text: '900만원',
  },
  {
    name: 1000,
    text: '1,000만원',
  },
];

export const BOOKSIZE_OPTIONS: SelectOptionDto<number>[] = [
  {
    name: 100,
    text: '100만원',
  },
  {
    name: 200,
    text: '200만원',
  },
  {
    name: 300,
    text: '300만원',
  },
  {
    name: 400,
    text: '400만원',
  },
  ...BOOKSIZE_OPTIONS2,
];

export const BOOKSIZE_BOTTOMSHEET_OPTIONS: SelectOptionDto<number>[] = [
  {
    name: 0,
    text: '직접입력',
    variant: 'input',
  },
  ...BOOKSIZE_OPTIONS,
];

export const BOOKSIZE_BOTTOMSHEET_OPTIONS2: SelectOptionDto<number>[] = [
  {
    name: 0,
    text: '직접입력',
    variant: 'input',
  },
  ...BOOKSIZE_OPTIONS2,
];

export const OPTIMIZATION_OPTIONS: SelectOptionDto<OptimizationType>[] = [
  {
    name: OPTIMIZATION_MAPPER.MARKET_WEIGHT,
    text: `${OPTIMIZATION_LOCALE[OPTIMIZATION_MAPPER.MARKET_WEIGHT]} 최적화`,
    subText: '시가총액의 비율대로 종목 비중을 배정하는 방법입니다.',
  },
  {
    name: OPTIMIZATION_MAPPER.EQUAL_WEIGHT,
    text: `${OPTIMIZATION_LOCALE[OPTIMIZATION_MAPPER.EQUAL_WEIGHT]} 최적화`,
    subText: '모든 종목에 각각 동일한 비중을 배정하는 방법입니다.',
  },
  {
    name: OPTIMIZATION_MAPPER.RISK_PARITY,
    text: `${OPTIMIZATION_LOCALE[OPTIMIZATION_MAPPER.RISK_PARITY]} 최적화`,
    subText:
      '모든 종목이 포트폴리오에 동일한 변동성을 기여하게끔 비중을 배정하는 방법입니다.',
  },
];

export const REBALANCING_OPTIONS: SelectOptionDto<RebalancingOptionType>[] = [
  {
    name: REBALANCING_OPTION_MAPPER.NONE,
    text: REBALANCING_OPTION_LOCALE[REBALANCING_OPTION_MAPPER.NONE],
  },
  {
    name: REBALANCING_OPTION_MAPPER.MONTH,
    text: REBALANCING_OPTION_LOCALE[REBALANCING_OPTION_MAPPER.MONTH],
  },
  {
    name: REBALANCING_OPTION_MAPPER.QUARTER,
    text: REBALANCING_OPTION_LOCALE[REBALANCING_OPTION_MAPPER.QUARTER],
  },
  {
    name: REBALANCING_OPTION_MAPPER.HALF,
    text: REBALANCING_OPTION_LOCALE[REBALANCING_OPTION_MAPPER.HALF],
  },
];

export const STRATEGY_ORDER_OPTIONS: SelectOptionDto<StrategyOrderMethodType>[] =
  [
    {
      name: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
      text: STRATEGY_ORDER_LOCALE[STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE],
    },
    {
      name: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE_ASC,
      text: STRATEGY_ORDER_LOCALE[STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE_ASC],
    },
    {
      name: STRATEGY_ORDER_METHOD_MAPPER.NAME,
      text: STRATEGY_ORDER_LOCALE[STRATEGY_ORDER_METHOD_MAPPER.NAME],
    },
  ];

export const MY_STRATEGY_ORDER_OPTIONS: SelectOptionDto<MyStrategyOrderType>[] =
  [
    {
      name: MY_STRATEGY_ORDER_MAPPER.BACKTEST_CUMULATIVE,
      text: MY_STRATEGY_ORDER_LOCALE[
        MY_STRATEGY_ORDER_MAPPER.BACKTEST_CUMULATIVE
      ],
    },
    {
      name: MY_STRATEGY_ORDER_MAPPER.BENCHMARK_OR_BASE_STRATEGY_CUMULATIVE,
      text: MY_STRATEGY_ORDER_LOCALE[
        MY_STRATEGY_ORDER_MAPPER.BENCHMARK_OR_BASE_STRATEGY_CUMULATIVE
      ],
    },
    {
      name: MY_STRATEGY_ORDER_MAPPER.FORWARD_CUMULATIVE,
      text: MY_STRATEGY_ORDER_LOCALE[
        MY_STRATEGY_ORDER_MAPPER.FORWARD_CUMULATIVE
      ],
    },
    {
      name: MY_STRATEGY_ORDER_MAPPER.FORWARD_START_DATE,
      text: MY_STRATEGY_ORDER_LOCALE[
        MY_STRATEGY_ORDER_MAPPER.FORWARD_START_DATE
      ],
    },
  ];

export const MY_STRATEGY_CARD_OPTIONS: SelectOptionDto<string>[] = [
  {
    name: 'duplicate',
    text: '전략 복제',
  },
  {
    name: 'edit',
    text: '전략 편집',
  },
  {
    name: 'updateName',
    text: '전략명 변경',
  },
  {
    name: 'delete',
    text: '전략 삭제',
  },
];

export const MY_KB_STRATEGY_CARD_OPTIONS: SelectOptionDto<string>[] = [
  {
    name: 'duplicate',
    text: '전략 복제',
  },
  {
    name: 'updateName',
    text: '전략명 변경',
  },
];

export const BACKTEST_START_DATE_OPTIONS: SelectOptionDto<number>[] = [
  {
    name: 1,
    text: '1년전',
  },
  {
    name: 3,
    text: '3년전',
  },
];

export const MONTH_PERIOD_OPTIONS: SelectOptionDto<StrategyOrderPeriodReturnType>[] =
  [
    {
      name: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_1,
      text: '최근 1개월',
    },
    {
      name: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
      text: '최근 3개월',
    },
    {
      name: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_6,
      text: '최근 6개월',
    },
  ];
