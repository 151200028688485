import { HistogramSliderModel } from '@models/common';
import React, { FC } from 'react';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import styled from 'styled-components';
import { Handle } from '../slider-units/Handle';
import { SliderRail } from '../slider-units/SliderRail';
import { Track } from '../slider-units/Track';

interface HIstogramSingleSliderProps extends HistogramSliderModel {
  domain?: number[];
  rangeIndex: number;
}

const HIstogramSingleSliderWrap = styled.div``;

export const HistogramSingleSlider: FC<HIstogramSingleSliderProps> = ({
  rangeIndex,
  domain = [0, 99],
  onChange,
  onUpdate,
}) => {
  const handleChange = (values: readonly number[]) => {
    if (onChange) onChange(values);
  };
  const handleUpdate = (values: readonly number[]) => {
    if (onUpdate) onUpdate(values);
  };

  return (
    <HIstogramSingleSliderWrap>
      <Slider
        mode={2}
        step={1}
        domain={domain}
        reversed={false}
        rootStyle={{
          position: 'relative' as 'relative',
          width: '100%',
        }}
        onUpdate={handleUpdate}
        onChange={handleChange}
        values={[rangeIndex, 99]}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => {
            return (
              <div className="slider-handles">
                {handles.map((handle) => {
                  if (handle.percent === 100) {
                    return null;
                  }
                  return (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  );
                })}
              </div>
            );
          }}
        </Handles>
        <Tracks left={false} right={true}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </HIstogramSingleSliderWrap>
  );
};
