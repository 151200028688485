import { ThemeKeywordBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { CheckBoxButton, FlexBox, Icon, Typography } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface ThemeKeywordBottomsheetProps
  extends ContentWidthModel,
    ThemeKeywordBottomsheetContentModel {}

const ThemeKeywordBottomsheetWrap = styled(BottomsheetTemplate)``;

const KeywordWrap = styled(FlexBox)`
  flex-wrap: wrap;
`;

const KeywordCheckbox = styled(CheckBoxButton)`
  flex: 0 0 50%;

  &:nth-child(n + 3) {
    margin-top: 16px;
  }
`;

export const ThemeKeywordBottomsheet: FC<ThemeKeywordBottomsheetProps> = ({
  contentWidth,
  initThemeGroups = [],
  themeGroups = [],
  onOk,
}) => {
  const [selectedKeywords, setSelectedKeywords] = React.useState<string[]>(
    initThemeGroups.map((item) => item.id)
  );

  const handleCheckbox = (item: string) => () => {
    if (new Set(selectedKeywords).has(item)) {
      setSelectedKeywords(
        selectedKeywords.filter((keyword) => keyword !== item)
      );
    } else {
      setSelectedKeywords([...selectedKeywords, item]);
    }
  };
  const handleReset = () => {
    setSelectedKeywords([]);
  };

  const handleOk = () => {
    if (onOk)
      onOk(
        themeGroups.filter((themeGroup) =>
          new Set(selectedKeywords).has(themeGroup.id)
        )
      );
  };

  return (
    <ThemeKeywordBottomsheetWrap
      contentWidth={contentWidth}
      button="double"
      headerContent={
        <button onClick={handleReset}>
          <FlexBox alignItems="center" spacing={4}>
            <Icon type="refresh" size={14} />
            <Typography
              as="span"
              variant="body2_content"
              color="secondary.text"
              fontWeight="medium"
            >
              초기화
            </Typography>
          </FlexBox>
        </button>
      }
      title="키워드 선택"
      type="themeGroups"
      onClickOk={handleOk}
    >
      <KeywordWrap>
        {themeGroups.map((themeGroup, index) => {
          const checked = new Set(selectedKeywords).has(themeGroup.id)
            ? 'select'
            : 'default';
          return (
            <KeywordCheckbox
              key={`keyword_checkBtn_${index}`}
              variant={checked}
              onClick={handleCheckbox(themeGroup.id)}
            >
              <Typography variant="body2_content">{themeGroup.name}</Typography>
            </KeywordCheckbox>
          );
        })}
      </KeywordWrap>
    </ThemeKeywordBottomsheetWrap>
  );
};
