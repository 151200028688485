import useVisualViewport from '@hooks/useVisualViewport';
import { StockItemModel } from '@models/strategy';
import { Icon, Typography } from '@ui/components';
import { getCssInput } from '@ui/util';
import { numberToPercent } from '@utils/common';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

interface WeightControlBoxProps {
  stock: StockItemModel;
  onClickMinus: () => void;
  onClickPlus: () => void;
  onWeightBlur: (weight: string) => void;
  onFocusWeight: () => boolean;
}

const WeightControlBoxWrap = styled.div<{ error: boolean }>`
  display: flex;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colorPalette.state.red : theme.colorPalette.border.input};
  border-radius: 4px;
  width: 120px;
  height: 40px;
  overflow: hidden;

  input {
    text-align: right;
    width: 100%;
    height: 100%;
    padding: 4px;
    padding-right: 14px;
    ${getCssInput()}
  }
`;

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 100%;
  background-color: ${({ theme }) => theme.colorPalette.divider.sub_filter};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;
const PercentText = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const WeightControlBox: FC<WeightControlBoxProps> = ({
  stock,
  onClickMinus,
  onClickPlus,
  onWeightBlur,
  onFocusWeight,
}) => {
  const { visualViewport } = useVisualViewport();
  const [weight, setWeight] = useState('');

  useEffect(() => {
    setWeight(numberToPercent(stock.weight));
  }, [stock]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regExp = /^(\d{0,2})(\.\d{0,2})?$/;

    if (!regExp.test(value)) {
      return;
    }

    setWeight(value);
  };

  const handleBlur = () => {
    handleResize();
    if (weight !== numberToPercent(stock.weight)) {
      onWeightBlur(weight);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    /** e.keyCode - Deprecated 되었지만 모바일 엔터 적용을 위해서 필요함 */
    if (e.code === 'Enter' || e.keyCode === 13) {
      e.currentTarget.blur();
    }
  };

  const handleResize = () => {
    let prevVisualViewport = 0;
    const currentVisualViewport = visualViewport.height;
    if (
      prevVisualViewport - 30 > currentVisualViewport &&
      prevVisualViewport - 100 < currentVisualViewport
    ) {
      const scrollHeight = window.document.scrollingElement?.scrollHeight || 0;
      const scrollTop = scrollHeight - visualViewport.height;

      window.scrollTo(0, scrollTop); // 입력창이 키보드에 가려지지 않도록 조절
    }
  };

  const handleFocus = (e: React.FocusEvent) => {
    onFocusWeight();
    handleResize();
  };

  return (
    <>
      <WeightControlBoxWrap error={stock.isError || false}>
        <ControlButton onClick={onClickMinus} disabled={+weight === 0}>
          <Icon type="minus-gray" size={10} />
        </ControlButton>
        <InputWrapper>
          <input
            value={weight}
            onFocus={handleFocus}
            onChange={handleChangeValue}
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
            name={`stock-${stock.ccid}`}
            inputMode="decimal"
            pattern="d*"
            readOnly={!stock.isEditable}
          />
          <PercentText>
            <Typography variant="overline_title" color="black.87">
              %
            </Typography>
          </PercentText>
        </InputWrapper>
        <ControlButton onClick={onClickPlus}>
          <Icon type="plus-gray" size={10} />
        </ControlButton>
      </WeightControlBoxWrap>
      {!!stock.weightChange && stock.weightChange !== 0 && (
        <Typography
          variant="overline_content"
          color={stock.weightChange > 0 ? 'state.red' : 'state.blue'}
        >
          {`(${numberToPercent(stock.weightChange)}%)`}
        </Typography>
      )}
    </>
  );
};
