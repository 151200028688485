import { FactorStyleEnum, UniverseCountryEnum } from '@graphql/generates';
import { StrategyFactorType } from '@models/strategy';

export const STRATEGY_UNIVERSE_COUNTRY_ENUM = [
  UniverseCountryEnum.Domestic,
] as const;

export const STRATEGY_TAGS = [
  ...STRATEGY_UNIVERSE_COUNTRY_ENUM,
  'strategy',
  'sector',
] as const;

/**
 * 전략스타일 항목
 * 전략상세 > 전략스타일 분석 차트에 사용되는 항목
 */
export const STRATEGY_STYLE_ENUM = [
  'size',
  'value',
  'momentum',
  'quality',
  /** 변동성 */
  'volatility',
  'beta',
  'dividend',
  'liquidity',
  'surprise',
] as const;

export const STRATEGY_FACTOR_STYLE_ENUM = [
  'BETA',
  'DIVIDENDS',
  'LIQUIDITY',
  'MOMENTUM',
  'QUALITY',
  'SCORE',
  'SIZE',
  'SURPRISE',
  'VALUE',
  'VOLATILITY',
] as const;

export const STRATEGY_FACTOR_MAPPER: Record<
  StrategyFactorType,
  FactorStyleEnum
> = {
  BETA: FactorStyleEnum.Beta,
  DIVIDENDS: FactorStyleEnum.Dividend,
  LIQUIDITY: FactorStyleEnum.Liquidity,
  MOMENTUM: FactorStyleEnum.Momentum,
  QUALITY: FactorStyleEnum.Quality,
  SCORE: FactorStyleEnum.Score,
  SIZE: FactorStyleEnum.Size,
  SURPRISE: FactorStyleEnum.Surprise,
  VALUE: FactorStyleEnum.Value,
  VOLATILITY: FactorStyleEnum.Volatility,
};

export const STRATEGY_FACTOR_NAME: Record<StrategyFactorType, string> = {
  BETA: '베타',
  DIVIDENDS: '배당금',
  LIQUIDITY: '유동성',
  MOMENTUM: '모멘텀',
  QUALITY: '퀄리티',
  SCORE: '스코어',
  SIZE: '사이즈',
  SURPRISE: '서프라이즈',
  VALUE: '밸류',
  VOLATILITY: '변동성',
};

export const STRATEGY_FACTOR_DESC: Record<StrategyFactorType, string> = {
  BETA: '베타 설명글',
  DIVIDENDS: '배당금 설명글',
  LIQUIDITY: '유동성 설명글',
  MOMENTUM: '모멘텀 설명글',
  QUALITY: '퀄리티 설명글',
  SCORE: '스코어 설명글',
  SIZE: '사이즈 설명글',
  SURPRISE: '서프라이즈 설명글',
  VALUE: '밸류 설명글',
  VOLATILITY: '변동성 설명글',
};
