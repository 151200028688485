import { Histogram } from '@components/common';
import { HistogramChartDataModel } from '@models/common';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { FactorInfoButton } from './FactorInfoButton';

const FactorEditCardWrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 8px;
  padding: 20px;
`;

const Header = styled(FlexBox)``;

const HeaderTitle = styled.div`
  & > h1 {
    display: inline;
  }

  & > button {
    margin-left: 6px;
  }
`;

const InputGroup = styled(FlexBox)`
  height: 40px;
`;

const Controller = styled.div`
  padding: 0 15px;
  height: 80px;
`;

const Dash = styled(Typography)`
  opacity: 25%;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
  border-radius: 4px;
  border-width: 0;
  caret-color: ${({ theme }) => theme.colorPalette.kb.yellow};
  color: ${({ theme }) => theme.colorPalette.black[87]};
  margin: 0;
  height: 40px;
`;

const Input = styled.input`
  border: 0;
  background-color: transparent;
  font-size: 16px;
  transform: scale(0.8);
  transform-origin: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colorPalette.black[87]};

  &::placeholder {
    font-size: 16px !important;
    transform: scale(0.8);
    transform-origin: center;
    color: ${({ theme }) => theme.colorPalette.black[87]};
    opacity: 0.25;
  }

  &:disabled {
    color: ${({ theme }) => theme.colorPalette.black[87]};
    opacity: 0.25;
  }
`;

interface FactorEditCardProps {
  header: {
    cmName: string;
    displayName: string;
    description: string;
    comments: string[];
    img: number;
    handleCloseCard?: (cmName: string) => () => void;
  };
  input: {
    minValue: string;
    maxValue: string;
    handleChangeInput?: (
      type: 'min' | 'max'
    ) => (e: ChangeEvent<HTMLInputElement>) => void;
    handleFocus?: (type: 'min' | 'max') => () => void;
    handleBlur?: (
      type: 'min' | 'max'
    ) => (e: React.FocusEvent<HTMLInputElement>) => void;
  };
  slider: {
    range: number[];
    histogramChartData: HistogramChartDataModel[];
    handleChange?: (values: readonly number[], type?: 'min' | 'max') => void;
    handleUpdate?: (values: readonly number[], type?: 'min' | 'max') => void;
  };
  onBlockerCallback?: () => void;
}

export const FactorEditCard = ({
  header,
  slider,
  input,
  onBlockerCallback,
}: FactorEditCardProps) => {
  return (
    <FactorEditCardWrap>
      <Header mb={16} alignItems="flex-start" justify="space-between">
        <HeaderTitle>
          <Typography as="h1" color="black.100" variant="body1_title">
            {header.displayName}
          </Typography>
          <FactorInfoButton
            title={header.displayName}
            description={header.description}
            comments={header.comments}
            img={header.img}
          />
        </HeaderTitle>
        <FlexBox alignItems="flex-start">
          <button onClick={header.handleCloseCard?.(header.cmName)}>
            <Icon type="close" />
          </button>
        </FlexBox>
      </Header>

      <div onClick={onBlockerCallback}>
        <InputGroup alignItems="center" mb={16} spacing={16}>
          <InputWrapper>
            <Input
              value={input.minValue}
              onChange={input.handleChangeInput?.('min')}
              onFocus={input.handleFocus?.('min')}
              onBlur={input.handleBlur?.('min')}
            />
          </InputWrapper>
          <Dash variant="caption_medium" color="black.87">
            -
          </Dash>
          <InputWrapper>
            <Input
              value={input.maxValue}
              onChange={input.handleChangeInput?.('max')}
              onFocus={input.handleFocus?.('max')}
              onBlur={input.handleBlur?.('max')}
            />
          </InputWrapper>
        </InputGroup>

        <Controller>
          <Histogram
            histogramChartData={slider.histogramChartData}
            range={slider.range}
            onChange={slider.handleChange}
            onUpdate={slider.handleUpdate}
          />
        </Controller>
      </div>
      {/* {ignoreNegative && (
      <Typography color="gray.blue" variant="overline_title" py={4} mt={8}>
        ∙ 이 팩터의 [상위n%]는 음수가 제외됩니다.
      </Typography>
    )} */}
    </FactorEditCardWrap>
  );
};
