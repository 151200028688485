import { KB_PICK_BOTTOMSHEET_INFO } from '@constants/feedback';
import { StrategyCategoryType } from '@models/strategy';
import { useBottomsheetStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import { useCountryCode } from '@stores/common/common.store';
import {
  Button,
  FlexBox,
  Icon,
  SwitchButton,
  Typography,
} from '@ui/components';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useQueryState } from '@hooks/useQueryState';

interface CategoryThemeContainerProps {
  createSearchCondition: (category: StrategyCategoryType) => JSX.Element;
}

type Theme = 'PICK' | 'VALUE' | 'KB';

const RefreshButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;

  width: 80px;
  flex-shrink: 0;
  padding: 10px;
  border-color: ${({ theme }) => theme.colorPalette.border.chip};
`;

const ThemeGroupButtonWrap = styled(FlexBox)`
  flex-wrap: wrap;
  max-height: 90px;
  overflow-y: scroll;
`;

const ThemeGroupButton = styled.button`
  color: ${({ theme }) => theme.colorPalette.secondary.text};
  background-color: ${({ theme }) => theme.colorPalette.secondary.chip};
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 12px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
`;

const KeywordButton = styled(Button)`
  border-color: ${({ theme }) => theme.colorPalette.border.chip};
`;

export const CategoryThemeContainer: FC<CategoryThemeContainerProps> = ({
  createSearchCondition,
}) => {
  const {
    themeTabIndex,
    themeGroups,
    selectedThemeGroups,
    setThemeTabIndex,
    setThemeGroups,
  } = useStrategyListStore();

  const countryCode = useCountryCode();
  const { openBottomsheet } = useBottomsheetStore();
  const handleThemeKeywordBottomSheet = () => {
    openBottomsheet('themeGroups')({
      initThemeGroups: selectedThemeGroups,
      themeGroups,
      onOk(themeGroupIds) {
        setThemeGroups(themeGroupIds);
      },
    });
  };

  const [theme] = useQueryState<Theme>({
    key: 'theme',
    defaultValue: 'PICK',
  });
 
  const handleThemeGroup = (themeGroupId: string) => () => {
    setThemeGroups(
      selectedThemeGroups.filter((theme) => theme.id !== themeGroupId)
    );
  };

  const handleResetThemeGroups = () => {
    setThemeGroups([]);
  };

  /**
   * 테마 전략 탭 관련 함수
   */
  const handleClickThemeIndex = (index: number) => () => {
    setThemeTabIndex(index);
  };

  const handleKbPickInfo = () => {
    openBottomsheet('info')({
      ...KB_PICK_BOTTOMSHEET_INFO,
    });
  };

  const isThemeGroupSelected = selectedThemeGroups.length > 0;
  const buttonList = [
    { index: 0, name: 'KB’s Pick', onClickBtn: handleClickThemeIndex(0) },
    { index: 1, name: '기업가치 제고', onClickBtn: handleClickThemeIndex(1) },
    { index: 2, name: 'KB 테마', onClickBtn: handleClickThemeIndex(2) },
  ].filter((item) => {
    if (countryCode === 'USA') {
      return item.name !== '기업가치 제고';
    }
    return item;
  });

  useEffect(() => {
    const themeIndexMap: Record<Theme, number> = {
      PICK: 0,
      VALUE: 1,
      KB: 2,
    };
    
    const themeIndex = themeIndexMap[theme as Theme] ?? 0;
    setThemeTabIndex(themeIndex);
  }, [theme, setThemeTabIndex, countryCode]);

  return (
    <>
      <SwitchButton
        variant="full"
        activeIndex={themeTabIndex}
        buttonList={buttonList}
      />
      {themeTabIndex === 0 && (
        <FlexBox mt={24} alignItems="center">
          <Icon type="pick" size={20} />
          <Typography
            as="span"
            variant="caption_medium"
            color="black.100"
            ml={6}
            mr={4}
          >
            ‘KB's Pick’ 프리셋이란?
          </Typography>
          <button onClick={handleKbPickInfo}>
            <Icon type="info" size={20} />
          </button>
        </FlexBox>
      )}
      {themeTabIndex === 2 && (
        <FlexBox direction="column">
          <FlexBox direction="column">
            <FlexBox direction="column" mt={24}>
              {isThemeGroupSelected && (
                <ThemeGroupButtonWrap spacing={4} mb={8}>
                  {selectedThemeGroups.map((themeGroup) => (
                    <ThemeGroupButton
                      key={`theme_group_${themeGroup.id}`}
                      onClick={handleThemeGroup(themeGroup.id)}
                    >
                      {themeGroup.name}
                      <Icon type="delete" size={10} />
                    </ThemeGroupButton>
                  ))}
                </ThemeGroupButtonWrap>
              )}
              <FlexBox spacing={10}>
                <KeywordButton
                  variant="plain"
                  size="medium"
                  onClick={handleThemeKeywordBottomSheet}
                >
                  <Typography color="black.60" variant="body2_title">
                    테마 키워드 선택
                  </Typography>
                </KeywordButton>
                {isThemeGroupSelected && (
                  <RefreshButton
                    variant="plain"
                    size="medium"
                    onClick={handleResetThemeGroups}
                  >
                    <Icon type="refresh-gray" size={16} />
                    <Typography variant="body2_title" color="black.40">
                      초기화
                    </Typography>
                  </RefreshButton>
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox height={8} my={16} mx={-20} bgColor="black.3" />
          {createSearchCondition('THEME')}
        </FlexBox>
      )}
    </>
  );
};
