import { MasterStrategySliceState } from './master-strategy.slice';

export const createMasterStrategyState = (): MasterStrategySliceState => ({
  universePreset: undefined,
  masterFactorIndex: 0,
  masterFactor: {
    cmName: '',
    histogramChartData: [],
    range: [],
  },
});
