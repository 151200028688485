import { useLayoutStore } from '@stores/ui';
import { FlexBox, Typography } from '@ui/components';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

interface CashBoxProps {
  totalWeight: number;
}

const CashBoxWrap = styled.div<{ width: number }>`
  position: fixed;
  bottom: 78px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: ${({ width }) => `${width}px`};
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colorPalette.white};
  z-index: ${({ theme }) => theme.zIndex.bottomsheet};
`;

const CashBoxInner = styled(FlexBox)`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.title};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
`;

const TotalWeightTypo = styled(Typography)`
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'Spoqa Han Sans Neo';
`;

export const CashBox: FC<CashBoxProps> = ({ totalWeight }) => {
  const { contentWidth } = useLayoutStore();
  const cash = useMemo(() => (100 - totalWeight).toFixed(2), [totalWeight]);
  return (
    <CashBoxWrap width={contentWidth}>
      <CashBoxInner justify="center" alignItems="center" spacing={4}>
        <FlexBox spacing={4} alignItems="center">
          <Typography as="span" variant="overline_content">
            종목비중
          </Typography>
          <Typography as="span" variant="overline_title">
            {totalWeight}%
          </Typography>
        </FlexBox>

        <Typography
          as="span"
          variant="overline_content"
          color="divider.appbar_tab"
        >
          |
        </Typography>
        <FlexBox spacing={4} alignItems="center">
          <Typography as="span" variant="overline_content">
            현금비중
          </Typography>
          <Typography as="span" variant="overline_title">
            {cash}%
          </Typography>
        </FlexBox>

        <TotalWeightTypo variant="body1_title">총 100.00%</TotalWeightTypo>
      </CashBoxInner>
    </CashBoxWrap>
  );
};
