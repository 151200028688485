import { DescInfo } from '@components/common/DescInfo';
import { Button, FlexBox, Icon, Typography } from '@ui/components';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface SelectControlBoxProps {
  onClickInfo: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickAddStock: () => void;
  onClickReset: () => void;
}

const HoldingControlButton = styled(Button)<{ flexBasis?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  border-color: ${({ theme }) => theme.colorPalette.border.chip};

  ${({ flexBasis }) => {
    if (flexBasis)
      return css`
        flex-basis: ${flexBasis}px;
        flex-grow: 0;
        flex-shrink: 0;
      `;
  }};
`;

export const SelectControlBox: FC<SelectControlBoxProps> = ({
  onClickInfo,
  onClickReset,
  onClickAddStock,
}) => {
  return (
    <FlexBox spacing={8} mb={24} direction="column">
      <FlexBox spacing={6}>
        <Typography variant="subTitle1_title" color="black.87">
          종목 리스트
        </Typography>
        <button onClick={onClickInfo}>
          <Icon size={18} type="info" />
        </button>
      </FlexBox>
      <DescInfo priceInfo />
      <FlexBox spacing={8} mt={16}>
        <HoldingControlButton
          size="medium"
          variant="plain"
          onClick={onClickReset}
        >
          <Icon type="refresh-black" size={18} mr={4} />
          <Typography variant="body2_title" color="black.60">
            원래 비중대로
          </Typography>
        </HoldingControlButton>
        <HoldingControlButton
          flexBasis={90}
          size="medium"
          variant="plain"
          onClick={onClickAddStock}
        >
          <Icon type="plus-black" size={12} mr={4} />
          <Typography variant="body2_title" color="black.60">
            종목추가
          </Typography>
        </HoldingControlButton>
      </FlexBox>
    </FlexBox>
  );
};
