import { LineChart, SelectButton } from '@components/common';
import { DescInfo } from '@components/common/DescInfo';
import {
  HoldingPositionInfo,
  RebalancingHistory,
  ReturnRateInfo,
  RiskAssessmentIndex,
  SectionTemplate,
  SectorIndustryComp,
} from '@components/strategy';
import {
  BACKTESTING_REVENUE_ANALYSIS_TOOLTIP,
  ORGANIZING_STOCKS_TOOLTIP,
  REBALANCING_HISTORY_TOOLTIP,
  SECTOR_INDUSTRY_TOOLTIP,
} from '@constants/common';
import {
  REBALANCING_OPTION_LOCALE_SENTENCE,
  SIMULATION_TYPE_MAPPER,
} from '@constants/strategy';
import { useProfitSelector } from '@hooks/useProfitSelector';
import { useMyStrategyStore } from '@stores/my-strategy';
import { FlexBox } from '@ui/components';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

interface TestResultTabContainerProps {
  strategyId: string;
}

const TestResultTabContainerWrap = styled.div``;

export const TestResultTabContainer: FC<TestResultTabContainerProps> = ({
  strategyId,
}) => {
  const {
    category,
    benchmarkOptions,
    benchmarkOption,
    detailedStrategyInfo,
    cumulativeReturn,
    riskAssessmentIndex,
    exposure,
    holdingPosition,
    rebalancingHistory,
    changeBenchmarkOption,
  } = useMyStrategyStore();
  const { bottomsheet, selectedBenchmark, handleSelect } = useProfitSelector({
    strategyId,
    benchmarkOption,
    benchmarkOptions,
    simulationType: SIMULATION_TYPE_MAPPER.BACKTEST,
    changeBenchmarkOption,
  });

  const isDIY = category === 'DIY';
  const isMaster = category === 'MASTER';
  const myStrategyValue =
    detailedStrategyInfo.myCumulativeReturnRate?.cumulative;
  const secondLabel = useMemo(() => {
    if (!detailedStrategyInfo.myCumulativeReturnRate || !category) return '-';
    return category === 'DIY' || category === 'MASTER'
      ? selectedBenchmark
      : '프리셋(Pre-set)';
  }, [
    detailedStrategyInfo.myCumulativeReturnRate,
    category,
    selectedBenchmark,
  ]);
  const secondValue =
    isDIY || isMaster
      ? detailedStrategyInfo.benchmarkCumulativeReturnRate?.cumulative
      : detailedStrategyInfo.baseCumulativeReturnRate?.cumulative;
  const thirdLabel =
    category === 'SECTOR' || category === 'THEME'
      ? selectedBenchmark
      : undefined;
  const thirdValue =
    detailedStrategyInfo.benchmarkCumulativeReturnRate?.cumulative;

  return (
    <TestResultTabContainerWrap>
      <SectionTemplate
        title="백테스팅 수익 분석"
        infoDescription={BACKTESTING_REVENUE_ANALYSIS_TOOLTIP}
        description={detailedStrategyInfo.baseDate}
      >
        <FlexBox direction="column" spacing={40}>
          <FlexBox direction="column">
            <ReturnRateInfo
              type="double"
              label="나의 전략"
              value={myStrategyValue}
              secondLabel={secondLabel}
              secondValue={secondValue}
              thirdLabel={thirdLabel}
              thirdValue={thirdValue}
            />
            <DescInfo priceInfo />
          </FlexBox>

          <FlexBox direction="column" spacing={24}>
            <FlexBox>
              <SelectButton
                variant="white"
                open={bottomsheet}
                placeholder="벤치마크"
                selectedValue={selectedBenchmark}
                onClick={handleSelect('benchmark')}
              />
            </FlexBox>
            <FlexBox direction="column" height={284}>
              {cumulativeReturn.data.length > 0 && (
                <LineChart data={cumulativeReturn} />
              )}
            </FlexBox>

            {riskAssessmentIndex && (
              <FlexBox direction="column">
                <RiskAssessmentIndex {...riskAssessmentIndex} />
                <DescInfo />
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={exposure.baseDate}
        minHeight={294}
        title="섹터/업종 구성"
        infoDescription={SECTOR_INDUSTRY_TOOLTIP}
      >
        <SectorIndustryComp variant="double" data={exposure.data} />
      </SectionTemplate>

      <SectionTemplate
        description={holdingPosition.baseDate}
        minHeight={364}
        title="종목구성(Top5)"
        infoDescription={ORGANIZING_STOCKS_TOOLTIP}
      >
        <HoldingPositionInfo
          cash={holdingPosition.cash}
          stockList={holdingPosition.data}
        />
      </SectionTemplate>

      <SectionTemplate
        description={rebalancingHistory.baseDate}
        minHeight={561}
        subDescription={rebalancingHistory.rebalancing}
        title="리밸런싱 내역"
        infoDescription={REBALANCING_HISTORY_TOOLTIP}
      >
        <RebalancingHistory
          color="orange"
          barChartData={rebalancingHistory.data}
          disableChart={
            rebalancingHistory.rebalancing ===
            REBALANCING_OPTION_LOCALE_SENTENCE.NONE
          }
        />
        <DescInfo />
      </SectionTemplate>
    </TestResultTabContainerWrap>
  );
};
