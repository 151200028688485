import { COUNTRY_MAPPER } from '@constants/common';
import { CountryCodeType } from '@models/common';
import { useStrategyListStore } from '@stores/readymade-list';
import { create } from 'zustand';

interface CommonState {
  /**
   * 국가 코드
   * */
  countryCode: CountryCodeType;
}

interface CommonAction {
  /**
   * 국가 코드를 파라미터로 넘겨받아 countryCode 업데이트.
   * @param {string} countryCode - 국가 코드.
   * @returns {void}
   */
  setCountryCode: (countryCode: CountryCodeType) => void;
}

const useCommonStore = create<CommonState & CommonAction>((set, get) => ({
  countryCode: COUNTRY_MAPPER.DOMESTIC,
  setCountryCode: (countryCode) => {
    const { countryCode: currentCountryCode } = get();
    const { reset } = useStrategyListStore.getState();
    if (currentCountryCode !== countryCode) {
      reset(countryCode);
    }
    set(() => ({
      countryCode,
    }));
  },
}));

export const useCountryCode = () =>
  useCommonStore((state) => state.countryCode);

export const useUpdateCountryCode = () =>
  useCommonStore((state) => state.setCountryCode);
