import { SelectOption } from '@components/common';
import {
  MONTH_PERIOD_OPTIONS,
  STRATEGY_ORDER_OPTIONS,
} from '@constants/common';
import { StrategyOrderBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface StrategyOrderBottomsheetProps
  extends StrategyOrderBottomsheetContentModel,
    ContentWidthModel {}

const StrategyOrderBottomsheetWrap = styled(BottomsheetTemplate)``;

export const StrategyOrderBottomsheet: FC<StrategyOrderBottomsheetProps> = ({
  contentWidth,
  order,
  period,
  options = STRATEGY_ORDER_OPTIONS,
  periodOptions,
  onClickOption,
  onClickPeriodOption,
}) => {
  return (
    <StrategyOrderBottomsheetWrap
      type="strategyOrder"
      contentWidth={contentWidth}
      title="전략 정렬 방법"
    >
      <FlexBox direction="column" spacing={24}>
        <div>
          <Typography mb={8}>전략 정렬</Typography>
          <SelectOption
            variant="switch"
            options={options}
            selected={order}
            onClick={onClickOption}
          />
        </div>
        {periodOptions && (
          <div>
            <Typography mb={8}>기간 수익률</Typography>
            <SelectOption
              variant="switch"
              options={MONTH_PERIOD_OPTIONS}
              selected={period}
              onClick={onClickPeriodOption}
            />
          </div>
        )}
      </FlexBox>
    </StrategyOrderBottomsheetWrap>
  );
};
