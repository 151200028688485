import {
  DUPLICATED_HOLDINGS_ALERT_CONTENT,
  EXCEEDED_HOLDINGS_ALERT_CONTENT,
  EXCLUDED_HOLDINGS_ALERT_CONTENT,
  MAX_HOLDINGS_COUNT,
} from '@constants/feedback';
import {
  HoldingPositionItem,
  HoldingType,
  Maybe,
  SimulationOptionScreeningInput,
  StrategyCategoryEnum,
  WhitelistInputItem,
} from '@graphql/generates';
import {
  StockItemModel,
  StrategyCategoryType,
  StrategyEditHoldingNotiType,
  WhiteListStockItemModel,
  WhiteListStockObjectType,
} from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { useToastStore } from '@stores/feedback/toast.store';
import { filterNonDataOfArray, isNumber, numberToPercent } from '@utils/common';
import { useStrategyEditStore } from '../strategy-edit.store';
import { STRATEGY_CATEGORY_MAPPER } from '@constants/strategy';

/**
 * 종목구성 > 초기 종목과 비교하여 변경된 비중을 반환한다.
 * @param {Object} stockItem - 타겟 종목 정보.
 * @param {boolean} stockItem.weight - 종목 비중 값.
 * @param {boolean} stockItem.isWhitelist - 화이트리스트 종목인지의 여부.
 * @param {boolean} stockItem.isAdd - 추가된 종목인지의 여부.
 * @param {Object} originItem - 초기 종목 정보.(타겟 종목의 ccid와 일치하는 초기 종목)
 * @param {Object} originItem.weight - 초기 종목 비중 값.
 * @returns {number | undefined} 비중 증감 값.
 */
export const getChangedWeight = (
  stockItem: HoldingPositionItem,
  originItem?: StockItemModel
) => {
  if ((stockItem.isWhitelist && stockItem.isAdd) || !originItem) {
    return stockItem.weight ?? 0;
  }
  const diff = (stockItem.weight ?? 0) - (originItem?.weight ?? 0);
  return diff === 0 ? 0 : diff;
};

/**
 * 사용자의 입력값과 받아온 데이터를 비교하여 동일한지 여부를 반환한다.
 * @param {number} whitelistWeight - 사용자 입력 비중 값.
 * @param {number} apiResponseWeight - 백엔드로부터 응답된 비중 값.
 * @returns {boolean}
 */
export const checkWeightIsSame = (
  whitelistWeight: number,
  apiResponseWeight: number
) => {
  const num1 = Math.round(whitelistWeight * 10_000) / 10_000;
  const num2 = Math.round(apiResponseWeight * 10_000) / 10_000;

  return num1 === num2;
};

/**
 * 화이트리스트 종목의 비중이 0.1% 미만인지 여부를 반환한다.
 * @param {Object} whitelist - 화이트리스트 종목 정보.
 * @param {number} whitelist.weight - 화이트리스트 종목 비중 값.
 * @param {boolean} whitelist.isAdd - 추가된 종목인지의 여부.
 * @param {boolean} passAddedStockIfZero - 추가된 종목이 최초 상태인 경우, 검사를 통과시킬지 여부.
 * @returns {boolean}
 */
export const getErrorCodeLessThanMinWeight = (
  whitelist?: WhiteListStockItemModel,
  passInitAddedStock: boolean = false
):
  | Extract<
      StrategyEditHoldingNotiType,
      'AT_LEAST_1' | 'AT_LEAST_1_ADDITIONAL_STOCK'
    >
  | undefined => {
  if (!whitelist) return;
  const { weight, isAdd } = whitelist;
  if (isAdd && passInitAddedStock && weight === 0) {
    // 추가된 종목이고,
    // 비중이 0인 경우(추가된 종목의 기본 비중이 0),
    // passAddedStockIfZero가 활성화 되어있다면
    return;
  }

  if (weight < 0.001) {
    // 비중이 0.1% 미만
    if (isAdd) {
      // 추가된 종목이라면
      return 'AT_LEAST_1_ADDITIONAL_STOCK';
    }
    return 'AT_LEAST_1';
  }
};

/**
 * 사용자의 입력값과 받아온 데이터를 통해서 정보성 텍스트를 띄울때 필요한 Code값을 반환한다.
 * @param {Object} stockItem - 백엔드로부터 응답된 비중 정보.
 * @param {string} stockItem.ccid - 백엔드로부터 응답된 비중 정보.
 * @param {boolean} stockItem.isWhitelist - 백엔드로부터 응답된 비중 정보.
 * @param {number} stockItem.weight - 백엔드로부터 응답된 비중 정보.
 * @param {Object} stockItem.share - 백엔드로부터 응답된 비중 정보.
 * @param {Object} whiteListObj - 사용자가 직접 비중을 입력하여 수정된 종목들의 집합 객체.
 * @param {Object} whiteListObj.ccid - 수정된 종목의 ccid.
 * @param {boolean} whiteListObj.isLastEdit - 수정된 종목의 마지막 수정 여부.
 * @param {boolean} whiteListObj.weight - 수정된 종목의 변경 비중.
 * @param {number} whiteListObj.prevWeight - 수정된 종목의 비중이 조정되기 이전 비중 값(사용자가 입력한 실제 값).
 * @returns {string | undefined} Code 값.
 */
export const getStockInfoCode = ({
  stockItem,
  whiteListObj,
}: {
  stockItem: HoldingPositionItem;
  whiteListObj?: WhiteListStockObjectType;
}) => {
  let infoCode: StrategyEditHoldingNotiType | undefined;
  let curWhiteListItem: WhiteListStockItemModel | undefined;

  if (whiteListObj) {
    curWhiteListItem = whiteListObj[stockItem.ccid];
  }

  if (whiteListObj && stockItem.isWhitelist) {
    if (curWhiteListItem) {
      const isSameValue = !curWhiteListItem.prevWeight;
      const isMaximalWeight = !isSameValue && !(stockItem.weight === 0);

      // 입력값과 받아온 데이터가 동일하지 않은 경우
      if (isMaximalWeight) {
        infoCode = 'MAXIMAL_WEIGHT';
      }

      if (curWhiteListItem.isLastEdit && curWhiteListItem.prevWeight) {
        const whitelistValues = Object.values(whiteListObj);
        const sumOfWhiteListWeight = whitelistValues.reduce((prev, cur) => {
          if (cur.isLastEdit) return prev;
          return prev + cur.weight;
        }, 0);

        // 수동으로 입력한 종목의 비중이 95%를 넘어가는 경우
        if (sumOfWhiteListWeight + curWhiteListItem.prevWeight > 0.95) {
          infoCode = 'OVER_95_PERCENT';
        }
      }
    }
  }

  if (!!stockItem.share) {
    // 1주당 비중이 최대 비중보다 큰 경우
    if (stockItem.share > 0.95) {
      infoCode = 'MORE_BOOKSIZE';
      return infoCode;
    }

    // 입력값이 1주당 비중보다 작은 경우
    if (
      curWhiteListItem &&
      curWhiteListItem.prevWeight &&
      curWhiteListItem.prevWeight < stockItem.share
    ) {
      infoCode = 'LESS_THAN_SHARE';
      return infoCode;
    }
  }

  // 비중이 0.1% 미만인 경우
  infoCode = getErrorCodeLessThanMinWeight(curWhiteListItem, true) || infoCode;

  return infoCode;
};

/**
 * 최초 종목 리스트를 파라미터로 받아 객체 형태로 변환하는 함수.
 * @param {Object[]} origin - holdings 쿼리의 최초 종목 리스트 정보.
 * @param {string} origin[].ccid - holding의 ccid.
 * @returns {Object} ccid를 key로 하는 종목 정보 객체.
 */
const getOriginKeyValueObj = (origin?: StockItemModel[]) => {
  let originObj: Record<string, StockItemModel> = {};

  if (origin && origin?.length > 0) {
    originObj = origin.reduce(
      (prev, curStock) => ({
        ...prev,
        [curStock.ccid]: curStock,
      }),
      {}
    );
  }

  return originObj;
};

/**
 * 제외 종목 리스트를 반환하는 함수.
 * @param {Object} holdingTypeObj - 초기/제외/현재 종목리스트.
 * @param {string[]} holdingTypeObj.baseHoldings - 초기 종목리스트.
 * @param {Object[]} holdingTypeObj.excludeHoldings - 제외 종목리스트.
 * @param {Object[]} holdingTypeObj.holdings - 현재 종목리스트.
 * @returns {Object[]} 제외 종목 리스트
 */
const getExcludeList = (
  holdingTypeObj: Maybe<HoldingType> | undefined
): StockItemModel[] => {
  const excludeList: StockItemModel[] = [];

  if (holdingTypeObj?.excludeHoldings) {
    const excludeHoldingsList = filterNonDataOfArray(
      holdingTypeObj.excludeHoldings
    );
    excludeHoldingsList.forEach((stock) => {
      const {
        ccid,
        code,
        name,
        weight,
        volume,
        isAdd,
        isWhitelist,
        share,
        synonym,
      } = stock;
      excludeList.push({
        ccid,
        code,
        synonym: synonym || '',
        name,
        volume: volume || 0,
        weight: weight || 0,
        weightPerShare: share || 0,
        isAdd: !!isAdd,
        isWhitelist: !!isWhitelist,
      });
    });
  }

  return excludeList;
};

const getWhiteListObj = (
  whitelistObj?: WhiteListStockObjectType
): WhiteListStockObjectType => {
  const newWhiteListObj: WhiteListStockObjectType = whitelistObj
    ? { ...whitelistObj }
    : {};

  return newWhiteListObj;
};

/**
 * 화이트리스트 객체를 배열로 변환하는 함수.
 * @param {Object} whitelistObj - 수정된 종목들의 집합 객체.
 * @param {Object} whiteListObj.ccid - 수정된 종목의 ccid.
 * @param {boolean} whitelistObj.isAdd - 수정된 종목이 추가된 종목인지의 여부.
 * @param {boolean} whiteListObj.isLastEdit - 수정된 종목의 마지막 수정 여부.
 * @param {boolean} whiteListObj.weight - 수정된 종목의 변경 비중.
 * @param {number} whiteListObj.prevWeight - 수정된 종목의 비중이 조정되기 이전 비중 값(사용자가 입력한 실제 값).
 * @returns {Object[]} 화이트리스트 배열.
 */
export const getWhitelistInput = (
  whitelistObj: WhiteListStockObjectType
): WhitelistInputItem[] => {
  return Object.values(whitelistObj).map((stock) => ({
    ccid: stock.ccid,
    weight: stock.weight,
    isAdd: stock.isAdd,
    isLastEdit: stock.isLastEdit,
  }));
};

export const getIsError = (code: StrategyEditHoldingNotiType | undefined) => {
  return (
    code === 'LESS_THAN_SHARE' ||
    code === 'MORE_BOOKSIZE' ||
    code === 'AT_LEAST_1' ||
    code === 'AT_LEAST_1_ADDITIONAL_STOCK'
  );
};

/**
 * 종목선택 데이터를 생성하는 함수.
 * @param {Object} holdingTypeObj - 초기/제외/현재 종목리스트를 갖고 있는 객체.
 * @param {string[]} holdingTypeObj.baseHoldings - 초기 종목리스트.
 * @param {Object[]} holdingTypeObj.excludeHoldings - 제외 종목리스트.
 * @param {Object[]} holdingTypeObj.holdings - 현재 종목리스트.
 * @param {Object[]} origins - 최초 종목리스트 정보.
 * @param {string} origins[].ccid - 종목 ccid.
 * @param {string} origins[].code - 종목 code.
 * @param {string} origins[].isAdd - 종목이 추가된 종목인지의 여부.
 * @param {string} origins[].isEditable - 종목의 수정 가능 여부.
 * @param {string} origins[].isError - 종목의 에러 발생 여부.
 * @param {string} origins[].isWhitelist - 종목이 화이트리스트인지의 여부.
 * @param {string} origins[].name - 종목명.
 * @param {string} origins[].synonym - 종목 동음이의어.
 * @param {string} origins[].volume - 비중에 따라 산출된 종목 갯수(온주화 결과물).
 * @param {string} origins[].weight - 종목 비중.
 * @param {string} origins[].weightChange - 종목의 변경된 비중. origin의 holding엔 해당 없음.
 * @param {string} origins[].weightPerShare - 종목 한 주당 비중.
 * @param {Object} whitelistObj - 수정된 종목들의 집합 객체.
 * @param {Object} whiteListObj.ccid - 수정된 종목의 ccid.
 * @param {boolean} whitelistObj.isAdd - 수정된 종목이 추가된 종목인지의 여부.
 * @param {boolean} whiteListObj.isLastEdit - 수정된 종목의 마지막 수정 여부.
 * @param {boolean} whiteListObj.weight - 수정된 종목의 변경 비중.
 * @param {number} whiteListObj.prevWeight - 수정된 종목의 비중이 조정되기 이전 비중 값(사용자가 입력한 실제 값).
 * @returns {Object} 종목선택 데이터.
 */
const getHoldingList = ({
  holdingTypeObj,
  origins,
  whitelistObj,
}: {
  holdingTypeObj?: Maybe<HoldingType>;
  origins?: StockItemModel[];
  whitelistObj?: WhiteListStockObjectType;
}) => {
  // 최초 종목리스트 정보. {ccid: stockInfo}
  const originObj = getOriginKeyValueObj(origins);
  // 화이트리스트 객체 {ccid: WhitelistInputItemModel}
  const newWhiteListObj = getWhiteListObj(whitelistObj);
  // 반환할 현재 종목 리스트
  const resList: StockItemModel[] = [];
  // 총 종목 비중
  let totalWeight = 0;
  // 총 종목 갯수
  let holdingsCnt = 0;

  if (holdingTypeObj?.holdings) {
    const holdingsList = filterNonDataOfArray(holdingTypeObj.holdings);
    holdingsList.forEach((stock) => {
      const { ccid, weight, volume, isAdd, isWhitelist } = stock;

      if (volume && volume > 0) {
        holdingsCnt += 1;
      }

      if (!newWhiteListObj[ccid] && isWhitelist && isNumber(weight)) {
        // 새로운 화이트리스트에 없는 종목이고, 화이트리스트인 종목인 경우
        // 새로운 화이트리스트에 추가
        newWhiteListObj[ccid] = {
          ccid,
          isAdd: !!isAdd,
          isLastEdit: false,
          weight,
        };
      }

      if (newWhiteListObj && newWhiteListObj[ccid] && isNumber(weight)) {
        if (!checkWeightIsSame(newWhiteListObj[ccid].weight, weight)) {
          // 종목의 비중을 사용자가 직접 입력하여 변경했고
          // 사용자가 입력한 비중과 받아온 데이터의 비중이 다른 경우
          newWhiteListObj[ccid] = {
            ...newWhiteListObj[ccid],
            weight,
            prevWeight: newWhiteListObj[ccid].weight,
          };
        }
      }
    });

    const editableCnt =
      holdingsList.length - Object.values(newWhiteListObj).length;
    holdingsList.forEach((stock) => {
      const {
        ccid,
        code,
        name,
        weight,
        volume,
        isAdd,
        isWhitelist,
        share,
        synonym,
      } = stock;

      // 비중 증감 값 (최신 holding.weight - 최초 holding.weight)
      const weightChange = origins?.length !== 0 ? getChangedWeight(stock, originObj[ccid]) : 0;
      // 종목 선정 알림 코드 (비중 수동입력한 종목의 경우만 필요한 값)
      const infoCode = getStockInfoCode({
        stockItem: stock,
        whiteListObj: newWhiteListObj,
      });
      // 에러 여부 (비중 수동입력한 종목의 경우만 필요한 값)
      const isError = getIsError(infoCode);
      const isEditable = !!isWhitelist || editableCnt > 3;

      resList.push({
        ccid,
        code,
        name,
        synonym: synonym || '',
        volume: volume || 0,
        weight: weight || 0,
        weightPerShare: share || 0,
        weightChange,
        isAdd: !!isAdd,
        isWhitelist: !!isWhitelist,
        infoCode,
        isError,
        isEditable,
      });
      totalWeight += stock.weight || 0;
    });
  }

  return {
    holdings: resList,
    holdingsCnt,
    totalWeight: +numberToPercent(totalWeight),
    newWhiteListObj,
  };
};

/**
 * 종목 정보를 조합하여 종목선택 데이터를 생성하는 함수.
 * @param {Object} holdingTypeObj - 초기/제외/현재 종목리스트.
 * @param {Object[]} origins - 최초 종목리스트 정보.
 * @param {Object} whitelistObj - 수정된 종목들의 집합 객체.
 * @returns {object} 종목선택 데이터.
 */
export const createHoldingObj = (
  holdingTypeObj?: Maybe<HoldingType>,
  origins?: StockItemModel[],
  whitelistObj?: WhiteListStockObjectType
) => {
  const excludeHoldings = getExcludeList(holdingTypeObj);
  const { holdings, newWhiteListObj, totalWeight, holdingsCnt } =
    getHoldingList({
      holdingTypeObj,
      origins,
      whitelistObj,
    });

  return {
    holdings,
    holdingsCnt,
    excludeHoldings,
    totalWeight,
    whitelistObj: newWhiteListObj,
    baseHoldings: holdingTypeObj?.baseHoldings || [],
  };
};

/**
 * 매번 마지막 수정된 화이트 리스트 종목을 유지하기 위해서 기존의 화이트 리스트의 isLastEdit를 false로 초기화.
 * @param {Object} whitelistObj - 수정된 종목들의 집합 객체.
 * @returns {Object}
 */
export const initWhiteListLastEdit = (
  whitelistObj: WhiteListStockObjectType
): WhiteListStockObjectType => {
  const whiteListValueList = Object.values(whitelistObj);
  const newWhiteListObj = whiteListValueList.reduce((prev, stock) => {
    return {
      ...prev,
      [stock.ccid]: {
        ...stock,
        isLastEdit: false,
      },
    };
  }, {});

  return newWhiteListObj;
};

/**
 * 화이트 리스트 객체에서 isAdd 인 항목을 빼고 제외시켜서 반환하는 함수.
 * @param {Object} whitelistObj - 수정된 종목들의 집합 객체.
 * @returns {Object}
 */
export const whiteListObjFilteredAdd = (
  whitelistObj: WhiteListStockObjectType
): WhiteListStockObjectType => {
  const whiteListValueList = Object.values(whitelistObj).filter(
    (stock) => stock.isAdd
  );
  const newWhiteListObj = whiteListValueList.reduce((prev, stock) => {
    return {
      ...prev,
      [stock.ccid]: {
        ...stock,
        weight: 0,
      },
    };
  }, {});

  return newWhiteListObj;
};

/**
 * 종목 추가 버튼 눌렀을 때,
 * 종목 추가가 안되는 경우에 알림 띄워는 함수.
 *
 * - 종목 갯수가 50개 이상이면 종목 추가 불가능.
 * - 추가종목은 5개 까지만 추가 가능.
 * @returns {boolean}
 */
export const checkEnableAddStock = () => {
  const { openConfirm } = useConfirmStore.getState();
  const { whitelistObj, holdings } = useStrategyEditStore.getState();

  const newWhiteListObj = initWhiteListLastEdit(whitelistObj);
  const newWhiteList = getWhitelistInput(newWhiteListObj);
  const addedWhitelist = newWhiteList.filter((item) => item.isAdd);

  const MAX_STOCK_CNT = 50;
  const MAX_ADD_STOCK_CNT = 5;

  // 종목 갯수가 50개 이상이면 종목 추가 불가능
  if (holdings.length >= MAX_STOCK_CNT) {
    openConfirm('alert')({
      ...EXCEEDED_HOLDINGS_ALERT_CONTENT,
    });
    return false;
  }

  // 추가종목은 5개 까지만 추가 가능
  if (addedWhitelist.length >= MAX_ADD_STOCK_CNT) {
    openConfirm('alert')({
      ...MAX_HOLDINGS_COUNT,
    });
    return false;
  }

  return true;
};

/**
 * 추가할 종목이 이미 추가된 종목인지 확인하는 함수.
 * @param {string} ccid - 추가할 종목의 ccid.
 * @returns {boolean}
 */
export const checkIsAlreadyAdded = (ccid: string) => {
  const { openToast } = useToastStore.getState();
  const { whitelistObj, holdings, excludeHoldings } =
    useStrategyEditStore.getState();

  const isExistInHoldings =
    holdings.findIndex((item) => item.ccid === ccid) > -1;
  const isExistInExcludeHoldings =
    excludeHoldings.findIndex((item) => item.ccid === ccid) > -1;
  if (whitelistObj[ccid] || isExistInHoldings) {
    openToast(DUPLICATED_HOLDINGS_ALERT_CONTENT);
    return true;
  } else if (isExistInExcludeHoldings) {
    openToast(EXCLUDED_HOLDINGS_ALERT_CONTENT);
    return true;
  }

  return false;
};

/**
 * 업종전략의 경우 섹터/업종 범위가 strategyId에 의해서만 결정될 수 있도록 처리.
 * @param {string} category - 전략 카테고리
 * @param {object} screeningObject - 스크리닝 인풋 객체
 * @param {string} screeningObject.industries - 업종 리스트
 * @returns {object}
 */
export function updateScreeningIfSectorIndustry(
  category: StrategyCategoryType,
  screeningObject: SimulationOptionScreeningInput
) {
  if (
    STRATEGY_CATEGORY_MAPPER[category] === StrategyCategoryEnum.SectorIndustry
  ) {
    // 업종전략의 섹터/업종 범위가 strategyId에 의해서만 결정될 수 있도록 처리.
    // industries 값을 특정할 경우 섹터/업종 범위가 오염됨.
    // 참고로 industries: []의 경우 전체 업종을 의미함. (나만의 전략 초깃값에 활용)
    // Holdings 요청에서만 수행(MyHoldings에서는 수행하지 않음).
    return {
      ...screeningObject,
      industries: undefined,
    };
  }
  return screeningObject;
}
