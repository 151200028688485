import {
  BacktestDateBottomsheet,
  BooksizeBottomsheet,
  EditBottomsheet,
  FactorInfoBottomSheet,
  InfoBottomSheet,
  MyStrategyOptionBottomsheet,
  MyStrategyOrderBottomsheet,
  OptimizationBottomsheet,
  OptionSelectionBottomSheet,
  RebalancingBottomsheet,
  StrategyOrderBottomsheet,
  ThemeKeywordBottomsheet,
} from '@components/feedback';
import { StockEmptyBottomSheet } from '@components/feedback/bottomsheet/StockEmptyBottomSheet';
import { COUNTRY_MAPPER } from '@constants/common';

import { useRouterMove } from '@hooks/useRouterMove';
import { BottomsheetType, EditOptionType } from '@models/feedback';
import { StrategyCategoryType } from '@models/strategy';
import { useCountryCode } from '@stores/common/common.store';
import { useBottomsheetStore } from '@stores/feedback';
import { useLayoutStore } from '@stores/ui';
import { FC } from 'react';

interface BottomsheetContainerProps {}

export const BottomsheetContainer: FC<BottomsheetContainerProps> = () => {
  const router = useRouterMove();
  const contentWidth = useLayoutStore((state) => state.contentWidth);
  const {
    info,
    infoContent,
    factorInfo,
    factorInfoContent,
    optionSelection,
    optionSelectionContent,
    strategyOrder,
    strategyOrderContent,
    myStrategyOrder,
    myStrategyOrderContent,
    edit,
    editContent,
    booksize,
    booksizeContent,
    optimization,
    optimizationContent,
    rebalancing,
    rebalancingContent,
    backtestDate,
    backtestDateContent,
    myStrategyOption,
    myStrategyOptionContent,
    themeGroups,
    themeGroupsContent,
    stockListEmpty,
    stockListEmptyContent,
    closeBottomsheet,
  } = useBottomsheetStore();
  const countryCode = useCountryCode();

  const handleClose = (type: BottomsheetType) => () => {
    closeBottomsheet(type);
  };

  const handleNext = (
    option: EditOptionType,
    id: string,
    category: StrategyCategoryType
  ) => {
    handleClose('edit')();

    /**
     * handleClose('edit') 실행 후 body의 position 을 비롯한 CSS가 변경되는데,
     * 이 때, router.push() 바로 실행되면 scrollReset 이 정상적으로 이뤄지지 않는다.
     * CSS 변경이 진행된 후 router.push() 를 실행하도록
     * window.requestAnimationFrame 사용하여 함수 실행을 지연시킨다.
     */
    window.requestAnimationFrame(() => {
      if (option === 'beginner-1') {
        router.moveToBeginner1Edit(id, countryCode);
      } else if (option === 'beginner-2') {
        router.moveToBeginner2Edit(id, countryCode);
      } else if (option === 'pro-1') {
        router.moveToPro1Edit(id, countryCode);
      } else if (option === 'pro-2') {
        if (countryCode === COUNTRY_MAPPER.USA) {
          router.moveToUsSimpleEdit(id);
        } else {
          router.moveToSimpleEdit(id);
        }
      } else if (option === 'pro-3') {
        if (category === 'DIY') {
          if (countryCode === COUNTRY_MAPPER.USA) {
            router.moveToUsDiyEdit(id);
          } else {
            router.moveToDiyEdit(id);
          }
          return;
        }

        if (countryCode === COUNTRY_MAPPER.USA) {
          router.moveToUsDetailEdit(id);
        } else {
          router.moveToDetailEdit(id);
        }
      }
    });
  };

  // 복수 컴포넌트 렌더링을 위해 Switch-Case 문이 아닌 if 문 사용
  return (
    <>
      {info && <InfoBottomSheet {...infoContent} contentWidth={contentWidth} />}
      {factorInfo && (
        <FactorInfoBottomSheet
          {...factorInfoContent}
          contentWidth={contentWidth}
        />
      )}
      {optionSelection && (
        <OptionSelectionBottomSheet
          {...optionSelectionContent}
          contentWidth={contentWidth}
        />
      )}
      {strategyOrder && (
        <StrategyOrderBottomsheet
          {...strategyOrderContent}
          contentWidth={contentWidth}
        />
      )}
      {myStrategyOrder && (
        <MyStrategyOrderBottomsheet
          {...myStrategyOrderContent}
          contentWidth={contentWidth}
        />
      )}
      {myStrategyOption && (
        <MyStrategyOptionBottomsheet
          {...myStrategyOptionContent}
          contentWidth={contentWidth}
        />
      )}
      {edit && (
        <EditBottomsheet
          {...editContent}
          contentWidth={contentWidth}
          onClickCancel={handleClose('edit')}
          onClickOk={handleNext}
        />
      )}
      {booksize && (
        <BooksizeBottomsheet {...booksizeContent} contentWidth={contentWidth} />
      )}
      {optimization && (
        <OptimizationBottomsheet
          {...optimizationContent}
          contentWidth={contentWidth}
        />
      )}
      {rebalancing && (
        <RebalancingBottomsheet
          {...rebalancingContent}
          contentWidth={contentWidth}
        />
      )}
      {backtestDate && (
        <BacktestDateBottomsheet
          {...backtestDateContent}
          contentWidth={contentWidth}
        />
      )}
      {themeGroups && (
        <ThemeKeywordBottomsheet
          {...themeGroupsContent}
          contentWidth={contentWidth}
        />
      )}
      {stockListEmpty && (
        <StockEmptyBottomSheet
          {...stockListEmptyContent}
          contentWidth={contentWidth}
        />
      )}
    </>
  );
};
