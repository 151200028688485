import { COUNTRY_MAPPER } from '@constants/common';
import { CountryCodeType } from '@models/common';
import { useConfirmStore } from '@stores/feedback';
import { useMyStrategyStore } from '@stores/my-strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { FlexBox, Typography } from '@ui/components';
import { useRouterMove } from '..';

interface useEditResultControllerProps {
  renew?: boolean;
  strategyId: string;
  prevStrategyId?: string;
  countryCode?: CountryCodeType;
}

export const useEditResultController = ({
  strategyId,
  countryCode,
  prevStrategyId = '',
  renew,
}: useEditResultControllerProps) => {
  const { closeConfirm, openConfirm } = useConfirmStore();
  const {
    moveToReadyMades,
    moveToUSReadyMades,
    moveToMyStrategies,

    simpleEditRoutes,
    detailEditRoutes,
  } = useRouterMove();

  const { category, isSimple, detailedStrategyInfo, investmentStrategyLevel } =
    useMyStrategyStore();
  const saveMyStrategy = useStrategyEditStore((state) => state.saveMyStrategy);

  const handleCancelFlotter = () => {
    if (isSimple) {
      simpleEditRoutes({
        strategyId,
        prevStrategyId,
        reEdit: true,
        investmentStrategyLevel,
        countryCode,
      });
      return;
    }

    detailEditRoutes({
      strategyId,
      prevStrategyId,
      reEdit: true,
      category: category || 'THEME',
      countryCode,
    });
  };

  const handleOkFlotter = () => {
    if (renew) {
      openConfirm('textInput')({
        autoClose: false,
        children: (
          <FlexBox direction="column" spacing={4}>
            <Typography variant="body1_content" color="black.60">
              수정된 전략을 저장하시겠습니까? 저장하시면 기존 전략의 기록은
              사라지며 모의투자는 새로 시작됩니다.
            </Typography>
            <Typography variant="caption_medium" color="gray.blue">
              *기존 전략의 유지를 원하시면 전략 보관함{'>'}전략 복제 후 편집해
              주세요.
            </Typography>
          </FlexBox>
        ),
        description: '저장할 전략명을 입력해주세요.',
        errorMsg: '동일한 전략명이 있습니다.',
        errorEmptyMsg: '전략명을 입력해주세요.',
        textLimit: 50,
        title: '전략 수정',
        initValue: detailedStrategyInfo.name,
        placeholder: detailedStrategyInfo.name,
        onOkPromise: async (name) => {
          return await saveMyStrategy(strategyId, name, prevStrategyId).then(
            (myStrategyId) => {
              if (myStrategyId) {
                if (countryCode === COUNTRY_MAPPER.USA) {
                  moveToMyStrategies(1);
                } else {
                  moveToMyStrategies();
                }
                closeConfirm('textInput');
              }
            }
          );
        },
      });
      return;
    }
    openConfirm('textInput')({
      autoClose: false,
      description: '저장할 전략명을 입력해주세요.',
      errorMsg: '동일한 전략명이 있습니다.',
      errorEmptyMsg: '전략명을 입력해주세요.',
      textLimit: 50,
      title: '전략 저장',
      initValue: detailedStrategyInfo.name,
      placeholder: detailedStrategyInfo.name,
      onOkPromise: async (name) => {
        return await saveMyStrategy(strategyId, name).then((myStrategyId) => {
          if (myStrategyId) {
            if (countryCode === COUNTRY_MAPPER.USA) {
              moveToMyStrategies(1);
            } else {
              moveToMyStrategies();
            }
            closeConfirm('textInput');
          }
        });
      },
    });
  };

  const handleCancel = () => {
    openConfirm('alert')({
      button: 'double',
      description: '편집한 내용을 저장하지 않고 나가시겠습니까?',
      title: '편집 취소',
      onOk: () => {
        if (renew) {
          moveToMyStrategies();
          return;
        }
        if (countryCode === COUNTRY_MAPPER.USA) {
          moveToUSReadyMades();
          return;
        }
        if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
          moveToReadyMades();
          return;
        }
      },
    });
  };

  return {
    handleCancelFlotter,
    handleOkFlotter,
    handleCancel,
  };
};
