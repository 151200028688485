import { COUNTRY_MAPPER } from '@constants/common';
import { EXCEEDED_STRATEGY_ALERT_CONTENT } from '@constants/feedback';
import { CountryCodeType } from '@models/common';
import { useAuthStore } from '@stores/auth';
import { useBottomsheetStore, useConfirmStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import { useRouterMove } from '..';

interface useStrategyListControllerProps {
  countryCode: CountryCodeType;
}

export const useStrategyListController = ({
  countryCode,
}: useStrategyListControllerProps) => {
  const { category } = useStrategyListStore();
  const { isStrategyCreationPossible, isStrategyCreationPossibleUs } =
    useAuthStore();
  const { openBottomsheet } = useBottomsheetStore();

  const {
    moveToUSMasterDetail,
    moveToUSReadymadeDetail,
    moveToMasterEdit,
    moveToUsMasterEdit,
    moveToMasterDetail,
    moveToReadymadeDetail,
  } = useRouterMove();
  const openConfirm = useConfirmStore((state) => state.openConfirm);

  /**
   * 전략 리스트(한국) - 전략 상세 클릭시 실행되는 함수
   */
  const handleClickItemCurriedKr = (id: string) => () => {
    if (category === 'MASTER') {
      moveToMasterDetail(id);
    } else {
      moveToReadymadeDetail(id);
    }
  };

  /**
   * 전략 리스트(미국) - 전략 상세 클릭시 실행되는 함수
   */
  const handleClickItemCurriedUs = (id: string) => () => {
    if (category === 'MASTER') {
      moveToUSMasterDetail(id);
    } else {
      moveToUSReadymadeDetail(id);
    }
  };

  /**
   * 전략 리스트(한국) - 전략 선택 클릭시 실행되는 함수
   */
  const handleClickSelectCurriedKr = (id: string) => () => {
    if (!isStrategyCreationPossible) {
      openConfirm('alert')({
        ...EXCEEDED_STRATEGY_ALERT_CONTENT,
      });
      return;
    }

    if (category === 'MASTER') {
      moveToMasterEdit(id);
      return;
    }
    openBottomsheet('edit')({ strategyId: id, category });
  };

  /**
   * 전략 리스트(미국) - 전략 선택 클릭시 실행되는 함수
   */
  const handleClickSelectCurriedUs = (id: string) => () => {
    if (!isStrategyCreationPossibleUs) {
      openConfirm('alert')({
        ...EXCEEDED_STRATEGY_ALERT_CONTENT,
      });
      return;
    }

    if (category === 'MASTER') {
      moveToUsMasterEdit(id);
      return;
    }
    openBottomsheet('edit')({ strategyId: id, category });
  };

  if (countryCode === COUNTRY_MAPPER.USA) {
    return {
      handleClickItemCurried: handleClickItemCurriedUs,
      handleClickSelectCurried: handleClickSelectCurriedUs,
    };
  }

  return {
    handleClickItemCurried: handleClickItemCurriedKr,
    handleClickSelectCurried: handleClickSelectCurriedKr,
  };
};
