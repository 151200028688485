import { COUNTRY_MAPPER } from '@constants/common';
import { KB_ERROR_MAPPER } from '@constants/error.const';
import { LOADING_MESSAGES } from '@constants/feedback';
import {
  GetMyStrategiesQueryVariables,
  MyStrategyOrderType,
  StrategyStatusEnum,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import { PageStatusType } from '@models/common';
import { MyStrategyCardDto } from '@models/strategy/my-strategy.model';
import { useConfirmStore } from '@stores/feedback';
import { move_contract } from '@utils/bridge';
import { create } from 'zustand';
import {
  convertDeletedMyStrategy,
  convertDuplicatedMyStrategy,
  convertIsActiveContract,
  convertIsBacktestData,
  convertMyStrategies,
  convertSyncMyStrategy,
  convertUpdatedMyStrategyName,
} from './my-strategy-list.convert';
import { createInitMyStrategies } from './my-strategy-list.init';

export interface MyStrategyStoreState {
  /**
   * 전략 보관함 페이지 상태
   * init: 초기화
   * loading: 로딩
   * success: 성공
   * error: 에러
   */
  pageStatus: PageStatusType;

  /** 전략보관함 리스트 */
  myStrategies: MyStrategyCardDto[] | [];

  /** 정렬 타입 */
  orderType: MyStrategyOrderType;

  /**
   * 국가 선택 Index
   * 0(Default) : 한국 전략
   * 1 : 미국 전략
   */
  nationIndex: number;
}

interface MyStrategyListState extends MyStrategyStoreState {
  setPageStatus: (pageStatus: PageStatusType) => void;
  initState: () => void;

  /** 전략 보관함 리스트 불러오기 */
  fetchMyStrategies: (queryObj: GetMyStrategiesQueryVariables) => {};

  /** 정렬 순서 변경 */
  changeOrder: (orderType: MyStrategyOrderType) => void;

  /** 백테스트 결과 진입 가능 여부 조회 */
  fetchIsBacktestData: (myStrategyId: string) => Promise<boolean | undefined>;

  /** 전략명 변경 */
  updateMyStrategyName: (
    myStrategyId: string,
    name: string
  ) => Promise<boolean | undefined>;

  /** 전략 복제 */
  duplicateMyStrategy: (
    myStrategyId: string,
    name: string
  ) => Promise<string | undefined>;

  /** 전략 삭제 */
  deleteMyStrategy: (myStrategyId: string) => Promise<boolean | undefined>;

  /** '투자 시작하기' 클릭시 전략의 Sync를 맞추는 작업 (캐싱을 위한 작업) */
  syncMyStrategy: (syncId: string, portfolioId: string | undefined) => void;

  /** '투자 시작하기' 클릭시  */
  checkIsActiveMyStrategy: (myStrategyId: string) => Promise<boolean>;

  /** 국가 탭 선택 변경 되었을 경우 */
  changeNationIndex: (index: number) => void;
}

export const useMyStrategyListStore = create<MyStrategyListState>(
  (set, get) => ({
    ...createInitMyStrategies(),
    setPageStatus: (pageStatus) => {
      set(() => ({ pageStatus }));
    },
    initState: () => {
      set(() => ({
        ...createInitMyStrategies(),
      }));
    },
    fetchMyStrategies: async (queryObj) => {
      const { openConfirm, closeConfirm } = useConfirmStore.getState();
      try {
        openConfirm('loading')({
          descriptions: LOADING_MESSAGES.loadMyStrategy,
        });
        const countries =
          queryObj.countries ||
          (get().nationIndex === 0
            ? COUNTRY_MAPPER.DOMESTIC
            : COUNTRY_MAPPER.USA);
        const response = await repo.getMyStrategies({
          ...queryObj,
          status: StrategyStatusEnum.Activated,
          countries,
        });
        const myStrategies = convertMyStrategies(response);
        set(() => ({ myStrategies, pageStatus: 'success' }));
      } catch (err) {
        console.error(err);
        set(() => ({ pageStatus: 'error' }));
      }
      closeConfirm('loading');
    },
    changeOrder: (orderType) => {
      set(() => ({ orderType }));
      get().fetchMyStrategies({ orderType });
    },
    fetchIsBacktestData: async (myStrategyId) => {
      const response = await repo.getIsBacktestData({ myStrategyId });
      return convertIsBacktestData(response);
    },
    updateMyStrategyName: async (id, name) => {
      const { myStrategies } = get();
      const hasSameName =
        myStrategies.findIndex((myStrategy) => myStrategy.title === name) !==
        -1;
      if (hasSameName) {
        throw new Error(KB_ERROR_MAPPER.DUPLICATED);
      }
      const response = await repo.updateMyStrategyName({ id, name });
      const result = convertUpdatedMyStrategyName(response);
      if (result) {
        const myStrategies = get().myStrategies.map((myStrategy) => {
          if (myStrategy.id === id) {
            return {
              ...myStrategy,
              title: name,
            };
          }
          return myStrategy;
        });
        set(() => ({ myStrategies }));
        return true;
      }
    },
    duplicateMyStrategy: async (id, name) => {
      const { pageStatus, myStrategies } = get();
      const { openConfirm } = useConfirmStore.getState();
      // 가지고 있는 전략 중에 동일명이 있으면 중복 생성 불가
      const hasSameName =
        myStrategies.findIndex((myStrategy) => myStrategy.title === name) !==
        -1;
      if (hasSameName) {
        throw new Error(KB_ERROR_MAPPER.DUPLICATED);
      }
      if (pageStatus !== 'success') return;

      openConfirm('loading')({
        descriptions: LOADING_MESSAGES.loadMyStrategy,
      });
      set({
        pageStatus: 'loading',
      });

      const response = await repo.duplicateMyStrategy({ id, name });
      const result = convertDuplicatedMyStrategy(response);
      if (result) {
        const { orderType, fetchMyStrategies } = get();
        await fetchMyStrategies({ orderType });
        return result;
      }
    },
    deleteMyStrategy: async (id) => {
      const { openConfirm, closeConfirm } = useConfirmStore.getState();
      openConfirm('loading')({
        descriptions: LOADING_MESSAGES.loadMyStrategy,
      });
      const response = await repo.deleteMyStrategy({ id });
      const result = convertDeletedMyStrategy(response);
      if (result) {
        const myStrategies = get().myStrategies.filter(
          (myStrategy) => myStrategy.id !== id
        );
        set(() => ({ myStrategies, pageStatus: 'success' }));
        closeConfirm('loading');
        return true;
      }

      closeConfirm('loading');
      set({
        pageStatus: 'error',
      });
    },
    syncMyStrategy: async (syncId, portfolioId) => {
      const response = await repo.syncMyStrategyHoldings({ id: syncId });
      const isAbleContract = convertSyncMyStrategy(response);

      if (isAbleContract && portfolioId) {
        move_contract(portfolioId);
      }
    },

    checkIsActiveMyStrategy: async (myStrategyId) => {
      const response = await repo.checkIsActiveContractPossible({
        myStrategyId,
      });

      const isActiveKbContractPossible = convertIsActiveContract(response);
      return isActiveKbContractPossible;
    },

    changeNationIndex: async (index) => {
      set(() => ({ nationIndex: index }));
      const countries =
        index === 0 ? COUNTRY_MAPPER.DOMESTIC : COUNTRY_MAPPER.USA;
      get().fetchMyStrategies({
        countries,
        orderType: get().orderType,
      });
    },
  })
);
