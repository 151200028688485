import { BaseStyledText } from '@ui/base';
import {
  AliasForTextProps,
  ThemedTextProps,
  UIHTMLComponentProps,
} from '@ui/model/ui.type';

import React, { ComponentType, forwardRef } from 'react';

interface TypographyProps
  extends UIHTMLComponentProps,
    ThemedTextProps,
    AliasForTextProps {
  htmlFor?: string;
  href?: string;
  ref?: React.Ref<HTMLElement>;
}

export const Typography: ComponentType<TypographyProps> = forwardRef<
  HTMLElement,
  TypographyProps
>(
  (
    {
      as,
      variant,
      color,
      size,
      fontWeight,
      lineHeight,
      align,
      whiteSpace,
      wordBreak,
      textOverflow,
      ...props
    },
    ref
  ) => (
    <BaseStyledText
      {...props}
      as={as}
      ref={ref as never}
      $variant={variant}
      $color={color}
      $size={size}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $align={align}
      $whiteSpace={whiteSpace}
      $wordBreak={wordBreak}
      $textOverflow={textOverflow}
    />
  )
);

Typography.displayName = 'Typography';
Typography.defaultProps = {
  variant: 'body2_content',
  color: 'black.87',
};
