import { COUNTRY_MAPPER } from '@constants/common';
import { DetailEditPageContainer } from '@containers/strategy-edit/DetailEditPageContainer';
import { FC } from 'react';

interface StrategyDetailEditDiyPageProps {
  reEdit?: boolean;
}
export const UsStrategyDetailEditDiyPage: FC<
  StrategyDetailEditDiyPageProps
> = ({ reEdit = false }) => {
  return (
    <DetailEditPageContainer
      category="DIY"
      reEdit={reEdit}
      countryCode={COUNTRY_MAPPER.USA}
    />
  );
};
