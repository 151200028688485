import { LOADING_MESSAGES } from '@constants/feedback';
import { INTERVAL_MAPPER } from '@constants/strategy';
import {
  GetCumulativeReturnQueryVariables,
  GetExposureQueryVariables,
  GetHoldingPositionAllQueryVariables,
  GetRebalancingHistoryQueryVariables,
  GetRiskAssessmentIndexQueryVariables,
  GetStrategyQuery,
  GetStrategyStyleQueryVariables,
  UniverseEnum,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import {
  ProfitChartModel,
  RebalancingHistoryModel,
  SelectOptionDto,
} from '@models/common';
import {
  DetailedStrategyInfoModel,
  ExposureModel,
  HoldingPositionModel,
  MasterInfoModel,
  PeriodType,
  RiskAssessmentIndexModel,
  StrategyCategoryType,
  StrategyStyleModel,
  UniverseType,
} from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { create } from 'zustand';
import {
  convertCumulativeReturn,
  convertExposure,
  convertHoldingPositionAll,
  convertMasterInfo,
  convertRebalancingHistory,
  convertRiskAssessmentIndex,
  convertStrategy,
  convertStrategyStyle,
} from './readymade-strategy.convert';
import { createInitReadymadeStrategyState } from './readymade-strategy.init';

interface StrategyDetailState
  extends StrategyDetailStoreState,
    StrategyDetailStateActions,
    StrategyDetailNetworkActions {}

export interface StrategyDetailStoreState {
  /** 전략 카테고리 정보 */
  category: StrategyCategoryType;
  /** 전략 상세 정보 */
  detailedStrategyInfo: DetailedStrategyInfoModel;
  /** 전략 스타일 차트 정보 */
  strategyStyle: StrategyStyleModel;
  /** 선택된 벤치마크 옵션 */
  benchmarkOption?: UniverseType;
  /** 벤치마크 셀렉트박스 옵션 리스트 */
  benchmarkOptions: SelectOptionDto<UniverseType>[];
  /** 누적수익률 정보 */
  cumulativeReturn: ProfitChartModel;
  /** 선택된 누적수익률 조회기간 옵션 */
  periodOption: PeriodType;
  /** 위험평가지표 정보 */
  riskAssessmentIndex: RiskAssessmentIndexModel;
  /** 섹터/업종 정보 */
  exposure: ExposureModel;
  /** 종목구성 정보 */
  holdingPosition: HoldingPositionModel;
  /** 리밸런싱 내역 정보 */
  rebalancingHistory: RebalancingHistoryModel;
  /** 대가들의전략 정보 */
  masterInfo: MasterInfoModel;
  /** 대가들의전략 팩터 정보 */
  masterFactors: string[];
}

interface StrategyDetailStateActions {
  /** State 초기화 함수 */
  initState: () => void;

  /** 대가들의 전략 관련 정보 세팅 함수 */
  setMasterInfo: (masterInfo: MasterInfoModel) => void;

  /** 벤치마크 옵션이 바뀌었을때 실행되는 함수 */
  changeBenchmarkOption: (
    benchmarkOption: UniverseType,
    strategyId: string
  ) => {};

  /** 기간 옵션이 바뀌었을때 실행되는 함수 */
  changePeriodOption: (periodOption: PeriodType, strategyId: string) => {};
}

interface StrategyDetailNetworkActions {
  /** 전략 상세 초기 데이터 불러오기 */
  fetchInitData: (strategyId: string) => void;

  /** 대가들의 전략 정보 불러오기 */
  fetchMasterInfo: (id: string) => void;

  /**
   * 전략 정보 불러오기
   * @param {string} strategyId - 전략 ID
   * @param {boolean} returnAnalysis - 수익 분석 데이터 불러올지 여부
   * @returns {void}
   * */
  fetchStrategy: (
    strategyId: string,
    returnAnalysis?: boolean
  ) => Promise<GetStrategyQuery>;

  /** 전략 스타일 분석 데이터 불러오기 */
  fetchStrategyStyle: (queryObj: GetStrategyStyleQueryVariables) => {};

  /**
   * 수익 분석 데이터 불러오기
   * fetchCumulativeReturn
   * fetchRiskAssessmentIndex
   * 받은 Param을 가지고 2개의 함수를 실행한다.
   */
  fetchReturnAnalysis: ({
    strategyId,
    benchmarkOption,
    periodOption,
  }: {
    strategyId: string;
    benchmarkOption: UniverseEnum;
    periodOption: PeriodType;
  }) => {};

  /** 수익 분석 - 누적 수익률 데이터 불러오기  */
  fetchCumulativeReturn: (queryObj: GetCumulativeReturnQueryVariables) => {};

  /** 수익 분석 - 위험평가지표 데이터 불러오기*/
  fetchRiskAssessmentIndex: (
    queryObj: GetRiskAssessmentIndexQueryVariables
  ) => {};

  /** 섹터/업종 분석 데이터 불러오기 */
  fetchExposure: (queryObj: GetExposureQueryVariables) => {};

  /** 종목구성 데이터 불러오기 */
  fetchHoldingPositionAll: (
    queryObj: GetHoldingPositionAllQueryVariables
  ) => {};

  /** 리밸런싱 내역 데이터 조회하기 */
  fetchRebalancingHistory: (
    queryObj: GetRebalancingHistoryQueryVariables
  ) => {};
}

function transPeriodOptionToParameter(periodOption: PeriodType) {
  return periodOption === 'ALL' ? undefined : periodOption;
}

export const useReadymadeStrategyStore = create<StrategyDetailState>(
  (set, get) => ({
    ...createInitReadymadeStrategyState(),

    initState: () => {
      set(() => ({ ...createInitReadymadeStrategyState() }));
    },
    setMasterInfo: (masterInfo) => {
      set(() => ({ masterInfo }));
    },
    fetchReturnAnalysis: async ({
      strategyId,
      benchmarkOption,
      periodOption,
    }) => {
      const { fetchCumulativeReturn, fetchRiskAssessmentIndex } = get();

      await Promise.all([
        fetchCumulativeReturn({
          benchmark: benchmarkOption,
          interval: INTERVAL_MAPPER.DAY,
          period: transPeriodOptionToParameter(periodOption),
          strategyId,
        }),
        fetchRiskAssessmentIndex({
          benchmark: benchmarkOption,
          strategyId,
        }),
      ]);
    },
    changeBenchmarkOption: async (benchmarkOption, strategyId) => {
      set(() => ({ benchmarkOption }));
      const { periodOption, fetchReturnAnalysis } = get();
      fetchReturnAnalysis({
        strategyId,
        benchmarkOption,
        periodOption,
      });
    },
    changePeriodOption: async (periodOption, strategyId) => {
      set(() => ({ periodOption }));
      const { benchmarkOption, fetchReturnAnalysis } = get();
      if (benchmarkOption) {
        fetchReturnAnalysis({
          strategyId,
          benchmarkOption,
          periodOption,
        });
      }
    },
    fetchInitData: async (strategyId) => {
      const {
        fetchStrategy,
        fetchStrategyStyle,
        fetchExposure,
        fetchHoldingPositionAll,
        fetchRebalancingHistory,
      } = get();
      const { openConfirm, closeConfirm } = useConfirmStore.getState();
      openConfirm('loading')({
        descriptions: LOADING_MESSAGES.loadStrategy,
      });

      Promise.all([
        fetchStrategy(strategyId),
        fetchStrategyStyle({ strategyId }),
        fetchExposure({ strategyId }),
        fetchHoldingPositionAll({ strategyId }),
        fetchRebalancingHistory({ strategyId }),
      ]).then(() => {
        closeConfirm('loading');
      });
    },
    fetchStrategy: async (id, returnAnalysis = true) => {
      const response = await repo.getStrategy({ id });
      const strategy = convertStrategy(response);
      if (strategy) {
        const { benchmarkOption: benchmark } = strategy;
        set(() => ({
          ...strategy,
          status: 'success',
          benchmarkOption: benchmark,
        }));

        if (returnAnalysis) {
          get().fetchReturnAnalysis({
            strategyId: id,
            benchmarkOption: benchmark,
            periodOption: 'ALL',
          });
        }
      }
      return response;
    },
    fetchStrategyStyle: async (queryObj) => {
      const response = await repo.getStrategyStyle(queryObj);
      const strategyStyle = convertStrategyStyle(response);
      if (strategyStyle) {
        set(() => ({ strategyStyle }));
      }
    },
    fetchCumulativeReturn: async (queryObj) => {
      const response = await repo.getCumulativeReturn(queryObj);
      const cumulativeReturn = convertCumulativeReturn(response);
      if (cumulativeReturn) {
        const { riskAssessmentIndex } = get();
        set(() => ({
          cumulativeReturn,
        }));
        if (!riskAssessmentIndex.baseDate) {
          set(() => ({
            riskAssessmentIndex: {
              ...riskAssessmentIndex,
              baseDate: cumulativeReturn.baseDate,
            },
          }));
        }
      }
    },
    fetchRiskAssessmentIndex: async (queryObj) => {
      const response = await repo.getRiskAssessmentIndex(queryObj);
      const riskAssessmentIndex = convertRiskAssessmentIndex(response);
      if (riskAssessmentIndex) {
        set(() => ({
          riskAssessmentIndex: {
            ...riskAssessmentIndex,
            baseDate: get().riskAssessmentIndex.baseDate,
          },
        }));
      }
    },
    fetchExposure: async (queryObj) => {
      const response = await repo.getExposure(queryObj);
      const exposure = convertExposure(response);
      if (exposure) {
        set(() => ({ exposure }));
      }
    },
    fetchHoldingPositionAll: async (queryObj) => {
      const response = await repo.getHoldingPositionAll(queryObj);
      const holdingPosition = convertHoldingPositionAll(response);
      if (holdingPosition) {
        set(() => ({
          holdingPosition,
        }));
      }
    },
    fetchRebalancingHistory: async (queryObj) => {
      const response = await repo.getRebalancingHistory(queryObj);
      const rebalancingHistory = convertRebalancingHistory(response);
      if (rebalancingHistory) {
        set(() => ({ rebalancingHistory }));
      }
    },
    fetchMasterInfo: async (id) => {
      const response = await repo.getStrategy({ id });
      const masterInfo = convertMasterInfo(response);
      set(() => ({ masterInfo }));
    },
  })
);
