/** 전략 스타일 분석 */
export const STRATEGY_STYLE_TOOLTIP = `전략이 보유하고 있는 포트폴리오의 팩터 스타일 점수를 기준일자 바탕으로 제공하여 해당 전략의 특성을 한눈에 확인하실 수 있습니다. 

투자기준 팩터 스타일별 설명
- 시가총액&자산규모 : 시가총액과 자산규모가 작을수록 점수가 높습니다.
- 가치 : 저평가된 종목들이 많을수록 점수가 높습니다.
- 성장 : 종목들의 성장동력이 높을 수록 점수가 높습니다.
- 퀄리티 : 종목들의 이익의 질이 높을수록 점수가 높습니다.
- 변동성 : 종목들의 변동성이 낮을 수록 점수가 높습니다.
- 시장민감도 : 종목들의 시장 민감도가 낮을수록 점수가 높습니다.
- 배당 : 종목들의 배당이 높을 수록 점수가 높습니다.
- 어닝서프라이즈 : 종목들의 실적이 예상보다 좋을수록 점수가 높습니다.
- 수급 : 종목들의 수급 및 유동성 개선이 잘 이루어질수록 점수가 높습니다.`;

/** 수익 분석 */
export const REVENUE_ANALYTICS_TOOLTIP = `설정하신 조회기간 내 수익률을 확인하실 수 있으며 원하는 기준 포트폴리오를 설정해 성과비교가 가능합니다. 

용어설명
- 수익률 : 조회기간의 누적수익률 값입니다. 

- 변동성 : 수익률의 일평균 표준편차를 뜻하며 포트폴리오의 수익률이 변동하는 정도를 뜻합니다. 

- 샤프지수 : 포트폴리오 수익률에서 국공채 수익률(무위험수익률 지표 확인중)을 뺀 값을 포트폴리오 수익률의 표준편차로 나눈 값입니다. 위험 1단위 대신 얻은 초과수익이 얼마인지를 측정하는 지표입니다.  
기준지표가 동일할 때, 더 높은 샤프지수를 나타내는 자산이 동일한 위험에 대해 더 높은 수익률을 제공합니다. 

- MDD : (Maximum DrawDown) 포트폴리오 성과가 새로운 고점에 도달하기 전에 이전 고점에서 저점까지의 최대 손실을 의미하며 지정된 기간에 걸친 하방 리스크를 측정하는 지표를 의미합니다. `;

/** 섹터/업종 분석 */
export const SECTOR_INDUSTRY_TOOLTIP = `한국 상장기업, 한국상장 대표기업 등 7가지 지수 내 구성 주식종목들을 섹터 및 업종별로 확인 하여 투자하실 수 있습니다. 예를들어 섹터란 에너지, 소재, 산업재, IT 등을 의미합니다. 업종이란 산업그룹을 의미하며 IT의 소프트웨어, 하드웨어, 반도체 디스플레이로 구성되어 있습니다.`;

/** 리밸런싱 분석 */
export const REBALANCING_HISTORY_TOOLTIP = `설정된 주기로 진행한 리밸런싱의 결과입니다. 
하나의 막대는 리밸런싱 실행일의 종목별 비중을 의미하며, 각각의 색은 개별 종목을 구분합니다.

* 기타 : Top5 종목을 제외한 나머지 종목들을 의미합니다.`;

/** 위험평가지수 */
export const ANNUAL_CONVERSION_TOOLTIP = `연환산 하기를 누르시면, 수익률과 변동성 정보가 연환산 된 값으로 보이게 됩니다. 

연환산은 설정된 조회기간을 1년단위로 변환하는 행위로, 이 전략을 1년간 투자했을 시 예상되는 값(연환산 수익률, 연환산 변동성)을 확인하실 수 있습니다.  샤프지수는 이미 연환산된 값을 적용하여 산출하며, MDD는 조회기간 내 최대 낙폭 값으로 연환산 할 수 없는 값입니다. `;

/** 종목구성(Top5) */
export const ORGANIZING_STOCKS_TOOLTIP_DETAIL =
  '해당 포트폴리오에 포함되는 종목들의 비중을 확인하실 수 있습니다.';
export const ORGANIZING_STOCKS_TOOLTIP =
  '설정하신 투자 기준에 따라 해당 전략이 보유하고 있는 종목들의 비중을 확인하실 수 있습니다.';

/** 제외 종목 */
export const EXCLUDE_HOLDINGS_TOOLTIP = `해당 전략에서 제외하신 종목들로, 향후 리밸런싱에서도 해당 종목들은 제외 됩니다. `;

/** 백테스팅 수익 분석 */
export const BACKTESTING_REVENUE_ANALYSIS_TOOLTIP = `포트폴리오를 생성할 당시의 백테스트 결과로써, 백테스트 시작일부터 전략생성일까지의 누적수익률 및 성과 평가에 대한 결과입니다.
(단, 포트폴리오의 구성종목은 최대 50종목 이하로 제한됩니다.)`;

/** 종목 리스트 */
export const STOCK_LIST_TOOLTIP = `설정하신 전략을 바탕으로 산출된 종목들의 비중입니다.
종목을 제외하실 시, 잔여 종목들의 비중을 안분하여 비중이 재계산됩니다.`;

/** 누적 수익률 */
export const CUMULATIVE_RETURN_TOOLTIP = `포트폴리오를 생성할 당시의 백테스트 결과로써, 백테스트 시작일부터 전략생성일까지의 누적수익률 및 성과 평가에 대한 결과입니다.
(단, 포트폴리오의 구성종목은 최대 50종목 이하로 제한됩니다.)`;

/** 투자기준 정보 */
export const STRATEGY_SETTING_TOOLTIP = `해당 전략의 규칙으로 향후 리밸런싱에 적용되는 설정값입니다.`;

/** 투자 금액 */
export const INVESTMENT_AMOUNT_TOOLTIP = `투자 금액에 따라 구성 종목수가 달라질 수 있으며, 금액이 적은 경우 투자 기준이 전부 반영되지 못할 가능성이 있습니다.
(단, 포트폴리오의 구성종목은 최대 50종목 이하로 제한됩니다.)`;
