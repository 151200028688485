import { LineChart, SelectButton } from '@components/common';
import { DescInfo } from '@components/common/DescInfo';
import { MyForwardTestInfo } from '@components/myStrategy';
import {
  HoldingPositionInfo,
  RebalancingHistory,
  RiskAssessmentIndex,
  SectionTemplate,
  SectorIndustryComp,
  StrategyStyleInfo,
} from '@components/strategy';
import {
  COUNTRY_MAPPER,
  ORGANIZING_STOCKS_TOOLTIP,
  REBALANCING_HISTORY_TOOLTIP,
  REVENUE_ANALYTICS_TOOLTIP,
  SECTOR_INDUSTRY_TOOLTIP,
  STRATEGY_STYLE_TOOLTIP,
} from '@constants/common';
import {
  REBALANCING_OPTION_LOCALE,
  SIMULATION_TYPE_MAPPER,
} from '@constants/strategy';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useMyStrategyResultController } from '@hooks/my-strategy/useMyStrategyResultController';
import { useMyStrategyLoader } from '@hooks/useMyStrategyLoader';
import { useProfitSelector } from '@hooks/useProfitSelector';
import { useRouterMove } from '@hooks/useRouterMove';
import { CountryCodeType } from '@models/common';
import { useMyStrategyStore } from '@stores/my-strategy';
import { FlexBox } from '@ui/components';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ForwardTestPageContainerProps {
  countryCode?: CountryCodeType;
}

export const ForwardTestPageContainer: FC<ForwardTestPageContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const [searchParams] = useSearchParams();
  const ci = new URLSearchParams(searchParams).get('u') || '';
  const { moveToBackTest, moveToUsBackTest } = useRouterMove();
  const {
    enableContract,
    apiStatus,
    strategyId,
    encryptedId,
    portfolioId,
    title,
    okText,
  } = useMyStrategyLoader('FORWARDTEST');
  const {
    isActiveKbContract,
    category,
    benchmarkOption,
    benchmarkOptions,
    detailedStrategyInfo,
    strategyStyle,
    cumulativeReturn,
    riskAssessmentIndex,
    exposure,
    holdingPosition,
    rebalancingHistory,
    changeBenchmarkOption,
  } = useMyStrategyStore();

  const { bottomsheet, selectedBenchmark, handleSelect } = useProfitSelector({
    strategyId,
    benchmarkOption,
    benchmarkOptions,
    simulationType: SIMULATION_TYPE_MAPPER.FORWARDTEST,
    changeBenchmarkOption,
  });

  const { handleClickOk, handlePrev } = useMyStrategyResultController({
    apiStatus,
    enableContract,
    countryCode,
    strategyId,
    encryptedId,
    portfolioId,
  });

  const handleClickPrev = () => {
    handlePrev(ci);
  };

  return (
    <LayoutContainer
      extended
      tagColor="green"
      tagName="모의투자"
      title={title}
      floater={{
        variant: 'double',
        okText,
        cancelText: '백테스트 결과 보기',
        onClickOk: handleClickOk,
        onClickCancel: () => {
          if (countryCode === COUNTRY_MAPPER.USA) {
            moveToUsBackTest(strategyId);
          } else {
            moveToBackTest(strategyId);
          }
        },
      }}
      onClickPrev={handleClickPrev}
    >
      <SectionTemplate minHeight={270} variant="custom">
        <FlexBox direction="column">
          <MyForwardTestInfo
            countryCode={countryCode}
            info={detailedStrategyInfo}
            category={category}
            isActiveKbContract={isActiveKbContract}
            benchmarkName={selectedBenchmark}
          />
          <DescInfo priceInfo />
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={strategyStyle.baseDate}
        minHeight={415}
        infoDescription={STRATEGY_STYLE_TOOLTIP}
        title="전략스타일 분석"
      >
        <FlexBox direction="column" spacing={16}>
          <StrategyStyleInfo strategyStyle={strategyStyle} />
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={cumulativeReturn.baseDate}
        infoDescription={REVENUE_ANALYTICS_TOOLTIP}
        minHeight={716}
        title="수익 분석"
      >
        <FlexBox direction="column" spacing={32}>
          <FlexBox direction="column" spacing={24}>
            <FlexBox>
              <SelectButton
                variant="white"
                open={bottomsheet}
                placeholder="벤치마크"
                selectedValue={selectedBenchmark}
                onClick={handleSelect('benchmark')}
              />
            </FlexBox>
            <FlexBox direction="column" height={284}>
              <LineChart data={cumulativeReturn} />
            </FlexBox>
            {riskAssessmentIndex && (
              <FlexBox direction="column">
                <RiskAssessmentIndex {...riskAssessmentIndex} />
                <DescInfo />
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={exposure.baseDate}
        minHeight={294}
        title="섹터/업종 구성"
        infoDescription={SECTOR_INDUSTRY_TOOLTIP}
      >
        <SectorIndustryComp variant="double" data={exposure.data} />
      </SectionTemplate>

      <SectionTemplate
        description={holdingPosition.baseDate}
        minHeight={364}
        title="종목구성(Top5)"
        infoDescription={ORGANIZING_STOCKS_TOOLTIP}
      >
        <HoldingPositionInfo
          cash={holdingPosition.cash}
          stockList={holdingPosition.data}
        />
      </SectionTemplate>

      <SectionTemplate
        description={rebalancingHistory.baseDate}
        minHeight={561}
        subDescription={rebalancingHistory.rebalancing}
        title="리밸런싱 내역"
        infoDescription={REBALANCING_HISTORY_TOOLTIP}
      >
        <RebalancingHistory
          color="orange"
          barChartData={rebalancingHistory.data}
          disableChart={
            rebalancingHistory.rebalancing === REBALANCING_OPTION_LOCALE.NONE
          }
        />
        <DescInfo />
      </SectionTemplate>
    </LayoutContainer>
  );
};
