import { BaseStyledTableHead } from '@ui/base';
import { ThemedTableHeadProps, UIHTMLComponentProps } from '@ui/model';
import { ComponentType, forwardRef } from 'react';
import { TableContext } from './Table';

interface TableHeadProps
  extends UIHTMLComponentProps<HTMLTableSectionElement>,
    ThemedTableHeadProps {}

export const TableHead: ComponentType<TableHeadProps> = forwardRef<
  HTMLTableSectionElement,
  TableHeadProps
>(({ height, bgColor, ...props }, ref) => (
  <TableContext.Provider value={{ isHead: true }}>
    <BaseStyledTableHead
      {...props}
      ref={ref}
      $height={height}
      $bgColor={bgColor}
    />
  </TableContext.Provider>
));

TableHead.displayName = 'TableHead';
TableHead.defaultProps = {};
