import { EmptyContent } from '@components/strategy/EmptyContent';
import { PageStatusType } from '@models/common';
import {
  StrategyCardModel,
  StrategyCategoryType,
  StrategyOrderPeriodReturnType,
  StrategyPaginationModel,
} from '@models/strategy';
import { Button, FlexBox } from '@ui/components';
import { FC } from 'react';
import { StrategyCard } from './StrategyCard';

interface StrategyListProps {
  category: StrategyCategoryType;
  status: PageStatusType;
  strategyList: StrategyCardModel[];
  pagination: StrategyPaginationModel;
  period: StrategyOrderPeriodReturnType;
  onClickItemCurried: (id: string) => () => void;
  onClickSelectCurried: (id: string) => () => void;
  onClickMoreList: () => void;
  isKbPick?: boolean;
}

export const StrategyList: FC<StrategyListProps> = ({
  category,
  status,
  strategyList,
  pagination,
  period,
  onClickItemCurried,
  onClickSelectCurried,
  onClickMoreList,
  isKbPick,
}) => {
  if (status === 'init' || status === 'loading') {
    return null;
  }
  if (strategyList.length === 0) {
    return <EmptyContent />;
  }

  return (
    <FlexBox direction="column" spacing={16}>
      {strategyList.map((item) => {
        const description = isKbPick
          ? item.kbPickDescription || item.description
          : item.description;
        return (
          <StrategyCard
            key={item.id}
            type={category}
            item={{ ...item, description }}
            period={period}
            onClickItemCurried={onClickItemCurried}
            onClickSelectCurried={onClickSelectCurried}
            isKbPick={isKbPick}
          />
        );
      })}
      {pagination.hasNextPage && (
        <Button
          variant="plain"
          size="big"
          color="black.60"
          onClick={onClickMoreList}
        >
          전략 더 보기
        </Button>
      )}
    </FlexBox>
  );
};
