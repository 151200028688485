import { EmptyLineChart } from '@components/strategy/EmptyContent';
import { LineType, ProfitChartModel } from '@models/common';
import { theme } from '@styles/theme';
import { FC, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  CategoricalChartFunc,
  CategoricalChartState,
} from 'recharts/types/chart/generateCategoricalChart';
import { LineChartLegendRender } from './ChartLegend';
import { DateTickComp, PercentTickFormat } from './ChartTick';
import { LineChartTooltip } from './LineChartTooltip';

interface LineChartProps {
  data: ProfitChartModel;
  hasLegend?: boolean;
}

export const LineChart: FC<LineChartProps> = ({ data, hasLegend = true }) => {
  const [payload, setPayload] = useState<CategoricalChartState | undefined>();

  const LineColorObj: Record<LineType, string> = {
    base: theme.colorPalette.secondary.default,
    benchmark: theme.colorPalette.divider.benchmark,
    my: theme.colorPalette.kb.yellow,
  };

  const { benchmarkName, myStrategyName, strategyName } = data;

  if (data.data.length < 2) {
    return <EmptyLineChart />;
  }

  const isDecimal = data.data.findIndex((item) => {
    let checkOverFivePercent = Math.abs(+(item?.benchmark || 0)) > 0.05;
    if (item.base) {
      if (Math.abs(+item.base) > 0.05) {
        checkOverFivePercent = true;
      }
    }
    if (item.myStrategy) {
      if (Math.abs(+item.myStrategy) > 0.05) {
        checkOverFivePercent = true;
      }
    }
    return checkOverFivePercent;
  });

  const handleMouseMove: CategoricalChartFunc = (state, e) => {
    if (state.isTooltipActive) {
      setPayload(state);
    }
  };

  return (
    <ResponsiveContainer>
      <RechartLineChart
        data={data.data}
        margin={{ left: -24, right: 8 }}
        onMouseMove={handleMouseMove}
      >
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          padding="no-gap"
          tick={<DateTickComp />}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={<PercentTickFormat decimal={isDecimal < 0} />}
        />
        <CartesianGrid
          vertical={false}
          stroke={theme.colorPalette.border.chartgrid}
        />
        {hasLegend && (
          <Legend
            height={48}
            verticalAlign="top"
            content={LineChartLegendRender}
          />
        )}
        <Tooltip
          cursor={{
            stroke: theme.colorPalette.black[60],
            strokeDasharray: '3 3',
          }}
          wrapperStyle={{ visibility: payload ? 'visible' : 'hidden' }}
          content={() => <LineChartTooltip {...payload} />}
        />
        {myStrategyName && (
          <Line
            name={myStrategyName}
            type="monotone"
            dataKey="myStrategy"
            stroke={LineColorObj.my}
            dot={false}
            strokeWidth={1.5}
            activeDot={{ stroke: LineColorObj.my, strokeWidth: 2, r: 4 }}
          />
        )}
        {strategyName && (
          <Line
            name={strategyName}
            type="monotone"
            dataKey="base"
            stroke={LineColorObj.base}
            dot={false}
            strokeWidth={1.5}
            activeDot={{ stroke: LineColorObj.base, strokeWidth: 2, r: 4 }}
          />
        )}
        <Line
          name={benchmarkName}
          type="monotone"
          dataKey="benchmark"
          stroke={LineColorObj.benchmark}
          dot={false}
          strokeWidth={1.5}
          activeDot={{ stroke: LineColorObj.benchmark, strokeWidth: 2, r: 4 }}
        />
      </RechartLineChart>
    </ResponsiveContainer>
  );
};
