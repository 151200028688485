import { InfoBox } from '@components/strategy-edit';
import {
  OPTIMIZATION_LOCALE,
  REBALANCING_OPTION_LOCALE,
} from '@constants/strategy';
import { CountryCodeType } from '@models/common';
import { useBottomsheetStore, useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { useLayoutStore } from '@stores/ui';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import {
  getDateFormattedString,
  replaceNumberByTenThousandUnit,
} from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

const SettingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
  padding: 16px 0;
`;

const SettingBtn = styled.button`
  display: flex;
  align-items: center;
`;

interface StrategySettingContainerProps {
  countryCode: CountryCodeType;
}

export const StrategySettingContainer: FC<StrategySettingContainerProps> = ({
  countryCode,
}) => {
  const { contentWidth } = useLayoutStore();
  const { closeBottomsheet, openBottomsheet } = useBottomsheetStore();
  const { openConfirm } = useConfirmStore();
  const {
    strategySettingObj: { booksize, optimization, rebalancing, startDate },
    setBooksize,
    setOptimization,
    setRebalancing,
    setStartDate,
  } = useStrategyEditStore();

  const handleClickVolume = () => {
    openBottomsheet('booksize')({
      booksize,
      countryCode,
      onClickOption: (booksizeInput) => {
        setBooksize(booksizeInput * 10000); // 투자금액 : 1만 -> 1원 단위로 치환
        closeBottomsheet('booksize');
      },
      onClickInputOption: () => {
        openConfirm('booksize')({
          countryCode,
          onOk: (booksizeInput: number) => {
            setBooksize(booksizeInput * 10000); // 투자금액 : 1만 -> 1원 단위로 치환
            closeBottomsheet('booksize');
          },
        });
      },
    });
  };

  const handleClickOptimize = () => {
    openBottomsheet('optimization')({
      optimization,
      onClickOption: (optimization) => {
        setOptimization(optimization);
        closeBottomsheet('optimization');
      },
    });
  };

  const handleClickPeriod = () => {
    openBottomsheet('rebalancing')({
      rebalancing,
      onClickOption: (rebalancing) => {
        setRebalancing(rebalancing);
        closeBottomsheet('rebalancing');
      },
    });
  };

  const handleClickBackTest = () => {
    openBottomsheet('backtestDate')({
      defaultDate: startDate,
      onOk: (date) => {
        setStartDate(date);
        closeBottomsheet('backtestDate');
      },
    });
  };

  return (
    <div>
      <FlexBox direction="column" pb={176}>
        <SettingBox>
          <Typography variant="body1_content">투자금액</Typography>
          <SettingBtn onClick={handleClickVolume}>
            <Typography variant="body2_title" color="secondary.text">
              {replaceNumberByTenThousandUnit(booksize)}
            </Typography>
            <Icon type="chevron-right" size={24} />
          </SettingBtn>
        </SettingBox>
        <SettingBox>
          <Typography variant="body1_content">최적화 옵션</Typography>
          <SettingBtn onClick={handleClickOptimize}>
            <Typography variant="body2_title" color="secondary.text">
              {OPTIMIZATION_LOCALE[optimization]}
            </Typography>
            <Icon type="chevron-right" size={24} />
          </SettingBtn>
        </SettingBox>
        <SettingBox>
          <Typography variant="body1_content">리밸런싱 주기</Typography>
          <SettingBtn onClick={handleClickPeriod}>
            <Typography variant="body2_title" color="secondary.text">
              {REBALANCING_OPTION_LOCALE[rebalancing || 'NONE']}
            </Typography>
            <Icon type="chevron-right" size={24} />
          </SettingBtn>
        </SettingBox>
        <SettingBox>
          <Typography variant="body1_content">백테스트 시작일</Typography>
          <SettingBtn onClick={handleClickBackTest}>
            <Typography variant="body2_title" color="secondary.text">
              {getDateFormattedString(startDate, 'YYYY/mm')}
            </Typography>
            <Icon type="chevron-right" size={24} />
          </SettingBtn>
        </SettingBox>
      </FlexBox>
      <InfoBox contentWidth={contentWidth} />
    </div>
  );
};
