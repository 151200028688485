import { EditHeadTab } from '@components/strategy-edit';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { getFloaterModelByStep } from '@utils/strategy/strategy-edit.util';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { EditCancelBtn } from './DetailEditPageContainer';
import { FactorEditContainer } from './FactorEditContainer';
import { FactorSelectContainer } from './FactorSelectContainer';
import { StockSelectorContainer } from './StockSelectorContainer';
import { StrategySettingContainer } from './StrategySettingContainer';
import { CountryCodeType } from '@models/common';

interface ThemeEditContainerProps {
  countryCode: CountryCodeType;
  onReplaceStep: (index: number) => void;
  onClose: () => void;
  onOk: () => void;
}

export const ThemeEditContainer: FC<ThemeEditContainerProps> = ({
  countryCode,
  onReplaceStep,
  onClose,
  onOk,
}) => {
  const location = useLocation();
  const holdings = useStrategyEditStore((state) => state.holdings);
  const urlParams = new URLSearchParams(location.search);
  const editStep = urlParams.get('step') || 1;

  const handleClickNext = () => {
    onReplaceStep(+editStep + 1);
  };

  const handleClickComplete = () => {
    onOk();
  };

  const handleClickPrev = () => {
    onReplaceStep(+editStep - 1);
  };

  const handleClickTabButton = (index: number) => () => {
    onReplaceStep(index + 1);
  };

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/4단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 4,
        okDisabled: +editStep === 4 && holdings.length === 0, // 종목구성 데이터 없는 경우 버튼 비활성화
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleClickComplete,
      })}
      optionalButton={EditCancelBtn(onClose)}
      onClickPrev={onClose}
    >
      <EditHeadTab
        type="sector"
        step={+editStep}
        onClickTab={handleClickTabButton}
        enableDesc={holdings.length !== 0}
      />
      {+editStep === 1 && <FactorSelectContainer />}
      {+editStep === 2 && <FactorEditContainer />}
      {+editStep === 3 && (
        <StrategySettingContainer countryCode={countryCode} />
      )}
      {+editStep === 4 && <StockSelectorContainer />}
    </LayoutContainer>
  );
};
