/* eslint-disable react-hooks/exhaustive-deps */
import { DescInfo } from '@components/common/DescInfo';
import { MyStrategyList } from '@components/myStrategy-list';
import { CountrySwitchButton } from '@components/myStrategy/CountrySwitchButton';
import { useMyStrategyListSelector } from '@hooks/my-strategy/useMyStrategyListSelector';
import { useBottomsheetStore } from '@stores/feedback';
import { useMyStrategyListStore } from '@stores/my-strategy-list/my-strategy-list.store';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import { LayoutContainer } from '../layout/LayoutContainer';

interface MyStrategyListContainerProps {}

export const MyStrategyListContainer: FC<MyStrategyListContainerProps> = () => {
  const { myStrategyOrder } = useBottomsheetStore();
  const { nationIndex, orderType, pageStatus, myStrategies } =
    useMyStrategyListStore();
  const {
    handleSwitchCountry,
    handleClickPrev,
    handleCreateStrategy,
    handleOrder,
    myStrategyCardHandler,
  } = useMyStrategyListSelector();

  return (
    <LayoutContainer
      navigation
      title="전략보관함"
      onClickPrev={handleClickPrev}
    >
      {pageStatus === 'init' || pageStatus === 'loading' ? (
        <div></div>
      ) : (
        <>
          <DescInfo />
          <FlexBox mt={16}>
            <CountrySwitchButton
              activeIndex={nationIndex}
              onClickBtn={handleSwitchCountry}
            />
          </FlexBox>
          <MyStrategyList
            orderType={orderType}
            myStrategyOrder={myStrategyOrder}
            myStrategyList={myStrategies}
            onChangeOrderType={handleOrder}
            onCreateStrategy={handleCreateStrategy}
            eventHandlers={myStrategyCardHandler}
          />
        </>
      )}
    </LayoutContainer>
  );
};
