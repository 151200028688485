import { ScThemedFlexBoxProps } from '@ui/model';
import {
  getBackgroundColorFromProps,
  getFlexAttrFromProps,
  getFlexBoxCss,
  getFlexItemAttrFromProps,
  getFlexSpacingFromProps,
  getFlexWrapFromProps,
  getHeightFromProps,
  getPaddingMarginFromProps,
  getPositionFromProps,
  getTextAlignFromProps,
  getWidthFromProps,
} from '@ui/util';

import styled from 'styled-components';

export const BaseStyledFlexBox = styled.div<ScThemedFlexBoxProps>`
  ${getFlexBoxCss}
  ${getFlexAttrFromProps}
  ${getFlexItemAttrFromProps}
  ${getFlexWrapFromProps}
  ${getTextAlignFromProps}
  ${getWidthFromProps}
  ${getHeightFromProps}
  ${getBackgroundColorFromProps}
  ${getFlexSpacingFromProps}
  ${getPaddingMarginFromProps}
  ${getPositionFromProps}
`;

BaseStyledFlexBox.displayName = 'BaseStyledFlexBox';
