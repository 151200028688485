import { ReturnRateInfo } from '@components/strategy';
import { COUNTRY_MAPPER } from '@constants/common';
import { CountryCodeType } from '@models/common';
import {
  DetailedMyStrategyInfoModel,
  MyForwardSummaryModel,
  StrategyCategoryType,
} from '@models/strategy';
import { FlexBox, ListTable, Typography } from '@ui/components';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DollarToggleButton } from './DollarToggleButton';

interface MyForwardTestInfoProps {
  info: DetailedMyStrategyInfoModel;
  category?: StrategyCategoryType;
  isActiveKbContract?: boolean;
  countryCode: CountryCodeType;
  benchmarkName?: string;
}

const MyForwardTestInfoWrap = styled.div``;

export const MyForwardTestInfo: FC<MyForwardTestInfoProps> = ({
  info: {
    baseDate,
    baseCumulativeReturnRate,
    benchmarkCumulativeReturnRate,
    myCumulativeReturnRate,
    forwardSummary,
    forwardSummaryExchange,
    exchangeRateInfo,
    createdExchangeRateInfo,
  },
  category,
  isActiveKbContract,
  countryCode,
  benchmarkName,
}) => {
  const [toggleExchange, setToggleExchange] = useState(false);
  const isDIY = category === 'DIY' || category === 'MASTER';

  const secondLabel = useMemo(() => {
    if (!myCumulativeReturnRate || !category) return '-';
    return category === 'DIY' || category === 'MASTER'
      ? benchmarkName
      : '프리셋(Pre-set)';
  }, [myCumulativeReturnRate, category, benchmarkName]);

  const thirdLabel =
    category === 'SECTOR' || category === 'THEME' ? benchmarkName : undefined;
  const thirdValue = benchmarkCumulativeReturnRate?.cumulative;

  const tableContent = useMemo<Record<string, string | React.ReactNode>>(() => {
    const targetObject = (
      toggleExchange ? forwardSummaryExchange : forwardSummary
    ) as MyForwardSummaryModel;
    return {
      '투자 기간': (
        <>
          {targetObject.investmentPeriod.map((item) => (
            <Typography
              key={`invest_period_${item}`}
              align="right"
              variant="caption_medium"
            >
              {item}
            </Typography>
          ))}
        </>
      ),
      '투자 금액': targetObject.investmentAmount,
      '평가 금액': targetObject.valuationAmount,
      '평가 손익': targetObject.valuationProfit,
    };
  }, [forwardSummary, forwardSummaryExchange, toggleExchange]);

  if (countryCode === COUNTRY_MAPPER.USA) {
    const secondValueTarget = isDIY
      ? benchmarkCumulativeReturnRate
      : baseCumulativeReturnRate;
    const secondValue = toggleExchange
      ? secondValueTarget?.exchangeCumulative
      : secondValueTarget?.cumulative;
    const value = toggleExchange
      ? myCumulativeReturnRate?.exchangeCumulative
      : myCumulativeReturnRate?.cumulative;
    return (
      <MyForwardTestInfoWrap>
        <Typography variant="caption_medium" color="gray.blue" mb={8}>
          {baseDate}
        </Typography>
        <FlexBox direction="column" spacing={24}>
          <ReturnRateInfo
            type="double"
            label="나의 전략"
            secondLabel={secondLabel}
            secondValue={secondValue}
            thirdLabel={thirdLabel}
            thirdValue={thirdValue}
            value={value}
          />
          <FlexBox direction="column">
            <FlexBox justify="space-between" alignItems="center" mb={8}>
              <Typography color="gray.blue" variant="caption_medium">
                * 환율: {exchangeRateInfo}
              </Typography>
              <DollarToggleButton onChange={setToggleExchange} />
            </FlexBox>
            <ListTable tableContent={tableContent} />
            {!toggleExchange && (
              <FlexBox justify="space-between">
                <FlexBox width="60%" py={6} direction="column">
                  <Typography variant="caption" color="black.60">
                    환손익
                  </Typography>
                  <Typography variant="caption" color="black.60">
                    *투자시작일 환율: {createdExchangeRateInfo}
                  </Typography>
                </FlexBox>
                <FlexBox
                  width="40%"
                  justify="flex-end"
                  alignItems="center"
                  pl={12}
                  py={6}
                >
                  <Typography
                    as="div"
                    variant="caption_medium"
                    color="black.87"
                  >
                    {forwardSummary?.valuationExchange}
                  </Typography>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>

          {isActiveKbContract && (
            <FlexBox direction="column" spacing={4} mt={-8}>
              <Typography variant="caption_medium" color="gray.blue">
                * 모의투자와 실제 성과 간의 차이
              </Typography>
              <Typography variant="overline_content" color="gray.blue">
                계약된 전략의 모의투자 결과와 실제 계좌의 성과 차이가
                거래시작일, 거래체결여부, 리밸런싱 승인, 거래수수료 등에 의해
                나타날 수 있습니다.
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      </MyForwardTestInfoWrap>
    );
  }

  return (
    <MyForwardTestInfoWrap>
      <Typography variant="caption_medium" color="gray.blue" mb={8}>
        {baseDate}
      </Typography>
      <FlexBox direction="column" spacing={24}>
        <ReturnRateInfo
          type="double"
          label="나의 전략"
          secondLabel={secondLabel}
          secondValue={
            isDIY
              ? benchmarkCumulativeReturnRate?.cumulative
              : baseCumulativeReturnRate?.cumulative
          }
          thirdLabel={thirdLabel}
          thirdValue={thirdValue}
          value={myCumulativeReturnRate?.cumulative}
        />
        <ListTable tableContent={tableContent} />
        {isActiveKbContract && (
          <FlexBox direction="column" spacing={4} mt={-8}>
            <Typography variant="caption_medium" color="gray.blue">
              * 모의투자와 실제 성과 간의 차이
            </Typography>
            <Typography variant="overline_content" color="gray.blue">
              계약된 전략의 모의투자 결과와 실제 계좌의 성과 차이가 거래시작일,
              거래체결여부, 리밸런싱 승인, 거래수수료 등에 의해 나타날 수
              있습니다.
            </Typography>
          </FlexBox>
        )}
      </FlexBox>
    </MyForwardTestInfoWrap>
  );
};
