import {
  MasterStrategyContent,
  PopupContentType,
  PopupType,
  StockCompositionContentModel,
  StockSearchContentModel,
} from '@models/feedback';
import { create } from 'zustand';

interface PopupState {
  stockComposition: boolean;
  stockCompositionContent?: StockCompositionContentModel;
  masterStrategy: boolean;
  masterStrategyContent: MasterStrategyContent;
  stockSearch: boolean;
  stockSearchContent?: StockSearchContentModel;
  closePopup: (variant: PopupType) => void;
  openPopup: <T extends PopupType>(
    variant: T
  ) => (content: PopupContentType<T>) => void;
}

export const usePopupStore = create<PopupState>((set) => ({
  stockComposition: false,
  stockCompositionContent: undefined,
  masterStrategy: false,
  masterStrategyContent: {
    factors: [],
  },
  stockSearch: false,
  stockSearchContent: undefined,
  closePopup: (variant) => {
    set(() => ({ [variant]: false }));
  },
  openPopup: (variant) => (content) => {
    set(() => ({
      [variant]: true,
      [`${variant}Content`]: content,
    }));
  },
}));
