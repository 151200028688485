import { authApi, baseApi } from '@core/network';
import * as graphqlTypes from './generates';

const auth = {
  /** M-able 앱 로그인 */
  signOn(args: graphqlTypes.SignOnMutationVariables) {
    return baseApi.request(graphqlTypes.SignOnDocument, args);
  },
  getMe() {
    return authApi.request(graphqlTypes.GetMeDocument);
  },
};

/** Readymade 전략 API */
const readymadeStrategy = {
  /** 전략 목록 */
  getStrategies(variables: graphqlTypes.GetStrategiesQueryVariables) {
    return baseApi.request(graphqlTypes.GetStrategiesDocument, variables);
  },
  /** 전략 목록 - 테마 그룹 조회 */
  getThemeGroups(variables: graphqlTypes.GetThemeGroupsQueryVariables) {
    return baseApi.request(graphqlTypes.GetThemeGroupsDocument, variables);
  },
  /** 전략 상세 */
  getStrategy(variables: graphqlTypes.GetStrategyQueryVariables) {
    return baseApi.request(graphqlTypes.GetStrategyDocument, variables);
  },
  /** 전략 상세 - 전략 정보 */
  getDetailedStrategy(
    variables: graphqlTypes.GetDetailedStrategyQueryVariables
  ) {
    return baseApi.request(graphqlTypes.GetDetailedStrategyDocument, variables);
  },
  /** 전략 상세 - 전략스타일 분석 */
  getStrategyStyle(variables: graphqlTypes.GetStrategyStyleQueryVariables) {
    return baseApi.request(graphqlTypes.GetStrategyStyleDocument, variables);
  },
  /** 전략 상세 - 수익분석(라인차트) */
  getCumulativeReturn(
    variables: graphqlTypes.GetCumulativeReturnQueryVariables
  ) {
    return baseApi.request(graphqlTypes.GetCumulativeReturnDocument, variables);
  },
  /** 전략 상세 - 수익분석(위험평가지수) */
  getRiskAssessmentIndex(
    variables: graphqlTypes.GetRiskAssessmentIndexQueryVariables
  ) {
    return baseApi.request(
      graphqlTypes.GetRiskAssessmentIndexDocument,
      variables
    );
  },
  /** 전략 상세 - 섹터/업종 구성 */
  getExposure(variables: graphqlTypes.GetExposureQueryVariables) {
    return baseApi.request(graphqlTypes.GetExposureDocument, variables);
  },
  /** 전략 상세 - 종목구성(Top5) */
  getHoldingPositionAll(
    variables: graphqlTypes.GetHoldingPositionAllQueryVariables
  ) {
    return baseApi.request(
      graphqlTypes.GetHoldingPositionAllDocument,
      variables
    );
  },
  /** 전략 상세 - 리밸런싱 내역 */
  getRebalancingHistory(
    variables: graphqlTypes.GetRebalancingHistoryQueryVariables
  ) {
    return baseApi.request(
      graphqlTypes.GetRebalancingHistoryDocument,
      variables
    );
  },
};

/** 전략 편집 API */
const strategyEdit = {
  /** 섹터 불러 오기 */
  getSectors(variables: graphqlTypes.GetSectorsQueryVariables) {
    return baseApi.request(graphqlTypes.GetSectorsDocument, variables);
  },

  /** 유니버스 불러오기 */
  getUniverseList(variables: graphqlTypes.GetUniversePresetsQueryVariables) {
    return baseApi.request(graphqlTypes.GetUniversePresetsDocument, variables);
  },

  /** 팩터 불러오기 */
  getFactors(variables: graphqlTypes.GetFactorsQueryVariables) {
    return baseApi.request(graphqlTypes.GetFactorsDocument, variables);
  },

  /** 간편 팩터 불러오기 */
  getStyleFactors(variables: graphqlTypes.GetStyleFactorsQueryVariables) {
    return baseApi.request(graphqlTypes.GetStyleFactorsDocument, variables);
  },

  /** 종목 불러오기 */
  getHoldings(variables: graphqlTypes.GetHoldingsQueryVariables) {
    return baseApi.request(graphqlTypes.GetHoldingsDocument, variables);
  },

  /** 종목 제회 및 추가시 종목 불러오기 */
  getVolumePosition(variables: graphqlTypes.GetVolumePositionQueryVariables) {
    return baseApi.request(graphqlTypes.GetVolumePositionDocument, variables);
  },

  /** 제외 종목 추가 삭제시 종목 불러오기 */

  /** 전략 생성 */
  createMyStrategyByReadyMade(
    variables: graphqlTypes.CreateMyStrategyByReadyMadeMutationVariables
  ) {
    return authApi.request(
      graphqlTypes.CreateMyStrategyByReadyMadeDocument,
      variables
    );
  },

  /** 전략 복제 */
  duplicateMyStrategy(
    variables: graphqlTypes.DuplicateMyStrategyMutationVariables
  ) {
    return authApi.request(graphqlTypes.DuplicateMyStrategyDocument, variables);
  },

  /** 종목 검색 */
  searchHoldings(variables: graphqlTypes.SearchHoldingsQueryVariables) {
    return baseApi.request(graphqlTypes.SearchHoldingsDocument, variables);
  },
};

/** 전략보관함 API */
const myStrategy = {
  /** 내 전략 - 백테스트 결과 진입 가능 여부 조회 */
  getIsBacktestData(variables: graphqlTypes.GetIsBacktestDataQueryVariables) {
    return authApi.request(graphqlTypes.GetIsBacktestDataDocument, variables);
  },

  /** 내 전략 - 전략 정보 */
  getMyStrategy(variables: graphqlTypes.GetMyStrategyQueryVariables) {
    return authApi.request(graphqlTypes.GetMyStrategyDocument, variables);
  },

  /** 내 전략 - 전략 정보 */
  getMyStrategyByPortfolioId(
    variables: graphqlTypes.GetMyStrategyByPortfolioIdQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyStrategyByPortfolioIdDocument,
      variables
    );
  },

  /** 전략보관함 - 전략 리스트 조회 */
  getMyStrategies(variables: graphqlTypes.GetMyStrategiesQueryVariables) {
    return authApi.request(graphqlTypes.GetMyStrategiesDocument, variables);
  },

  /** 내 전략 상세 - 전략 상세 */
  getDetailedMyStrategy(
    variables: graphqlTypes.GetDetailedMyStrategyQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetDetailedMyStrategyDocument,
      variables
    );
  },

  getMyStrategyStyle(variables: graphqlTypes.GetMyStrategyStyleQueryVariables) {
    return authApi.request(graphqlTypes.GetMyStrategyStyleDocument, variables);
  },

  /** 내 전략 상세 - 수익분석(누적수익률) */
  getMyCumulativeReturn(
    variables: graphqlTypes.GetMyCumulativeReturnQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyCumulativeReturnDocument,
      variables
    );
  },

  /** 내 전략 상세 - 수익분석(위험평가지수) */
  getMyRiskAssessmentInddex(
    variables: graphqlTypes.GetMyRiskAssessmentIndexQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyRiskAssessmentIndexDocument,
      variables
    );
  },

  /** 내 전략 상세 - 종목 조회 */
  getMyHoldingsPositionAll(
    variables: graphqlTypes.GetMyHoldingPositionAllQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyHoldingPositionAllDocument,
      variables
    );
  },

  /** 내 전략 상세 - 업종/섹터 조회 */
  getMyExposure(variables: graphqlTypes.GetMyExposureQueryVariables) {
    return authApi.request(graphqlTypes.GetMyExposureDocument, variables);
  },

  /** 내 전략 상세 - 리밸런싱 이력 조회 */
  getMyRebalancingHistory(
    variables: graphqlTypes.GetMyRebalancingHistoryQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyRebalancingHistoryDocument,
      variables
    );
  },

  /** 내 전략 상세 - 제외 종목 조회 */
  getMyStrategyBlacklist(
    variables: graphqlTypes.GetMyStrategyBlacklistQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.GetMyStrategyBlacklistDocument,
      variables
    );
  },

  /** 전략보관함 - 전략명 변경 */
  updateMyStrategyName(
    variables: graphqlTypes.UpdateMyStrategyNameMutationVariables
  ) {
    return authApi.request(
      graphqlTypes.UpdateMyStrategyNameDocument,
      variables
    );
  },

  /** 내 전략 투자 시작 가능 여부 */
  checkIsActiveContractPossible(
    variables: graphqlTypes.IsActiveKbContractPossibleQueryVariables
  ) {
    return authApi.request(
      graphqlTypes.IsActiveKbContractPossibleDocument,
      variables
    );
  },

  /** 전략보관함 - 전략 삭제 */
  deleteMyStrategy(variables: graphqlTypes.DeleteMyStrategyMutationVariables) {
    return authApi.request(graphqlTypes.DeleteMyStrategyDocument, variables);
  },

  /** 백테스트 결과 - 전략 저장 */
  saveMyStrategy(variables: graphqlTypes.SaveMyStrategyMutationVariables) {
    return authApi.request(graphqlTypes.SaveMyStrategyDocument, variables);
  },

  /** 투자시작하기전에 KB와 싱크 맞추는 작업 */
  syncMyStrategyHoldings(
    variables: graphqlTypes.SyncMyStrategyHoldingsMutationVariables
  ) {
    return authApi.request(
      graphqlTypes.SyncMyStrategyHoldingsDocument,
      variables
    );
  },
};

const myStrategyEdit = {
  /** 내 전략 편집 - 상세 편집(DIY) 섹터/업종 조회 */
  getMySectors(variables: graphqlTypes.GetMySectorsQueryVariables) {
    return authApi.request(graphqlTypes.GetMySectorsDocument, variables);
  },

  /** 내 전략 편집 - 상세 편집 팩터 조회 */
  getMyFactors(variables: graphqlTypes.GetMyFactorsQueryVariables) {
    return authApi.request(graphqlTypes.GetMyFactorsDocument, variables);
  },

  /** 내 전략 편집 - 간편 편집 팩터 조회 */
  getMyStyleFactors(variables: graphqlTypes.GetMyStyleFactorsQueryVariables) {
    return authApi.request(graphqlTypes.GetMyStyleFactorsDocument, variables);
  },

  /** 내 전략 편집 - 종목 구성 조회 */
  getMyHoldings(variables: graphqlTypes.GetMyHoldingsQueryVariables) {
    return authApi.request(graphqlTypes.GetMyHoldingsDocument, variables);
  },

  /** 내 전략 편집 - 종목 편/출입 조회 */
  getMyVolumePosition(
    variables: graphqlTypes.GetMyVolumePositionQueryVariables
  ) {
    return authApi.request(graphqlTypes.GetMyVolumePositionDocument, variables);
  },

  createMyStrategy(variables: graphqlTypes.CreateMyStrategyMutationVariables) {
    return authApi.request(graphqlTypes.CreateMyStrategyDocument, variables);
  },
};

export const repo = {
  ...auth,
  ...readymadeStrategy,
  ...strategyEdit,
  ...myStrategy,
  ...myStrategyEdit,
};
