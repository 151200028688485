import { DescInfo } from '@components/common/DescInfo';
import { PRICE_INFO_DESCRIPTION } from '@constants/common';
import { FlexBox, Typography } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface InfoBoxProps {
  contentWidth: number;
}

const InfoBoxWrap = styled.div<{ width: number }>`
  position: fixed;
  bottom: 92px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: ${({ width }) => `${width}px`};
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colorPalette.white};
`;

const InfoBoxInner = styled(FlexBox)`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.title};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
`;

const InfoItem = styled(FlexBox)``;

export const InfoBox: FC<InfoBoxProps> = ({ contentWidth }) => {
  return (
    <InfoBoxWrap width={contentWidth}>
      <DescInfo />
      <InfoBoxInner spacing={4} direction="column" mt={8}>
        <InfoItem spacing={4}>
          <Typography variant="caption_medium">∙</Typography>
          <Typography variant="caption_medium">
            관리종목, 거래정지 종목, 투자주의/경고/위험 종목은 자동으로 사전에
            제외한 후 포트폴리오를 설계합니다.
          </Typography>
        </InfoItem>
        <InfoItem spacing={4}>
          <Typography variant="caption_medium" color="state.red">
            ∙
          </Typography>
          <Typography variant="caption_medium" color="state.red">
            {PRICE_INFO_DESCRIPTION}
          </Typography>
        </InfoItem>
      </InfoBoxInner>
    </InfoBoxWrap>
  );
};
