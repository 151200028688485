import { LevelProContainer } from '@containers/strategy-edit/expert/LevelProContainer';
import { FC } from 'react';

interface StrategyPro1EditPageProps {
  reEdit?: boolean;
}

export const StrategyPro1EditPage: FC<StrategyPro1EditPageProps> = ({
  reEdit = false,
}) => {
  return <LevelProContainer reEdit={reEdit} />;
};
