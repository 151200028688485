import { StrategyCategoryType } from '@models/strategy';
import { useLayoutStore } from '@stores/ui';
import { Typography } from '@ui/components';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export interface TabItemDto {
  name: StrategyCategoryType;
  text: string;
  description: string;
}

interface TabsProps {
  category: StrategyCategoryType;
  children?: React.ReactNode;
  tabList: TabItemDto[];
  onClickItemCurried: (category: StrategyCategoryType) => () => void;
}

const TabsWrap = styled.div<{ contentWidth: number }>`
  position: fixed;
  top: 48px;

  background-color: ${({ theme }) => theme.colorPalette.white};

  margin-left: -20px;
  margin-right: -20px;

  padding-left: 20px;
  padding-right: 20px;

  z-index: ${({ theme }) => theme.zIndex.layout};

  ${({ contentWidth }) =>
    css`
      width: ${contentWidth}px;
    `};
`;

// TODO: 1px border 밑으로 내리기
const TabList = styled.div`
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 24px;
  overflow: auto;

  margin-left: -20px;
  margin-right: -20px;

  padding-left: 20px;
  padding-right: 20px;

  background-color: ${({ theme }) => theme.colorPalette.white};

  z-index: ${({ theme }) => theme.zIndex.layout};
`;

const TabBottom = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colorPalette.border.default};
  z-index: 0;
`;

const TabItem = styled.div<{ isActive: boolean }>`
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.colorPalette.black[87]
        : theme.colorPalette.border.default};
  z-index: 1;
`;

export const Tabs: FC<TabsProps> = ({
  category,
  children,
  tabList,
  onClickItemCurried,
}) => {
  const { contentWidth } = useLayoutStore();
  return (
    <TabsWrap contentWidth={contentWidth}>
      <TabList>
        {tabList.map((item) => (
          <TabItem
            onClick={onClickItemCurried(item.name)}
            key={item.name}
            isActive={category === item.name}
          >
            <Typography
              variant="body2_title"
              color={category === item.name ? 'black.87' : 'black.47'}
            >
              {item.text}
            </Typography>
          </TabItem>
        ))}
        <TabBottom />
      </TabList>
      {children}
    </TabsWrap>
  );
};
