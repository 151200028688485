import { SectorIndustryCheckDto, StrategySectorType } from '@models/strategy';
import { SectorIndustrySliceState } from './sector-industry.slice';

export const SECTOR_TOGGLE_INIT: Record<StrategySectorType, boolean> = {
  COMMUNICATIONS: false,
  CONSUMER_DISCRETIONARY: false,
  CONSUMER_STAPLES: false,
  ENERGY: false,
  FINANCIALS: false,
  HEALTH_CARE: false,
  INDUSTRIALS: false,
  INFORMATION_TECHNOLOGY: false,
  MATERIALS: false,
  REAL_ESTATE: false,
  UTILITIES: false,
};

export const SECTOR_CHECK_INIT_TRUE: SectorIndustryCheckDto = {
  all: true,
  sector: {
    COMMUNICATIONS: true,
    CONSUMER_DISCRETIONARY: true,
    CONSUMER_STAPLES: true,
    ENERGY: true,
    FINANCIALS: true,
    HEALTH_CARE: true,
    INDUSTRIALS: true,
    INFORMATION_TECHNOLOGY: true,
    MATERIALS: true,
    REAL_ESTATE: true,
    UTILITIES: true,
  },
  industry: {
    AUTOMOBILES_COMPONENTS: true,
    BANKS: true,
    CAPITAL_GOODS: true,
    COMMERCIAL_PROFESSIONAL_SERVICES: true,
    CONSUMER_DURABLES_APPAREL: true,
    CONSUMER_SERVICES: true,
    DIVERSIFIED_FINANCIALS: true,
    ENERGY: true,
    FOOD_BEVERAGE_TOBACCO: true,
    FOOD_STAPLES_RETAILING: true,
    HEALTH_CARE_EQUIPMENT_SERVICES: true,
    HOUSEHOLD_PERSONAL_PRODUCTS: true,
    INSURANCE: true,
    MATERIALS: true,
    MEDIA_ENTERTAINMENT: true,
    PHARMACEUTICALS_BIOTECHNOLOGY_LIFE_SCIENCES: true,
    REAL_ESTATE: true,
    RETAILING: true,
    REAL_ESTATE_MANAGEMENT_DEVELOPMENT: true,
    SEMICONDUCTORS_SEMICONDUCTOR_EQUIPMENT: true,
    SOFTWARE_SERVICES: true,
    TECHNOLOGY_HARDWARE_EQUIPMENT: true,
    TELECOMMUNICATION_SERVICES: true,
    TRANSPORTATION: true,
    UTILITIES: true,
  },
};

export const SECTOR_CHECK_INIT_FALSE: SectorIndustryCheckDto = {
  all: false,
  sector: {
    COMMUNICATIONS: false,
    CONSUMER_DISCRETIONARY: false,
    CONSUMER_STAPLES: false,
    ENERGY: false,
    FINANCIALS: false,
    HEALTH_CARE: false,
    INDUSTRIALS: false,
    INFORMATION_TECHNOLOGY: false,
    MATERIALS: false,
    REAL_ESTATE: false,
    UTILITIES: false,
  },
  industry: {
    AUTOMOBILES_COMPONENTS: false,
    BANKS: false,
    CAPITAL_GOODS: false,
    COMMERCIAL_PROFESSIONAL_SERVICES: false,
    CONSUMER_DURABLES_APPAREL: false,
    CONSUMER_SERVICES: false,
    DIVERSIFIED_FINANCIALS: false,
    ENERGY: false,
    FOOD_BEVERAGE_TOBACCO: false,
    FOOD_STAPLES_RETAILING: false,
    HEALTH_CARE_EQUIPMENT_SERVICES: false,
    HOUSEHOLD_PERSONAL_PRODUCTS: false,
    INSURANCE: false,
    MATERIALS: false,
    MEDIA_ENTERTAINMENT: false,
    PHARMACEUTICALS_BIOTECHNOLOGY_LIFE_SCIENCES: false,
    REAL_ESTATE: false,
    REAL_ESTATE_MANAGEMENT_DEVELOPMENT: false,
    RETAILING: false,
    SEMICONDUCTORS_SEMICONDUCTOR_EQUIPMENT: false,
    SOFTWARE_SERVICES: false,
    TECHNOLOGY_HARDWARE_EQUIPMENT: false,
    TELECOMMUNICATION_SERVICES: false,
    TRANSPORTATION: false,
    UTILITIES: false,
  },
};

export const createInitSectors = (flag: boolean) => ({
  all: flag,
  sector: {
    COMMUNICATIONS: flag,
    CONSUMER_DISCRETIONARY: flag,
    CONSUMER_STAPLES: flag,
    ENERGY: flag,
    FINANCIALS: flag,
    HEALTH_CARE: flag,
    INDUSTRIALS: flag,
    INFORMATION_TECHNOLOGY: flag,
    MATERIALS: flag,
    REAL_ESTATE: flag,
    UTILITIES: flag,
  },
  industry: {
    AUTOMOBILES_COMPONENTS: flag,
    BANKS: flag,
    CAPITAL_GOODS: flag,
    COMMERCIAL_PROFESSIONAL_SERVICES: flag,
    CONSUMER_DURABLES_APPAREL: flag,
    CONSUMER_SERVICES: flag,
    DIVERSIFIED_FINANCIALS: flag,
    ENERGY: flag,
    FOOD_BEVERAGE_TOBACCO: flag,
    FOOD_STAPLES_RETAILING: flag,
    HEALTH_CARE_EQUIPMENT_SERVICES: flag,
    HOUSEHOLD_PERSONAL_PRODUCTS: flag,
    INSURANCE: flag,
    MATERIALS: flag,
    MEDIA_ENTERTAINMENT: flag,
    PHARMACEUTICALS_BIOTECHNOLOGY_LIFE_SCIENCES: flag,
    REAL_ESTATE: flag,
    RETAILING: flag,
    REAL_ESTATE_MANAGEMENT_DEVELOPMENT: flag,
    SEMICONDUCTORS_SEMICONDUCTOR_EQUIPMENT: flag,
    SOFTWARE_SERVICES: flag,
    TECHNOLOGY_HARDWARE_EQUIPMENT: flag,
    TELECOMMUNICATION_SERVICES: flag,
    TRANSPORTATION: flag,
    UTILITIES: flag,
  },
});

export const createInitSectorIndustryState = (): SectorIndustrySliceState => ({
  checkedCnt: 0,
  sectorCheckObj: createInitSectors(true),
  sectorToggleObj: SECTOR_TOGGLE_INIT,
  industries: [],
});
