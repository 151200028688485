import {
  MySimulationOptionInfo,
  MyStrategyBasicInfo,
  MyStrategyBlacklistInfo,
} from '@components/myStrategy';
import { SectionTemplate, StrategyStyleInfo } from '@components/strategy';
import {
  EXCLUDE_HOLDINGS_TOOLTIP,
  STRATEGY_SETTING_TOOLTIP,
  STRATEGY_STYLE_TOOLTIP,
} from '@constants/common';
import { useMyStrategyStore } from '@stores/my-strategy';
import { FlexBox } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface StrategyDetailTabContainerProps {}

const StrategyDetailTabContainerWrap = styled.div``;

export const StrategyDetailTabContainer: FC<
  StrategyDetailTabContainerProps
> = () => {
  const {
    category,
    myStrategyBasicInfo,
    mySimulationOptionInfo,
    myStrategyBlacklistInfo,
    strategyStyle,
  } = useMyStrategyStore();

  return (
    <StrategyDetailTabContainerWrap>
      <SectionTemplate minHeight={96} variant="custom">
        <MyStrategyBasicInfo {...myStrategyBasicInfo} category={category} />
      </SectionTemplate>
      <SectionTemplate
        description={strategyStyle.baseDate}
        minHeight={415}
        infoDescription={STRATEGY_STYLE_TOOLTIP}
        title="전략스타일 분석"
      >
        <FlexBox direction="column" spacing={16}>
          <StrategyStyleInfo strategyStyle={strategyStyle} />
        </FlexBox>
      </SectionTemplate>
      <SectionTemplate
        minHeight={216}
        title="투자기준 정보"
        infoDescription={STRATEGY_SETTING_TOOLTIP}
      >
        <MySimulationOptionInfo
          mySimulationOptionInfo={mySimulationOptionInfo}
        />
      </SectionTemplate>
      <SectionTemplate
        minHeight={128}
        title="제외 종목"
        infoDescription={EXCLUDE_HOLDINGS_TOOLTIP}
      >
        <MyStrategyBlacklistInfo
          myStrategyBlacklistInfo={myStrategyBlacklistInfo}
        />
      </SectionTemplate>
    </StrategyDetailTabContainerWrap>
  );
};
