import { SimpleEditPageContainer } from '@containers/strategy-edit/SimpleEditPageContainer';
import { FC } from 'react';

interface StrategySimpleEditPageProps {
  reEdit?: boolean;
}

export const StrategySimpleEditPage: FC<StrategySimpleEditPageProps> = ({
  reEdit = false,
}) => {
  return <SimpleEditPageContainer reEdit={reEdit} />;
};
