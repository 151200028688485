import { CashDto, MyStockCompositionDto } from '@models/strategy';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface StockCompositionTableCellProps {
  width?: number;
  right?: boolean;
}

interface PopupProps {
  popup?: boolean;
}

interface CellProps extends StockCompositionTableCellProps {
  children?: React.ReactNode;
  header?: boolean;
}

interface StockCompositionTableProps extends PopupProps {
  caption?: boolean;
  cash?: CashDto;
  stockList: MyStockCompositionDto[];
}

const StockCompositionTableWrap = styled.div<PopupProps>`
  ${({ popup }) => {
    if (popup) {
      return css`
        padding-top: 28px;
        padding-bottom: 8px;
      `;
    }
    return null;
  }}
`;

const StockCompositionTableCaption = styled(FlexBox)`
  margin-bottom: 8px;
`;

const StockCompositionTableHeader = styled(FlexBox)`
  border-top: 1px solid black;
`;

const StockCompositionTableBody = styled(FlexBox)<PopupProps>`
  ${({ popup }) => {
    if (popup) {
      return css`
        height: calc(100vh - 155px);
        overflow-y: auto;
      `;
    }
    return null;
  }}
`;

const StockCompositionTableRow = styled(FlexBox)`
  width: 100%;
`;

const StockCompositionTableCell = styled.div<StockCompositionTableCellProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
  flex: 1;
  padding: 11px 0;
  ${({ width }) => {
    if (width) {
      return css`
        max-width: ${width}px;
      `;
    }
    return null;
  }};

  &:not(:nth-of-type(1)) {
    text-align: right;
  }
`;

export const Cell: FC<CellProps> = ({ children, header, ...props }) => {
  return (
    <StockCompositionTableCell {...props}>{children}</StockCompositionTableCell>
  );
};

export const StockCompositionTable: FC<StockCompositionTableProps> = ({
  caption = false,
  cash,
  popup,
  stockList,
}) => {
  if (stockList.length === 0) {
    return (
      <FlexBox
        direction="column"
        justify="center"
        alignItems="center"
        height={160}
        mt={48}
        spacing={16}
      >
        <Icon type="info" size={40} />
        <Typography variant="body2_title" color="black.60" align="center">
          전략에 부합하는 종목이 없습니다. <br />
          전략을 편집하고 포트폴리오를 최적화 해보세요.
        </Typography>
      </FlexBox>
    );
  }

  return (
    <StockCompositionTableWrap popup={popup}>
      {caption && (
        <StockCompositionTableCaption>
          <Typography as="span" variant="caption">
            총
            <Typography as="span" color="secondary.text" variant="caption">
              {' '}
              {stockList.length}
            </Typography>
            개
          </Typography>
        </StockCompositionTableCaption>
      )}
      <StockCompositionTableHeader>
        <StockCompositionTableRow>
          <Cell header>
            <Typography variant="caption_medium">종목명</Typography>
          </Cell>
          <Cell width={145} header>
            <Typography variant="caption_medium">비중</Typography>
          </Cell>
        </StockCompositionTableRow>
      </StockCompositionTableHeader>
      <StockCompositionTableBody direction="column" popup={popup}>
        {stockList.map((item, index) => {
          return (
            <StockCompositionTableRow key={`stock_list_row_${index}`}>
              <Cell>
                <FlexBox alignItems="center">
                  <FlexBox width={18}>
                    <Typography variant="overline_title">
                      {index + 1}
                    </Typography>
                  </FlexBox>
                  <Typography variant="overline_content">
                    {item.name}
                  </Typography>
                </FlexBox>
              </Cell>
              <Cell width={145}>
                <Typography variant="overline_content">
                  {item.percent}
                </Typography>
              </Cell>
            </StockCompositionTableRow>
          );
        })}

        {cash && (
          <StockCompositionTableRow key={`stock_list_row_cash`}>
            <Cell>
              <Typography variant="overline_content" color="gray.blue">
                {cash.name}
              </Typography>
            </Cell>
            <Cell>
              <Typography variant="overline_content" color="gray.blue">
                {cash.percent}
              </Typography>
            </Cell>
          </StockCompositionTableRow>
        )}
      </StockCompositionTableBody>
    </StockCompositionTableWrap>
  );
};
