import { MIN_WIDTH } from '@constants/layout.const';
import { ToastModel } from '@models/feedback/toast.model';
import { ContentWidthModel } from '@models/layout.model';
import { Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface ToastProps extends ToastModel, ContentWidthModel {}

const ToastWrap = styled.div<ContentWidthModel>`
  width: ${({ contentWidth }) => `${contentWidth}px`};
  position: fixed;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.popup};
`;

const ToastContent = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.state.snackbar};
  padding: 10px 26px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-align: center;
  word-break: keep-all;
`;

export const Toast: FC<ToastProps> = ({ contentWidth = MIN_WIDTH, text }) => {
  return (
    <ToastWrap contentWidth={contentWidth}>
      <ToastContent>
        <Typography
          variant="caption_medium"
          color="fixedwhite"
          whiteSpace="pre-wrap"
        >
          {text}
        </Typography>
      </ToastContent>
    </ToastWrap>
  );
};
