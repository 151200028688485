import { Badge } from '@components/common';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface MyStrategyBlacklistInfoProps {
  myStrategyBlacklistInfo: string[];
}

const MyStrategyBlacklistInfoWrap = styled(FlexBox)`
  flex-wrap: wrap;
`;

export const MyStrategyBlacklistInfo: FC<MyStrategyBlacklistInfoProps> = ({
  myStrategyBlacklistInfo,
}) => {
  if (myStrategyBlacklistInfo.length === 0) {
    return (
      <Typography align="center" variant="caption_medium" mt={32}>
        제외된 종목이 없습니다.
      </Typography>
    );
  }

  return (
    <MyStrategyBlacklistInfoWrap spacing={8}>
      {myStrategyBlacklistInfo.map((holding) => (
        <Badge key={`blacklist_${holding}`} active>
          {holding}
        </Badge>
      ))}
    </MyStrategyBlacklistInfoWrap>
  );
};
