import { STRATEGY_CATEGORY_VIEW_MAPPER } from '@constants/strategy';
import { IsActiveKbContractPossibleQuery } from '@graphql/auth';
import {
  DeleteMyStrategyMutation,
  DuplicateMyStrategyMutation,
  GetIsBacktestDataQuery,
  GetMyStrategiesQuery,
  SyncMyStrategyHoldingsMutation,
  UpdateMyStrategyNameMutation,
} from '@graphql/generates';
import { MyStrategyCardDto } from '@models/strategy';
import { filterNonDataOfArray, getDateFormattedString } from '@utils/common';
import { createReturnSummary } from './my-strategy-list.helper';

export const convertMyStrategies = (
  data: GetMyStrategiesQuery
): MyStrategyCardDto[] => {
  const resList: MyStrategyCardDto[] = [];

  const { myStrategies } = data;
  if (myStrategies) {
    if (myStrategies.edges) {
      const strategyList = filterNonDataOfArray(myStrategies.edges);
      strategyList.forEach((strategy) => {
        if (strategy?.node) {
          const item = strategy.node;
          const createdAt = getDateFormattedString(
            item.createdAt,
            'YYYY/mm/dd'
          );
          resList.push({
            id: item.id,
            portfolioId: item.portfolioId || '',
            title: item.name,
            category: STRATEGY_CATEGORY_VIEW_MAPPER[item.category],
            createdAt,
            baseStrategyName: item.baseStrategyName || '',
            universePresetName: item.universePreset.name || '',
            isSimple: item.isSimple,
            isForwardAvailable: item.isForwardAvailable,
            isActiveKbContract: item.isActiveKbContract,
            investmentStrategyLevel: item.investmentStrategyLevel,
            ...createReturnSummary(item.returnSummary),
          });
        }
      });
    }
  }

  return resList;
};

export const convertIsBacktestData = (data: GetIsBacktestDataQuery) => {
  return data.isBacktestData?.isBacktestData;
};

export const convertUpdatedMyStrategyName = (
  data: UpdateMyStrategyNameMutation
) => {
  const updateMyStrategyName = data.updateMyStrategyName;
  if (!updateMyStrategyName) return;
  const { errors, ok } = updateMyStrategyName;
  if (errors && errors[0]) {
    return errors[0].key;
  }
  return ok || false;
};
export const convertDuplicatedMyStrategy = (
  data: DuplicateMyStrategyMutation
) => {
  const duplicateMyStrategy = data.duplicateMyStrategy;
  if (!duplicateMyStrategy) return;
  const { errors, myStrategyId } = duplicateMyStrategy;
  if (errors && errors[0]) {
    return errors[0].key;
  }
  return myStrategyId || undefined;
};

export const convertDeletedMyStrategy = (data: DeleteMyStrategyMutation) => {
  const deleteMyStrategy = data.deleteMyStrategy;
  if (!deleteMyStrategy) return;
  const { errors, ok } = deleteMyStrategy;
  if (errors && errors[0]) {
    return errors[0].key;
  }
  return ok || false;
};

export const convertSyncMyStrategy = (
  data: SyncMyStrategyHoldingsMutation
): boolean => {
  const syncMyStrategy = data.syncMyStrategyHoldings;
  if (!syncMyStrategy) return false;
  const { ok } = syncMyStrategy;
  if (ok) {
    return true;
  }
  return false;
};

export const convertIsActiveContract = (
  data: IsActiveKbContractPossibleQuery
): boolean => {
  const { isActiveKbContractPossible } = data;
  return isActiveKbContractPossible?.isActiveKbContractPossible ?? false;
};
