import { useScrollBlocker } from '@hooks/useScrollBlocker';
import { ContentWidthModel } from '@models/layout.model';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface DimmedProps {
  dimmedBackgroundColor?: string;
  dimmedOpacity?: number;
}

interface ZindexProps {
  zIndex?: number;
}

interface FeedbackTemplateProps
  extends ContentWidthModel,
    DimmedProps,
    ZindexProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

interface FeedbackTemplateStyleProps extends ContentWidthModel, ZindexProps {}

const FeedbackTemplateWrap = styled.div<FeedbackTemplateStyleProps>`
  height: 100vh;
  width: ${({ contentWidth }) => `${contentWidth}px`};
  max-width: 430px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme, zIndex }) => {
    if (zIndex) {
      return css`
        z-index: ${zIndex};
      `;
    }
    return css`
      z-index: ${theme.zIndex.bottomsheet};
    `;
  }}
`;

export const Dimmed = styled.div<DimmedProps>`
  background-color: ${({ theme }) => theme.colorPalette.background.dimmed};
  height: 100%;
  width: 100%;
  opacity: 0.5;
  top: 0;
  position: absolute;
  ${({ dimmedBackgroundColor }) => {
    if (dimmedBackgroundColor) {
      return `background-color: ${dimmedBackgroundColor};`;
    }
  }}
  ${({ dimmedOpacity }) => {
    if (dimmedOpacity) {
      return `opacity: ${dimmedOpacity};`;
    }
  }}
`;

export const FeedbackTemplate: FC<FeedbackTemplateProps> = ({
  contentWidth,
  children,
  dimmedBackgroundColor,
  dimmedOpacity,
  zIndex,
  onClose,
}) => {
  useScrollBlocker();

  return (
    <FeedbackTemplateWrap contentWidth={contentWidth} zIndex={zIndex}>
      <Dimmed
        dimmedBackgroundColor={dimmedBackgroundColor}
        dimmedOpacity={dimmedOpacity}
        onClick={onClose}
      />
      {children}
    </FeedbackTemplateWrap>
  );
};
