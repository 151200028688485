import { UniverseList } from '@components/strategy-edit/master-edit/UniverseList';
import { COUNTRY_MAPPER } from '@constants/common';
import { CountryCodeType } from '@models/common';
import { UniversePresetType } from '@models/strategy';
import { useStrategyListStore } from '@stores/readymade-list';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { FC } from 'react';

interface UniverseSelectorContainerProps {
  renew?: boolean;
  reEdit?: boolean;
  strategyId: string;
  countryCode: CountryCodeType;
}

export const UniverseSelectorContainer: FC<UniverseSelectorContainerProps> = ({
  reEdit,
  renew,
  strategyId,
  countryCode,
}) => {
  const { universePresets } = useStrategyListStore();
  const {
    universePreset,
    setUniversePreset,
    fetchMasterFactors,
    fetchMyMasterFactors,
  } = useStrategyEditStore();

  const handleClickButton = (name: UniversePresetType) => () => {
    setUniversePreset(name);
    if (name !== universePreset) {
      handleChangePreset(name);
    }
  };

  const handleChangePreset = (name: UniversePresetType) => {
    if (reEdit || renew) {
      if (!!universePreset) {
        fetchMyMasterFactors({
          myStrategyId: strategyId,
          universePreset: name,
        });
      }
    } else {
      if (!!universePreset) {
        fetchMasterFactors({
          strategyId,
          universePreset: name,
        });
      }
    }
  };

  return (
    <UniverseList
      renew={renew}
      activeUniverse={universePreset}
      universeList={
        countryCode === COUNTRY_MAPPER.DOMESTIC
          ? [...universePresets.PUBLIC, ...universePresets.REPRESENT]
          : universePresets.USA
      }
      onClickUniverse={handleClickButton}
    />
  );
};
