import { FactorInfoBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';
import { svgPath } from '@utils/common';

interface InfoBottomSheetProps
  extends FactorInfoBottomsheetContentModel,
    ContentWidthModel {}

const InfoBottomSheetWrap = styled(BottomsheetTemplate)``;

const MasterSourceWrap = styled.ul`
  & > [aria-label='source'] {
    margin-left: 20px;
    list-style-type: disc;
  }
`;

export const FactorInfoBottomSheet: FC<InfoBottomSheetProps> = ({
  button = 'single',
  contentWidth,
  description,
  title,
  img,
  comments,
}) => {
  return (
    <InfoBottomSheetWrap
      contentWidth={contentWidth}
      type="factorInfo"
      title={title}
      button={button}
    >
      <FlexBox direction="column" spacing={8}>
        <img
          src={svgPath('/image/factor', img as number)}
          alt={`${title} Icon`}
          width="100%"
        />
        <Typography
          whiteSpace="pre-wrap"
          color="black.60"
          variant="body1_content"
          mt={8}
        >
          {description}
        </Typography>
        <MasterSourceWrap>
          {comments &&
            comments.length > 0 &&
            comments.map((comment, index) => (
              <Typography
                aria-label="source"
                variant="overline_content"
                color="gray.blue"
                key={`master-source-${index}`}
                as="li"
              >
                {comment}
              </Typography>
            ))}
        </MasterSourceWrap>
      </FlexBox>
    </InfoBottomSheetWrap>
  );
};
