import {
  STACK_BAR_CHART_BLUE_COLOR,
  STACK_BAR_CHART_ORANGE_COLOR,
} from '@constants/common';
import { ChartColorType, ChartWeightDto } from '@models/common';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { numberToPercent } from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

interface PieChartLegendListProps {
  legendList: ChartWeightDto[];
  color: ChartColorType;
}

const LegendCircle = styled.div<{ bgColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  flex-shrink: 0;
`;

export const PieChartLegendList: FC<PieChartLegendListProps> = ({
  legendList,
  color,
}) => {
  const colorList =
    color === 'blue'
      ? STACK_BAR_CHART_BLUE_COLOR
      : STACK_BAR_CHART_ORANGE_COLOR;
  return (
    <FlexBox direction="column">
      {legendList.map((legend, i) => (
        <FlexBox
          width={148}
          justify="space-between"
          spacing={16}
          alignItems="baseline"
          key={legend.name}
          py={4}
        >
          <FlexBox alignItems="baseline" spacing={6}>
            <LegendCircle bgColor={colorList[i]} />
            <Typography variant="overline_content" color="black.87">
              {legend.name}
            </Typography>
          </FlexBox>
          <Typography variant="overline_content" color="black.60">
            {`${numberToPercent(legend.weight)}%`}
          </Typography>
        </FlexBox>
      ))}
    </FlexBox>
  );
};
