import { useBottomsheetStore } from '@stores/feedback';
import { Icon } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface InfoButtonProps {
  description?: string;
  title?: string;
  size?: number;
}

const InfoButtonWrap = styled.button``;

export const InfoButton: FC<InfoButtonProps> = ({
  description,
  title,
  size = 18,
}) => {
  const { openBottomsheet } = useBottomsheetStore();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    openBottomsheet('info')({
      description,
      title,
    });
  };
  return (
    <InfoButtonWrap onClick={handleClick}>
      <Icon type="info" size={size} />
    </InfoButtonWrap>
  );
};
