import { LOADING_MESSAGES } from '@constants/feedback';
import {
  SaveMyStrategyMutation,
  SimulationOptionScreeningInput,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import { InvestmentStrategyLevelType, PresetType } from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { StateCreator } from 'zustand';
import { getWhitelistInput } from '../stock-selector/stock-selector.helper';
import { createInitStrategyEditState } from '../strategy-edit.init';
import { StrategyEditState } from '../strategy-edit.store';
import { createInitCommonEditState } from './strategy-edit.common.init';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';

export interface StrategyEditCommonSliceState {
  /** 다시 편집 하기 */
  renew: boolean;
  /** 저장된 전략 편집 여부 */
  reEdit: boolean;
  /** 전략 ID */
  strategyId: string;
  /** Data Fetching 여부 */
  loading: boolean;
  beforeMount: boolean;
}

export const convertSavedMyStrategy = (data: SaveMyStrategyMutation) => {
  const saveMyStrategy = data.saveMyStrategy;
  if (!saveMyStrategy) return;
  const { errors, myStrategyId } = saveMyStrategy;
  if (errors && errors[0]) {
    return errors[0].key;
  }
  return myStrategyId || undefined;
};

export interface StrategyEditCommonSlice extends StrategyEditCommonSliceState {
  initEditState: () => void;

  /** 전략 ID를 저장 */
  setStrategyId: (strategyId: string) => void;

  /** 내 전략 수정, 전략 수정인지 여부를 저장 */
  updateRenewAndReEdit: (renew: boolean, reEdit: boolean) => void;

  /** 편집 취소 */
  editCancel: (
    moveToMyStrategies: () => void,
    moveToReadyMades: () => void
  ) => void;

  /** 전략(백테스트 결과) 생성 */
  createMyStrategy: (
    myStrategyId: string,
    /** 간편 편집 여부 */
    isSimple: boolean,
    /** 재편집 여부 */
    isRenew: boolean,
    /** 투자 전략 레벨 */
    investmentStrategyLevel?: InvestmentStrategyLevelType
  ) => Promise<string | undefined>;
  /** 전략 저장 */
  saveMyStrategy: (
    myStrategyId: string,
    name: string,
    editingMyStrategyId?: string
  ) => Promise<string | undefined>;
}

export const createStrategyEditCommonSlice: StateCreator<
  StrategyEditState,
  [],
  [],
  StrategyEditCommonSlice
> = (set, get) => ({
  ...createInitCommonEditState(),
  setStrategyId: (strategyId) => {
    if (strategyId !== get().strategyId) {
      set(() => ({
        strategyId,
      }));
    }
  },

  updateRenewAndReEdit: (renew, reEdit) => {
    set(() => ({
      renew,
      reEdit,
      beforeMount: false,
    }));
  },

  initEditState: () => {
    set(() => ({
      ...createInitStrategyEditState(),
    }));
  },

  editCancel: (moveToMyStrategies, moveToReadyMades) => {
    const { renew } = get();
    const { openConfirm } = useConfirmStore.getState();

    openConfirm('alert')({
      button: 'double',
      description: '편집한 내용을 저장하지 않고 나가시겠습니까?',
      title: '편집 취소',
      onOk: () => {
        if (renew) {
          moveToMyStrategies();
        } else {
          moveToReadyMades();
        }
      },
    });
  },

  createMyStrategy: async (
    strategyId,
    isSimple,
    isRenew,
    investmentStrategyLevel = INVESTMENT_STRATEGY_LEVEL_MAPPER.EXPERT_LEVEL_PRO
  ) => {
    /** FIXME: 로딩 기획 완료 시 리팩토링 */
    const { closeConfirm, openConfirm } = useConfirmStore.getState();
    const {
      industries,
      inputFactors,
      styleFactors,
      stylePresetObj,
      strategySettingObj,
      excludeHoldings,
      universePreset,
      whitelistObj,
      validateWhiteList,
    } = get();
    const { booksize, optimization, startDate, rebalancing } =
      strategySettingObj;
    const blacklist = excludeHoldings.map(
      (excludeHolding) => excludeHolding.ccid
    );
    const whitelist = getWhitelistInput(whitelistObj);

    if (!validateWhiteList()) {
      return;
    }

    let simulationOptionScreening: SimulationOptionScreeningInput = {
      factors: styleFactors
        .filter((factor) => !!factor.type)
        .map((factor) => ({
          cmName: factor.cmName,
          ...stylePresetObj[factor.type as PresetType],
        })),
      booksize,
      optimization,
      startDate,
      rebalancing: rebalancing === 'NONE' ? null : rebalancing,
      blacklist,
      whitelist,
      universePreset,
    };

    if (!isSimple) {
      simulationOptionScreening = {
        ...simulationOptionScreening,
        factors: inputFactors,
        industries: industries.length > 0 ? industries : undefined,
      };
    }
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.backtest,
    });
    let _myStrategyId;
    if (isRenew) {
      const response = await repo.createMyStrategy({
        myStrategyId: strategyId,
        simulationOptionScreening,
      });
      _myStrategyId = response.createMyStrategy?.myStrategyId || undefined;
    } else {
      const response = await repo.createMyStrategyByReadyMade({
        strategyId,
        simulationOptionScreening,
        isSimple,
        investmentStrategyLevel,
      });
      _myStrategyId = response.createMyStrategyByReadyMade?.myStrategyId;
    }
    closeConfirm('loading');
    return _myStrategyId;
  },

  saveMyStrategy: async (myStrategyId, name, editingMyStrategyId) => {
    const { closeConfirm, openConfirm } = useConfirmStore.getState();
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.saveStrategy,
    });
    const response = await repo.saveMyStrategy({
      myStrategyId,
      name,
      editingMyStrategyId,
    });
    const result = convertSavedMyStrategy(response);
    closeConfirm('loading');
    if (result) {
      return result;
    }
  },
});
