import { MasterStrategyPopup } from '@components/feedback/popup/MasterStrategyPopup';
import { StockCompositionPopup } from '@components/feedback/popup/StockCompositionPopup';
import { StockSearchPopup } from '@components/feedback/popup/StockSearchPopup';
import { usePopupStore } from '@stores/feedback/popup.store';
import { useLayoutStore } from '@stores/ui';
import { FC } from 'react';

interface PopupContainerProps {}

export const PopupContainer: FC<PopupContainerProps> = () => {
  const contentWidth = useLayoutStore((state) => state.contentWidth);
  const {
    stockComposition,
    stockCompositionContent,
    masterStrategy,
    masterStrategyContent,
    stockSearch,
    stockSearchContent,
  } = usePopupStore();
  return (
    <>
      {stockComposition && (
        <StockCompositionPopup
          {...stockCompositionContent}
          contentWidth={contentWidth}
        />
      )}
      {masterStrategy && (
        <MasterStrategyPopup
          {...masterStrategyContent}
          contentWidth={contentWidth}
        />
      )}
      {stockSearch && (
        <StockSearchPopup {...stockSearchContent} contentWidth={contentWidth} />
      )}
    </>
  );
};
