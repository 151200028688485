import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetContainer } from './BottomsheetContainer';
import { ConfirmContainer } from './ConfirmContainer';
import { PopupContainer } from './PopupContainer';
import { ToastContainer } from './ToastContainer';

interface FeedbackContainerProps {}

const FeedbackContainerWrap = styled.div``;

export const FeedbackContainer: FC<FeedbackContainerProps> = () => {
  return (
    <FeedbackContainerWrap>
      <BottomsheetContainer />
      <ConfirmContainer />
      <PopupContainer />
      <ToastContainer />
    </FeedbackContainerWrap>
  );
};
