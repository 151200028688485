/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from '@components/layout';
import { COUNTRY_MAPPER } from '@constants/common';
import { STRATEGY_CATEGORY_TAB } from '@constants/strategy';
import { ThemeGroupStatusEnum } from '@graphql/generates';
import { useStrategyListController } from '@hooks/readymade/useStrategyListController';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import { useScrollDetector } from '@hooks/useScrollDetector';
import { CountryCodeType } from '@models/common';
import { StrategyCategoryType } from '@models/strategy';
import { useUpdateCountryCode } from '@stores/common/common.store';
import { useBottomsheetStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import { FlexBox } from '@ui/components';
import { move_back_menu } from '@utils/bridge/bridge.util';
import { handleBlockPopupState } from '@utils/common';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutContainer } from '../layout/LayoutContainer';
import { StrategyListContainer } from './StrategyListContainer';
import { StrategyListTabContainer } from './StrategyListTabContainer';

interface ReadymadeListContainerProps {
  countryCode?: CountryCodeType;
}

export const ReadymadeListContainer: FC<ReadymadeListContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const nav = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlCategory = urlParams.get('category') as StrategyCategoryType | null;
  const strategyId = urlParams.get('strategyId') || '';

  const {
    pageStatus,
    category,
    setCategory,
    initStrategies,
    fetchThemeGroups,
  } = useStrategyListStore();

  const { handleClickSelectCurried } = useStrategyListController({
    countryCode,
  });

  const updateCountryCode = useUpdateCountryCode();
  const { strategyOrder, edit } = useBottomsheetStore();

  const { isScrollingDown, isTop } = useScrollDetector(strategyOrder || edit);
  const [show, setShow] = useState(false);
  const observingTargetRef = useIntersectionObserver((check: boolean) => {
    setShow(check);
  });
  const openFixedMenu = !isTop && !show;

  const handleClickCategoryCurried = (category: StrategyCategoryType) => () => {
    setCategory(countryCode, category);
    nav(`?category=${category}`, { replace: true });
  };

  useEffect(() => {
    if (pageStatus === 'init') {
      initStrategies({ countries: countryCode });
    }

    updateCountryCode(countryCode);

    const handlePopState = handleBlockPopupState(move_back_menu);
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useLayoutEffect(() => {
    if (urlCategory && category !== urlCategory) {
      setCategory(countryCode, urlCategory);
    }
    if (strategyId) {
      handleClickSelectCurried(strategyId)();
    }
  }, [strategyId, urlCategory]);

  useLayoutEffect(() => {
    fetchThemeGroups({
      status: ThemeGroupStatusEnum.Activated,
      countries: [countryCode],
    });
  }, [countryCode]);

  return (
    <LayoutContainer navigation onClickPrev={move_back_menu}>
      <Tabs
        category={category}
        tabList={STRATEGY_CATEGORY_TAB}
        onClickItemCurried={handleClickCategoryCurried}
      >
        <StrategyListTabContainer
          folded={!openFixedMenu}
          isScrollingDown={isScrollingDown}
        />
      </Tabs>
      <FlexBox mt={8} />
      <StrategyListTabContainer />
      <FlexBox mt={16} ref={observingTargetRef} />
      <StrategyListContainer countryCode={countryCode} />
    </LayoutContainer>
  );
};
