import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface MasterFactorInfoProps {
  factors: string[];
}

const MasterFactorInfoWrap = styled.div`
  width: 100%;
`;

const Factor = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
  padding: 11px 0;
`;

export const MasterFactors: FC<MasterFactorInfoProps> = ({ factors }) => {
  return (
    <MasterFactorInfoWrap className="wrap">
      {factors.map((factor, index) => (
        <Factor key={`factor_${index}`} alignItems="center" spacing={8}>
          <Typography variant="overline_title">{index + 1}</Typography>
          <Typography variant="overline_content">{factor}</Typography>
        </Factor>
      ))}
    </MasterFactorInfoWrap>
  );
};
