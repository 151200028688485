import { BOTTOMSHEET_EDIT_TAB } from '@constants/feedback';
import { STRATEGY_EDIT_TAB_INFO } from '@constants/strategy';
import { editTabType } from '@models/feedback';
import { Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface TabsProps {
  activeTab: editTabType;
  onClickTab: (tab: editTabType) => () => void;
}

const TabsWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
`;

const TabList = styled.div`
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  overflow: auto;
  background-color: ${({ theme }) => theme.colorPalette.white};
  z-index: ${({ theme }) => theme.zIndex.layout};
`;

const TabBottom = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colorPalette.border.default};
  z-index: 0;
`;

const TabItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;

  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.colorPalette.black[87]
        : theme.colorPalette.border.default};
  z-index: 1;
`;

export const EditTab: FC<TabsProps> = ({ activeTab, onClickTab }) => {
  return (
    <TabsWrap>
      <TabList>
        {BOTTOMSHEET_EDIT_TAB.map((tab) => (
          <TabItem
            onClick={onClickTab(tab)}
            isActive={tab === activeTab}
            key={tab}
          >
            <Typography
              variant="body1_title"
              color={tab === activeTab ? 'black.87' : 'black.47'}
            >
              {STRATEGY_EDIT_TAB_INFO[tab].name}
            </Typography>
            <Typography
              variant="overline_title"
              color={tab === activeTab ? 'black.87' : 'black.47'}
            >
              {STRATEGY_EDIT_TAB_INFO[tab].subName}
            </Typography>
          </TabItem>
        ))}
        <TabBottom />
      </TabList>
    </TabsWrap>
  );
};
