import { MIN_WIDTH } from '@constants/layout.const';
import { FloaterModel } from '@models/layout.model';
import { theme } from '@styles/theme';
import { Button, ButtonGroup } from '@ui/components';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface FloaterWrapProps {
  contentWidth?: number;
  isDarkMode: () => boolean;
}

interface FloaterProps extends FloaterModel, FloaterWrapProps {}

const FloaterWrap = styled.div<FloaterWrapProps>`
  bottom: 0;
  height: 92px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.layout};

  button {
    font-size: 17px;
  }

  ${({ contentWidth }) =>
    css`
      width: ${contentWidth}px;
    `};

  ${({ isDarkMode }) =>
    isDarkMode()
      ? css`
          background: linear-gradient(
            to bottom,
            rgba(20, 20, 20, 0),
            rgba(20, 20, 20, 0.7),
            rgba(20, 20, 20, 1),
            rgba(20, 20, 20, 1)
          );
        `
      : css`
          background: ${theme.colorPalette.white};
        `};
`;

export const Floater: FC<FloaterProps> = ({
  variant,
  okText = '확인',
  okDisabled = false,
  cancelText = '취소',
  contentWidth = MIN_WIDTH,
  onClickOk,
  onClickCancel,
  isDarkMode,
}) => {
  const overTwo = variant === 'double';
  return (
    <FloaterWrap contentWidth={contentWidth} isDarkMode={isDarkMode}>
      <ButtonGroup>
        {overTwo && (
          <Button onClick={onClickCancel} variant="plain">
            {cancelText}
          </Button>
        )}
        <Button color="fixedblack" onClick={onClickOk} disabled={okDisabled}>
          {okText}
        </Button>
      </ButtonGroup>
    </FloaterWrap>
  );
};
