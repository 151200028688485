import { ScThemedInputProps, ScThemedInputWrapperProps } from '@ui/model';
import {
  getCssInput,
  getCssInputDisabled,
  getCssInputFontSize,
  getCssTextFieldVariantProps,
  getHeightFromProps,
  getTextAlignFromProps,
  getTextFieldFocusProps,
} from '@ui/util';
import styled from 'styled-components';
import { Typography } from '..';

export const BaseStyledInput = styled.input<ScThemedInputProps>`
  width: 100%;
  ${getCssInputFontSize}
  ${getCssInput}
  ${getHeightFromProps}
  ${getCssInputDisabled}
  ${getTextAlignFromProps}
`;

export const OverlayNumberInput = styled.input<ScThemedInputProps>`
  position: absolute;
  left: 0;
  right: 0;
  ${getCssInput}
  ${getHeightFromProps}
  ${getCssInputDisabled}
  ${getTextAlignFromProps}
  padding: 8px;
`;

export const BottomLine = styled.div<{ isError?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.kb.gray};
  ${({ theme, isError }) =>
    isError && `border-color: ${theme.colorPalette.state.red};`};
`;

export const DescWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 1px;
  font-size: 10px;
  line-height: 13px;
  color: ${({ theme }) => theme.colorPalette.state.red};
`;

export const UnitText = styled(Typography)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colorPalette.black[87]};
  ${({ disabled }) => disabled && 'opacity: 0.25;'};
`;

export const ErrorIconBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
  padding-left: 4px;
`;

export const ResetBtn = styled.button`
  display: flex;
  align-items: center;
  padding-right: 4px;
`;

export const TextFieldWrapper = styled.div<ScThemedInputWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme, isError }) =>
    isError
      ? theme.colorPalette.background.error
      : theme.colorPalette.black[3]};
  border-radius: 4px;

  ${getCssTextFieldVariantProps}
  ${getTextFieldFocusProps}
`;
