import {
  STRATEGY_ORDER_MAPPER,
  STRATEGY_ORDER_METHOD_MAPPER,
  STRATEGY_ORDER_PERIOD_MAPPER,
} from '@constants/strategy';

/**
 * 키워드 Init 값
 */
export const KEYWORD_INIT = {
  DIY: '',
  EMP: '',
  MASTER: '',
  SECTOR: '',
  THEME: '',
};

/**
 * 전략 리스트 정렬 Init 값
 */
export const STRATEGY_LIST_SORT_INIT = {
  DIY: STRATEGY_ORDER_MAPPER.CUMULATIVE,
  EMP: STRATEGY_ORDER_MAPPER.CUMULATIVE,
  MASTER: STRATEGY_ORDER_MAPPER.CUMULATIVE,
  SECTOR: STRATEGY_ORDER_MAPPER.CUMULATIVE,
  THEME: STRATEGY_ORDER_MAPPER.CUMULATIVE,
};

export const STRATEGY_LIST_ORDER_INIT = {
  DIY: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
  EMP: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
  MASTER: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
  SECTOR: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
  THEME: STRATEGY_ORDER_METHOD_MAPPER.CUMULATIVE,
};

/**
 * 전략 리스트 조회 기간 Init 값
 */
export const STRATEGY_LIST_PERIOD_INIT = {
  DIY: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
  EMP: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
  MASTER: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
  SECTOR: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
  THEME: STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3,
};

export const PAGINATION_INIT_OBJ = {
  hasNextPage: false,
  endCursor: '',
};
