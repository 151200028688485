import { AlertContentModel } from '@models/feedback';

export const CONFIRM_ENUM = [
  'alert',
  'booksize',
  'loading',
  'textInput',
] as const;

/** 보유 가능 전략 초과 경고문 */
export const EXCEEDED_STRATEGY_ALERT_CONTENT: AlertContentModel = {
  button: 'single',
  descriptions: [
    '전략보관함에는 전략을 최대 5개까지만 보관하실 수 있습니다.',
    '전략을 새로 만들거나 복제하시려면 보관 중인 전략을 삭제한 후 시도해주세요.',
  ],
  title: '보관 가능 전략 초과',
};

/** 모의투자 성과 열람 불가 경고문 */
export const NOT_AVAILABLE_VIEW_FORWARD_TEST: AlertContentModel = {
  button: 'single',
  description:
    '모의투자 성과는 전략생성일 기준 1영업일 이후 부터 확인하실 수 있습니다.',
  title: '모의투자 성과',
};

/** 로딩 메시지 */
export const LOADING_MESSAGES = {
  backtest: [
    '해당 전략의 백테스팅을 진행 중입니다.',
    '',
    '과거의 성과를 기반으로 투자 전략의',
    '성능을 예측하고, 향후의 투자에 대한 의사 결정을',
    '내릴 수 있습니다.',
    '백테스팅은 최대 30초 소요될 수 있습니다.',
  ],
  saveStrategy: [
    '해당 전략을 전략보관함에',
    '안전하게 저장하고 있습니다.',
    '잠시만 기다려주세요.',
  ],
  loadStrategy: [
    '해당 전략의 성과를 불러오고 있습니다.',
    '잠시만 기다려주세요.',
  ],
  loadHoldings: ['설정된 조건을 바탕으로', '종목과 비중을 불러오고 있습니다.'],
  loadMyStrategy: [
    '보관된 전략들을 최신화하고 있습니다.',
    '잠시만 기다려주세요.',
  ],
};

/** KB API 에러 */
export const KB_API_ERROR: AlertContentModel = {
  button: 'single',
  description:
    '전략 저장 중 일시적인 오류가 발생했습니다. 추후 다시 시도해 주시기 바랍니다. 이용에 불편을 드려 대단히 죄송합니다.',
  title: '전략 저장 실패 안내',
};

/** 시스템 에러 */
export const SYSTEM_ERROR: AlertContentModel = {
  button: 'single',
  description:
    '시스템에 일시적인 오류가 발생했습니다. 추후 다시 시도해 주시기 바랍니다. 이용에 불편을 드려 대단히 죄송합니다.',
  title: '시스템 오류 발생',
};

/** 백테스트 결과 생성 에러 */
export const BACKTEST_CREATION_ERROR: AlertContentModel = {
  button: 'single',
  description:
    '전략 생성 중 일시적인 오류가 발생하였습니다. 다시 시도해 주시기 바랍니다. 이용에 불편을 드려 대단히 죄송합니다.',
  title: '전략 생성 오류 및 재시도 요청',
};

/** 백테스트 결과 진입 가능 여부 조회 */
export const BACKTEST_ACCESS_CHECK: AlertContentModel = {
  button: 'single',
  descriptions: ['백테스트 결과를 저장 중 입니다.', '잠시만 기다려주세요.'],
  title: '백테스트 결과',
};

/** 종목 추가 50개 초과 경고문 */
export const EXCEEDED_HOLDINGS_ALERT_CONTENT: AlertContentModel = {
  button: 'single',
  descriptions: ['최대 보유 종목 수는 50개 입니다.'],
  title: '보유 종목 개수 초과',
};

/** 이미 있는 종목 추가시 알림 */
export const DUPLICATED_HOLDINGS_ALERT_CONTENT = '이미 편입된 종목입니다.';

/** 제외된 종목 추가시 알림 */
export const EXCLUDED_HOLDINGS_ALERT_CONTENT = '이미 제외된 종목입니다.';

/** 종목 추가는 5개까지만 가능 */
export const MAX_HOLDINGS_COUNT: AlertContentModel = {
  button: 'single',
  descriptions: ['추가 가능한 종목 수는 5개까지 입니다.'],
  title: '추가 종목수 제한',
};

/** 부합하는 종목이 없을 때 알림 */
export const NO_MATCHED_HOLDINGS_ALERT_CONTENT: AlertContentModel = {
  button: 'single',
  descriptions: [
    '전략에 부합하는 종목이 없습니다. ',
    '전략을 다시 편집해 주세요.',
  ],
  title: '종목 선정',
};

/** 전략에 부합하는 종목이 5개 미만 일때 알림 */
export const LESS_THEN_FIVE_STOCKS_ALERT: AlertContentModel = {
  button: 'single',
  descriptions: [
    '전략에 부합하는 종목이 5개 미만입니다.',
    '전략을 다시 편집해 주세요.',
  ],
  title: '종목 선정',
};
