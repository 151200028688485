import { StrategyList } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import { LOADING_MESSAGES } from '@constants/feedback';
import { useReadymadeListData } from '@hooks/readymade/useReadymadeListData';
import { useStrategyListController } from '@hooks/readymade/useStrategyListController';
import { CountryCodeType } from '@models/common';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import { FC, useEffect, useState } from 'react';

interface StrategyListContainerProps {
  countryCode: CountryCodeType;
}

export const StrategyListContainer: FC<StrategyListContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const [strategySize, setStrategySize] = useState(20);
  const [loading, setLoading] = useState<boolean | undefined>(false);

  const { isFetching, strategyList, pageObj, isKbPick } = useReadymadeListData({
    size: strategySize,
  });
  const { category, pageStatus, periodTypes, themeTabIndex } =
    useStrategyListStore();
  const { openConfirm, closeConfirm } = useConfirmStore();

  const selectedPeriod = periodTypes[category]; // 전략 카드에 표시할 수익률 기간(1개월 | 3개월 | 6개월)

  const handleGetMoreList = () => {
    setStrategySize(strategySize + 20);
  };

  const { handleClickItemCurried, handleClickSelectCurried } =
    useStrategyListController({ countryCode });

  useEffect(() => {
    setStrategySize(20);
  }, [category, themeTabIndex]);

  // 최소 0.5초는 기다리고 로딩창을 끄기
  useEffect(() => {
    if (isFetching) {
      setLoading(true);
      openConfirm('loading')({
        descriptions: LOADING_MESSAGES.loadMyStrategy,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    if (!isFetching && !loading) {
      closeConfirm('loading');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isFetching]);

  return (
    <StrategyList
      category={category}
      status={pageStatus}
      strategyList={strategyList}
      pagination={pageObj}
      period={selectedPeriod}
      onClickItemCurried={handleClickItemCurried}
      onClickSelectCurried={handleClickSelectCurried}
      onClickMoreList={handleGetMoreList}
      isKbPick={isKbPick}
    />
  );
};
