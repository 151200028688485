const DARK_BACKGROUND_COLOR_PALETTE_DICTIONARY = {
  dimmed: 'rgba(1, 1, 1, 0.5)',
  table: '#191E24',
  table_highlight: 'rgba(254, 211, 56, 0.08)',
  box: '#222222',
  input_box: '#222222',
  general_button_2: 'transparent',
  general_button_2_pressed: 'transparent',
  error: '#211A1D',
} as const;

const DARK_BLACK_COLOR_PALETTE_DICTIONARY = {
  1: '#16191C',
  3: '#222222',
  7: '#333333',
  13: '#444444',
  40: '#666666',
  47: '#777777',
  60: '#999999',
  73: '#ACACAC',
  87: '#E0E0E0',
  100: '#ffffff',
} as const;

const DARK_BORDER_COLOR_PALETTE_DICTIONARY = {
  default: '#444444',
  button: '#555555',
  chip: '#444444',
  input: 'tranparent',
  select: 'tranparent',
  chartgrid: '#333333',
} as const;

const DARK_DIVIDER_COLOR_PALETTE_DICTIONARY = {
  appbar_tab: '#444444',
  title: '#333333',
  sub_filter: '#1F1F1F',
  list: '#222222',
  column: '#333333',
  contents: '#222222',
  tables: '#303030',
  benchmark: '#444444',
} as const;

const DARK_GRAY_COLOR_PALETTE_DICTIONARY = {
  blue: '#959CAB',
} as const;

const DARK_KB_COLOR_PALETTE_DICTIONARY = {
  yellow: '#FED338',
  yellow_pressed: '#FFCC00',
  gray: '#F1E7D9',
} as const;

const DARK_SECONDARY_COLOR_PALETTE_DICTIONARY = {
  default: '#4B76A4',
  bright: '#80ABDA',
  text: '#95BFED',
  chip: 'rgba(75, 118, 164, 0.1)',
} as const;

const DARK_STATE_COLOR_PALETTE_DICTIONARY = {
  red: '#D93B3B',
  blue: '#2E80D2',
  green: '#00B9A6',
  purple: '#9785FF',
  snackbar: 'rgba(34, 45, 74, 0.85)',
} as const;

export const DARK_COLOR_PALETTE = {
  background: DARK_BACKGROUND_COLOR_PALETTE_DICTIONARY,
  border: DARK_BORDER_COLOR_PALETTE_DICTIONARY,
  divider: DARK_DIVIDER_COLOR_PALETTE_DICTIONARY,
  secondary: DARK_SECONDARY_COLOR_PALETTE_DICTIONARY,
  black: DARK_BLACK_COLOR_PALETTE_DICTIONARY,
  gray: DARK_GRAY_COLOR_PALETTE_DICTIONARY,
  kb: DARK_KB_COLOR_PALETTE_DICTIONARY,
  state: DARK_STATE_COLOR_PALETTE_DICTIONARY,
  white: '#141414',
  fixedblack: '#222222',
  fixedwhite: '#FCFCFC',
} as const;
