import { HoldingListBox } from '@components/strategy-edit';
import { useHoldingListEvent } from '@hooks/edit/stock-selector/useHoldingListEvent';
import { useCountryCode } from '@stores/common/common.store';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';

import { FC } from 'react';

export const HoldingListContainer: FC = () => {
  const countryCode = useCountryCode();
  const { holdings, holdingsCnt } = useStrategyEditStore();
  const {
    handleClickWeightBtn,
    handleBlurWeight,
    handleFocusWeight,
    handleClickExclude,
    handleClickRefresh,
  } = useHoldingListEvent();

  return (
    <HoldingListBox
      countryCode={countryCode}
      holdings={holdings}
      holdingsCnt={holdingsCnt === 0 ? holdings.length : holdingsCnt}
      onClickExclude={handleClickExclude}
      onClickWeightMinus={handleClickWeightBtn('minus')}
      onClickWeightPlus={handleClickWeightBtn('plus')}
      onClickRefresh={handleClickRefresh}
      onBlurWeight={handleBlurWeight}
      onFocusWeight={handleFocusWeight}
    />
  );
};
