import { AlertContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { theme } from '@styles/theme';
import { Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { ConfirmTemplate } from './ConfirmTemplate';

interface AlertProps extends AlertContentModel, ContentWidthModel {}

const AlertWrap = styled(ConfirmTemplate)``;

export const Alert: FC<AlertProps> = ({
  button = 'double',
  contentWidth,
  description,
  descriptions = [],
  bottomContent,
  title,
  onOk,
}) => {
  return (
    <AlertWrap
      type="alert"
      button={button}
      contentWidth={contentWidth}
      title={title}
      onOk={onOk}
      zIndex={theme.zIndex.alert}
    >
      <>
        <Typography
          variant="body1_content"
          color="black.60"
          wordBreak="break-all"
        >
          {description}
        </Typography>
        {descriptions.map((_description, index) => (
          <Typography
            key={`alert_desc_${index}`}
            variant="body1_content"
            color="black.60"
            wordBreak="break-all"
            style={{ lineBreak: 'anywhere' }}
          >
            {_description}
          </Typography>
        ))}
        {bottomContent}
      </>
    </AlertWrap>
  );
};
