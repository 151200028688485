import { Label } from '@ui/components';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface ToggleButtonProps {
  checked?: boolean;
  disabled?: boolean;
  initChecked?: boolean;
  onChange?: (checked: boolean) => void;
  onClickDisabled?: () => void;
}

const DollarLabel = styled(Label)<{ disabled: boolean }>`
  .slider:before {
    background-image: url('/icon/dollar-white.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  input:checked + .slider:before {
    background-image: url('/icon/dollar-gray.svg');
  }
`;

export const DollarToggleButton: FC<ToggleButtonProps> = ({
  disabled = false,
  initChecked = false,
  onChange,
  onClickDisabled,
}) => {
  const [_checked, setChecked] = useState(initChecked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (disabled) {
      if (onClickDisabled) onClickDisabled();
      return;
    }
    setChecked((v) => !v);
    if (onChange) onChange(e.target.checked);
  };

  useEffect(() => {
    setChecked(initChecked);
  }, [initChecked]);

  return (
    <DollarLabel disabled={disabled}>
      <input type="checkbox" checked={_checked} onChange={handleChange} />
      <span className="slider" />
    </DollarLabel>
  );
};
