/**
 * 숫자를 넘겨 받아 Percent로 변환하는 함수
 * 소숫점 2자리 반환 한다.
 *
 * @param {number} num - Percent로 변환할 숫자
 * @returns {string} 소숫점 2자리 숫자형태를 지니는 문자열
 *
 * @example
 *
 * numberToPercent(0.8212) => "82.12"
 * numberToPercent(0.0085) => "0.85"
 *
 * @group Utils
 */
export const numberToPercent = (num: number) => {
  return (Math.round(num * 10000) / 100).toFixed(2); // NOTE: 반올림 처리됨
};

/**
 * 숫자를 넘겨 받아 소숫점 2자리 까지 잘라서 반환하는 함수
 * 0 또한 포함하는 String으로 반환한다.
 *
 * @param {number} num - 소숫점 정리가 필요한 수
 * @param {number} limit - (Optional) 소숫점 자리수
 * @returns {string} num을 소숫점 limit 까지 잘린 문자열
 *
 * @example
 * numberFixedTwo(12) => 12.00
 * numberToPercent(0.85421) => 0.85
 *
 * @group Utils
 */
export const numberFixedWithLimit = (num: number, limit: number = 2) => {
  return num.toFixed(limit);
};

/**
 * 숫자 또는 수처럼 보이는 문자를 넘겨받아서
 * 세자리 수마다 끊어서 콤마 찍기
 *
 * @param {(string | number)} x - 콤마 찍을 숫자(문자열)
 * @returns {string} 콤마가 찍힌 숫자형태의 문자열
 *
 * @example
 * numberWithCommas(10000) => "10,000"
 * numberWithCommas("10000") => "10,000"
 *
 * @group Utils
 */
export function numberWithCommas(x: number | string) {
  let onlyNumber = x.toString().replace(/,/gi, '');
  let parts = onlyNumber.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

/**
 * 콤마가 찍혀있는 숫자형태의 문자열의 콤마 제거한다.
 *
 * @param {string} x - 콤마가 찍힌 숫자형태의 문자열
 * @returns {string} 콤마가 제거된 문자열
 * @method Number.unComma
 *
 * @example
 * unComma("10,000") => "10000"
 *
 * @group Utils
 */
export function unComma(x: string) {
  let parts = x.toString().split('.');
  if (typeof x === 'string') {
    if (parts[0].includes(',')) {
      parts[0] = parts[0].toString().replace(/[^\d]+/g, '');
    }
  }
  return parts.join('.');
}

/**
 * 1만원 단위로 치환된 콤마가 찍힌 숫자형태의 문자열을 반환한다.
 * 원 표시 유무를 선택할 수 있다.
 *
 * @param x - 숫자
 * @param printUnit - 원 표시
 * @returns 1만원 단위로 치환된 콤마가 찍힌 숫자형태의 문자열
 *
 * @example
 * replaceNumberByTenThousandUnit(10000000) => "1,000만원"
 * replaceNumberByTenThousandUnit(1000000000) => "10억원"
 *
 * @group Utils
 */
export function replaceNumberByTenThousandUnit(
  x: number,
  printUnit: boolean = true
) {
  let replacement = 0;
  let unit = '만원';
  if (x >= 10000) {
    replacement = Math.floor(x / 10000);
    if (x >= 100000000) {
      unit = '억원';
      replacement = +(replacement / 10000).toFixed(4);
    }
  }
  return `${numberWithCommas(replacement)}${printUnit ? unit : ''}`;
}

/**
 * 자릿수를 넘거받아 난수를 생성한다.
 * @param {number} n - 자릿수
 * @returns
 *
 * @group Utils
 */
export function generateRandomCode(n: number) {
  let str = '';
  for (let i = 0; i < n; i++) {
    str += Math.floor(Math.random() * 10);
  }
  return str;
}

/**
 * 타깃 넘버가 옵션 범위에 포함되는지의 여부를 확인한다.
 * @param x - 타깃 넘버
 * @param option - 옵션(최솟값, 최댓값)
 * @returns {boolean}
 *
 * @example
 * numberInRange(1000, { min: 100, max: 10000}) => true
 *
 * @group Utils
 */
export const numberInRange = (
  x: number,
  option: { min: number; max: number }
) => {
  if (isNaN(x)) return false;
  if (x < option.min || x > option.max) return false;
  return true;
};
