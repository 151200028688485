import { initChart } from '@constants/common';
import mock from '@mocks/risk-assessment-index.json';
import { StrategyStyleModel } from '@models/strategy';
import { StrategyDetailStoreState } from './readymade-strategy.store';

export const initStrategyStyle: StrategyStyleModel = {
  baseDate: '',
  chartData: { ...initChart },
};

export const createInitReadymadeStrategyState =
  (): StrategyDetailStoreState => ({
    category: 'THEME' as const,
    detailedStrategyInfo: {
      description: '',
      name: '',
    },
    masterFactors: [],
    benchmarkOption: undefined,
    benchmarkOptions: [],
    strategyStyle: { ...initStrategyStyle },
    cumulativeReturn: { ...initChart },
    periodOption: 'ALL' as const,
    riskAssessmentIndex: { ...mock },
    exposure: { ...initChart },
    holdingPosition: { ...initChart },
    rebalancingHistory: { ...initChart, legendList: [], rebalancing: '' },
    masterInfo: {
      id: '',
      desc: '',
      shortDesc: '',
      name: '',
      factors: [],
      src: [],
      img: '',
    },
  });
