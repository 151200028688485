import { MIN_WIDTH } from '@constants/layout.const';
import { create } from 'zustand';

interface LayoutState {
  /** 레이아웃 width */
  contentWidth: number;
}

interface LayoutAction {
  /**
   * 클라이언트 width를 넘겨받아 contentWidth 설정
   * @param contentWidth 현재 클라이언트 width
   * @returns
   */
  setContentWidth: (contentWidth: number) => void;
}

/**
 * 레이아웃 store
 */
export const useLayoutStore = create<LayoutState & LayoutAction>(
  (set, get) => ({
    contentWidth: MIN_WIDTH,
    setContentWidth: (_contentWidth) => {
      let contentWidth = _contentWidth;
      if (contentWidth > 430)
        contentWidth = 430; // TODO: KB_WIDTH CONST 등으로 const화
      else if (contentWidth < MIN_WIDTH) contentWidth = MIN_WIDTH;
      set(() => ({ contentWidth }));
    },
  })
);
