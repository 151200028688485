/* eslint-disable react-hooks/exhaustive-deps */
import { LineChart, SelectButton } from '@components/common';
import {
  DetailedStrategyInfo,
  HoldingPositionInfo,
  RebalancingHistory,
  RiskAssessmentIndex,
  SectionTemplate,
  SectorIndustryComp,
  StrategyStyleInfo,
} from '@components/strategy';
import {
  COUNTRY_MAPPER,
  ORGANIZING_STOCKS_TOOLTIP_DETAIL,
  REBALANCING_HISTORY_TOOLTIP,
  REVENUE_ANALYTICS_TOOLTIP,
  SECTOR_INDUSTRY_TOOLTIP,
  STRATEGY_STYLE_TOOLTIP,
} from '@constants/common';
import { EXCEEDED_STRATEGY_ALERT_CONTENT } from '@constants/feedback';
import { REBALANCING_OPTION_LOCALE } from '@constants/strategy';
import { useProfitSelector } from '@hooks/useProfitSelector';
import { useRouterMove } from '@hooks/useRouterMove';
import { CountryCodeType } from '@models/common';
import { useAuthStore } from '@stores/auth';
import { useBottomsheetStore, useConfirmStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { FlexBox } from '@ui/components';
import { check_from_mable_menu, move_back_menu } from '@utils/bridge';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutContainer } from '../layout/LayoutContainer';

interface ReadymadeDetailContainerProps {
  countryCode?: CountryCodeType;
}

export const ReadymadeDetailContainer: FC<ReadymadeDetailContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const { moveToReadyMades, moveToUSReadyMades } = useRouterMove();
  const { strategyId = '' } = useParams<string>();
  const {
    category,
    detailedStrategyInfo,
    strategyStyle,
    benchmarkOption,
    benchmarkOptions,
    cumulativeReturn,
    periodOption,
    riskAssessmentIndex,
    exposure,
    holdingPosition,
    rebalancingHistory,
    initState,
    changeBenchmarkOption,
    changePeriodOption,
    fetchInitData,
  } = useReadymadeStrategyStore();
  const { isStrategyCreationPossible, isStrategyCreationPossibleUs, fetchMe } =
    useAuthStore();
  const {
    bottomsheet,
    isBottomsheetTypeBenchmark,
    selectedBenchmark,
    selectedPeriod,
    handleSelect,
  } = useProfitSelector({
    strategyId,
    benchmarkOption,
    benchmarkOptions,
    periodOption,
    changeBenchmarkOption,
    changePeriodOption,
  });
  const themeTabIndex = useStrategyListStore((state) => state.themeTabIndex);
  const isKbPick = category === 'THEME' && themeTabIndex === 0; // KB's Pick 탭의 전략 카드로부터 접근했는지 여부

  const { openBottomsheet } = useBottomsheetStore();
  const { openConfirm } = useConfirmStore();

  useEffect(() => {
    fetchMe();
    fetchInitData(strategyId);
    return () => {
      initState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** '전략 선택' 버튼 클릭 했을 때 실행되는 함수 */
  const handleClickStrategySelect = () => {
    // 전략 생성이 가능하면 전략 편집 바텀시트 띄우기
    if (
      (countryCode === COUNTRY_MAPPER.DOMESTIC && isStrategyCreationPossible) ||
      (countryCode === COUNTRY_MAPPER.USA && isStrategyCreationPossibleUs)
    ) {
      openBottomsheet('edit')({ strategyId, category });
      return;
    }
    openConfirm('alert')({
      ...EXCEEDED_STRATEGY_ALERT_CONTENT,
    });
  };

  const handleClickPrevBtn = () => {
    if (check_from_mable_menu()) {
      move_back_menu();
      return;
    }

    if (countryCode === COUNTRY_MAPPER.USA) {
      moveToUSReadyMades();
    } else {
      moveToReadyMades();
    }
  };

  return (
    <LayoutContainer
      floater={{
        variant: 'double',
        okText: '전략 선택',
        cancelText: '이전',
        onClickCancel: handleClickPrevBtn,
        onClickOk: handleClickStrategySelect,
      }}
      onClickPrev={handleClickPrevBtn}
      title={detailedStrategyInfo.name}
      extended
    >
      <SectionTemplate variant="custom">
        <DetailedStrategyInfo
          {...detailedStrategyInfo}
          description={
            isKbPick
              ? detailedStrategyInfo.kbPickDescription ||
                detailedStrategyInfo.description
              : detailedStrategyInfo.description
          }
        />
      </SectionTemplate>
      <SectionTemplate
        description={strategyStyle.baseDate}
        minHeight={415}
        infoDescription={STRATEGY_STYLE_TOOLTIP}
        title="전략스타일 분석"
      >
        <StrategyStyleInfo strategyStyle={strategyStyle} />
      </SectionTemplate>

      <SectionTemplate
        description={cumulativeReturn.baseDate}
        infoDescription={REVENUE_ANALYTICS_TOOLTIP}
        minHeight={716}
        title="수익 분석"
      >
        <FlexBox direction="column" spacing={32}>
          <FlexBox spacing={8}>
            <SelectButton
              variant="white"
              open={bottomsheet && isBottomsheetTypeBenchmark}
              placeholder="벤치마크"
              selectedValue={selectedBenchmark}
              onClick={handleSelect('benchmark')}
            />
            <SelectButton
              variant="white"
              open={bottomsheet && !isBottomsheetTypeBenchmark}
              placeholder="조회기간"
              selectedValue={selectedPeriod}
              onClick={handleSelect('period')}
            />
          </FlexBox>
          <FlexBox direction="column" height={284}>
            {cumulativeReturn.data.length > 0 && (
              <LineChart data={cumulativeReturn} />
            )}
          </FlexBox>
          {riskAssessmentIndex && (
            <RiskAssessmentIndex {...riskAssessmentIndex} />
          )}
        </FlexBox>
      </SectionTemplate>

      <SectionTemplate
        description={exposure.baseDate}
        minHeight={294}
        title="섹터/업종 구성"
        infoDescription={SECTOR_INDUSTRY_TOOLTIP}
      >
        <SectorIndustryComp variant="single" data={exposure.data} />
      </SectionTemplate>

      <SectionTemplate
        description={holdingPosition.baseDate}
        minHeight={364}
        title="종목구성(Top5)"
        infoDescription={ORGANIZING_STOCKS_TOOLTIP_DETAIL}
      >
        <HoldingPositionInfo stockList={holdingPosition.data} />
      </SectionTemplate>

      <SectionTemplate
        description={rebalancingHistory.baseDate}
        minHeight={620}
        subDescription={rebalancingHistory.rebalancing}
        title="리밸런싱 내역"
        infoDescription={REBALANCING_HISTORY_TOOLTIP}
      >
        <RebalancingHistory
          barChartData={rebalancingHistory.data}
          disableChart={
            rebalancingHistory.rebalancing === REBALANCING_OPTION_LOCALE.NONE
          }
        />
      </SectionTemplate>
    </LayoutContainer>
  );
};
