import {
  MY_STRATEGY_ORDER_OPTIONS,
  STRATEGY_ORDER_OPTIONS,
} from '@constants/common';
import { OPTIMIZATION_MAPPER } from '@constants/strategy';
import {
  BacktestDateBottomsheetContentModel,
  BooksizeBottomsheetContentModel,
  BottomSheetContentType,
  BottomsheetType,
  EditBottomsheetContentModel,
  FactorInfoBottomsheetContentModel,
  InfoBottomsheetContentModel,
  MyStrategyOptionBottomsheetContentModel,
  MyStrategyOrderBottomsheetContentModel,
  OptimizationBottomsheetContentModel,
  OptionSelectionBottomsheetContentModel,
  RebalancingBottomsheetContentModel,
  StockListEmptyBottomsheetContentModel,
  StrategyOrderBottomsheetContentModel,
  ThemeKeywordBottomsheetContentModel,
} from '@models/feedback';
import { create } from 'zustand';

interface BottomsheetState {
  /** Info(i) 버튼 */
  info: boolean;
  infoContent: InfoBottomsheetContentModel;
  /** 팩터(i) 버튼 */
  factorInfo: boolean;
  factorInfoContent: FactorInfoBottomsheetContentModel;
  /** 옵션선택 */
  optionSelection: boolean;
  optionSelectionContent: OptionSelectionBottomsheetContentModel;
  /** 전략선택 - 정렬 */
  strategyOrder: boolean;
  strategyOrderContent: StrategyOrderBottomsheetContentModel;
  /** 전략상세 - 수익분석 */
  profitSelector: boolean;
  /** 전략목록/상세 - 편집 방법 선택 */
  edit: boolean;
  editContent: EditBottomsheetContentModel;
  /** 전략편집 - 투자 금액 */
  booksize: boolean;
  booksizeContent: BooksizeBottomsheetContentModel;
  /** 전략편집 - 최적화 옵션 */
  optimization: boolean;
  optimizationContent: OptimizationBottomsheetContentModel;
  /** 전략편집 - 리밸런싱 주기 */
  rebalancing: boolean;
  rebalancingContent: RebalancingBottomsheetContentModel;
  /** 전략편집 - 백테스트 시작일 */
  backtestDate: boolean;
  backtestDateContent: BacktestDateBottomsheetContentModel;
  /** 전략보관함 - 정렬 */
  myStrategyOrder: boolean;
  myStrategyOrderContent: MyStrategyOrderBottomsheetContentModel;
  /** 전략보관함 - 보관함 옵션 */
  myStrategyOption: boolean;
  myStrategyOptionContent: MyStrategyOptionBottomsheetContentModel;
  /** 전략선택 - 테마 그룹 선택 */
  themeGroups: boolean;
  themeGroupsContent: ThemeKeywordBottomsheetContentModel;
  /** 투자시작하기 - 모의투자 | 백테스트 */
  stockListEmpty: boolean;
  stockListEmptyContent: StockListEmptyBottomsheetContentModel;

  closeBottomsheet: (variant: BottomsheetType) => void;
  openBottomsheet: <T extends BottomsheetType>(
    variant: T
  ) => (content: BottomSheetContentType<T>) => void;
}

export const useBottomsheetStore = create<BottomsheetState>((set) => ({
  info: false,
  infoContent: {
    title: '',
  },
  factorInfo: false,
  factorInfoContent: {
    title: '',
  },
  optionSelection: false,
  optionSelectionContent: {},
  strategyOrder: false,
  strategyOrderContent: {
    options: STRATEGY_ORDER_OPTIONS,
  },
  myStrategyOrder: false,
  myStrategyOrderContent: {
    options: MY_STRATEGY_ORDER_OPTIONS,
  },
  profitSelector: false,
  edit: false,
  editContent: {
    strategyId: '',
    category: 'THEME',
  },
  booksize: false,
  booksizeContent: {
    booksize: 500,
  },
  optimization: false,
  optimizationContent: {
    optimization: OPTIMIZATION_MAPPER.MARKET_WEIGHT,
  },
  rebalancing: false,
  rebalancingContent: {},
  backtestDate: false,
  backtestDateContent: {},
  myStrategyOption: false,
  myStrategyOptionContent: {},
  themeGroups: false,
  themeGroupsContent: {},
  stockListEmpty: false,
  stockListEmptyContent: {
    type: 'stockEmpty',
  },
  closeBottomsheet: (variant) => {
    set(() => ({ [variant]: false }));
  },
  openBottomsheet: (variant) => (content) => {
    set(() => ({
      [variant]: true,
      [`${variant}Content`]: content,
    }));
  },
}));
