import { BaseStyledTable } from '@ui/base';
import { ThemedTableProps, UIHTMLComponentProps } from '@ui/model';
import { ComponentType, createContext, forwardRef } from 'react';

interface TableProps
  extends UIHTMLComponentProps<HTMLTableElement>,
    ThemedTableProps {}

export interface TableContextDto {
  isHead: boolean;
}

export const TableContext = createContext<TableContextDto | null>(null);

export const Table: ComponentType<TableProps> = forwardRef<
  HTMLTableElement,
  TableProps
>(({ width, variant, ...props }, ref) => {
  return (
    <BaseStyledTable
      {...props}
      ref={ref as never}
      $width={width}
      $isScrollActive={variant === 'scroll'}
    />
  );
});

Table.displayName = 'Table';
Table.defaultProps = {
  width: '100%',
};
