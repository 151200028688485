import { COUNTRY_MAPPER } from '@constants/common';
import { NOT_AVAILABLE_VIEW_FORWARD_TEST } from '@constants/feedback';
import { useMyStrategyResultController } from '@hooks/my-strategy/useMyStrategyResultController';
import { useMyStrategyLoader } from '@hooks/useMyStrategyLoader';
import { useRouterMove } from '@hooks/useRouterMove';
import { CountryCodeType } from '@models/common';
import { useConfirmStore } from '@stores/feedback';
import { FlexBox } from '@ui/components/Flexbox';
import { SwitchButton } from '@ui/components/button/SwitchButton';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LayoutContainer } from '../layout/LayoutContainer';
import { StrategyDetailTabContainer } from './StrategyDetailTabContainer';
import { TestResultTabContainer } from './TestResultTabContainer';

interface BackTestPageContainerProps {
  countryCode?: CountryCodeType;
}

export const BackTestPageContainer: FC<BackTestPageContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const [searchParams] = useSearchParams();
  const ci = new URLSearchParams(searchParams).get('u') || '';
  const { moveToForwardTest, moveToUsForwardTest } = useRouterMove();
  const {
    okText,
    apiStatus,
    enableContract,
    strategyId,
    encryptedId,
    portfolioId,
    title,
    isForwardAvailable,
  } = useMyStrategyLoader('BACKTEST');

  const { openConfirm } = useConfirmStore();
  const [tabIndex, setTabIndex] = useState(0);
  const handleSwitchButton = (index: number) => () => {
    setTabIndex(index);
  };

  const { handleClickOk, handlePrev } = useMyStrategyResultController({
    apiStatus,
    enableContract,
    countryCode,
    strategyId,
    encryptedId,
    portfolioId,
  });

  const handleClickPrev = () => {
    handlePrev(ci);
  };

  return (
    <LayoutContainer
      extended
      tagColor="orange"
      tagName="백테스트 결과"
      title={title}
      floater={{
        variant: 'double',
        okText,
        cancelText: '모의투자 보기',
        onClickOk: handleClickOk,
        onClickCancel() {
          if (isForwardAvailable) {
            if (countryCode === COUNTRY_MAPPER.USA) {
              moveToUsForwardTest(strategyId);
            } else {
              moveToForwardTest(strategyId);
            }
            return;
          }
          openConfirm('alert')({
            ...NOT_AVAILABLE_VIEW_FORWARD_TEST,
          });
        },
      }}
      onClickPrev={handleClickPrev}
    >
      <FlexBox mb={24}>
        <SwitchButton
          variant="full"
          buttonList={[
            {
              index: 0,
              name: '백테스트 결과',
              onClickBtn: handleSwitchButton(0),
            },
            {
              index: 1,
              name: '전략상세',
              onClickBtn: handleSwitchButton(1),
            },
          ]}
          activeIndex={tabIndex}
        />
      </FlexBox>
      {tabIndex === 0 && <TestResultTabContainer strategyId={strategyId} />}
      {tabIndex === 1 && <StrategyDetailTabContainer />}
    </LayoutContainer>
  );
};
