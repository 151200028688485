import {
  GetHoldingsQuery,
  GetMyHoldingsQuery,
  GetMyVolumePositionQuery,
  GetVolumePositionQuery,
  SearchHoldingsQuery,
} from '@graphql/generates';
import { HoldingModel, HoldingsConvertParamModel } from '@models/strategy';
import { filterNonDataOfArray } from '@utils/common';
import { createHoldingObj } from './stock-selector.helper';

export const convertHoldings = (
  param: HoldingsConvertParamModel<GetHoldingsQuery>
) => {
  const { data, origin } = param;
  const { holdings } = data;
  return createHoldingObj(holdings?.holdings, origin);
};

export const convertVolumePositions = (
  param: HoldingsConvertParamModel<GetVolumePositionQuery>
) => {
  const { data, origin, whitelistObj } = param;
  const { volumePosition } = data;
  return createHoldingObj(volumePosition?.holdings, origin, whitelistObj);
};

export const convertMyHoldings = (
  param: HoldingsConvertParamModel<GetMyHoldingsQuery>
) => {
  const { data, origin, whitelistObj } = param;
  const { myHoldings } = data;
  return createHoldingObj(myHoldings?.myHoldings, origin, whitelistObj);
};

export const convertMyVolumePositions = (
  param: HoldingsConvertParamModel<GetMyVolumePositionQuery>
) => {
  const { data, origin, whitelistObj } = param;
  const { myVolumePosition } = data;
  return createHoldingObj(myVolumePosition?.myHoldings, origin, whitelistObj);
};

export const convertSearchHoldings = (data: SearchHoldingsQuery) => {
  const info = data.searchHoldings?.searchHoldings;

  if (!info || !info.holdings) return;

  if (info.holdings.length === 0) return;

  const holdings: HoldingModel[] = filterNonDataOfArray(info.holdings).map(
    (item) => {
      return {
        ccid: item.ccid,
        name: item.name,
        code: item.code,
        synonym: item.synonym || '',
      };
    }
  );

  return holdings;
};
