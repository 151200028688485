import { useCategoryController } from '@hooks/readymade/useCategoryController';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { CategorySectorContainer } from './CategorySectorContainer';
import { CategoryThemeContainer } from './CategoryThemeContainer';

interface StrategyListTabContainerProps {
  isScrollingDown?: boolean;
  folded?: boolean;
}

const StrategyListTabContainerWrap = styled.div<StrategyListTabContainerProps>`
  background-color: ${({ theme }) => theme.colorPalette.white};
  padding-top: 28px;
  padding-bottom: 8px;
  transition: all 0.5s ease;

  ${({ isScrollingDown, folded }) => {
    if (isScrollingDown || folded) {
      return css`
        margin-top: -500px;
      `;
    }
  }}
`;

export const StrategyListTabContainer: FC<StrategyListTabContainerProps> = (
  props
) => {
  const { category, createSearchCondition } = useCategoryController();

  if (category === 'SECTOR') {
    return (
      <StrategyListTabContainerWrap {...props}>
        <CategorySectorContainer
          createSearchCondition={createSearchCondition}
        />
      </StrategyListTabContainerWrap>
    );
  }

  if (category === 'THEME') {
    return (
      <StrategyListTabContainerWrap {...props}>
        <CategoryThemeContainer createSearchCondition={createSearchCondition} />
      </StrategyListTabContainerWrap>
    );
  }

  return (
    <StrategyListTabContainerWrap {...props}>
      {createSearchCondition(category)}
    </StrategyListTabContainerWrap>
  );
};
