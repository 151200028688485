import { COUNTRY_MAPPER } from '@constants/common';
import { SimpleEditPageContainer } from '@containers/strategy-edit/SimpleEditPageContainer';
import { FC } from 'react';

interface StrategySimpleEditPageProps {
  reEdit?: boolean;
}

export const UsStrategySimpleEditPage: FC<StrategySimpleEditPageProps> = ({
  reEdit = false,
}) => {
  return (
    <SimpleEditPageContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />
  );
};
