import { COUNTRY_MAPPER } from '@constants/common';
import { CountryCodeType } from '@models/common';
import { HoldingModel } from '@models/strategy';
import { FlexBox, Typography } from '@ui/components';
import { ThemeColorType } from '@ui/model';
import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

interface StockSearchItemProps {
  countryCode: CountryCodeType;
  item: HoldingModel;
  keyword: string;
  onClickItem: (ccid: string) => MouseEventHandler<HTMLLIElement>;
}

interface SpanProps {
  children: React.ReactNode;
  color?: ThemeColorType;
}

const Item = styled.li`
  display: flex;
  padding: 10px 0;

  &:active {
    background-color: ${({ theme }) => theme.colorPalette.black[3]};
  }
`;

const UsItem = styled(Item)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const IncNameBox = styled.div`
  & > p {
    width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
  }
`;

const Span: FC<SpanProps> = ({ children, color }) => {
  return (
    <Typography
      color={color || 'black.87'}
      variant="caption_medium"
      whiteSpace="pre"
    >
      {children}
    </Typography>
  );
};

export const StockSearchItem: FC<StockSearchItemProps> = ({
  countryCode,
  item,
  keyword,
  onClickItem,
}) => {
  const name = item.name.toUpperCase();
  const index = name.indexOf(keyword.toUpperCase());

  if (countryCode === COUNTRY_MAPPER.USA) {
    if (index >= 0) {
      const front = item.name.slice(0, index);
      const highlight = item.name.slice(index, index + keyword.length);
      const back = item.name.slice(index + keyword.length);

      return (
        <UsItem key={item.ccid} role="button" onClick={onClickItem(item.ccid)}>
          <FlexBox direction="column">
            <Typography variant="caption_medium" color="state.purple">
              {item.code}
            </Typography>
            <FlexBox>
              {front && <Span>{front}</Span>}
              <Span color="secondary.text">{highlight}</Span>
              {back && <Span>{back}</Span>}
            </FlexBox>
          </FlexBox>
          <IncNameBox>
            <Typography>{item.synonym}</Typography>
          </IncNameBox>
        </UsItem>
      );
    }

    return (
      <UsItem key={item.ccid} role="button" onClick={onClickItem(item.ccid)}>
        <FlexBox direction="column">
          <Typography variant="caption_medium" color="state.purple">
            {item.code}
          </Typography>
          <Span>{item.name}</Span>
        </FlexBox>
        <IncNameBox>
          <Typography>{item.synonym}</Typography>
        </IncNameBox>
      </UsItem>
    );
  } else {
    if (index >= 0) {
      const front = item.name.slice(0, index);
      const highlight = item.name.slice(index, index + keyword.length);
      const back = item.name.slice(index + keyword.length);
      return (
        <Item key={item.ccid} role="button" onClick={onClickItem(item.ccid)}>
          {front && <Span>{front}</Span>}
          <Span color="secondary.text">{highlight}</Span>
          {back && <Span>{back}</Span>}
        </Item>
      );
    }
    return (
      <Item key={item.ccid} role="button" onClick={onClickItem(item.ccid)}>
        <Span>{item.name}</Span>
      </Item>
    );
  }
};
