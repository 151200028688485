import { INTERVAL_MAPPER, SIMULATION_TYPE_MAPPER } from '@constants/strategy';
import {
  GetDetailedMyStrategyQueryVariables,
  GetMyCumulativeReturnQueryVariables,
  GetMyExposureQueryVariables,
  GetMyHoldingPositionAllQueryVariables,
  GetMyRebalancingHistoryQueryVariables,
  GetMyRiskAssessmentIndexQueryVariables,
  GetMyStrategyStyleQueryVariables,
  InvestmentStrategyLevelEnum,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import {
  ProfitChartModel,
  RebalancingHistoryModel,
  SelectOptionDto,
} from '@models/common';
import {
  ExposureModel,
  HoldingPositionModel,
  RiskAssessmentIndexModel,
  StrategyCategoryType,
  StrategyStyleModel,
  UniversePresetType,
  UniverseType,
} from '@models/strategy';
import {
  DetailedMyStrategyInfoModel,
  MyStrategyBasicInfoModel,
  SimulationType,
} from '@models/strategy/my-strategy.model';
import { create } from 'zustand';
import {
  convertDetailedMyStrategy,
  convertMyCumulativeReturn,
  convertMyExposure,
  convertMyHoldingPositionAll,
  convertMyRiskAssessmentIndex,
  convertMyStrategy,
  convertMyStrategyBlacklist,
  convertMyStrategyByPortfolioId,
  convertMyStrategyStyle,
  convertRebalancingHistory,
} from './my-strategy.convert';
import { createMyStrategyInfo } from './my-strategy.helper';
import { createInitMyStrategyState } from './my-strategy.init';

interface MyStrategyState {
  /**
   * 내전략 포트폴리오 ID.
   * 앱에서 내전략 상세페이지로 넘어올 때,
   * 포트폴리오ID를 통해 내전략을 조회한다.
   * 이후 앱 관련 로직에 활용된다.
   * */
  portfolioId?: string;
  /** 내전략 이름 */
  name: string;
  /** 내전략 카테고리 */
  category?: StrategyCategoryType;
  /** 내전략 등록일 */
  createdAt: number;
  /** 내전략 간편전략 여부 */
  isSimple: boolean;
  /** 내전략 자문계약 여부 */
  isActiveKbContract: boolean;
  /** 내전략 모의투자 열람가능 여부 */
  isForwardAvailable: boolean;
  /** 내전략 전략상세정보 */
  detailedStrategyInfo: DetailedMyStrategyInfoModel;
  /** 내전략 스타일차트 정보 */
  strategyStyle: StrategyStyleModel;
  /** 선택된 벤치마크 옵션 */
  benchmarkOption?: UniverseType;
  /** 벤치마크 셀렉트박스 옵션 리스트 */
  benchmarkOptions: SelectOptionDto<UniverseType>[];
  /** 누적수익률 정보 */
  cumulativeReturn: ProfitChartModel;
  /** 위험평가지표 정보 */
  riskAssessmentIndex: RiskAssessmentIndexModel;
  /** 섹터/업종 정보 */
  exposure: ExposureModel;
  /** 종목구성 정보 */
  holdingPosition: HoldingPositionModel;
  /** 리밸런싱 내역 정보 */
  rebalancingHistory: RebalancingHistoryModel;
  /** 내전략 유니버스 */
  universePreset: UniversePresetType | undefined;

  /** 내전략 > 백테스트 결과 > 전략상세 탭 */
  /** 내전략 기본정보 */
  myStrategyBasicInfo: MyStrategyBasicInfoModel;
  /** 내전략 투자기준 정보 */
  mySimulationOptionInfo: string[][];
  /** 내전략 제외종목 정보 */
  myStrategyBlacklistInfo: string[];

  investmentStrategyLevel: InvestmentStrategyLevelEnum;

  init: () => void;
  changeBenchmarkOption: (
    benchmarkOption: UniverseType,
    myStrategyId: string,
    simulationType?: SimulationType
  ) => {};
  fetchMyStrategy: (
    myStrategyId: string
  ) => Promise<{ id: string; category: StrategyCategoryType } | undefined>;
  fetchDetailedMyStrategy: (
    queryObj: GetDetailedMyStrategyQueryVariables,
    isDiy: boolean
  ) => {};
  fetchMyStrategyStyle: (
    queryObj: GetMyStrategyStyleQueryVariables,
    category: StrategyCategoryType
  ) => {};
  fetchMyStrategyBlacklist: (
    myStrategyId: string,
    save?: boolean
  ) => Promise<string[] | undefined>;
  fetchMyCumulativeReturn: (
    queryObj: GetMyCumulativeReturnQueryVariables
  ) => {};
  fetchMyRiskAssessmentIndex: (
    queryObj: GetMyRiskAssessmentIndexQueryVariables,
    isDiy: boolean
  ) => {};
  fetchRebalancingHistory: (
    queryObj: GetMyRebalancingHistoryQueryVariables
  ) => void;
  fetchHoldingPositionAll: (
    queryObj: GetMyHoldingPositionAllQueryVariables
  ) => void;
  fetchExposure: (queryObj: GetMyExposureQueryVariables) => void;
}

export const useMyStrategyStore = create<MyStrategyState>((set, get) => ({
  ...createInitMyStrategyState(),
  init: () => {
    set(() => ({
      ...createInitMyStrategyState(),
    }));
  },
  changeBenchmarkOption: async (
    benchmarkOption,
    myStrategyId,
    simulationType = SIMULATION_TYPE_MAPPER.ALL
  ) => {
    set(() => ({ benchmarkOption }));
    const { category, fetchMyCumulativeReturn, fetchMyRiskAssessmentIndex } =
      get();
    await Promise.all([
      fetchMyCumulativeReturn({
        benchmark: benchmarkOption,
        interval: INTERVAL_MAPPER.DAY,
        myStrategyId,
        simulationType,
      }),
      fetchMyRiskAssessmentIndex(
        {
          benchmark: benchmarkOption,
          myStrategyId,
          simulationType,
        },
        category === 'DIY' || category === 'MASTER'
      ),
    ]);
  },
  fetchMyStrategy: async (myStrategyId) => {
    let myStrategy: ReturnType<typeof createMyStrategyInfo> | undefined;
    if (+myStrategyId > 0) {
      const response = await repo.getMyStrategyByPortfolioId({
        portfolioId: myStrategyId,
      });
      myStrategy = convertMyStrategyByPortfolioId(response);
    } else {
      const response = await repo.getMyStrategy({ id: myStrategyId });
      myStrategy = convertMyStrategy(response);
    }
    if (myStrategy) {
      const my = myStrategy;
      set(() => ({
        ...my.myStrategyInfo,
      }));
      return {
        id: myStrategy.myStrategyInfo.id,
        category: myStrategy.myStrategyInfo.category,
      };
    }
  },
  fetchDetailedMyStrategy: async (queryObj, isDiy) => {
    const response = await repo.getDetailedMyStrategy(queryObj);
    const detailedInfo = convertDetailedMyStrategy(response);
    if (detailedInfo) {
      set(() => ({ ...detailedInfo }));
      const { fetchMyCumulativeReturn, fetchMyRiskAssessmentIndex } = get();
      const { benchmarkOption: benchmark } = detailedInfo;
      const { id: myStrategyId, simulationType } = queryObj;
      await Promise.all([
        fetchMyCumulativeReturn({
          benchmark,
          interval: INTERVAL_MAPPER.DAY,
          myStrategyId,
          simulationType,
        }),
        fetchMyRiskAssessmentIndex(
          {
            benchmark,
            myStrategyId,
            simulationType,
          },
          isDiy
        ),
      ]);
    }
  },
  fetchMyStrategyStyle: async (queryObj, category) => {
    const response = await repo.getMyStrategyStyle(queryObj);
    const strategyStyle = convertMyStrategyStyle(response, category);
    if (strategyStyle) {
      set(() => ({
        strategyStyle,
      }));
    }
  },
  fetchMyStrategyBlacklist: async (myStrategyId, save = true) => {
    const response = await repo.getMyStrategyBlacklist({ myStrategyId });
    const myStrategyBlacklistInfo = convertMyStrategyBlacklist(response);
    if (myStrategyBlacklistInfo && save) {
      set(() => ({
        myStrategyBlacklistInfo: myStrategyBlacklistInfo.nameList,
      }));
    }
    return myStrategyBlacklistInfo?.numberList;
  },
  fetchMyCumulativeReturn: async (queryObj) => {
    const response = await repo.getMyCumulativeReturn(queryObj);
    const cumulativeReturn = convertMyCumulativeReturn(response);
    if (cumulativeReturn) {
      const { riskAssessmentIndex } = get();
      set(() => ({
        cumulativeReturn,
      }));
      if (!riskAssessmentIndex.baseDate) {
        set(() => ({
          riskAssessmentIndex: {
            ...riskAssessmentIndex,
            baseDate: cumulativeReturn.baseDate,
          },
        }));
      }
    }
  },
  fetchMyRiskAssessmentIndex: async (queryObj, isDiy) => {
    const response = await repo.getMyRiskAssessmentInddex(queryObj);
    const riskAssessmentIndex = convertMyRiskAssessmentIndex(
      response,
      queryObj.benchmark,
      isDiy
    );
    if (riskAssessmentIndex) {
      set(() => ({
        riskAssessmentIndex: {
          ...riskAssessmentIndex,
          baseDate: get().riskAssessmentIndex.baseDate,
        },
      }));
    }
  },
  fetchRebalancingHistory: async (queryObj) => {
    const response = await repo.getMyRebalancingHistory(queryObj);
    const rebalancingHistory = convertRebalancingHistory(response);
    if (rebalancingHistory) {
      set(() => ({ rebalancingHistory }));
    }
  },
  fetchHoldingPositionAll: async (queryObj) => {
    const response = await repo.getMyHoldingsPositionAll(queryObj);
    const holdingPosition = convertMyHoldingPositionAll(response);
    if (holdingPosition) {
      set(() => ({
        holdingPosition,
      }));
    }
  },
  fetchExposure: async (queryObj) => {
    const response = await repo.getMyExposure(queryObj);
    const exposure = convertMyExposure(response);
    if (exposure) {
      set(() => ({ exposure }));
    }
  },
}));
