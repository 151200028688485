import {
  EXCLUDE_HOLDINGS_TOOLTIP,
  STOCK_LIST_TOOLTIP,
} from '@constants/common';
import { STRATEGY_EDIT_TOAST_MESSAGE } from '@constants/strategy/strategy-edit.const';
import { StockItemModel } from '@models/strategy';
import { useBottomsheetStore } from '@stores/feedback';
import { useToastStore } from '@stores/feedback/toast.store';
import {
  useStockSelectorAction,
  useStrategyEditStore,
} from '@stores/strategy-edit/strategy-edit.store';
import { useEffect } from 'react';

interface useStockSelectorEventProps {
  simpleMode?: boolean;
}

export const useStockSelectorEvent = ({
  simpleMode = false,
}: useStockSelectorEventProps) => {
  const {
    loading,
    holdings,
    whitelistObj,
    strategyId,
    beforeMount,
    initHoldings,
    fetchInitHoldings,
  } = useStrategyEditStore();
  const { includeStockItem } = useStockSelectorAction();
  const { openToast } = useToastStore();
  const { closeBottomsheet, openBottomsheet } = useBottomsheetStore();

  /**
   * ℹ️ 버튼 클릭시 실행되는 함수
   * @param type
   * @returns
   */
  const handleClickInfo =
    (type: 'list' | 'exclude') =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let title = '제외 종목';
      let description = EXCLUDE_HOLDINGS_TOOLTIP;

      if (type === 'list') {
        title = '종목 리스트';
        description = STOCK_LIST_TOOLTIP;
      }

      e.stopPropagation();
      openBottomsheet('info')({
        button: 'single',
        description,
        title,
        onClickOk: () => closeBottomsheet('info'),
      });
    };

  const handleClickInclude = (stock: StockItemModel) => () => {
    includeStockItem(stock);
  };

  useEffect(() => {
    // FIXME: prevSimulationOptionScreening 세팅을 위해 Simple/DetailPageContainer에서도 호출하고 있어 최초 마운트 시 두번 요청됨
    if (!beforeMount) {
      fetchInitHoldings(strategyId, simpleMode);
    }

    return () => {
      // TODO: 우선 이 화면 나갈때 초기화 하지만 추후에는 팩터 섹터 값들 다 체크해서 값이 변하는게 있으면 초기화하도록 수정하기
      initHoldings();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeMount]);

  // TODO: Data Fetch 후에 Convert 데이터 받아서 처리해도 괜찮을듯. (Store쪽에서 처리하기)
  useEffect(() => {
    const whiteList = Object.values(whitelistObj).filter(
      (item) => item.isLastEdit
    );
    if (whiteList.length > 0) {
      const ccid = whiteList[0].ccid;
      const filterItem = holdings.filter((item) => item.ccid === ccid);

      if (
        filterItem.length > 0 &&
        filterItem[0].infoCode &&
        whiteList[0].prevWeight &&
        loading === false
      ) {
        openToast(STRATEGY_EDIT_TOAST_MESSAGE[filterItem[0].infoCode]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelistObj, loading]);

  return {
    handleClickInfo,
    handleClickInclude,
  };
};
