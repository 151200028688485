import { repo } from '@graphql/repo';
import { create } from 'zustand';
import { convertMe } from './auth.convert';

interface AuthState {
  /** 보관 가능 전략 초과 유무 */
  isStrategyCreationPossible: boolean;

  /** 미국 전략 보관 가능 여부 */
  isStrategyCreationPossibleUs: boolean;
}

interface AuthAction {
  /** 내 정보 조회 */
  fetchMe: () => {};
}

/**
 * 사용자 정보 store
 */
export const useAuthStore = create<AuthState & AuthAction>((set) => ({
  isStrategyCreationPossible: true,
  isStrategyCreationPossibleUs: true,

  fetchMe: async () => {
    const response = await repo.getMe();
    const me = convertMe(response);
    if (me) {
      set(() => ({
        isStrategyCreationPossible: me.isStrategyCreationPossible,
        isStrategyCreationPossibleUs: me.isStrategyCreationPossibleUs,
      }));
    }
  },
}));
