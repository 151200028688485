import { handleException } from '@stores/common';
import { DocumentNode } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';
import env from './env';
import {
  createAuthHttpHeaderProvider,
  createBaseHttpHeaderProvider,
  pipeAcceptContentType,
  pipeJWT_TokenHeader,
} from './httpHeader.factory';

// graphql-request의 type 참고
export type GQLVariables = {
  [key: string]: any;
};

// graphql-request의 type 참고
export type GQLRequestDocument = string | DocumentNode;

const createBaseHeader = () => {
  return createBaseHttpHeaderProvider(pipeAcceptContentType);
};

export const createAuthHeader = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // TODO: Auth Token 관리 리팩토링
  const token = Cookies.get(env.authTokenKey);

  return createAuthHttpHeaderProvider(token)(
    pipeAcceptContentType,
    pipeJWT_TokenHeader
  );
};

/**
 * GraphQL error parser
 * TODO: 정해진 에러 타입 (GreenError)에 맞춰서 코드 작성하기
 */
export const gqlErrorResToData =
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    interrupt?: (error: any) => Promise<void>
  ) =>
  (err: any) => {
    if (interrupt) {
      interrupt(err);
    }
    handleException(err);
  };

/**
 * GraphQL Error Handler (200 Error)
 */
export const gqlSuccessErrorHandler = (res: any) => {
  const keys = Object.keys(res);
  const dataName = keys[0] as any;
  const errors = res[dataName].errors;
  if (!!errors && errors.length > 0) {
    throw new Error(errors[0].key);
  }
};

/**
 *
 * @param baseUrl
 * @param headerProvider
 * @param interrupt
 * @returns
 */
export const createGraphqlClient = (
  baseUrl: string,
  headerProvider: () => Record<string, string> = () => ({}),
  interrupt?: (error: any) => Promise<any>
) => {
  let client: GraphQLClient | null = null;

  function getClient() {
    if (client === null) {
      client = new GraphQLClient(baseUrl);
    }

    client.setHeaders(headerProvider());
    return client;
  }

  const fnCatchError = gqlErrorResToData(interrupt);

  return {
    request<T = any, V = GQLVariables>(
      document: GQLRequestDocument,
      variables?: V
    ): Promise<T> {
      try {
        return getClient()
          .request(document, variables)
          .then((res) => {
            gqlSuccessErrorHandler(res);
            return res;
          })
          .catch(fnCatchError);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

export const baseApi = createGraphqlClient(env.apiBaseUrl, createBaseHeader);
export const authApi = createGraphqlClient(env.apiBaseUrl, createAuthHeader);
