import { BaseStyledFlexBox } from '@ui/base';
import {
  AliasForTextProps,
  ThemedFlexBoxProps,
  UIHTMLComponentProps,
} from '@ui/model';
import { ComponentType, forwardRef } from 'react';

interface FlexBoxProps
  extends UIHTMLComponentProps,
    ThemedFlexBoxProps,
    AliasForTextProps {
  ref?: React.Ref<HTMLElement>;
}

export const FlexBox: ComponentType<FlexBoxProps> = forwardRef<
  HTMLElement,
  FlexBoxProps
>(
  (
    {
      as,
      align,
      width = 'initial',
      height = 'initial',
      spacing = '0',
      bgColor = 'transparent',
      pos,
      ...props
    },
    ref
  ) => (
    <BaseStyledFlexBox
      {...props}
      as={as}
      ref={ref as never}
      $width={width}
      $height={height}
      $spacing={spacing}
      $bgColor={bgColor}
      $align={align}
      $pos={pos}
    />
  )
);

FlexBox.displayName = 'FlexBox';
