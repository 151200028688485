import { InvestmentStrategyLevelEnum } from '@graphql/auth';
import { GradeEnum } from '@graphql/generates';
import {
  InvestmentStrategyLevelType,
  StrategyEditHoldingNotiType,
  TopPercentType,
} from '@models/strategy';

/** 나만의전략 상세 편집 탭 메뉴 */
export const STRATEGY_DETAIL_TAB = [
  {
    tabName: '섹터/업종',
    desc: '투자하고 싶은 섹터나 산업을 선택하세요.',
  },
  {
    tabName: '팩터선택',
    desc: '다양한 팩터들의 세부 값을 나만의 기준으로 조정하여 전략을 구성합니다.',
  },
  {
    tabName: '팩터조정',
    desc: '다양한 팩터들의 세부 값을 나만의 기준으로 조정하여 전략을 구성합니다.',
  },
  {
    tabName: '투자기준',
    desc: '투자 금액, 최적화 옵션, 리밸런싱 주기, 백테스트 시작일을 원하는 대로 설정해보세요.',
  },
  {
    tabName: '종목선택',
    desc: '구성된 종목 리스트에서 종목을 제외하거나, +버튼을 눌러 제외한 종목을 리스트에 재편입시킬 수 있습니다.',
  },
];

/** 간편 편집 탭 메뉴 */
export const STRATEGY_SIMPLE_TAB = [
  {
    tabName: '투자 스타일',
    desc: '간편하게 팩터의 강도만을 선택하여 전략을 구성합니다.',
  },
  {
    tabName: '투자기준',
    desc: '투자 금액, 최적화 옵션, 리밸런싱 주기, 백테스트 시작일을 원하는 대로 설정해보세요.',
  },
  {
    tabName: '종목선택',
    desc: '구성된 종목 리스트에서 종목을 제외하거나, +버튼을 눌러 제외한 종목을 리스트에 재편입시킬 수 있습니다.',
  },
];

/** 비기너 Level2 편집 탭 메뉴 */
export const STRATEGY_BEGINNER_LEVEL2_TAB = [
  {
    tabName: '투자기준',
    desc: '투자 금액, 최적화 옵션, 리밸런싱 주기, 백테스트 시작일을 원하는 대로 설정해보세요.',
  },
  {
    tabName: '종목선택',
    desc: '구성된 종목 리스트에서 종목을 제외하거나, +버튼을 눌러 제외한 종목을 리스트에 재편입시킬 수 있습니다.',
  },
];

/** PRO Level1 편집 탭 메뉴 */
export const STRATEGY_PRO_LEVEL1_TAB = [
  {
    tabName: '투자 스타일',
    desc: '간편하게 팩터의 강도만을 선택하여 전략을 구성합니다.',
  },
  {
    tabName: '금액만 설정',
    desc: '',
  },
];

/** 대가들의 전략 편집 탭 메뉴 */
export const STRATEGY_MASTER_TAB = [
  {
    tabName: '유니버스',
    desc: '기준이 되는 유니버스를 선택하여 전략을 구성합니다.',
  },
  {
    tabName: '팩터조정',
    desc: '스코어가 높을 수록 대가들의 투자 스타일에 적합한 종목입니다.',
  },
  {
    tabName: '투자기준',
    desc: '투자 금액, 최적화 옵션, 리밸런싱 주기, 백테스트 시작일을 원하는 대로 설정해보세요.',
  },
  {
    tabName: '종목선택',
    desc: '구성된 종목 리스트에서 종목을 제외하거나, +버튼을 눌러 제외한 종목을 리스트에 재편입시킬 수 있습니다.',
  },
];

/** 편집 조정 상위 버튼 목록 */
export const TOP_PERCENT_ENUM = ['low', 'middle', 'high'] as const;

export const SIMPLE_FACTOR_GRADE_VIEW_MAPPER: Record<
  GradeEnum,
  TopPercentType
> = {
  HIGH: 'high',
  MIDDLE: 'middle',
  LOW: 'low',
};

/** 편집 조정 상위 버튼 텍스트 */
export const TOP_PERCENT_LOCALE: Record<TopPercentType, string> = {
  high: '상위 20%',
  middle: '상위 40%',
  low: '상위 60%',
};

/** 간편 편집 설명 텍스트 */
export const SIMPLE_PRESET_LOCALE: Record<TopPercentType, string> = {
  high: '상위 0~20%',
  middle: '상위 0~40%',
  low: '상위 0~60%',
};

/**
 * 종목 선정 알림 코드 목록
 */
export const STRATEGY_EDIT_NOTIFICATION_CODE = [
  // 입력 비중이 95% 초과일 경우
  'OVER_95_PERCENT',
  // 비중 조절이 필요할 경우
  'NEED_MODIFY_WEIGHT',
  // 비중이 0.1% 미만일 경우
  'AT_LEAST_1',
  // 추가된 종목의 비중이 0.1% 미만일 경우
  'AT_LEAST_1_ADDITIONAL_STOCK',
  // 1주당 비중이 전체 투자금액의 95%를 초과할 경우
  'MORE_BOOKSIZE',
  // 입력한 비중이 최대 비중으로 조정될 경우
  'MAXIMAL_WEIGHT',
  // 입력한 비중이 1주당 비중보다 작을 경우
  'LESS_THAN_SHARE',
] as const;

/**
 * 종목 선정 알림 정보 텍스트
 */
export const STRATEGY_EDIT_NOTIFICATION_TEXT: Record<
  StrategyEditHoldingNotiType,
  string
> = {
  OVER_95_PERCENT: `수동으로 입력 가능한(비중이 고정된) 최대 비중은 95%입니다.
해당 종목에 입력 가능한 잔여 비중에 맞춰 비중이 자동으로 변경되었습니다.`,
  NEED_MODIFY_WEIGHT: '',
  AT_LEAST_1: '비중고정 항목은 최소 1주, 비중 0.10% 이상 설정해주세요.',
  AT_LEAST_1_ADDITIONAL_STOCK:
    '추가 종목은 최소 1주, 비중 0.10% 이상 설정해주세요.',
  MORE_BOOKSIZE: `해당 종목 1주의 금액이 전체 투자금액의 95%를 초과합니다.
투자 가능하도록 투자기준에서 투자금액을 늘려주세요.`,
  MAXIMAL_WEIGHT: '투자 가능한 최대 비중으로 조정되었습니다.',
  LESS_THAN_SHARE: `입력하신 비중이 1주 당 비중보다 작습니다.
최소 1종목은 투자 가능하도록 비중을 조정해주세요.`,
};

/**
 * 종목 선정 토스트 메시지
 */
export const STRATEGY_EDIT_TOAST_MESSAGE: Record<
  StrategyEditHoldingNotiType,
  string
> = {
  OVER_95_PERCENT: `최대 입력가능 비중 95%에 맞춰 비중이 자동조정 되었습니다.`,
  NEED_MODIFY_WEIGHT: `수동으로 입력 가능한 최대 비중은 95% 입니다.
다른 종목의 비중을 조정한 후 시도해주세요.`,
  AT_LEAST_1: '최소 1주, 비중 0.10% 이상 투자 가능하도록 비중을 조정해주세요.',
  AT_LEAST_1_ADDITIONAL_STOCK:
    '최소 1주, 비중 0.10% 이상 투자 가능하도록 비중을 조정해주세요.',
  MORE_BOOKSIZE: `해당 종목 1주의 금액이 전체 투자금액의 95%를 초과합니다.
투자 가능하도록 투자기준에서 투자금액을 늘려주세요.`,
  MAXIMAL_WEIGHT: '투자 가능한 최대 비중으로 조정되었습니다.',
  LESS_THAN_SHARE: `입력하신 비중이 1주 당 비중보다 작습니다. 
투자 가능한 비중을 입력해주세요.`,
};

/**
 * 대가들의 전략 재편집 시 유니버스 토스트 메시지
 */
export const STRATEGY_EDIT_MASTER_TOAST_MESSAGE =
  '유니버스 변경을 원하실 경우 전략을 새로 생성해주세요.';

/**
 * 투자 전략 레벨
 */
export const INVESTMENT_STRATEGY_LEVEL_ENUM = [
  InvestmentStrategyLevelEnum.ExpertLevelPro,
  InvestmentStrategyLevelEnum.IntermediateLevel1,
  InvestmentStrategyLevelEnum.IntermediateLevel2,
  InvestmentStrategyLevelEnum.IntermediateLevel3,
  InvestmentStrategyLevelEnum.NoviceLevel1,
  InvestmentStrategyLevelEnum.NoviceLevel2,
  InvestmentStrategyLevelEnum.NoviceLevel3,
] as const;

export const INVESTMENT_STRATEGY_LEVEL_LOCALE: Record<
  InvestmentStrategyLevelType,
  string
> = {
  EXPERT_LEVEL_PRO: 'EXPERT_LEVEL_PRO',
  INTERMEDIATE_LEVEL1: 'INTERMEDIATE_LEVEL1',
  INTERMEDIATE_LEVEL2: 'INTERMEDIATE_LEVEL2',
  INTERMEDIATE_LEVEL3: 'INTERMEDIATE_LEVEL3',
  NOVICE_LEVEL1: 'NOVICE_LEVEL1',
  NOVICE_LEVEL2: 'NOVICE_LEVEL2',
  NOVICE_LEVEL3: 'NOVICE_LEVEL3',
};

export const INVESTMENT_STRATEGY_LEVEL_MAPPER: Record<
  InvestmentStrategyLevelType,
  InvestmentStrategyLevelType
> = {
  EXPERT_LEVEL_PRO: InvestmentStrategyLevelEnum.ExpertLevelPro,
  INTERMEDIATE_LEVEL1: InvestmentStrategyLevelEnum.IntermediateLevel1,
  INTERMEDIATE_LEVEL2: InvestmentStrategyLevelEnum.IntermediateLevel2,
  INTERMEDIATE_LEVEL3: InvestmentStrategyLevelEnum.IntermediateLevel3,
  NOVICE_LEVEL1: InvestmentStrategyLevelEnum.NoviceLevel1,
  NOVICE_LEVEL2: InvestmentStrategyLevelEnum.NoviceLevel2,
  NOVICE_LEVEL3: InvestmentStrategyLevelEnum.NoviceLevel3,
};
