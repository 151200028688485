import { TextVariantType } from '@ui/model';

/**
 * 폰트 타입 이름 리스트
 */
export const TEXT_SIZE_UNIT_LIST = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7_title',
  'h7_content',
  'subTitle1_title',
  'subTitle1_content',
  'subTitle2',
  'subTitle2_medium',
  'body1_title',
  'body1_content',
  'body2_title',
  'body2_content',
  'caption',
  'caption_medium',
  'overline_title',
  'overline_content',
  'tag',
  'list',
  'list_medium',
] as const;

/**
 * 폰트 사이즈 Key Value 객체
 */
export const FONT_SIZE_DICTIONARY = {
  h1: '36px',
  h2: '34px',
  h3: '28px',
  h4: '24px',
  h5: '22px',
  h6: '21px',
  h7_title: '20px',
  h7_content: '20px',
  subTitle1_title: '18px',
  subTitle1_content: '18px',
  subTitle2: '17px',
  subTitle2_medium: '17px',
  body1_title: '16px',
  body1_content: '16px',
  body2_title: '14px',
  body2_content: '14px',
  caption: '13px',
  caption_medium: '13px',
  overline_title: '12px',
  overline_content: '12px',
  tag: '10px',
  list: '16px',
  list_medium: '16px',
} as const;

/**
 * Line Height Key Value 객체
 */
export const LINE_HEIGHT_DICTIONARY = {
  h1: '49px',
  h2: '47px',
  h3: '36px',
  h4: '33px',
  h5: '31px',
  h6: '30px',
  h7_title: '27px',
  h7_content: '29px',
  subTitle1_title: '24px',
  subTitle1_content: '26px',
  subTitle2: '25px',
  subTitle2_medium: '25px',
  body1_title: '22px',
  body1_content: '24px',
  body2_title: '19px',
  body2_content: '21px',
  caption: '20px',
  caption_medium: '20px',
  overline_title: '16px',
  overline_content: '18px',
  tag: '13px',
  list: '24px',
  list_medium: '24px',
} as const;

/**
 * 폰트 Weight Key Value 객체
 */
export const FONT_WEIGHT_DICTIONARY: Record<TextVariantType, number> = {
  h1: 500,
  h2: 500,
  h3: 500,
  h4: 500,
  h5: 500,
  h6: 500,
  h7_title: 500,
  h7_content: 500,
  subTitle1_title: 500,
  subTitle1_content: 300,
  subTitle2: 300,
  subTitle2_medium: 500,
  body1_title: 500,
  body1_content: 300,
  body2_title: 500,
  body2_content: 300,
  caption: 300,
  caption_medium: 500,
  overline_title: 500,
  overline_content: 300,
  tag: 500,
  list: 300,
  list_medium: 500,
} as const;

export const FONT_WEIGHT_LIST = [
  'medium',
  'light',
  'semibold',
  'bold',
] as const;

export const WHITE_SPACE_LIST = [
  'nowrap',
  'normal',
  'pre',
  'pre-wrap',
] as const;

export const TEXT_ALIGN_LIST = ['center', 'left', 'right'] as const;

export const WORD_BREAK_LIST = [
  'break-all',
  'break-word',
  'normal',
  'keep-all',
] as const;
