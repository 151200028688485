import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { TextVariantType } from '@ui/model';
import { FC } from 'react';
import styled from 'styled-components';

interface EmptyContentProps {
  desc?: string;
  descVariant?: TextVariantType;
  subDesc?: string;
  subDescVariant?: TextVariantType;
  paddingTop?: number;
}

interface EmptyStackBarChartProps {
  isReadyMade: boolean;
}

const EmptyContentWrap = styled.div<{ paddingTop: number }>`
  padding-top: ${({ paddingTop }) => paddingTop}px;
`;

const EmptyStackBarChartBox = styled.div<{ readymade: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${({ readymade }) =>
    readymade
      ? `url('/image/original_rebalancing_bg.png')`
      : `url('/image/edit_rebalancing_bg.png')`};
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0;
`;

const EmptyLineChartBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/image/line_chart_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 60px;
`;

export const InfoTextBox = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #52555c;
  color: #ffee00;
  border-radius: 4px;
`;

export const EmptyContent: FC<EmptyContentProps> = ({
  desc = '검색결과가 없습니다.',
  descVariant = 'subTitle2_medium',
  subDesc,
  subDescVariant = 'caption_medium',
  paddingTop = 200,
}) => {
  return (
    <EmptyContentWrap paddingTop={paddingTop}>
      <FlexBox spacing={14} direction="column" justify="center">
        <Icon type="info" size={40} />
        <div>
          <Typography color="black.87" variant={descVariant} align="center">
            {desc}
          </Typography>
          {subDesc && (
            <Typography
              color="gray.blue"
              variant={subDescVariant}
              align="center"
            >
              {subDesc}
            </Typography>
          )}
        </div>
      </FlexBox>
    </EmptyContentWrap>
  );
};

export const EmptyStackBarChart: FC<EmptyStackBarChartProps> = ({
  isReadyMade,
}) => {
  return (
    <EmptyStackBarChartBox readymade={isReadyMade}>
      <InfoTextBox>
        리밸런싱 내역이 존재하지 않습니다. <br />
        하단의 보유비중은 최초 평가 기준입니다.
      </InfoTextBox>
    </EmptyStackBarChartBox>
  );
};

export const EmptyLineChart = () => {
  return (
    <EmptyLineChartBox>
      <InfoTextBox>
        수익률 그래프는 2영업일 이후부터 확인하실 수 있습니다.
      </InfoTextBox>
    </EmptyLineChartBox>
  );
};

export const EmptyStockList = () => {
  return (
    <FlexBox
      direction="column"
      alignItems="center"
      justify="center"
      height={272}
      spacing={14}
    >
      <Icon type="info" size={40} />
      <div>
        <Typography variant="body1_title">
          전략에 부합하는 종목이 없습니다.
        </Typography>
        <Typography variant="caption_medium" color="gray.blue" align="center">
          전략을 다시 편집해주세요.
        </Typography>
      </div>
    </FlexBox>
  );
};
