import { GetMeQuery } from '@graphql/generates';

/**
 * GetMeQuery의 response 중 user 데이터만 추출하기 위한 함수
 * @param {Object} data - GetMeQuery의 response 데이터.
 * @returns {Object}
 */
export const convertMe = (data: GetMeQuery) => {
  return data.me?.user;
};
