import { Toast } from '@components/feedback/Toast';
import { useToastStore } from '@stores/feedback/toast.store';
import { useLayoutStore } from '@stores/ui';
import { FC, useEffect } from 'react';

interface ToastContainerProps {}

export const ToastContainer: FC<ToastContainerProps> = () => {
  const contentWidth = useLayoutStore((state) => state.contentWidth);
  const { isOpen, text } = useToastStore();

  /**
   * 토스트 메시지가 열리면 3초 후에 닫히도록 설정
   */
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        useToastStore.setState({ isOpen: false });
      }, 3000);
    }
  }, [isOpen]);

  return <>{isOpen && <Toast text={text} contentWidth={contentWidth} />}</>;
};
