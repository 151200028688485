import { useSearchHoldingsQuery } from '@graphql/base';
import { CountryCodeType } from '@models/common';
import { convertSearchHoldings } from '@stores/strategy-edit/stock-selector/stock-selector.convert';

interface useSearchHoldingDataProps {
  keyword: string;
  countryCode: CountryCodeType;
}

export const useSearchHoldingData = ({
  keyword,
  countryCode,
}: useSearchHoldingDataProps) => {
  const { data } = useSearchHoldingsQuery({
    keyword,
    country: countryCode,
  });

  if (data) {
    const searchedHoldings = convertSearchHoldings(data);
    return searchedHoldings || [];
  }

  return [];
};
