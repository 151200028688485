import { FlexBox, Typography } from '@ui/components';
import styled from 'styled-components';

const MasterSourceWrap = styled.ul`
  & > [aria-label='source'] {
    margin-left: 20px;
    list-style-type: disc;
  }
`;

export const PerformanceGap = () => {
  return (
    <FlexBox spacing={20} direction="column">
      <Typography color="black.60" variant="body1_content">
        계약된 전략의 모의투자 결과와 실제 계좌의 성과 차이가 다음과 같은 사유로
        발생할 수 있습니다.{' '}
      </Typography>
      <MasterSourceWrap>
        <Typography
          aria-label="source"
          color="black.60"
          variant="body1_content"
          as="li"
        >
          잉여예수금 추가
          <br />
          (투자금액의 5% 반영)
        </Typography>
        <Typography
          aria-label="source"
          color="black.60"
          variant="body1_content"
          as="li"
        >
          실거래 시점, 가격, 수수료 등
        </Typography>
      </MasterSourceWrap>
      <Typography color="black.60" variant="body1_content">
        이 점 감안하여 모의투자 성과는 참고로만 이용 부탁드립니다.
      </Typography>
    </FlexBox>
  );
};
