import { BaseStyledTableRow } from '@ui/base';
import { UIHTMLComponentProps } from '@ui/model';
import { ComponentType, forwardRef } from 'react';

interface TableRowProps extends UIHTMLComponentProps<HTMLTableRowElement> {}

export const TableRow: ComponentType<TableRowProps> = forwardRef<
  HTMLTableRowElement,
  TableRowProps
>(({ className, ...props }, ref) => {
  return <BaseStyledTableRow {...props} ref={ref} className={className} />;
});

TableRow.displayName = 'TableRow';
