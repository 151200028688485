import {
  ButtonUIHTMLComponentProps,
  IconNameType,
  ThemedCheckBoxProps,
} from '@ui/model';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

interface CheckBoxButtonProps
  extends ButtonUIHTMLComponentProps,
    ThemedCheckBoxProps {
  ref?: React.Ref<HTMLButtonElement>;
}

const CheckBoxButtonWrap = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CheckBoxButton: React.ComponentType<CheckBoxButtonProps> =
  React.forwardRef<HTMLButtonElement, CheckBoxButtonProps>(
    ({ variant = 'default', disabled, children, ...props }, ref) => {
      let checkBoxType: IconNameType = 'checkbox-default';
      if (variant === 'default' && disabled) {
        checkBoxType = 'checkbox-default-disable';
      } else if (variant === 'select' && !disabled) {
        checkBoxType = 'checkbox-selected';
      } else if (variant === 'select' && disabled) {
        checkBoxType = 'checkbox-selected-disable';
      }

      return (
        <CheckBoxButtonWrap {...props} ref={ref as never}>
          <Icon type={checkBoxType} />
          {children}
        </CheckBoxButtonWrap>
      );
    }
  );
