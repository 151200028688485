import { StockItemModel } from '@models/strategy';
import { FlexBox, Icon, Typography } from '@ui/components';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ExceptListBoxProps {
  excludeHoldings: StockItemModel[];
  onClickInfo: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickInclude: (stock: StockItemModel) => () => void;
}

const ExceptListBoxWrap = styled.div``;

const Divider = styled.div`
  margin: 32px -20px;
  background-color: ${({ theme }) => theme.colorPalette.black[3]};
  height: 8px;
`;

const ExceptList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ExceptBoxBtn = styled.button`
  color: ${({ theme }) => theme.colorPalette.secondary.text};
  background-color: ${({ theme }) => theme.colorPalette.secondary.chip};
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 6px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 2px;
`;

export const ExceptListBox: FC<ExceptListBoxProps> = ({
  excludeHoldings,
  onClickInfo,
  onClickInclude,
}) => {
  return (
    <ExceptListBoxWrap>
      <FlexBox spacing={6} mb={16}>
        <Typography variant="subTitle1_title" color="black.87">
          제외 종목
        </Typography>
        <button onClick={onClickInfo}>
          <Icon size={18} type="info" />
        </button>
      </FlexBox>
      {excludeHoldings.length === 0 ? (
        <FlexBox justify="center">
          <Typography variant="caption">제외된 종목이 없습니다.</Typography>
        </FlexBox>
      ) : (
        <ExceptList>
          {excludeHoldings.map((stock) => (
            <ExceptBoxBtn
              key={`except_holding_${stock.ccid}`}
              onClick={onClickInclude(stock)}
            >
              <Icon type="plus-blue" size={10} />
              {stock.name}
            </ExceptBoxBtn>
          ))}
        </ExceptList>
      )}
      <Divider />
    </ExceptListBoxWrap>
  );
};
