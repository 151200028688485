import {
  Alert,
  BooksizeConfirm,
  Loading,
  TextInputConfirm,
} from '@components/feedback/confirm';
import { useConfirmStore } from '@stores/feedback';
import { useLayoutStore } from '@stores/ui';
import { FC } from 'react';

interface ConfirmContainerProps {}

export const ConfirmContainer: FC<ConfirmContainerProps> = () => {
  const contentWidth = useLayoutStore((state) => state.contentWidth);
  const {
    alert,
    alertContent,
    booksize,
    booksizeContent,
    loading,
    loadingContent,
    textInput,
    textInputContent,
  } = useConfirmStore();
  return (
    <>
      {alert && <Alert contentWidth={contentWidth} {...alertContent} />}
      {booksize && (
        <BooksizeConfirm contentWidth={contentWidth} {...booksizeContent} />
      )}
      {loading && <Loading contentWidth={contentWidth} {...loadingContent} />}
      {textInput && (
        <TextInputConfirm contentWidth={contentWidth} {...textInputContent} />
      )}
    </>
  );
};
