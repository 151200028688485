import { useEffect, useState } from 'react';

/**
 * 스크롤 방향과 최상단 여부를 감지하는 커스텀 훅
 * @param {boolean} off - 스크롤 감지를 끄고 싶을 때 사용
 * @returns {{isScrollingDown: boolean, isTop: boolean}} returnObject - 스크롤 방향과 최상단 여부를 반환
 * - isScrollingDown: 스크롤 방향이 아래로 향하는지 여부
 * - isTop: 최상단 여부
 *
 * @example
 * const { isScrollingDown, isTop } = useScrollDetector(strategyOrder || edit);
 */
export const useScrollDetector = (off?: boolean) => {
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('up'); // 스크롤 진행 방향
  const [isTop, setIsTop] = useState<boolean>(true); // 최상단 여부

  const isScrollingDown = scrollDirection === 'down';

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      setIsTop(scrollY === 0);

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDirection(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!off && !ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [off, scrollDirection]);

  return {
    isScrollingDown,
    isTop,
  };
};
