import { repo } from '@graphql/repo';
import env from './env';

/**
 * URL Querystring에서 CI 추출
 *
 * @param {string} querystring URL Querystring. 테스트를 위해 특정 값 주입 가능하도록 설계.
 * @default window.location.search
 *
 * @returns {string} CI 값
 */
export const extractCiFromQuerystring = (
  querystring = window.location.search
) => {
  const _querystring = querystring;
  const ci = _querystring
    .split('&')
    .map((item) => item.split('='))
    .find(([key]) => key.includes('u'));
  return ci ? ci[1] : '';
};

/**
 * CI 업데이트 필요 여부와 CI 값 반환
 *
 * @param {string} querystring URL Querystring. 테스트를 위해 특정 값 주입 가능하도록 설계.
 * @default window.location.search
 *
 * @returns {object} CI 업데이트 필요 여부와 CI 값. {need: boolean, ci: string}
 */
export const needCiUpdate = (querystring = window.location.search) => {
  const ci = extractCiFromQuerystring(querystring);
  const localCi = localStorage.getItem(env.ciKey) || '';
  const need = !!ci && (!localCi || ci !== localCi);
  return {
    need,
    ci: need ? ci : localCi,
  };
};

/**
 * CI 값 반환 및 해당 값을 localStorage에 저장
 * @param uid 강제 업데이트할 CI 값.
 * @returns {string} CI 값.
 */
export const getCi = (uid?: string) => {
  let ci;
  if (uid) {
    ci = uid;
  } else {
    ci = needCiUpdate().ci;
  }
  if (ci) {
    localStorage.setItem(env.ciKey, ci);
  }
  return ci;
};

/**
 * CI 값으로 토큰 발급받아 쿠키에 저장
 * @param ci CI 값
 * @returns {object}
 */
export const getToken = async (ci: string) => {
  try {
    const res = await repo.signOn({ uid: ci });
    if (res?.signOn?.token) {
      /* 토큰 쿠키 만료기간 : 3시간 */
      const expires = new Date(Date.now() + 60 * 60 * 3 * 1000).toUTCString();
      const token: string = res.signOn.token || '';
      const cookie = `${env.authTokenKey}=${token};expires=${expires};path=/`;
      document.cookie = cookie;

      return {
        token,
        code: 1000,
        message: 'Token Success Saved LocalStorage',
      };
    }
  } catch (e) {}
};
