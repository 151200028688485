import { SelectOptionModel, SelectOptionType } from '@models/common';
import { Button, ButtonGroup, Icon, Typography } from '@ui/components';
import React from 'react';
import styled, { css } from 'styled-components';

interface VariantProps {
  variant?: 'vertical' | 'switch';
}

interface SelectOptionProps<T = string | number>
  extends SelectOptionModel<T>,
    VariantProps {}

const SelectOptionWrap = styled.ul<VariantProps>`
  ${({ variant }) =>
    variant === 'switch' &&
    css`
      display: flex;
      gap: 8px;
    `}
`;

const Option = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 15px;
`;

const OptionButton = styled(Button)<{ isSelected: boolean }>`
  background-color: ${({ theme }) => theme.colorPalette.white};
  border: 1px solid ${({ theme }) => theme.colorPalette.border.chip};

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.colorPalette.secondary.default};
    `}
`;

const Text = styled.div`
  max-width: 234px;
`;

export const SelectOption = <T extends string | number>({
  variant = 'vertical',
  checkVisible = true,
  options,
  selected: defaultSelected,
  onClick,
  onClickInputOption,
}: SelectOptionProps<T>) => {
  const [selected, setSelected] = React.useState(defaultSelected);
  const isHorizontal = variant === 'switch';

  const handleClick: (
    name: T,
    variant?: SelectOptionType
  ) => React.MouseEventHandler<HTMLElement> = (name, variant) => (e) => {
    e.stopPropagation();
    if (variant === 'input') {
      if (onClickInputOption) onClickInputOption();
      return;
    }
    setSelected(name);
    if (onClick) onClick(name);
  };

  if (isHorizontal) {
    return (
      <ButtonGroup>
        {options.map(({ name, text, variant: optionVariant }) => (
          <OptionButton
            key={`option_${name}`}
            color="black.60"
            size="medium"
            variant="general"
            isSelected={selected === name}
            onClick={handleClick(name, optionVariant)}
          >
            <Typography
              color={selected === name ? 'secondary.default' : 'black.60'}
              whiteSpace="nowrap"
              variant="body2_title"
            >
              {text}
            </Typography>
          </OptionButton>
        ))}
      </ButtonGroup>
    );
  }

  return (
    <SelectOptionWrap variant={variant}>
      {options.map(({ name, text, subText, variant: optionVariant }) => {
        const isSelected = selected === name;
        const isInputOption = optionVariant === 'input';

        return (
          <Option
            key={`option_${name}`}
            role="button"
            onClick={handleClick(name, optionVariant)}
          >
            <Text>
              <Typography
                variant="subTitle2"
                fontWeight={isSelected ? 'medium' : 'light'}
                color={isSelected ? 'secondary.text' : 'black.87'}
              >
                {text}
              </Typography>
              {subText && (
                <Typography
                  variant="caption"
                  color={isSelected ? 'secondary.text' : 'black.47'}
                  mt={8}
                >
                  {subText}
                </Typography>
              )}
            </Text>
            {isInputOption && <Icon type="chevron-right" />}
            {checkVisible && !isInputOption && isSelected && (
              <Icon type="check" />
            )}
          </Option>
        );
      })}
    </SelectOptionWrap>
  );
};
