import { SelectOption } from '@components/common';
import { OptionSelectionBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';
import { MIN_WIDTH } from '@constants/layout.const';

interface OptionSelectionBottomsheet
  extends OptionSelectionBottomsheetContentModel,
    ContentWidthModel {}

const OptionSelectionBottomsheetWrap = styled(BottomsheetTemplate)``;

export const OptionSelectionBottomSheet: FC<OptionSelectionBottomsheet> = ({
  contentWidth = MIN_WIDTH,
  selectOption,
  title,
}) => {
  return (
    <OptionSelectionBottomsheetWrap
      contentWidth={contentWidth}
      type="optionSelection"
      title={title}
    >
      {selectOption && <SelectOption {...selectOption} />}
    </OptionSelectionBottomsheetWrap>
  );
};
