import { StateCreator } from 'zustand';
import { StrategyEditState } from '../strategy-edit.store';
import { UniversePresetType } from '@models/strategy';
import { MasterHistogramModel } from '@models/common';
import {
  GetFactorsQueryVariables,
  GetMyFactorsQueryVariables,
  GetMyStrategyQueryVariables,
  SimulationOptionScreeningInput,
} from '@graphql/generates';
import { convertMyStrategy } from '@stores/my-strategy';
import { repo } from '@graphql/repo';
import {
  convertMasterFactor,
  convertMyMasterFactor,
  convertMyMasterInfo,
} from './master-strategy.convert';
import { createMasterStrategyState } from './master-strategy.init';

export interface MasterStrategySliceState {
  /** 유니버스 */
  universePreset: UniversePresetType | undefined;
  /** 팩터 슬라이더 인덱스 */
  masterFactorIndex: number;
  /** 팩터 정보 */
  masterFactor: MasterHistogramModel;
}

export interface MasterStrategySlice extends MasterStrategySliceState {
  setUniversePreset: (universe: UniversePresetType) => void;
  setMasterFactorIndex: (index: number) => void;
  fetchMasterFactors: (queryObj: GetFactorsQueryVariables) => void;
  fetchMyMasterFactors: (queryObj: GetMyFactorsQueryVariables) => void;
  fetchMyMasterInfo: (queryObj: GetMyStrategyQueryVariables) => void;
}

export const createMasterStrategySlice: StateCreator<
  StrategyEditState,
  [],
  [],
  MasterStrategySlice
> = (set, get) => ({
  ...createMasterStrategyState(),
  setUniversePreset: (universePreset) => {
    set(() => ({ universePreset }));
  },
  setMasterFactorIndex: (index) => {
    const masterFactor = get().masterFactor;

    set(() => ({
      masterFactorIndex: index,
      inputFactors: [
        {
          cmName: masterFactor.cmName,
          min: index + 1,
          max: masterFactor.range[1],
        },
      ],
    }));
  },
  fetchMasterFactors: async (queryObj) => {
    const response = await repo.getFactors(queryObj);
    const { masterFactor, masterFactorIndex } = convertMasterFactor(response);
    set(() => ({
      beforeMount: false,
      masterFactor,
      masterFactorIndex,
      inputFactors: [
        {
          cmName: masterFactor.cmName,
          min: masterFactor.range[0],
          max: masterFactor.range[1],
        },
      ],
    }));
  },
  fetchMyMasterFactors: async (queryObj) => {
    const response = await repo.getMyFactors(queryObj);
    const { masterFactor, masterFactorIndex } = convertMyMasterFactor(response);
    const newPrevSimulationOptionScreening = {
      ...get().prevSimulationOptionScreening,
      factors: [
        {
          cmName: masterFactor.cmName,
          min: masterFactorIndex + 1,
          max: masterFactor.range[1],
        },
      ],
    } as SimulationOptionScreeningInput;

    set(() => ({
      masterFactor,
      masterFactorIndex,
      inputFactors: [
        {
          cmName: masterFactor.cmName,
          min: masterFactorIndex + 1,
          max: masterFactor.range[1],
        },
      ],
      prevSimulationOptionScreening: {
        ...newPrevSimulationOptionScreening,
      },
    }));
  },
  fetchMyMasterInfo: async (queryObj) => {
    const response = await repo.getMyStrategy(queryObj);
    const masterInfo = convertMyMasterInfo(response);
    const myStrategy = convertMyStrategy(response);
    if (myStrategy) {
      set(() => ({
        strategySettingObj: {
          ...myStrategy.strategySettingObj,
        },
      }));
    }

    return masterInfo;
  },
});
