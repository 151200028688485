import { COUNTRY_MAPPER } from '@constants/common';
import { LevelProContainer } from '@containers/strategy-edit/expert/LevelProContainer';
import { FC } from 'react';

interface UsStrategyPro1EditPageProps {
  reEdit?: boolean;
}

export const UsStrategyPro1EditPage: FC<UsStrategyPro1EditPageProps> = ({
  reEdit = false,
}) => {
  return <LevelProContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />;
};
