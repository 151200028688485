import { ReturnRateText } from '@components/common';
import { MyStrategyCardDto } from '@models/strategy';
import { FlexBox, Icon, Typography } from '@ui/components';
import { FC, useState } from 'react';
import styled from 'styled-components';

interface BackResultBoxProps {
  strategy: MyStrategyCardDto;
}

const BackResultBoxWrap = styled.div``;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
`;

/**
 * 전략보관함 카드
 * 백테스트 결과 박스 컴포넌트
 */
export const BackResultBox: FC<BackResultBoxProps> = ({ strategy }) => {
  const [isOpenToggle, setIsOpenToggle] = useState(false);

  const handleToggle = () => {
    setIsOpenToggle(!isOpenToggle);
  };

  const isCompareBenchmark =
    strategy.category === 'DIY' || strategy.category === 'MASTER';

  return (
    <BackResultBoxWrap>
      <FlexBox mb={8} justify="space-between" alignItems="center">
        <FlexBox
          wrap="wrap"
          alignItems="center"
          justify="space-between"
          width="100%"
        >
          <FlexBox spacing={4} alignItems="center">
            <Typography color="black.87" variant="caption_medium">
              백테스트 결과
            </Typography>
            <Typography color="black.40" variant="overline_title">
              {strategy.backtestDate}
            </Typography>
          </FlexBox>
          <ToggleButton onClick={handleToggle}>
            <Icon type={isOpenToggle ? 'arrow-up' : 'arrow-down'} size={24} />
          </ToggleButton>
        </FlexBox>
      </FlexBox>
      {isOpenToggle && (
        <FlexBox direction="column">
          <FlexBox justify="space-between" py={6}>
            <Typography color="black.47" variant="overline_content">
              {isCompareBenchmark ? '벤치마크' : '프리셋(Pre-set)'}
            </Typography>
            <Typography color="black.87" variant="overline_title" align="right">
              {strategy.category === 'MASTER'
                ? strategy.universePresetName
                : strategy.baseStrategyName}
            </Typography>
          </FlexBox>
          <FlexBox justify="space-between" py={6}>
            <Typography color="black.47" variant="overline_content">
              누적수익률
            </Typography>
            <ReturnRateText
              value={strategy.backtestCumulativeReturn}
              type="overline_title"
              fontWeight="medium"
            />
          </FlexBox>
          <FlexBox justify="space-between" py={6}>
            <Typography color="black.47" variant="overline_content">
              연평균 수익률
            </Typography>
            <ReturnRateText
              value={strategy.backtestAnnualAvgReturn}
              type="overline_title"
              colorOff
              fontWeight="medium"
            />
          </FlexBox>
          <FlexBox justify="space-between" py={6}>
            <Typography color="black.47" variant="overline_content">
              연평균 변동성
            </Typography>
            <ReturnRateText
              value={strategy.backtestAnnualAvgVolatility}
              type="overline_title"
              colorOff
              fontWeight="medium"
            />
          </FlexBox>
          <FlexBox justify="space-between" py={6}>
            <Typography color="black.47" variant="overline_content">
              MDD
            </Typography>
            <ReturnRateText
              value={strategy.backtestMdd}
              type="overline_title"
              colorOff
              fontWeight="medium"
            />
          </FlexBox>
        </FlexBox>
      )}
    </BackResultBoxWrap>
  );
};
