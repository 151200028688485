import {
  AlertContentModel,
  BooksizeContentModel,
  ConfirmContentType,
  ConfirmType,
  LoadingContentModel,
  TextInputContentModel,
} from '@models/feedback';
import { create } from 'zustand';

interface ConfirmState {
  /** 경고창 */
  alert: boolean;
  alertContent: AlertContentModel;
  /** 투자금액 입력창 */
  booksize: boolean;
  booksizeContent: BooksizeContentModel;
  /** 로딩 */
  loading: boolean;
  loadingContent: LoadingContentModel;
  /** 텍스트 인풋 */
  textInput: boolean;
  textInputContent: TextInputContentModel;
  closeConfirm: (variant: ConfirmType) => void;
  openConfirm: <T extends ConfirmType>(
    variant: T
  ) => (content: ConfirmContentType<T>) => void;
}

export const useConfirmStore = create<ConfirmState>((set, get) => ({
  alert: false,
  alertContent: {},
  booksize: false,
  booksizeContent: {},
  loading: false,
  loadingContent: {},
  textInput: false,
  textInputContent: {},
  closeConfirm: (variant) => {
    set(() => ({ [variant]: false }));
  },
  openConfirm: (variant) => (content) => {
    set(() => ({
      [variant]: true,
      [`${variant}Content`]: content,
    }));
  },
}));
