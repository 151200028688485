/**
 * 헤더 파이프. (Base & Auth)
 * 아래와 같은 데이터가 추가 된다.
 * Accept: 'application/json'
 * @param headerData 데이터를 추가 할 헤더
 */
export const pipeAcceptContentType = (headerData: Record<string, string>) => {
  headerData.Accept = 'application/json';

  return headerData;
};

/**
 * 헤더 파이프. (Auth Only)
 * 토큰이 필요할 때 사용된다.
 * 전달된 토큰 값이 유효하지 않다면 키값을 생성하지 않는다.
 * @param headerData 데이터를 추가 할 헤더
 * @param token 사용될 베어러 토큰.
 */
export const pipeJWT_TokenHeader = (
  headerData: Record<string, string>,
  token?: string
) => {
  if (token) {
    headerData.Authorization = `JWT ${token}`;
  }

  return headerData;
};

/**
 * HTTP 헤더를 제공한다.
 * pipe 로 필요한 헤더 요소를 함수로 구성가능
 * Token이 필요없는 호출에 사용됨
 */
export const createBaseHttpHeaderProvider = (
  ...pipes: Array<(defHeader: Record<string, string>) => Record<string, string>>
) => {
  return pipes.reduce((prev, fn) => fn(prev), {}) as Record<string, string>;
};

/**
 * HTTP 헤더를 제공한다.
 * pipe 로 필요한 헤더 요소를 함수로 구성가능
 * Token이 필요한 호출에 사용됨
 */
export const createAuthHttpHeaderProvider =
  (token?: string) =>
  (
    ...pipes: Array<
      (
        defHeader: Record<string, string>,
        token?: string
      ) => Record<string, string>
    >
  ) => {
    // TODO: Error 처리 공통화 시켜보기
    if (!token) {
      throw new Error(
        '로그인 상태가 만료 되었습니다.\n다시 로그인 하여 주시기 바랍니다.'
      );
    }

    return pipes.reduce((prev, fn) => fn(prev, token), {}) as Record<
      string,
      string
    >;
  };
