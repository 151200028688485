import { MIN_WIDTH } from '@constants/layout.const';
import { useScrollBlocker } from '@hooks/useScrollBlocker';
import { PopupType } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { usePopupStore } from '@stores/feedback/popup.store';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface PopupTemplateProps extends ContentWidthModel {
  children?: React.ReactNode;
  title?: string;
  type: PopupType;
}

const PopupTemplateWrap = styled.div<ContentWidthModel>`
  background-color: ${({ theme }) => theme.colorPalette.white};
  width: ${({ contentWidth }) => `${contentWidth}px`};
  height: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.popup};
`;

const PopupHeader = styled(FlexBox)`
  padding: 12px 10px 12px 20px;
`;

const PopupContent = styled.div`
  padding: 0 20px;
`;

export const PopupTemplate: FC<PopupTemplateProps> = ({
  children,
  contentWidth = MIN_WIDTH,
  title,
  type,
}) => {
  useScrollBlocker(false);

  const closePopup = usePopupStore((state) => state.closePopup);

  return (
    <PopupTemplateWrap contentWidth={contentWidth}>
      <PopupHeader alignItems="center" justify="space-between" height={48}>
        <Typography variant="h7_title">{title}</Typography>
        <button onClick={() => closePopup(type)}>
          <Icon type="close" size={30} />
        </button>
      </PopupHeader>
      <PopupContent>{children}</PopupContent>
    </PopupTemplateWrap>
  );
};
