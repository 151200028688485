import {
  RISK_ASSESSMENT_INDEX_ENUM,
  RISK_ASSESSMENT_INDEX_LOCALE,
  STRATEGY_CATEGORY_VIEW_MAPPER,
  STRATEGY_STYLE_ENUM,
  UNIVERSE_LOCALE,
  UNIVERSE_MAPPER,
} from '@constants/strategy';
import {
  GetCumulativeReturnQuery,
  GetExposureQuery,
  GetHoldingPositionAllQuery,
  GetRebalancingHistoryQuery,
  GetRiskAssessmentIndexQuery,
  GetStrategyQuery,
  GetStrategyStyleQuery,
  UniverseEnum,
} from '@graphql/generates';
import {
  ChartWeightDto,
  ProfitChartModel,
  SelectOptionDto,
  TickDto,
} from '@models/common';
import {
  DetailedStrategyInfoModel,
  ExposureDto,
  ExposureModel,
  MasterInfoModel,
  RiskAssessmentIndexHeadItemModel,
  RiskAssessmentIndexModel,
  StrategyCategoryType,
  StrategyStyleModel,
  UniverseType,
} from '@models/strategy';
import {
  createBaseDate,
  createHoldingPositions,
  getRebalancingHistoryModel,
  transFloatToPercentage,
} from '@stores/common';
import { filterNonDataOfArray, numberFixedWithLimit } from '@utils/common';
import {
  createBenchmarkOptions,
  createChartData,
} from './readymade-strategy.helper';

export const convertStrategy = (data: GetStrategyQuery) => {
  const strategy = data.strategy?.strategy;
  let _category: StrategyCategoryType | undefined;
  let detailedStrategyInfo: DetailedStrategyInfoModel = {
    name: '',
    description: '',
    cumulativeReturnRate: undefined,
  };
  let benchmarks: UniverseEnum[] = [];
  let benchmarkOptions: SelectOptionDto<UniverseEnum>[] = [];

  if (strategy) {
    const { category, baseDate, name, description, __typename } = strategy;
    if (__typename !== 'MasterStrategyType') {
      if (strategy.returnSummaryV2) {
        const { benchmark: benchmarkCumulative, strategy: presetCumulative } =
          strategy.returnSummaryV2;
        detailedStrategyInfo.cumulativeReturnRate =
          presetCumulative?.month3 || undefined;
        detailedStrategyInfo.benchmarkCumulativeReturnRate =
          benchmarkCumulative?.month3 || undefined;
      }
    }
    if (__typename === 'ThemeStrategyType') {
      benchmarks = filterNonDataOfArray(strategy.benchmarks);
      benchmarkOptions = createBenchmarkOptions(benchmarks);
      detailedStrategyInfo.benchmarkName =
        UNIVERSE_LOCALE[benchmarks[0] as UniverseEnum];

      detailedStrategyInfo.kbPickDescription =
        strategy.kbPickDescription || undefined;
    }
    if (category) {
      _category = STRATEGY_CATEGORY_VIEW_MAPPER[category];
    }
    detailedStrategyInfo = {
      ...detailedStrategyInfo,
      name,
      description,
      baseDate: createBaseDate(baseDate),
    };
  }
  return {
    category: _category,
    detailedStrategyInfo,
    benchmarks,
    benchmarkOption: benchmarks[0] || UNIVERSE_MAPPER.KOSPI,
    benchmarkOptions,
  };
};

export const convertStrategyStyle = (data: GetStrategyStyleQuery) => {
  const info = data.strategyStyle?.info;

  if (!info) return;

  const { benchmark, strategy, benchmarkName, strategyName, baseDate } = info;

  const _strategyName = strategyName || '';
  const _benchmarkName = !!benchmarkName ? UNIVERSE_LOCALE[benchmarkName] : '';
  const ticks: TickDto[] = [];

  STRATEGY_STYLE_ENUM.forEach((factorStylekey) => {
    const tick: TickDto = { name: '' };
    if (strategy && strategy[factorStylekey]) {
      tick.base = strategy[factorStylekey]?.value || 0;
      tick.name = String(strategy[factorStylekey]?.name);
    }
    if (benchmark && benchmark[factorStylekey]) {
      tick.benchmark = benchmark[factorStylekey]?.value || 0;
      if (!tick.name) tick.name = String(benchmark[factorStylekey]?.name);
    }
    ticks.push(tick);
  });

  const chartData: ProfitChartModel = {
    strategyName: _strategyName,
    benchmarkName: _benchmarkName,
    data: ticks,
  };

  const strategyStyle: StrategyStyleModel = {
    baseDate: createBaseDate(baseDate),
    chartData,
  };

  return strategyStyle;
};

export const convertCumulativeReturn = (data: GetCumulativeReturnQuery) => {
  const cumulativeReturn = data.cumulativeReturn?.cumulativeReturn;

  if (!cumulativeReturn || !cumulativeReturn.chart) return;

  const _chart = filterNonDataOfArray(cumulativeReturn.chart);

  const _cumulativeReturn: ProfitChartModel = {
    ...createChartData(_chart),
    baseDate: createBaseDate(cumulativeReturn.baseDate),
  };
  return _cumulativeReturn;
};

export const convertRiskAssessmentIndex = (
  data: GetRiskAssessmentIndexQuery
): RiskAssessmentIndexModel | undefined => {
  const info = data.riskAssessmentIndex?.riskAssessmentIndex?.info;

  if (!info) return;

  const { benchmark, strategy } = info;

  if (!benchmark || !strategy) return;

  const head: RiskAssessmentIndexHeadItemModel[] = [
    {
      text: '구분',
      width: 80,
    },
    {
      text: '프리셋',
      width: 120,
    },
    {
      text: UNIVERSE_LOCALE[benchmark.name as UniverseType],
      width: 120,
    },
  ];

  const standardData: string[][] = [];
  const yearData: string[][] = [];

  RISK_ASSESSMENT_INDEX_ENUM.forEach((key) => {
    let strategyValue = '-';
    let benchmarkValue = '-';

    const isSharpe = key === 'sharpe';

    if (strategy?.[key] && strategy?.[key] !== null) {
      const num = strategy?.[key] || 0;
      strategyValue = isSharpe
        ? numberFixedWithLimit(num, 2)
        : transFloatToPercentage(num);
    }
    if (benchmark?.[key] && benchmark?.[key] !== null) {
      const num = benchmark?.[key] || 0;
      benchmarkValue = isSharpe
        ? numberFixedWithLimit(num, 2)
        : transFloatToPercentage(num);
    }
    const row = [
      RISK_ASSESSMENT_INDEX_LOCALE[key],
      strategyValue,
      benchmarkValue,
    ];
    if (key === 'cumulative' || key === 'volatility') {
      standardData.push(row);
    } else if (key === 'annualAvgReturn' || key === 'annualAvgVolatility') {
      yearData.push(row);
    } else {
      standardData.push(row);
      yearData.push(row);
    }
  });

  return {
    head,
    data: {
      standard: standardData,
      year: yearData,
    },
  };
};

export const convertExposure = (
  data: GetExposureQuery
): ExposureModel | undefined => {
  const exposure = data.exposure?.exposure;

  if (!exposure || !exposure.strategy) return;

  const {
    baseDate,
    strategy: { industries, sectors },
  } = exposure;

  const _baseDate = createBaseDate(baseDate);
  const _industries: ChartWeightDto[] = [];
  const _sectors: ChartWeightDto[] = [];
  const exposureData: ExposureDto[] = [];

  if (industries) {
    for (const industry of industries) {
      if (!industry) continue;
      _industries.push({
        name: industry.name,
        weight: industry.weight,
      });
    }
  }
  if (sectors) {
    for (const sector of sectors) {
      if (!sector) continue;
      _sectors.push({
        name: sector.name,
        weight: sector.weight,
      });
    }
  }

  exposureData.push({
    industries: _industries,
    sectors: _sectors,
  });

  return {
    baseDate: _baseDate,
    data: exposureData,
  };
};

export const convertHoldingPositionAll = (data: GetHoldingPositionAllQuery) => {
  const holdingPositionAll = data.holdingPositionAll?.holdingPositionAll;

  if (!holdingPositionAll) return;

  const { baseDate, position } = holdingPositionAll;
  const _baseDate = createBaseDate(baseDate);
  const positions = createHoldingPositions(filterNonDataOfArray(position));
  return {
    baseDate: _baseDate,
    data: positions,
  };
};

export const convertRebalancingHistory = (data: GetRebalancingHistoryQuery) => {
  const rebalancingHistory = data.rebalancingHistory?.rebalancingHistory;
  if (!rebalancingHistory) return;

  const result = getRebalancingHistoryModel(rebalancingHistory);
  return result;
};

export const convertMasterInfo = (data: GetStrategyQuery): MasterInfoModel => {
  const resObj: MasterInfoModel = {
    id: '',
    desc: '',
    shortDesc: '',
    name: '',
    img: '',
    factors: [],
    src: [],
  };

  if (data) {
    if (data.strategy?.strategy) {
      const strategy = data.strategy.strategy;

      resObj.shortDesc = strategy.shortDescription || '';
      resObj.desc = strategy.description || '';
      resObj.name = strategy.name || '';
      resObj.id = strategy.id || '';

      if (strategy.__typename === 'MasterStrategyType') {
        resObj.img = strategy.img || '';
        resObj.factors = [...filterNonDataOfArray(strategy.masterFactors)];
        resObj.src = [...filterNonDataOfArray(strategy.masterSrc)];
      }
    }
  }

  return resObj;
};
