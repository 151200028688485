import { SelectOption } from '@components/common';
import { REBALANCING_OPTIONS } from '@constants/common';
import { RebalancingBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface RebalancingBottomsheetProps
  extends RebalancingBottomsheetContentModel,
    ContentWidthModel {}

const RebalancingBottomsheetWrap = styled(BottomsheetTemplate)``;

export const RebalancingBottomsheet: FC<RebalancingBottomsheetProps> = ({
  contentWidth,
  rebalancing,
  onClickOption,
}) => {
  return (
    <RebalancingBottomsheetWrap
      type="rebalancing"
      title="리밸런싱 주기"
      description="투자 전략에 맞추어 포트폴리오 내 종목 비중을 재조정할 주기를 설정해주세요. "
      contentWidth={contentWidth}
    >
      <SelectOption
        options={REBALANCING_OPTIONS}
        selected={rebalancing === undefined ? 'NONE' : rebalancing}
        onClick={onClickOption}
      />
    </RebalancingBottomsheetWrap>
  );
};
