import { LABEL_MAPPED_NAME } from '@constants/common';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { numberToPercent } from '@utils/common';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import styled from 'styled-components';

const LineChartTooltipWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 8px;
  pointer-events: all;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const LineChartTooltip = (state: CategoricalChartState | undefined) => {
  if (!state || !state.activePayload) return null;

  return (
    <LineChartTooltipWrap>
      <Typography color="fixedwhite" variant="overline_title">
        {state.activeLabel}
      </Typography>
      <Divider />
      {state.activePayload.map((item) => (
        <FlexBox key={item.name} width={100} justify="space-between">
          <Typography color="fixedwhite" variant="overline_content">
            {LABEL_MAPPED_NAME[item.dataKey as string]}
          </Typography>
          <Typography color="fixedwhite" variant="overline_content">
            {numberToPercent(Number(item.value))}%
          </Typography>
        </FlexBox>
      ))}
    </LineChartTooltipWrap>
  );
};
