import { PERIOD_OPTIONS } from '@constants/common';
import { PERIOD_LOCALE, UNIVERSE_LOCALE } from '@constants/strategy';
import { SelectOptionDto } from '@models/common';
import { PeriodType, SimulationType, UniverseType } from '@models/strategy';
import { useBottomsheetStore } from '@stores/feedback';
import React from 'react';

type OptionType = 'benchmark' | 'period';

interface UseProfitSelectorProps {
  strategyId: string;
  benchmarkOption?: UniverseType;
  benchmarkOptions: SelectOptionDto<UniverseType>[];
  periodOption?: PeriodType;
  simulationType?: SimulationType;
  changeBenchmarkOption: (
    benchmarkOption: UniverseType,
    strategyId: string,
    simulationType?: SimulationType
  ) => void;
  changePeriodOption?: (
    benchmarkOption: PeriodType,
    strategyId: string
  ) => void;
}

/**
 * 전략상세 > 수익분석 Selectbox(벤치마크, 조회기간 등) 사용에 필요한 데이터, 함수 제공을 위한 커스텀 훅.
 * @param {UseProfitSelectorProps} props - UseProfitSelectorProps
 * - strategyId: 전략 ID값
 * - benchmarkOption: 벤치마크 옵션
 * - benchmarkOptions: 벤치마크 옵션 리스트
 * - periodOption: 조회기간 옵션
 * - simulationType: 시뮬레이션 타입
 * - changeBenchmarkOption: 벤치마크 옵션 변경 함수
 * - changePeriodOption: 조회기간 옵션 변경 함수
 * @group Hooks
 * 
 * @example
 * const { bottomsheet, selectedBenchmark, handleSelect } = useProfitSelector({
    strategyId,
    benchmarkOption,
    benchmarkOptions,
    simulationType: SIMULATION_TYPE_MAPPER.FORWARDTEST,
    changeBenchmarkOption,
  }); // 전략보관함/모의투자 결과 페이지
 */
export const useProfitSelector = ({
  strategyId,
  benchmarkOption,
  benchmarkOptions,
  periodOption = 'ALL',
  simulationType,
  changeBenchmarkOption,
  changePeriodOption,
}: UseProfitSelectorProps) => {
  const { optionSelection, closeBottomsheet, openBottomsheet } =
    useBottomsheetStore();
  const [bottomsheetType, setBottomsheetType] =
    React.useState<OptionType>('benchmark');

  const isBottomsheetTypeBenchmark = bottomsheetType === 'benchmark';

  const handleClick = (type: OptionType) => (name: unknown) => {
    if (type === 'benchmark') {
      changeBenchmarkOption(name as UniverseType, strategyId, simulationType);
    } else if (type === 'period') {
      if (changePeriodOption) {
        changePeriodOption(name as PeriodType, strategyId);
      }
    }

    closeBottomsheet('optionSelection');
  };

  const handleSelect: (
    type: OptionType
  ) => React.MouseEventHandler<HTMLElement> = (type) => (e) => {
    e.stopPropagation();
    setBottomsheetType(type);

    if (type === 'benchmark') {
      openBottomsheet('optionSelection')({
        selectOption: {
          options: benchmarkOptions,
          selected: benchmarkOption,
          onClick: handleClick(type),
        },
        title: '벤치마크',
      });
      return;
    }

    openBottomsheet('optionSelection')({
      selectOption: {
        options: PERIOD_OPTIONS,
        selected: periodOption,
        onClick: handleClick(type),
      },
      title: '조회기간',
    });
  };

  return {
    bottomsheet: optionSelection,
    isBottomsheetTypeBenchmark,
    selectedBenchmark: benchmarkOption && UNIVERSE_LOCALE[benchmarkOption],
    selectedPeriod: periodOption && PERIOD_LOCALE[periodOption],
    handleSelect,
  };
};
