import { StockCompositionTable } from '@components/strategy';
import { MIN_WIDTH } from '@constants/layout.const';
import { StockCompositionContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { PopupTemplate } from './PopupTemplate';

interface StockCompositionPopupProps
  extends StockCompositionContentModel,
    ContentWidthModel {}

const PopupWrap = styled(PopupTemplate)``;

export const StockCompositionPopup: FC<StockCompositionPopupProps> = ({
  cash,
  contentWidth = MIN_WIDTH,
  stockList,
  title = '종목구성',
}) => {
  return (
    <PopupWrap
      contentWidth={contentWidth}
      title={title}
      type="stockComposition"
    >
      <StockCompositionTable
        caption
        popup
        stockList={stockList || []}
        cash={cash}
      />
    </PopupWrap>
  );
};
