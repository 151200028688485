import animationData from '@assets/lotties/loading.json';
import { LoadingContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { ConfirmTemplate } from './ConfirmTemplate';

interface LoadingProps extends LoadingContentModel, ContentWidthModel {}

const LoadingWrap = styled(ConfirmTemplate)`
  background-color: transparent;
`;

export const Loading: FC<LoadingProps> = ({
  contentWidth,
  descriptions = [],
}) => {
  return (
    <LoadingWrap type="loading" contentWidth={contentWidth}>
      <FlexBox direction="column" alignItems="center" spacing={24}>
        <Lottie
          width={81.82}
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
        />
        <div>
          {descriptions.map((description, index) => (
            <Typography
              key={`loading_description_${index}`}
              align="center"
              variant="caption_medium"
              color="fixedwhite"
              mb={description === '' ? 16 : 0}
            >
              {description}
            </Typography>
          ))}
        </div>
      </FlexBox>
    </LoadingWrap>
  );
};
