import {
  STRATEGY_BEGINNER_LEVEL2_TAB,
  STRATEGY_DETAIL_TAB,
  STRATEGY_MASTER_TAB,
  STRATEGY_PRO_LEVEL1_TAB,
  STRATEGY_SIMPLE_TAB,
} from '@constants/strategy/strategy-edit.const';
import { theme } from '@styles/theme';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface EditHeadTabProps {
  type: 'master' | 'diy' | 'sector' | 'simple' | 'beginner' | 'pro';
  step: number;
  enableDesc?: boolean;
  onClickTab: (index: number) => () => void;
}

interface StepProps {
  step: number;
  isLast: boolean;
  isFirst: boolean;
  info: TabMenuItem;
  isActive: boolean;
  onClick: () => void;
}

interface TabMenuItem {
  tabName: string;
  desc: string;
}

const EditHeadBox = styled.div`
  position: sticky;
  display: flex;
  top: 48px;
  background-color: ${({ theme }) => theme.colorPalette.white};
  z-index: ${({ theme }) => theme.zIndex.layout};
  border-radius: 4px;
  box-shadow: 0 0 0 1px inset
    ${({ theme }) => theme.colorPalette.divider.column};
  overflow: hidden;
`;

const ProgressStepWrap = styled(FlexBox)`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colorPalette.divider.column};
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.column};
  align-items: center;
  justify-content: center;

  & > p:not(.first) {
    padding-left: 8px;
  }

  &.active {
    border: 0;
  }
`;

const EditStepWrap = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: transparent;
  position: relative;

  & > div {
    &.active {
      background-color: ${({ theme }) => theme.colorPalette.secondary.default};
      border-radius: 1px;
    }
  }
`;

const SvgWrap = styled.div<{ isActive: boolean }>`
  position: absolute;
  right: -11px;
  width: 12px;
  height: 40px;
  background-color: transparent;
  z-index: 1;
`;

const ArrowIcon: FC<{ isActive: boolean }> = ({ isActive }) => {
  const fillColor = isActive
    ? theme.colorPalette.secondary.default
    : theme.colorPalette.white;
  const strokeColor = isActive
    ? theme.colorPalette.secondary.default
    : theme.colorPalette.divider.column;
  return (
    <SvgWrap isActive={isActive}>
      <svg
        width="12"
        height="40"
        viewBox="0 0 12 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline
          points="0,0 10,20 0,40"
          style={{
            fill: fillColor,
            stroke: strokeColor,
            strokeWidth: 1,
          }}
        />
      </svg>
    </SvgWrap>
  );
};

const EditStep: FC<StepProps> = ({
  isFirst,
  isLast,
  info,
  isActive,
  onClick,
}) => {
  const fontColor = isActive ? 'fixedwhite' : 'black.60';
  let csName = '';
  if (isActive) {
    csName += 'active';
  }
  if (isFirst) {
    csName += ' first';
  }
  if (isLast) {
    csName += ' last';
  }
  return (
    <EditStepWrap className={csName} onClick={onClick}>
      <ProgressStepWrap className={csName}>
        <Typography variant="overline_title" color={fontColor}>
          {info.tabName}
        </Typography>
      </ProgressStepWrap>
      {!isLast && <ArrowIcon isActive={isActive} />}
    </EditStepWrap>
  );
};

export const EditHeadTab: FC<EditHeadTabProps> = ({
  step,
  type,
  enableDesc = true,
  onClickTab,
}) => {
  let TabMenuItemList: TabMenuItem[] = [];
  if (type === 'master') {
    TabMenuItemList = [...STRATEGY_MASTER_TAB];
  }

  if (type === 'diy') {
    TabMenuItemList = [...STRATEGY_DETAIL_TAB];
  }

  if (type === 'sector') {
    const sectorTabList = [...STRATEGY_DETAIL_TAB];
    sectorTabList.shift();
    TabMenuItemList = [...sectorTabList];
  }

  if (type === 'simple') {
    TabMenuItemList = [...STRATEGY_SIMPLE_TAB];
  }

  if (type === 'beginner') {
    TabMenuItemList = [...STRATEGY_BEGINNER_LEVEL2_TAB];
  }

  if (type === 'pro') {
    TabMenuItemList = [...STRATEGY_PRO_LEVEL1_TAB];
  }

  return (
    <>
      <EditHeadBox>
        {TabMenuItemList.map((tab, index) => (
          <EditStep
            isActive={index + 1 === step}
            isFirst={index === 0}
            isLast={index === TabMenuItemList.length - 1}
            info={tab}
            step={step}
            onClick={onClickTab(index)}
            key={`Step-${index}`}
          />
        ))}
      </EditHeadBox>
      <FlexBox mb={24} mt={8}>
        {TabMenuItemList[step - 1].desc !== '' && enableDesc && (
          <Typography color="gray.blue" variant="caption_medium">
            * {TabMenuItemList[step - 1].desc}
          </Typography>
        )}
      </FlexBox>
    </>
  );
};
