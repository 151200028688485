import { InfoBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface InfoBottomSheetProps
  extends InfoBottomsheetContentModel,
    ContentWidthModel {}

const InfoBottomSheetWrap = styled(BottomsheetTemplate)``;

export const InfoBottomSheet: FC<InfoBottomSheetProps> = ({
  button = 'single',
  contentWidth,
  description,
  descriptions,
  descriptionVariant,
  title,
}) => {
  return (
    <InfoBottomSheetWrap
      contentWidth={contentWidth}
      type="info"
      title={title}
      button={button}
    >
      {description && (
        <Typography
          whiteSpace="pre-wrap"
          color="black.60"
          variant={descriptionVariant}
        >
          {description}
        </Typography>
      )}
      {descriptions && (
        <FlexBox direction="column" spacing={24}>
          {descriptions.map((_description, index) => (
            <Typography
              key={`info_desc_${index}`}
              whiteSpace="pre-wrap"
              color="black.60"
              variant={descriptionVariant}
            >
              {_description}
            </Typography>
          ))}
        </FlexBox>
      )}
    </InfoBottomSheetWrap>
  );
};
