import { Typography } from '@ui/components';
import { FontWeightType, TextVariantType, ThemeColorType } from '@ui/model';
import { numberToPercent } from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

interface ReturnRateTextProps {
  type?: TextVariantType;
  value?: number | null;
  unit?: string;
  fontWeight?: FontWeightType;
  colorOff?: boolean;
  fontType?: 'spoqa';
}

const ReturnRateTextTypo = styled(Typography)`
  &.spoqa {
    font-family: 'Spoqa Han Sans Neo';
  }
`;

export const ReturnRateText: FC<ReturnRateTextProps> = ({
  type = 'h5',
  value,
  unit = '%',
  fontWeight = 'bold',
  colorOff = false,
  fontType,
}) => {
  if (typeof value !== 'number') {
    return <Typography variant={type}>-</Typography>;
  }

  const textColor: ThemeColorType = value < 0 ? 'state.blue' : 'state.red';
  const percentValue: string =
    `${value > 0 ? '+' : ''}` + numberToPercent(value) + unit;

  return (
    <ReturnRateTextTypo
      color={colorOff ? 'black.87' : textColor}
      variant={type}
      fontWeight={fontWeight}
      className={`return-rate-text ${fontType ? fontType : ''}`}
    >
      {percentValue}
    </ReturnRateTextTypo>
  );
};
