import { LegendList, StackBarChart } from '@components/common';
import {
  ChartColorType,
  ChartWeightDto,
  HistoryInfoItemDto,
} from '@models/common';
import { Icon, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyStackBarChart } from './EmptyContent';

interface RebalancingHistoryProps {
  barChartData: HistoryInfoItemDto[];
  color?: ChartColorType;
  disableChart: boolean;
}

const RebalancingHistoryWrap = styled.div`
  width: 100%;
  height: 232px;
`;

const PageBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.colorPalette.border.chip};
  border-radius: 4px;
  width: 36px;
  height: 36px;

  &:disabled {
    & > img {
      opacity: 0.25;
    }
  }
`;

export const RebalancingHistory: FC<RebalancingHistoryProps> = ({
  barChartData,
  color = 'blue',
  disableChart,
}) => {
  const location = useLocation();
  const isReadyMade = location.pathname.includes('readymades');
  const [sliceChartList, setSliceChartList] = useState<HistoryInfoItemDto[][]>(
    []
  );
  const [weightList, setWeightList] = useState<ChartWeightDto[]>([]);
  const [curIndex, setCurIndex] = useState(1);

  useEffect(() => {
    const chartDataList = [];
    let chartDataItem = [];
    for (let i = barChartData.length - 1, count = 1; i >= 0; i--, count++) {
      chartDataItem.push(barChartData[i]);

      if (count % 12 === 0) {
        chartDataList.push(chartDataItem.reverse());
        chartDataItem = [];
      }
    }
    if (chartDataItem.length !== 0) {
      chartDataList.push(chartDataItem.reverse());
    }

    if (barChartData.length > 0) {
      const lastIndex = barChartData.length - 1;
      const newLegendList: ChartWeightDto[] = barChartData[
        lastIndex
      ].stockList.map((stock) => {
        return {
          name: stock.name,
          weight: stock.weight,
        };
      });

      newLegendList.push({
        name: '현금',
        weight: barChartData[lastIndex].cash,
      });

      setWeightList(newLegendList);
    }

    setSliceChartList(chartDataList.reverse());
    setCurIndex(chartDataList.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartData]);

  const handleClickPrevBtn = () => {
    setCurIndex(curIndex - 1);
  };

  const handleClickNextBtn = () => {
    setCurIndex(curIndex + 1);
  };

  const handleClickBarChart = (tooltipIndex: number) => {
    let targetIndex = 0;
    for (let i = 0; i < curIndex - 1; i++) {
      targetIndex += sliceChartList[i].length;
    }
    targetIndex += tooltipIndex;

    const newLegendList: ChartWeightDto[] = barChartData[
      targetIndex
    ].stockList.map((stock) => {
      return {
        name: stock.name,
        weight: stock.weight,
      };
    });

    newLegendList.push({
      name: '현금',
      weight: barChartData[targetIndex].cash,
    });

    setWeightList(newLegendList);
  };

  return (
    <>
      {disableChart && <EmptyStackBarChart isReadyMade={isReadyMade} />}
      {!disableChart && sliceChartList.length > 0 && (
        <RebalancingHistoryWrap>
          <StackBarChart
            data={sliceChartList[curIndex - 1]}
            color={color}
            onClickBar={handleClickBarChart}
          />
        </RebalancingHistoryWrap>
      )}
      {sliceChartList.length > 1 && (
        <FlexBox mt={16} alignItems="center" spacing={16} justify="center">
          <PageBtn disabled={curIndex === 1} onClick={handleClickPrevBtn}>
            <Icon type="page-prev" />
          </PageBtn>
          <FlexBox alignItems="center" justify="center" spacing={2} width={40}>
            <Typography variant="caption_medium">{curIndex}</Typography>
            <Typography variant="caption">/</Typography>
            <Typography variant="caption">{sliceChartList.length}</Typography>
          </FlexBox>
          <PageBtn
            disabled={curIndex === sliceChartList.length}
            onClick={handleClickNextBtn}
          >
            <Icon type="page-next" />
          </PageBtn>
        </FlexBox>
      )}
      <FlexBox mt={16}>
        {weightList.length === 0 ? (
          <FlexBox width="100%" justify="center" mt={8}>
            <Typography variant="caption_medium">
              조건에 부합하는 종목이 없습니다.
            </Typography>
          </FlexBox>
        ) : (
          <LegendList color={color} legendList={weightList} />
        )}
      </FlexBox>
    </>
  );
};
