import { SearchCondition } from '@components/readymade-list/SearchCondition';
import { COUNTRY_MAPPER } from '@constants/common';
import {
  STRATEGY_CATEGORY_PLACEHOLDER,
  US_STRATEGY_CATEGORY_PLACEHOLDER,
} from '@constants/strategy';
import { KeywordType, StrategyCategoryType } from '@models/strategy';
import { useCountryCode } from '@stores/common/common.store';
import { useBottomsheetStore } from '@stores/feedback';
import { useStrategyListStore } from '@stores/readymade-list';
import _ from 'lodash';

export const useCategoryController = () => {
  const {
    keyword,
    category,
    orderTypes,
    periodTypes,
    setKeyword,
    changeOrder,
    changePeriod,
  } = useStrategyListStore();

  const { strategyOrder, closeBottomsheet, openBottomsheet } =
    useBottomsheetStore();
  const countryCode = useCountryCode();

  const handleOrder = (orderCategory: StrategyCategoryType) => () => {
    const orderType = orderTypes[orderCategory];
    const period = periodTypes[orderCategory];
    openBottomsheet('strategyOrder')({
      order: orderType,
      period,
      periodOptions: true,
      onClickOption(name) {
        changeOrder(name, orderCategory);
        closeBottomsheet('strategyOrder');
      },
      onClickPeriodOption(name) {
        changePeriod(name, orderCategory);
        closeBottomsheet('strategyOrder');
      },
    });
  };

  const handleChange = (category: StrategyCategoryType) =>
    _.debounce((keywordStr: string | number) => {
      const newKeyword: KeywordType = { ...keyword, [category]: keywordStr };
      setKeyword(newKeyword);
    }, 200);

  const handleReset = (category: StrategyCategoryType) => () => {
    const newKeyword: KeywordType = { ...keyword, [category]: '' };
    setKeyword(newKeyword);
  };

  const handleSearch = (category: StrategyCategoryType) => () => {};

  /**
   * 전략 리스트 검색 컴포넌트의 이벤트를 처리하는데 필요한 함수를 반환한다.
   */
  const handleConditionControl = (category: StrategyCategoryType) => {
    return {
      onChange: handleChange(category),
      onReset: handleReset(category),
      onSearch: handleSearch(category),
      changeOrder: handleOrder(category),
    };
  };

  /**
   * 전략 리스트 검색 컴포넌트를 생성하는데 필요한 Props를 반환한다.
   */
  const handleConditionProps = (category: StrategyCategoryType) => {
    return {
      category,
      strategyOrder,
      orderTypes: orderTypes[category],
      periodType: periodTypes[category],
      keyword: keyword[category],
      placeholder:
        countryCode === COUNTRY_MAPPER.DOMESTIC
          ? STRATEGY_CATEGORY_PLACEHOLDER[category]
          : US_STRATEGY_CATEGORY_PLACEHOLDER[category],
    };
  };

  const createSearchCondition = (category: StrategyCategoryType) => {
    return (
      <SearchCondition
        {...handleConditionProps(category)}
        {...handleConditionControl(category)}
      />
    );
  };

  return {
    category,
    createSearchCondition,
    handleConditionProps,
    handleConditionControl,
  };
};
