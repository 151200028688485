import {
  STACK_BAR_CHART_BLUE_COLOR,
  STACK_BAR_CHART_ORANGE_COLOR,
} from '@constants/common';
import { ChartColorType, ChartWeightDto } from '@models/common';
import { theme } from '@styles/theme';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { numberToPercent } from '@utils/common';
import { FC } from 'react';
import styled from 'styled-components';

interface LegendListProps {
  legendList: ChartWeightDto[];
  color: ChartColorType;
}

const LegendCircle = styled.div<{ bgColor: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
`;

export const LegendList: FC<LegendListProps> = ({ legendList, color }) => {
  const colorList =
    color === 'blue'
      ? STACK_BAR_CHART_BLUE_COLOR
      : STACK_BAR_CHART_ORANGE_COLOR;
  return (
    <FlexBox direction="column" width="100%">
      {legendList.map((legend, i) => {
        const circleBgColor =
          legend.name === '현금'
            ? theme.colorPalette.divider.appbar_tab
            : colorList[i];
        return (
          <FlexBox
            height={30}
            justify="space-between"
            alignItems="center"
            key={legend.name}
          >
            <FlexBox alignItems="center" spacing={6}>
              <LegendCircle bgColor={circleBgColor} />
              <Typography variant="overline_content" color="black.87">
                {legend.name}
              </Typography>
            </FlexBox>
            <Typography variant="overline_content" color="black.60">
              {`${numberToPercent(legend.weight)}%`}
            </Typography>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
