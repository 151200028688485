import { SelectOption } from '@components/common';
import {
  BOOKSIZE_BOTTOMSHEET_OPTIONS,
  BOOKSIZE_BOTTOMSHEET_OPTIONS2,
  COUNTRY_MAPPER,
  PRICE_INFO_DESCRIPTION,
} from '@constants/common';
import { BooksizeBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface BooksizeBottomsheetProps
  extends BooksizeBottomsheetContentModel,
    ContentWidthModel {}

const BooksizeBottomsheetWrap = styled(BottomsheetTemplate)``;

export const BooksizeBottomsheet: FC<BooksizeBottomsheetProps> = ({
  booksize,
  countryCode = COUNTRY_MAPPER.DOMESTIC,
  contentWidth,
  onClickOption,
  onClickInputOption,
}) => {
  const options =
    countryCode === COUNTRY_MAPPER.DOMESTIC
      ? BOOKSIZE_BOTTOMSHEET_OPTIONS
      : BOOKSIZE_BOTTOMSHEET_OPTIONS2;
  return (
    <BooksizeBottomsheetWrap
      type="booksize"
      description={`*${PRICE_INFO_DESCRIPTION}`}
      title="투자 금액"
      contentWidth={contentWidth}
    >
      <SelectOption
        options={options}
        selected={booksize / 10_000}
        onClick={onClickOption}
        onClickInputOption={onClickInputOption}
      />
    </BooksizeBottomsheetWrap>
  );
};
