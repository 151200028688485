import { useEffect, useRef } from 'react';

/**
 * Viewport에 요소가 들어오는지 감지하는 커스텀 훅.
 * @param {function(flag: boolean): void} callback - 조건 만족 후 실행될 콜백 함수
 * @param {IntersectionObserverInit} options - IntersectionObserver 설정 객체
 * - root: 뷰포트 기준으로 설정할 부모 요소
 * - rootMargin: root 영역을 기준으로 설정할 마진
 * - threshold: 콜백 함수가 실행될 임계값
 * @returns {MutableRefObject<null | HTMLDivElement>} target - 해당 target을 감지 대상 요소에 적용. ex. `<div ref={target} />`
 * @group Hooks
 *
 * @example
 * const [extendedHeaderView, setExtendedHeaderView] = useState(true);
 * const target = useIntersectionObserver((isIntersecting) =>
    setExtendedHeaderView(isIntersecting)
  );
 */
export const useIntersectionObserver = (
  callback: (flag: boolean) => void,
  options: IntersectionObserverInit = { threshold: 1 }
) => {
  const target = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry.isIntersecting);
      });
    }, options);

    if (target.current) {
      observer.observe(target.current);
    }
    return () => observer && observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, callback]);

  return target;
};
