import { RiskAssessmentIndexType } from '@models/strategy';

/**
 * 위험평가지수 데이터 타입
 * standard: 기본 데이터
 * year: 연환산 데이터
 */
export const RISK_ASSESSMENT_INDEX_DATA_TYPE = ['standard', 'year'] as const;

/**
 * 위험평가지수 항목
 * 전략상세 > 위험평가지수 표에 사용되는 항목
 */
export const RISK_ASSESSMENT_INDEX_ENUM = [
  /** 수익률 */
  'cumulative',
  /** 변동성 */
  'volatility',
  /** 연환산 수익률 */
  'annualAvgReturn',
  /** 연환산 변동성 */
  'annualAvgVolatility',
  /** 샤프지수 */
  'sharpe',
  /** MDD */
  'mdd',
] as const;

export const RISK_ASSESSMENT_INDEX_LOCALE: Record<
  RiskAssessmentIndexType,
  string
> = {
  cumulative: '수익률',
  mdd: 'MDD',
  sharpe: '샤프지수',
  volatility: '변동성',
  annualAvgReturn: '수익률',
  annualAvgVolatility: '변동성',
};
