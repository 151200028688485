import { COUNTRY_MAPPER } from '@constants/common';
import { UNIVERSE_PRESET_MAPPER } from '@constants/strategy';
import {
  GetStrategiesQueryVariables,
  GetThemeGroupsQueryVariables,
  GetUniversePresetsQueryVariables,
  ThemeGroupStatusEnum,
} from '@graphql/generates';
import { repo } from '@graphql/repo';
import { CountryCodeType, PageStatusType } from '@models/common';
import {
  StrategyCategoryType,
  StrategyOrderMethodType,
  StrategyOrderPeriodReturnType,
  StrategyUniverseType,
  ThemeGroupDto,
  UniverseItemModel,
  UniversePresetType,
} from '@models/strategy';
import { useAuthStore } from '@stores/auth';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { create } from 'zustand';
import {
  convertThemeGroups,
  convertUniverseList,
} from './strategy-list.convert';
import {
  KEYWORD_INIT,
  STRATEGY_LIST_ORDER_INIT,
  STRATEGY_LIST_PERIOD_INIT,
} from './strategy-list.init';

interface StrategyListState
  extends StrategyListNetworkActions,
    StrategyListStateActions {
  /** 데이터 상태 */
  pageStatus: PageStatusType;
  /** 정렬 데이터 */
  orderTypes: Record<StrategyCategoryType, StrategyOrderMethodType>;
  periodTypes: Record<StrategyCategoryType, StrategyOrderPeriodReturnType>;
  keyword: Record<StrategyCategoryType, string>;
  category: StrategyCategoryType;
  /** 유니버스 */
  universePresets: Record<StrategyUniverseType, UniverseItemModel[]>;
  universe: UniversePresetType;
  /** 테마 전략 탭 관련 state */
  themeTabIndex: number;
  universeIndex: number;
  /** 테마 전략 탭 - 테마 그룹 관련 state */
  themeGroups: ThemeGroupDto[];
  selectedThemeGroups: ThemeGroupDto[];
}

interface StrategyListStateActions {
  changeOrder: (
    orderType: StrategyOrderMethodType,
    categoryType: StrategyCategoryType
  ) => void;
  changePeriod: (
    periodType: StrategyOrderPeriodReturnType,
    categoryType: StrategyCategoryType
  ) => void;
  setKeyword: (keyword: Record<StrategyCategoryType, string>) => void;
  setCategory: (
    countryCode: CountryCodeType,
    category: StrategyCategoryType
  ) => void;
  changeUniverse: (universe: UniversePresetType) => void;
  setThemeTabIndex: (index: number) => void;
  setUniverseIndex: (index: number) => void;
  setThemeGroups: (selectedThemeGroups: ThemeGroupDto[]) => void;
  updateCountryCode: (countryCode: string) => void;
  reset: (countryCode: CountryCodeType) => void;
}

interface StrategyListNetworkActions {
  /**
   * 초기 전략 리스트 호출하기
   */
  initStrategies: (queryObj: GetStrategiesQueryVariables) => void;
  fetchUniversePreset: (queryObj: GetUniversePresetsQueryVariables) => void;
  /**
   * **KB 테마 - 테마 키워드 선택**
   * @desc 테마 키워드로 구성된 themeGroup 데이터를 호출한다.
   */
  fetchThemeGroups: (queryObj: GetThemeGroupsQueryVariables) => void;
}

export const useStrategyListStore = create<StrategyListState>((set, get) => ({
  pageStatus: 'init',
  orderTypes: { ...STRATEGY_LIST_ORDER_INIT },
  periodTypes: { ...STRATEGY_LIST_PERIOD_INIT },
  themeTabIndex: 0,
  universeIndex: 0,
  updateCountryCode: (countryCode) => {
    const {
      universe: currentUniverse,
      universePresets,
      fetchUniversePreset,
    } = get();

    let universe = UNIVERSE_PRESET_MAPPER.KOSPI200_KOSDAQ150_KRX300;
    if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
      if (
        currentUniverse === UNIVERSE_PRESET_MAPPER.NASDAQ100 ||
        currentUniverse === UNIVERSE_PRESET_MAPPER.SNP500 ||
        currentUniverse === UNIVERSE_PRESET_MAPPER.SNP500_NASDAQ100
      ) {
      } else {
        universe = currentUniverse;
      }
    }
    if (countryCode === COUNTRY_MAPPER.USA) {
      universe = UNIVERSE_PRESET_MAPPER.SNP500;
      if (
        currentUniverse === UNIVERSE_PRESET_MAPPER.NASDAQ100 ||
        currentUniverse === UNIVERSE_PRESET_MAPPER.SNP500 ||
        currentUniverse === UNIVERSE_PRESET_MAPPER.SNP500_NASDAQ100
      ) {
        universe = currentUniverse;
      } else {
      }
    }

    if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
      if (universePresets.PUBLIC.length === 0) {
        fetchUniversePreset({});
      }
    }

    if (countryCode === COUNTRY_MAPPER.USA) {
      if (universePresets.USA.length === 0) {
        fetchUniversePreset({ countries: countryCode });
      }
    }

    set(() => ({ universe }));
  },
  setThemeTabIndex: (index) => {
    set(() => ({
      themeTabIndex: index,
      keyword: KEYWORD_INIT,
      orderTypes: STRATEGY_LIST_ORDER_INIT,
      periodTypes: STRATEGY_LIST_PERIOD_INIT,
    }));
  },
  setUniverseIndex: (index) => {
    set(() => ({ universeIndex: index }));
  },
  themeGroups: [],
  selectedThemeGroups: [],
  setThemeGroups: (selectedThemeGroups) => {
    set(() => ({
      selectedThemeGroups,
    }));
  },
  fetchThemeGroups: async (queryObj) => {
    const response = await repo.getThemeGroups(queryObj);

    const themeGroups = convertThemeGroups(response);
    if (themeGroups) {
      set(() => ({ themeGroups }));
    }
  },

  changeOrder: (orderType, category) => {
    set(() => ({
      orderTypes: {
        ...get().orderTypes,
        [category]: orderType,
      },
    }));
  },
  changePeriod(orderType, categoryType) {
    set((state) => ({
      periodTypes: {
        ...state.periodTypes,
        [categoryType]: orderType,
      },
    }));
  },

  keyword: {
    ...KEYWORD_INIT,
  },
  setKeyword: (keyword) => {
    set(() => ({
      keyword,
    }));
  },
  category: 'THEME',
  setCategory: async (countryCode, category) => {
    const { reset } = get();
    reset(countryCode);
    set(() => ({
      category,
    }));
  },
  initStrategies: async (queryObj) => {
    const { fetchMe } = useAuthStore.getState();
    const { fetchSectors } = useStrategyEditStore.getState();
    const { fetchThemeGroups } = get();
    fetchSectors();
    fetchMe();
    fetchThemeGroups({
      status: ThemeGroupStatusEnum.Activated,
      countries: queryObj.countries,
    });

    set(() => ({
      pageStatus: 'success',
    }));
  },

  universePresets: {
    USA: [],
    PUBLIC: [],
    REPRESENT: [],
  },
  universe: UNIVERSE_PRESET_MAPPER.KOSPI200_KOSDAQ150_KRX300,
  changeUniverse: async (universe) => {
    if (universe === get().universe) return;
    set(() => ({ universe }));
  },
  fetchUniversePreset: async (queryObj) => {
    const response = await repo.getUniverseList(queryObj);
    const universePresets = convertUniverseList(response);
    set(() => ({
      universePresets,
    }));
  },
  reset: (countryCode) => {
    let universe = UNIVERSE_PRESET_MAPPER.KOSPI200_KOSDAQ150_KRX300;
    if (countryCode === COUNTRY_MAPPER.USA) {
      universe = UNIVERSE_PRESET_MAPPER.SNP500;
    }

    set(() => ({
      universe,
      keyword: {
        ...KEYWORD_INIT,
      },
      orderTypes: {
        ...STRATEGY_LIST_ORDER_INIT,
      },
      periodTypes: {
        ...STRATEGY_LIST_PERIOD_INIT,
      },
      universeIndex: 0,
      themeTabIndex: 0,
      selectedThemeGroups: [],
    }));
  },
}));
