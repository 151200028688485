import { HeaderModel } from '@models/layout.model';
import { FlexBox } from '@ui/components/Flexbox';
import React from 'react';
import styled, { css } from 'styled-components';
import { Navigation } from './Navigation';
import { MIN_WIDTH } from '@constants/layout.const';
import { Icon } from '@ui/components';

interface ExtendedProps {
  extended?: boolean;
  extendedHeaderView?: boolean;
}

interface MainHeaderProps extends HeaderModel {
  visible?: boolean;
}

interface HeaderWrapperProps {
  contentWidth?: number;
}

interface HeaderProps
  extends MainHeaderProps,
    HeaderWrapperProps,
    ExtendedProps {}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
  ${({ contentWidth }) =>
    css`
      width: ${contentWidth}px;
    `};
`;

const HeaderInner = styled.div`
  background: ${({ theme }) => theme.colorPalette.white};
`;

const HeaderContent = styled(FlexBox)`
  position: relative;
`;

const CommonButton = styled.button`
  background-color: transparent;
`;

const BackButton = styled(CommonButton)<{ absolute?: boolean }>`
  margin-right: 10px;
  ${({ absolute }) => {
    if (absolute) {
      return css`
        left: 0;
        position: absolute;
        z-index: ${({ theme }) => theme.zIndex.header};
      `;
    }
  }}
`;

const OptionalButton = styled(CommonButton)`
  position: absolute;
  right: 12px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  max-width: 258px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colorPalette.black[100]};
`;

const MainHeader: React.FC<MainHeaderProps> = ({
  contentWidth,
  navigation,
  optionalButton,
  pathname,
  title,
  visible = true,
  onClickPrev,
  onNavigation,
}) => {
  return (
    <HeaderContent alignItems="center" height={48}>
      <BackButton absolute={navigation} onClick={onClickPrev}>
        <Icon type="back" />
      </BackButton>
      {optionalButton && <OptionalButton>{optionalButton}</OptionalButton>}
      {navigation && (
        <Navigation
          contentWidth={contentWidth}
          pathname={pathname}
          onNavigation={onNavigation}
        />
      )}
      {!navigation && visible && <Title>{title}</Title>}
    </HeaderContent>
  );
};

export const Header: React.FC<HeaderProps> = ({
  contentWidth = MIN_WIDTH,
  extended = false,
  extendedHeaderView = true,
  navigation,
  pathname,
  optionalButton,
  title,
  onClickPrev,
  onNavigation,
}) => {
  return (
    <HeaderWrapper contentWidth={contentWidth}>
      <HeaderInner>
        <MainHeader
          contentWidth={contentWidth}
          navigation={navigation}
          optionalButton={optionalButton}
          pathname={pathname}
          title={title}
          visible={extended ? !extendedHeaderView : true}
          onClickPrev={onClickPrev}
          onNavigation={onNavigation}
        />
      </HeaderInner>
    </HeaderWrapper>
  );
};
