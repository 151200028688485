import { ROOT_PATH_ENUM } from '@constants/router.const';
import { extractCiFromQuerystring } from '@core/token';
import { ExternalPageType } from '@models/router.model';
import { isAndroid, isIOS } from '@utils/common';

/**
 * M-able과 통신하기 위해서 사용하는 bridge 함수의 파라미터
 * @param callbackId 콜백 ID
 * @param command 명령어
 * @param args 넘겨줘야 하는 데이터 객체
 */
interface BridgeParamDto {
  callbackId?: string;
  command: string;
  args?: any;
}

/**
 * M-able과 통신에 사용되는 연결 함수
 * @param {BridgeParamDto}
 */
function bridge({ callbackId, command, args }: BridgeParamDto) {
  const postMessage = {
    callbackId,
    command,
    args,
  };

  const postMsg = JSON.stringify(postMessage);
  if (isAndroid()) {
    window.quantit_interface.postMessage(postMsg);
  }

  if (isIOS()) {
    window.webkit.messageHandlers.quantit_interface.postMessage(postMsg);
  }
}

/**
 * 계좌 현황으로 이동하는 함수
 * @param di_port_id 전략 ID
 */
export function move_my_di_account(di_port_id: string) {
  bridge({
    command: 'move_my_di_account',
    args: {
      di_port_id,
    },
  });
}

/**
 * 마블 메뉴로 이동하는 함수
 */
export function move_back_menu() {
  bridge({
    command: 'move_back_menu',
  });
}

/**
 * 리밸런싱 진행하는 화면으로 이동하는 함수
 * @param di_port_id 전략 ID
 */
export function move_rebalancing(di_port_id: string) {
  bridge({
    command: 'move_rebalancing',
    args: {
      di_port_id,
    },
  });
}

/**
 * 투자시작하기를 통해서 실행되는 함수
 * @param di_port_id 전략 ID
 */
export function move_contract(di_port_id: string) {
  bridge({
    command: 'move_contract',
    args: {
      di_port_id,
    },
  });
}

/**
 * 다이렉트인덱싱 알아보기 GNB 메뉴를 통해서 실행되는 함수
 */
function move_di_info() {
  bridge({
    command: 'move_di_info',
  });
}

/**
 * My 자문계약현황 GNB 메뉴를 통해서 실행되는 함수
 */
function move_my_di_contract() {
  bridge({
    command: 'move_my_di_contract',
  });
}

/**
 * GNB 메뉴를 통해서 실행되는 브릿지 함수를 실행하는 함수
 */
export function execute_gnb_bridge(page: ExternalPageType) {
  if (page === 'direct-indexing') {
    move_di_info();
  } else if (page === 'my-account-info') {
    move_my_di_account('');
  } else if (page === 'my-contract-account') {
    move_my_di_contract();
  }
}

// (안드로이드) 뒤로가기 이벤트
export function moveBackward() {
  if (isAndroid()) {
    /**
     *  전략 보관함, 전략선택(한국), 전략선택(미국) 페이지에서는
     *  안드로이드 뒤로가기 이벤트가 탐지 되었을 경우에 마블 메뉴로 보낸다.
     */
    if (ROOT_PATH_ENUM.includes(window.location.pathname)) {
      move_back_menu();
      return;
    }

    if (check_from_mable_menu()) {
      move_back_menu();
      return;
    }

    window.history.back();
  }
}

/**
 * 전략 보관함 탭을 클릭 했을 때 실행되는 함수
 */
export function tab_selected(tab_id: string) {
  bridge({
    command: 'tab_selected',
    args: {
      tab_id,
    },
  });
}

/**
 *  마블 메뉴에서 바로 넘어온 경우인지 확인하는 함수
 *  QueryString에 CI 값이 존재한다면 true, 아니라면 false를 반환한다.
 */
export function check_from_mable_menu() {
  if (extractCiFromQuerystring(window.location.search) === '') {
    return false;
  }
  return true;
}
