import {
  ButtonUIHTMLComponentProps,
  IconNameType,
  ThemedRadioBoxProps,
} from '@ui/model';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

interface RadioBoxButtonProps
  extends ButtonUIHTMLComponentProps,
    ThemedRadioBoxProps {
  ref?: React.Ref<HTMLButtonElement>;
}

const RadioBoxButtonWrap = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RadioBoxButton: React.ComponentType<RadioBoxButtonProps> =
  React.forwardRef<HTMLButtonElement, RadioBoxButtonProps>(
    ({ variant = 'default', disabled, children, ...props }, ref) => {
      let radioBoxType: IconNameType = 'radiobox-default';
      if (variant === 'default' && disabled) {
        radioBoxType = 'radiobox-default-disable';
      } else if (variant === 'select' && !disabled) {
        radioBoxType = 'radiobox-selected';
      } else if (variant === 'select' && disabled) {
        radioBoxType = 'radiobox-selected-disable';
      }

      return (
        <RadioBoxButtonWrap {...props} ref={ref as never}>
          <Icon type={radioBoxType} />
          {children}
        </RadioBoxButtonWrap>
      );
    }
  );
