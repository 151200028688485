import {
  ExternalPageType,
  PageCommonType,
  PageInfoModel,
  PageKrType,
  PageType,
  PageUsType,
} from '@models/router.model';

export const ROOT_PATH_ENUM = [
  '/my-strategies',
  '/readymades',
  '/us-readymades',
];

export const PAGE_COMMON_ENUM = ['home', 'my-strategies'] as const;

export const PAGE_US_ENUM = [
  'us-readymades',
  'us-readymade-detail',
  'us-readymade-detail-master',

  'us-strategy-beginner-1-edit', // 초급자 Level 1
  'us-strategy-beginner-2-edit', // 초급자 Level 2
  'us-strategy-pro-1-edit', // 중상급자 Level 1
  'us-strategy-simple-edit', // 중상급자 Level 2
  'us-strategy-detail-edit', // 중상급자 Level Pro
  'us-strategy-diy-edit', // 중상급자 Level Pro DIY
  'us-strategy-master-edit', // 중상급자 Level Pro MASTER

  'us-strategy-beginner-1-re-edit',
  'us-strategy-beginner-2-re-edit',
  'us-strategy-pro-1-re-edit',
  'us-strategy-simple-re-edit',
  'us-strategy-detail-re-edit',
  'us-strategy-diy-re-edit',
  'us-strategy-master-re-edit',

  'my-us-strategy-beginner-1-edit',
  'my-us-strategy-beginner-2-edit',
  'my-us-strategy-pro-1-edit',
  'my-us-strategy-simple-edit',
  'my-us-strategy-detail-edit',
  'my-us-strategy-master-edit',
  'my-us-strategy-diy-edit',

  'us-strategy-edit-result',
  'my-us-strategy-edit-result',
  'my-us-strategy-back-test',
  'my-us-strategy-forward-test',
] as const;

export const PAGE_KR_ENUM = [
  'readymades',
  'readymade-detail',
  'readymade-detail-master',

  'strategy-beginner-1-edit', // 초급자 Level 1
  'strategy-beginner-2-edit', // 초급자 Level 2
  'strategy-pro-1-edit', // 중상급자 Level 1
  'strategy-simple-edit', // 중상급자 Level 2
  'strategy-detail-edit', // 중상급자 Level Pro
  'strategy-diy-edit', // 중상급자 Level Pro DIY
  'strategy-master-edit', // 중상급자 Level Pro MASTER

  'strategy-beginner-1-re-edit',
  'strategy-beginner-2-re-edit',
  'strategy-pro-1-re-edit',
  'strategy-simple-re-edit',
  'strategy-detail-re-edit',
  'strategy-diy-re-edit',
  'strategy-master-re-edit',

  'my-strategy-beginner-1-edit',
  'my-strategy-beginner-2-edit',
  'my-strategy-pro-1-edit',
  'my-strategy-simple-edit',
  'my-strategy-detail-edit',
  'my-strategy-master-edit',
  'my-strategy-diy-edit',

  'strategy-edit-result',
  'my-strategy-edit-result',
  'my-strategy-back-test',
  'my-strategy-forward-test',
] as const;

export const PAGE_ENUM = [
  ...PAGE_COMMON_ENUM,
  ...PAGE_KR_ENUM,
  ...PAGE_US_ENUM,
] as const;

export const EXTERNAL_PAGE_ENUM = ['direct-indexing'] as const;

/**
 *  미국, 한국에 따른 차이가 없는 페이지들
 */
export const PAGE_COMMON_INFO: Record<PageCommonType, PageInfoModel> = {
  home: {
    name: '랜딩페이지',
    page: '',
  },
  'my-strategies': {
    name: '전략보관함',
    page: 'my-strategies',
  },
};

export const PAGE_US_INFO: Record<PageUsType, PageInfoModel> = {
  'us-readymades': {
    name: '전략 선택 (미국)',
    page: 'us-readymades',
  },
  'us-readymade-detail': {
    name: '전략 상세 (미국)',
    page: 'us-readymades/:strategyId',
  },
  'us-readymade-detail-master': {
    name: '대가들의 전략 상세 (미국)',
    page: 'us-readymades/:strategyId/master',
  },

  'us-strategy-beginner-1-edit': {
    name: '초급자 Level 1',
    page: 'us-readymades/:strategyId/beginner-1',
  },
  'us-strategy-beginner-2-edit': {
    name: '초급자 Level 2',
    page: 'us-readymades/:strategyId/beginner-2',
  },
  'us-strategy-pro-1-edit': {
    name: '중상급자 Level 1',
    page: 'us-readymades/:strategyId/pro-1',
  },
  'us-strategy-simple-edit': {
    name: '간편 편집',
    page: 'us-readymades/:strategyId/simple-edit',
  },
  'us-strategy-detail-edit': {
    name: '상세 편집',
    page: 'us-readymades/:strategyId/detail-edit',
  },
  'us-strategy-diy-edit': {
    name: 'DIY 편집 ',
    page: 'us-readymades/:strategyId/diy-edit',
  },
  'us-strategy-master-edit': {
    name: '대가들의 전략 편집',
    page: 'us-readymades/:strategyId/master-edit',
  },

  'us-strategy-beginner-1-re-edit': {
    name: '초급자 Level 1',
    page: 'us-readymades/:strategyId/beginner-1/re',
  },
  'us-strategy-beginner-2-re-edit': {
    name: '초급자 Level 2',
    page: 'us-readymades/:strategyId/beginner-2/re',
  },
  'us-strategy-pro-1-re-edit': {
    name: '중상급자 Level 1',
    page: 'us-readymades/:strategyId/pro-1/re',
  },
  'us-strategy-simple-re-edit': {
    name: '간편 다시 편집',
    page: 'us-readymades/:strategyId/simple-edit/re',
  },
  'us-strategy-detail-re-edit': {
    name: '상세 다시 편집',
    page: 'us-readymades/:strategyId/detail-edit/re',
  },
  'us-strategy-diy-re-edit': {
    name: 'DIY 다시 편집 ',
    page: 'us-readymades/:strategyId/diy-edit/re',
  },
  'us-strategy-master-re-edit': {
    name: '대가들의 전략 다시 편집',
    page: 'us-readymades/:strategyId/master-edit/re',
  },

  'us-strategy-edit-result': {
    name: '백테스팅 결과',
    page: 'us-readymades/:strategyId/edit-result',
  },
  'my-us-strategy-edit-result': {
    name: '백테스팅 결과',
    page: 'my-us-strategies/:strategyId/edit-result/:prevStrategyId',
  },

  'my-us-strategy-back-test': {
    name: '전략보관함-백테스트 결과',
    page: 'my-us-strategies/:strategyId/back-test',
  },
  'my-us-strategy-forward-test': {
    name: '전략보관함-전략 상세',
    page: 'my-us-strategies/:strategyId/forward-test',
  },

  'my-us-strategy-beginner-1-edit': {
    name: '초급자 Level 1',
    page: 'my-us-strategies/:strategyId/beginner-1/:prevStrategyId',
  },
  'my-us-strategy-beginner-2-edit': {
    name: '초급자 Level 2',
    page: 'my-us-strategies/:strategyId/beginner-2/:prevStrategyId',
  },
  'my-us-strategy-pro-1-edit': {
    name: '중상급자 Level 1',
    page: 'my-us-strategies/:strategyId/pro-1/:prevStrategyId',
  },
  'my-us-strategy-simple-edit': {
    name: '간편 편집',
    page: 'my-us-strategies/:strategyId/simple-edit/:prevStrategyId',
  },
  'my-us-strategy-detail-edit': {
    name: '상세 편집',
    page: 'my-us-strategies/:strategyId/detail-edit/:prevStrategyId',
  },
  'my-us-strategy-master-edit': {
    name: '대가 편집',
    page: 'my-us-strategies/:strategyId/master-edit/:prevStrategyId',
  },
  'my-us-strategy-diy-edit': {
    name: '상세 편집 DIY',
    page: 'my-us-strategies/:strategyId/detail-edit-diy/:prevStrategyId',
  },
};

export const PAGE_KR_INFO: Record<PageKrType, PageInfoModel> = {
  readymades: {
    name: '전략 선택 (한국)',
    page: 'readymades',
  },
  'readymade-detail': {
    name: '전략 상세',
    page: 'readymades/:strategyId',
  },
  'readymade-detail-master': {
    name: '대가들의 전략 상세',
    page: 'readymades/:strategyId/master',
  },

  'strategy-beginner-1-edit': {
    name: '비기너 Level 1',
    page: 'readymades/:strategyId/beginner-1',
  },
  'strategy-beginner-2-edit': {
    name: '비기너 Level 2',
    page: 'readymades/:strategyId/beginner-2',
  },
  'strategy-pro-1-edit': {
    name: '프로 Level 1',
    page: 'readymades/:strategyId/pro-1',
  },
  'strategy-simple-edit': {
    name: '간편 편집',
    page: 'readymades/:strategyId/simple-edit',
  },
  'strategy-detail-edit': {
    name: '상세 편집',
    page: 'readymades/:strategyId/detail-edit',
  },
  'strategy-diy-edit': {
    name: 'DIY 편집 ',
    page: 'readymades/:strategyId/diy-edit',
  },
  'strategy-master-edit': {
    name: '대가들의 전략 편집',
    page: 'readymades/:strategyId/master-edit',
  },

  'strategy-beginner-1-re-edit': {
    name: '비기너 Level 1',
    page: 'readymades/:strategyId/beginner-1/re',
  },
  'strategy-beginner-2-re-edit': {
    name: '비기너 Level 2',
    page: 'readymades/:strategyId/beginner-2/re',
  },
  'strategy-pro-1-re-edit': {
    name: '프로 Level 1',
    page: 'readymades/:strategyId/pro-1/re',
  },
  'strategy-simple-re-edit': {
    name: '간편 다시 편집',
    page: 'readymades/:strategyId/simple-edit/re',
  },
  'strategy-detail-re-edit': {
    name: '상세 다시 편집',
    page: 'readymades/:strategyId/detail-edit/re',
  },
  'strategy-diy-re-edit': {
    name: 'DIY 다시 편집 ',
    page: 'readymades/:strategyId/diy-edit/re',
  },
  'strategy-master-re-edit': {
    name: '대가들의 전략 다시 편집',
    page: 'readymades/:strategyId/master-edit/re',
  },

  'strategy-edit-result': {
    name: '백테스팅 결과',
    page: 'readymades/:strategyId/edit-result',
  },
  'my-strategy-edit-result': {
    name: '백테스팅 결과',
    page: 'my-strategies/:strategyId/edit-result/:prevStrategyId',
  },

  'my-strategy-back-test': {
    name: '전략보관함-백테스트 결과',
    page: 'my-strategies/:strategyId/back-test',
  },
  'my-strategy-forward-test': {
    name: '전략보관함-전략 상세',
    page: 'my-strategies/:strategyId/forward-test',
  },

  'my-strategy-beginner-1-edit': {
    name: '비기너 Level 1',
    page: 'my-strategies/:strategyId/beginner-1/:prevStrategyId',
  },
  'my-strategy-beginner-2-edit': {
    name: '비기너 Level 2',
    page: 'my-strategies/:strategyId/beginner-2/:prevStrategyId',
  },
  'my-strategy-pro-1-edit': {
    name: '프로 Level 1',
    page: 'my-strategies/:strategyId/pro-1/:prevStrategyId',
  },
  'my-strategy-simple-edit': {
    name: '간편 편집',
    page: 'my-strategies/:strategyId/simple-edit/:prevStrategyId',
  },
  'my-strategy-detail-edit': {
    name: '상세 편집',
    page: 'my-strategies/:strategyId/detail-edit/:prevStrategyId',
  },
  'my-strategy-master-edit': {
    name: '대가 편집',
    page: 'my-strategies/:strategyId/master-edit/:prevStrategyId',
  },
  'my-strategy-diy-edit': {
    name: '상세 편집 DIY',
    page: 'my-strategies/:strategyId/detail-edit-diy/:prevStrategyId',
  },
};

export const PAGE_INFO: Record<PageType, PageInfoModel> = {
  ...PAGE_COMMON_INFO,
  ...PAGE_KR_INFO,
  ...PAGE_US_INFO,
};

export const EXTERNAL_PAGE_INFO: Record<ExternalPageType, PageInfoModel> = {
  'direct-indexing': {
    name: '다이렉트인덱싱 알아보기',
    page: 'direct-indexing',
  },
};
