/* eslint-disable react-hooks/exhaustive-deps */
import { PAGE_INFO } from '@constants/router.const';
import env from '@core/env';
import { getCi, getToken } from '@core/token';
import { Button } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { TextField } from '@ui/components/TextField';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutContainer } from './layout/LayoutContainer';

interface MainRendingPageContainerProps {}

export const MainRendingPageContainer: FC<
  MainRendingPageContainerProps
> = () => {
  // CI 없는 환경에서의 테스트를 위한 임시 처리
  const [testId, setTestId] = useState('');
  const [testToken, setTestToken] = useState('');

  const nav = useNavigate();
  const moveToStorage = () => {
    nav(PAGE_INFO['my-strategies'].page);
  };

  const handleSignOn = async () => {
    const info = await getToken(getCi(testId));
    setTestToken(info ? info.token : '');
  };

  if (env.production) return null;

  return (
    <LayoutContainer onClickPrev={() => {}}>
      <FlexBox mt={40}>
        <TextField
          value={testId}
          placeholder="웹 테스트용 ID (비번 필요없음)"
          onClickReset={() => setTestId('')}
          onChangeValue={(value) => setTestId(`${value}`)}
        />
        <Button color="fixedblack" onClick={handleSignOn}>
          로그인
        </Button>
      </FlexBox>
      <FlexBox mt={12}>
        <TextField
          value={testToken}
          placeholder="Token값 채워질 TextField"
          disabled
        />
      </FlexBox>
      <FlexBox mt={40}>
        <Button color="fixedblack" onClick={moveToStorage}>
          전략보관함으로 이동하기
        </Button>
      </FlexBox>
    </LayoutContainer>
  );
};
