import { STRATEGY_EDIT_NOTIFICATION_TEXT } from '@constants/strategy/strategy-edit.const';
import { StrategyEditHoldingNotiType } from '@models/strategy';
import { Typography } from '@ui/components';
import { ThemeColorType } from '@ui/model';
import React, { FC } from 'react';
import styled from 'styled-components';

interface StockInfoBoxProps {
  code?: StrategyEditHoldingNotiType;
}

const StockInfoBoxWrap = styled.div`
  padding-bottom: 12px;
`;

const InfoBox = styled.div`
  background-color: ${({ theme }) =>
    theme.colorPalette.background.general_button_2};
  border-radius: 4px;
  padding: 6px;

  & > pre {
    font-family: 'KBFG Text';
    letter-spacing: -0.8px;
  }
`;

export const StockInfoBox: FC<StockInfoBoxProps> = ({ code }) => {
  if (!code) return null;
  let textColor: ThemeColorType = 'gray.blue';

  switch (code) {
    case 'AT_LEAST_1':
    case 'AT_LEAST_1_ADDITIONAL_STOCK':
    case 'MORE_BOOKSIZE':
    case 'LESS_THAN_SHARE':
      textColor = 'state.red';
      break;

    case 'MAXIMAL_WEIGHT':
    case 'OVER_95_PERCENT':
      textColor = 'gray.blue';
      break;
  }

  return (
    <StockInfoBoxWrap>
      <InfoBox>
        <Typography variant="tag" color={textColor} as="pre">
          {STRATEGY_EDIT_NOTIFICATION_TEXT[code]}
        </Typography>
      </InfoBox>
    </StockInfoBoxWrap>
  );
};
