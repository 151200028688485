import { useEffect, useState } from 'react';

const getViewport = () => ({
  width: Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ),
  height: Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ),
});

const getVisualViewport = () => {
  const width = !!window.visualViewport ? window.visualViewport.width : 0;
  const height = !!window.visualViewport ? window.visualViewport.height : 0;

  return {
    width,
    height,
  };
};

export default function useVisualViewport() {
  const [viewport, setViewport] = useState(getViewport());
  const [visualViewport, setVisualViewport] = useState(getVisualViewport());

  useEffect(() => {
    const handleResize = () => {
      setVisualViewport(getVisualViewport());
      setViewport(getViewport());
    };

    window.visualViewport?.addEventListener('resize', handleResize);
    return () => {
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, []);

  return { viewport, visualViewport };
}
