import {
  BaseStyledInput,
  BottomLine,
  DescWrapper,
  ErrorIconBox,
  ResetBtn,
  TextFieldWrapper,
  UnitText,
} from '@ui/base/BaseStyledInput';
import { InputUIHTMLComponentProps, ThemedInputProps } from '@ui/model';
import { unComma } from '@utils/common';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { FlexBox } from './Flexbox';
import { Icon } from './Icon';

interface TextFieldProps extends InputUIHTMLComponentProps, ThemedInputProps {
  ref?: React.Ref<HTMLInputElement>;
  helperMsg?: string;

  /** 단위 텍스트 */
  unit?: string;

  /** Error 여부 */
  error?: boolean;

  /** 에러 메시지 */
  errorMsg?: string;

  /** 검색 가능 여부  */
  search?: 'left' | 'right';

  /** 타당검 검증과 별개로 무조건 실행되어야하는 함수 */
  onBlurForce?: () => void;

  /** 타당검 검증 함수 - 조건식 만족하면 값 변환 */
  onBlurValidator?: (value: number | string | readonly string[]) => boolean;

  /** 타당검 검증과 별개로 무조건 실행되어야하는 함수 */
  onFocusForce?: () => void;

  /** 타당검 검증 함수 - 조건식 만족하면 값 변환 */
  onFocusValidator?: (value: number | string | readonly string[]) => boolean;

  /** Value Reset Callback Function */
  onClickReset?: () => void;

  /** Search Callback Function */
  onClickSearch?: () => void;

  /** Value Change Callback Function */
  onChangeValue?: (value: number | string) => void;

  /** 타당검 검증 함수 - 조건식 만족하면 값 변환 */
  onChangeValidator?: (value: number | string | readonly string[]) => boolean;
}

const filterCommaValue = (value: string) =>
  Number(unComma(value)).toLocaleString();

export const TextField: React.ComponentType<TextFieldProps> = React.forwardRef<
  HTMLInputElement,
  TextFieldProps
>(
  (
    {
      align = 'left',
      disabled,
      height = '20px',
      error,
      errorMsg,
      fontScale,
      helperMsg,
      maxLength,
      placeholder,
      search,
      type,
      unit,
      value = '',
      variant,
      onFocus,
      onFocusForce,
      onFocusValidator,
      onBlur,
      onBlurForce,
      onBlurValidator,
      onClickReset,
      onClickSearch,
      onChangeValue,
      onChangeValidator,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const [enableReset, setEnableReset] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (onClickSearch) {
        /** e.keyCode - Deprecated 되었지만 모바일 엔터 적용을 위해서 필요함 */
        if (e.code === 'Enter' || e.keyCode === 13) {
          onClickSearch();
          e.currentTarget.blur();
        }
      }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocus(true);
      if (!error && align === 'left' && !!inputValue) {
        setEnableReset(true);
      }
      if (onFocusForce) {
        onFocusForce();
      }
      if (onFocus) {
        if (onFocusValidator && !onFocusValidator(inputValue)) {
          setInputValue(value);
          return;
        }
        onFocus(e);
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocus(false);
      if (onBlurForce) {
        onBlurForce();
      }
      if (onBlur) {
        if (onBlurValidator && !onBlurValidator(inputValue)) {
          setInputValue(value);
          return;
        }
        onBlur(e);
      }
    };

    const handleReset = () => {
      setInputValue('');
      if (onClickReset) {
        onClickReset();
      }
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value;

      // (WebView AOS Only) maxLength 적용 안되는 이슈 처리를 위함
      if (maxLength && value.length > maxLength) {
        return;
      }

      if (onChangeValidator && !onChangeValidator(value)) return;

      setInputValue(value);
      if (type === 'number') {
        setInputValue(filterCommaValue(value));
      }
      if (onChangeValue) {
        onChangeValue(value);
      }

      if (!error && align === 'left' && !!value) {
        setEnableReset(true);
      }
    };

    useEffect(() => {
      if (!inputValue) {
        setEnableReset(false);
      }
    }, [inputValue]);

    // FIXME: 추후 이 부분 포함해서 리팩토링
    useEffect(() => {
      setInputValue(
        type === 'number' ? filterCommaValue(String(value)) : value
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <TextFieldWrapper isFocus={isFocus} isError={error} variant={variant}>
        {search === 'left' && (
          <ResetBtn onClick={onClickSearch}>
            <Icon type="search" size={24} />
          </ResetBtn>
        )}
        {error && align === 'right' && (
          <ErrorIconBox>
            <Icon type="error" size={12} />
          </ErrorIconBox>
        )}
        <BaseStyledInput
          {...props}
          ref={ref as never}
          $align={align}
          $height={height}
          disabled={disabled}
          fontScale={fontScale}
          inputMode={type === 'number' ? 'numeric' : undefined}
          maxLength={maxLength}
          pattern={type === 'number' ? 'd*' : undefined}
          placeholder={placeholder}
          type={'text'}
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
        {enableReset && (
          <ResetBtn onClick={handleReset}>
            <Icon type="list-delete" size={14} />
          </ResetBtn>
        )}

        {unit && align === 'right' && (
          <FlexBox flexShrink={0} ml={4}>
            <UnitText variant="caption" disabled={disabled}>
              {unit}
            </UnitText>
          </FlexBox>
        )}
        {error && align === 'left' && (
          <ErrorIconBox>
            <Icon type="error" size={12} />
          </ErrorIconBox>
        )}
        {search === 'right' && (
          <ResetBtn onClick={onClickSearch}>
            <Icon type="search" size={24} />
          </ResetBtn>
        )}
        {!variant && isFocus && <BottomLine isError={error} />}
        {error && errorMsg && <DescWrapper>{errorMsg}</DescWrapper>}
      </TextFieldWrapper>
    );
  }
);
