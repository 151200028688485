import { LOADING_MESSAGES } from '@constants/feedback';
import { SIMULATION_TYPE_MAPPER } from '@constants/strategy';
import { useConfirmStore } from '@stores/feedback';
import { useMyStrategyStore } from '@stores/my-strategy';
import { useMyStrategyListStore } from '@stores/my-strategy-list';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * 내 전략 상세(MyStrategy) 정보 조회를 위한 커스텀 훅. 
 * 자주 사용되는 내 전략 상세 호출 로직을 커스텀 훅으로 분리하여 재사용성을 높임.
 * @param _simulationType 'ALL' | 'BACKTEST' | 'FORWARDTEST'
 * @returns
 * @group Hooks
 *
 * @example
 * const {
    strategyId,
    portfolioId,
    title,
    isForwardAvailable,
    isActiveKbContract,
  } = useMyStrategyLoader('BACKTEST'); // 전략보관함/백테스트 결과 페이지
 */
export const useMyStrategyLoader = (
  _simulationType: 'ALL' | 'BACKTEST' | 'FORWARDTEST'
) => {
  const { strategyId = '' } = useParams<string>();
  const [encryptedId, setEncryptedId] = useState(strategyId);
  const [apiStatus, setApiStatus] = useState('init');
  const [enableContract, setEnableContract] = useState(true);
  const { openConfirm, closeConfirm } = useConfirmStore();
  const {
    init,
    fetchMyStrategy,
    fetchDetailedMyStrategy,
    fetchMyStrategyStyle,
    fetchMyStrategyBlacklist,
    fetchRebalancingHistory,
    fetchHoldingPositionAll,
    fetchExposure,
    portfolioId,
    name,
    createdAt,
    isForwardAvailable,
    isActiveKbContract,
  } = useMyStrategyStore();
  const { checkIsActiveMyStrategy } = useMyStrategyListStore();

  const okText = useMemo(() => {
    if (isActiveKbContract) {
      return '리밸런싱 하기';
    }

    if (enableContract) {
      return '투자 시작하기';
    }

    return '전략 편집하기';
  }, [isActiveKbContract, enableContract]);

  const simulationType = SIMULATION_TYPE_MAPPER[_simulationType];

  useEffect(() => {
    window.scrollTo(0, 0);
    setApiStatus('loading');
    openConfirm('loading')({
      descriptions: LOADING_MESSAGES.loadStrategy,
    });
    fetchMyStrategy(strategyId)
      .then((myStrategyInfo) => {
        if (!myStrategyInfo) throw new Error();
        const { id: myStrategyId, category } = myStrategyInfo;
        setEncryptedId(myStrategyId);
        Promise.all([
          fetchDetailedMyStrategy(
            {
              id: myStrategyId,
              simulationType,
            },
            category === 'DIY' || category === 'MASTER'
          ),
          fetchMyStrategyStyle(
            {
              myStrategyId,
              simulationType,
            },
            category
          ),
          fetchMyStrategyBlacklist(myStrategyId),
          fetchExposure({
            id: myStrategyId,
            simulationType,
          }),
          fetchHoldingPositionAll({
            id: myStrategyId,
            simulationType,
          }),
          fetchRebalancingHistory({
            id: myStrategyId,
            simulationType,
          }),
          checkIsActiveMyStrategy(myStrategyId).then((result) => {
            if (!result) {
              setEnableContract(false);
            }
          }),
        ])
          .then(() => {
            closeConfirm('loading');
            setApiStatus('success');
          })
          .catch(() => {
            closeConfirm('loading');
            setApiStatus('error');
          });
      })
      .catch(() => {
        closeConfirm('loading');
        setApiStatus('error');
      });

    return () => {
      init();
      setApiStatus('init');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    apiStatus,
    enableContract,
    strategyId,
    encryptedId,
    portfolioId,
    title: name,
    createdAt,
    isForwardAvailable,
    isActiveKbContract,
    okText,
  };
};
