import { create } from 'zustand';

interface ToastState {
  isOpen: boolean;
  text: string;
  closeToast: () => void;
  openToast: (text: string) => void;
}

export const useToastStore = create<ToastState>((set) => ({
  isOpen: false,
  text: '',
  closeToast: () => {
    set(() => ({ isOpen: false }));
  },
  openToast: (text) => {
    set(() => ({ isOpen: true, text }));
  },
}));
