import { STRATEGY_EDIT_TOAST_MESSAGE } from '@constants/strategy/strategy-edit.const';
import { StockItemModel } from '@models/strategy';
import { useConfirmStore } from '@stores/feedback';
import { useToastStore } from '@stores/feedback/toast.store';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { isNumber } from 'lodash';

export const useHoldingListEvent = () => {
  const {
    whitelistObj,
    removeStockItem,
    resetStockItem,
    changeStockWeightByInput,
    changeStockWeight,
  } = useStrategyEditStore();
  const { openToast } = useToastStore();
  const { openConfirm } = useConfirmStore();

  const handleOpenConfirm = () => {
    openConfirm('alert')({
      title: '종목 구성',
      descriptions: [
        '기본 종목이 3개 이하일 땐 비중 조정 및 종목 제외를 할 수 없습니다.',
      ],
      button: 'single',
    });
  };

  /**
   * 종목의 '+' | '-' 버튼 클릭시 실행되는 함수
   * @param type
   */
  const handleClickWeightBtn =
    (type: 'plus' | 'minus') => (stock: StockItemModel) => () => {
      if (!stock.isEditable) {
        handleOpenConfirm();
        return;
      }

      let weight = stock.weight;

      if (type === 'plus') {
        const whitelistValues = Object.values(whitelistObj);
        const sumOfWhiteListWeight = whitelistValues.reduce((prev, cur) => {
          return prev + cur.weight;
        }, 0);

        if (sumOfWhiteListWeight + stock.weightPerShare > 0.95) {
          openToast(STRATEGY_EDIT_TOAST_MESSAGE['NEED_MODIFY_WEIGHT']);
          return;
        }

        weight = stock.weight + stock.weightPerShare;
        weight = Math.ceil(weight * 10_000_000) / 10_000_000;
      }

      if (type === 'minus') {
        weight = stock.weight - stock.weightPerShare;
        weight = Math.ceil(weight * 10_000_000) / 10_000_000;
      }

      changeStockWeight({
        ccid: stock.ccid,
        weight,
        isAdd: !!stock.isAdd,
        isLastEdit: true,
      });
    };

  /**
   * 종목에서 'X'버튼 클릭시(제거)
   * - 해당 종목이 추가된 종목이면 추가된 종목(white-list)에서 제거
   * - 해당 종목이 기존에 있던 종목이면 제외 종목(black-list) 리스트에 추가
   *
   * 바뀐 리스트를 바탕으로 C2 API로부터 종목을 받아온다.
   */
  const handleClickExclude = (stock: StockItemModel) => () => {
    if (!stock.isEditable) {
      handleOpenConfirm();
      return;
    }

    removeStockItem(stock);
  };

  /**
   * 🔄 (비중 고정 오른쪽에 위치함) 버튼 클릭시 실행되는 함수
   * - 추가된 종목의 경우 비중이 0으로 돌아가고
   * - 기존에 있던 종목의 경우 고정된 비중을 제외하고서 전략에 따라 설정되는 비중만큼 재설정 or 제외 된다.
   */
  const handleClickRefresh = (stock: StockItemModel) => () => {
    resetStockItem(stock);
  };

  /**
   * Input 창에서 Blur 되었을 경우 실행되는 함수
   * - 입력된 값이 숫자가 아니거나 빈 값이면 기존의 비중으로 돌아간다.
   * - '이동' | 'Go' 키패드 클릭시 실행됨
   */
  const handleBlurWeight =
    (stock: StockItemModel) => (weightString: string) => {
      let weight = +weightString;
      if (!isNumber(weight) || weightString === '') {
        weight = stock.weight;
      } else {
        weight = weight / 100;
      }

      changeStockWeightByInput({
        ccid: stock.ccid,
        weight,
        isAdd: !!stock.isAdd,
        isLastEdit: true,
      });
    };

  const handleFocusWeight = (stock: StockItemModel) => () => {
    const isEditable = !!stock.isEditable;
    if (!isEditable) {
      // 수정 불가능한 종목의 경우 Focus 시 알림창을 띄워준다.
      handleOpenConfirm();
    }
    return !!stock.isEditable;
  };

  return {
    handleClickWeightBtn,
    handleClickExclude,
    handleClickRefresh,
    handleBlurWeight,
    handleFocusWeight,
  };
};
