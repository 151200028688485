import { COUNTRY_MAPPER } from '@constants/common';
import { useEditController } from '@hooks/edit/useEditController';
import { CountryCodeType } from '@models/common';
import { StrategyCategoryType } from '@models/strategy';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { Typography } from '@ui/components';
import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DIYEditContainer } from './DIYEditContainer';
import { ThemeEditContainer } from './ThemeEditContainer';

interface DetailEditPageContainerProps {
  category?: StrategyCategoryType;
  renew?: boolean;
  reEdit?: boolean;
  prevStrategyId?: string;
  countryCode?: CountryCodeType;
}

export const EditCancelBtn = (onClose: () => void) => {
  return (
    <Typography color="secondary.text" variant="body2_title" onClick={onClose}>
      편집 취소
    </Typography>
  );
};

export const DetailEditPageContainer: FC<DetailEditPageContainerProps> = ({
  category = 'THEME',
  renew = false,
  reEdit = false,
  prevStrategyId = '',
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const { fetchStrategy } = useReadymadeStrategyStore();
  const {
    updateRenewAndReEdit,
    fetchSectors,
    fetchMySectors,
    fetchFactors,
    fetchMyFactors,
    fetchMySimulationOption,
    setPrevSimulationOptionScreening,
    sectorInfoList,
  } = useStrategyEditStore();

  const { handleClickComplete, handleCancel } = useEditController({
    countryCode,
    renew,
    reEdit,
    prevStrategyId,
  });

  const { strategyId = '' } = useParams<string>();
  const [, setSearchParams] = useSearchParams();

  const handleReplaceStep = (index: number) => {
    let query = `step=${index}`;
    if (reEdit) query += `&reEdit=${reEdit}`;
    setSearchParams(query, { replace: true });
  };

  useEffect(() => {
    if (renew || reEdit) {
      Promise.all([
        fetchMySectors(strategyId),
        fetchMyFactors(strategyId),
        fetchMySimulationOption(strategyId),
      ]).then(() => {
        setPrevSimulationOptionScreening(false);
        updateRenewAndReEdit(renew, reEdit);
      });
    } else {
      if (sectorInfoList.length === 0) {
        fetchSectors();
      }
      fetchFactors({
        strategyId,
      });

      // 전략 카테고리 세팅을 위한 호출
      fetchStrategy(strategyId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (category === 'DIY') {
    return (
      <DIYEditContainer
        countryCode={countryCode}
        onReplaceStep={handleReplaceStep}
        onClose={handleCancel}
        onOk={handleClickComplete}
      />
    );
  }

  return (
    <ThemeEditContainer
      countryCode={countryCode}
      onReplaceStep={handleReplaceStep}
      onClose={handleCancel}
      onOk={handleClickComplete}
    />
  );
};
