import { GetTrackProps, SliderItem } from 'react-compound-slider';
import styled, { css } from 'styled-components';

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
}

interface TrackWrapProps {
  left: number;
  width: number;
}

const TrackWrap = styled.div<TrackWrapProps>`
  position: absolute;
  transform: translate(0%, -50%);
  height: 4px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colorPalette.kb.yellow};
  border-radius: 7px;
  cursor: pointer;
  ${({ left, width }) => {
    return css`
      left: ${left}%;
      width: ${width}%;
    `;
  }}
`;

export const Track: React.FC<TrackProps> = ({
  source,
  target,
  getTrackProps,
}) => {
  const left = source.percent;
  const width = target.percent - left;
  return <TrackWrap left={left} width={width} {...getTrackProps()} />;
};
