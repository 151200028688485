import {
  RISK_ASSESSMENT_INDEX_ENUM,
  RISK_ASSESSMENT_INDEX_LOCALE,
  STRATEGY_STYLE_ENUM,
  UNIVERSE_LOCALE,
  UNIVERSE_MAPPER,
} from '@constants/strategy';
import {
  GetDetailedMyStrategyQuery,
  GetMyCumulativeReturnQuery,
  GetMyExposureQuery,
  GetMyHoldingPositionAllQuery,
  GetMyRebalancingHistoryQuery,
  GetMyRiskAssessmentIndexQuery,
  GetMyStrategyBlacklistQuery,
  GetMyStrategyByPortfolioIdQuery,
  GetMyStrategyQuery,
  GetMyStrategyStyleQuery,
} from '@graphql/generates';
import { ChartWeightDto, ProfitChartModel, TickDto } from '@models/common';
import {
  DetailedMyStrategyInfoModel,
  ExposureDto,
  ExposureModel,
  HoldingPositionModel,
  RiskAssessmentIndexHeadItemModel,
  RiskAssessmentIndexModel,
  StrategyCategoryType,
  StrategyStyleModel,
  UniverseType,
} from '@models/strategy';
import {
  createBaseDate,
  createHoldingPositions,
  getRebalancingHistoryModel,
  transFloatToPercentage,
} from '@stores/common';
import { filterNonDataOfArray, numberFixedWithLimit } from '@utils/common';
import { createBenchmarkOptions } from '..';
import {
  createCash,
  createExchangeRateInfo,
  createForwardSummary,
  createForwardSummaryExchange,
  createMyChartData,
  createMyStrategyInfo,
} from './my-strategy.helper';

export const convertMyStrategy = (data: GetMyStrategyQuery) => {
  const info = data.myStrategy?.myStrategy;

  if (!info) return;

  return createMyStrategyInfo(info);
};

export const convertMyStrategyByPortfolioId = (
  data: GetMyStrategyByPortfolioIdQuery
) => {
  const info = data.myStrategyByPortfolioId?.myStrategy;

  if (!info) return;

  return createMyStrategyInfo(info);
};

export const convertDetailedMyStrategy = (data: GetDetailedMyStrategyQuery) => {
  const info = data.detailedMyStrategy?.detailedMyStrategy;

  if (!info) return;

  const {
    name,
    baseDate,
    myStrategy,
    strategy,
    benchmark,
    benchmarks,
    forwardSummary,
    forwardSummaryExchange,
    exchangeRateInfo,
    createdExchangeRateInfo,
  } = info;

  const _baseDate = createBaseDate(baseDate);
  const baseCumulative = {
    cumulative: strategy?.cumulative || 0,
    exchangeCumulative: strategy?.exchangeCumulative || 0,
  };
  const benchmarkCumulative = {
    cumulative: benchmark?.cumulative || 0,
    exchangeCumulative: benchmark?.exchangeCumulative || 0,
  };
  const myCumulative = {
    cumulative: myStrategy?.cumulative || 0,
    exchangeCumulative: myStrategy?.exchangeCumulative || 0,
  };

  const detailedStrategyInfo: DetailedMyStrategyInfoModel = {
    name,
    baseDate: _baseDate,
    baseCumulativeReturnRate: baseCumulative,
    benchmarkCumulativeReturnRate: benchmarkCumulative,
    myCumulativeReturnRate: myCumulative,
    forwardSummary: createForwardSummary(forwardSummary),
    forwardSummaryExchange: createForwardSummaryExchange(
      forwardSummaryExchange
    ),
    exchangeRateInfo: createExchangeRateInfo(exchangeRateInfo),
    createdExchangeRateInfo: createExchangeRateInfo(createdExchangeRateInfo),
  };
  const _benchmarks = filterNonDataOfArray(benchmarks);

  const benchmarkOptions = createBenchmarkOptions(_benchmarks);

  return {
    detailedStrategyInfo,
    benchmarkOption: _benchmarks[0] || UNIVERSE_MAPPER.KOSPI,
    benchmarkOptions,
  };
};

export const convertMyStrategyStyle = (
  data: GetMyStrategyStyleQuery,
  category: StrategyCategoryType
) => {
  const info = data.myStrategyStyle?.info;

  if (!info) return;

  const {
    benchmark,
    strategy,
    myStrategy,
    benchmarkName,
    strategyName,
    myStrategyName,
    baseDate,
    factorInfo,
  } = info;

  const _myStrategyName = myStrategyName || undefined;
  const _strategyName = strategyName || undefined;
  const _benchmarkName = benchmarkName
    ? UNIVERSE_LOCALE[benchmarkName as UniverseType]
    : undefined;
  const ticks: TickDto[] = [];

  STRATEGY_STYLE_ENUM.forEach((factorStylekey) => {
    const tick: TickDto = { name: '' };
    if (myStrategy && myStrategy[factorStylekey]) {
      tick.myStrategy = myStrategy[factorStylekey]?.value || 0;
      tick.name = String(myStrategy[factorStylekey]?.name);
    }
    if (strategy && strategy[factorStylekey]) {
      tick.base = strategy[factorStylekey]?.value || 0;
      if (!tick.name) {
        tick.name = String(strategy[factorStylekey]?.name);
      }
    }
    if (benchmark && benchmark[factorStylekey]) {
      tick.benchmark = benchmark[factorStylekey]?.value || 0;
      if (!tick.name) {
        tick.name = String(benchmark[factorStylekey]?.name);
      }
    }
    ticks.push(tick);
  });

  const chartData: ProfitChartModel = {
    myStrategyName: _myStrategyName,
    strategyName: _strategyName,
    benchmarkName: _benchmarkName,
    data: ticks,
  };

  const detailInfo = filterNonDataOfArray(factorInfo).map(({ name, value }) => {
    /**
     * 대가들의 전략일 경우에는 정수로만 표시 ex: 4 ~ 7
     * 값이 같을 경우에는 한가지 값만 표시 ex: 1 ~ 1
     */
    if (category === 'MASTER') {
      const valueArray = value.split('~');
      let minValue = +valueArray[0];
      let maxValue = +valueArray[1];

      let range = `${minValue} ~ ${maxValue}`;
      if (minValue === maxValue) {
        range = `${minValue}`;
      }
      return [name, range];
    }

    const valueArray = value.split('~');
    if (valueArray.length !== 2) return [name, value];

    let minValue = numberFixedWithLimit(+valueArray[0], 4);
    let maxValue = numberFixedWithLimit(+valueArray[1], 4);

    if (minValue === 'NaN') {
      minValue = valueArray[0];
    }
    if (maxValue === 'NaN') {
      maxValue = valueArray[1];
    }

    let range = `${minValue} ~ ${maxValue}`;

    // TODO: 엣지 케이스 처리(소수점 4자리 같을 경우) 정해지면 수정
    if (minValue === maxValue) {
      const newMaxValue = `${+maxValue + 0.0001}`;
      range = `${minValue} ~ ${newMaxValue}`;
    }

    return [name, value.includes('~') ? range : value];
  });

  const strategyStyle: StrategyStyleModel = {
    baseDate: createBaseDate(baseDate),
    chartData,
    detailInfo,
  };

  return strategyStyle;
};

export const convertMyStrategyBlacklist = (
  data: GetMyStrategyBlacklistQuery
) => {
  const myStrategyBlacklist = data.myStrategyBlacklist?.myStrategyBlacklist;
  if (!myStrategyBlacklist) return;
  const nameList: string[] = [];
  const numberList: string[] = [];
  filterNonDataOfArray(myStrategyBlacklist).forEach((item) => {
    nameList.push(item.name);
    numberList.push(item.ccid);
  });
  return {
    nameList,
    numberList,
  };
};

export const convertMyCumulativeReturn = (data: GetMyCumulativeReturnQuery) => {
  const myCumulativeReturn = data.myCumulativeReturn?.myCumulativeReturn;

  if (!myCumulativeReturn || !myCumulativeReturn.chart) return;

  const _chart = filterNonDataOfArray(myCumulativeReturn.chart);
  const _myCumulativeReturn: ProfitChartModel = {
    ...createMyChartData(_chart),
    baseDate: createBaseDate(myCumulativeReturn.baseDate),
  };
  return _myCumulativeReturn;
};

export const convertMyRiskAssessmentIndex = (
  data: GetMyRiskAssessmentIndexQuery,
  benchmarkName: UniverseType,
  isDiy: boolean
): RiskAssessmentIndexModel | undefined => {
  const info = data.myRiskAssessmentIndex?.myRiskAssessmentIndex?.info;

  if (!info) return;

  const { benchmark, strategy, myStrategy } = info;
  const textNameList: string[] = [];
  textNameList.push('나의 전략');
  if (!isDiy) {
    // DIY 아닌 경우 프리셋 정보 추가
    textNameList.push('프리셋');
  }
  textNameList.push(UNIVERSE_LOCALE[benchmarkName]);
  const columnWidth = textNameList.length === 3 ? 80 : 120;
  const head: RiskAssessmentIndexHeadItemModel[] = [
    {
      text: '구분',
      width: 80,
    },
    ...textNameList.map((text) => ({
      text,
      width: columnWidth,
    })),
  ];

  const standardData: string[][] = [];
  const yearData: string[][] = [];

  RISK_ASSESSMENT_INDEX_ENUM.forEach((key) => {
    let myStrategyValue = '-';
    let strategyValue = '-';
    let benchmarkValue = '-';

    const isSharpe = key === 'sharpe';

    if (myStrategy?.[key] && myStrategy?.[key] !== null) {
      const num = myStrategy?.[key] || 0;
      myStrategyValue = isSharpe
        ? numberFixedWithLimit(num, 2)
        : transFloatToPercentage(num);
    }
    if (strategy?.[key] && strategy?.[key] !== null) {
      const num = strategy?.[key] || 0;
      strategyValue = isSharpe
        ? numberFixedWithLimit(num, 2)
        : transFloatToPercentage(num);
    }
    if (benchmark?.[key] && benchmark?.[key] !== null) {
      const num = benchmark?.[key] || 0;
      benchmarkValue = isSharpe
        ? numberFixedWithLimit(num, 2)
        : transFloatToPercentage(num);
    }
    const row = [RISK_ASSESSMENT_INDEX_LOCALE[key], myStrategyValue];
    if (!isDiy) {
      // DIY 아닌 경우 프리셋 정보 추가
      row.push(strategyValue);
    }
    row.push(benchmarkValue);

    if (key === 'cumulative' || key === 'volatility') {
      standardData.push(row);
    } else if (key === 'annualAvgReturn' || key === 'annualAvgVolatility') {
      yearData.push(row);
    } else {
      standardData.push(row);
      yearData.push(row);
    }
  });

  return {
    head,
    data: {
      standard: standardData,
      year: yearData,
    },
  };
};

export const convertRebalancingHistory = (
  data: GetMyRebalancingHistoryQuery
) => {
  const rebalancingHistory = data.myRebalancingHistory?.myRebalancingHistory;
  if (!rebalancingHistory) return;

  const result = getRebalancingHistoryModel(rebalancingHistory);

  return result;
};

export const convertMyHoldingPositionAll = (
  data: GetMyHoldingPositionAllQuery
): HoldingPositionModel | undefined => {
  const holdingPositionAll = data.myHoldingPositionAll?.myHoldingPositionAll;

  if (!holdingPositionAll) return;

  const { baseDate, cash, position } = holdingPositionAll;
  const _baseDate = createBaseDate(baseDate);
  const positions = createHoldingPositions(
    filterNonDataOfArray(position),
    true
  );
  const cashInfo = createCash(cash);

  return {
    baseDate: _baseDate,
    cash: cashInfo,
    data: positions,
  };
};

export const convertMyExposure = (
  data: GetMyExposureQuery
): ExposureModel | undefined => {
  const myExposure = data.myExposure?.myExposure;

  if (!myExposure) return;

  const { baseDate, strategy, myStrategy } = myExposure;

  const _baseDate = createBaseDate(baseDate);

  // targetStrategies : [내 전략, 프리셋]
  const targetStrategies = [myStrategy || undefined, strategy || undefined];
  const _myExposure: ExposureDto[] = [];

  for (const targetStrategy of targetStrategies) {
    if (!targetStrategy) continue;
    const { industries, sectors } = targetStrategy;
    const _industries: ChartWeightDto[] = [];
    const _sectors: ChartWeightDto[] = [];
    if (industries) {
      for (const industry of industries) {
        if (!industry) continue;
        _industries.push({
          name: industry.name,
          weight: industry.weight,
        });
      }
    }
    if (sectors) {
      for (const sector of sectors) {
        if (!sector) continue;
        _sectors.push({
          name: sector.name,
          weight: sector.weight,
        });
      }
    }
    _myExposure.push({
      industries: _industries,
      sectors: _sectors,
    });
  }

  return {
    baseDate: _baseDate,
    data: _myExposure,
  };
};
