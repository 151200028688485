import { StackBarChartItemDto } from '@models/common';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { TooltipProps } from 'recharts';
import styled from 'styled-components';

const StackBarTooltipWrap = styled.div<{
  position: 'first' | 'last' | undefined;
}>`
  width: 82px;
  height: 32px;
  border-radius: 3px;
  background-color: #52555c;

  & > div {
    font-size: 12px;
    font-weight: 500;
    color: #ffee00;
  }

  :after {
    content: '';
    position: absolute;
    left: 36px;
    top: 24px;
    width: 12px;
    height: 12px;
    background-color: #52555c;
    border-radius: 2px;
    transform: rotate(45deg);
  }

  ${({ position }) => {
    if (position === 'first') {
      return `
        :after {
          left: 12px;
        }
      `;
    } else if (position === 'last') {
      return `
        :after {
          left: 58px;
        }
      `;
    }
  }};
`;

const DateText = styled(Typography)`
  color: #ffee00;
`;

export type TooltipPositionType = 'first' | 'last' | undefined;

export const renderBarChartTooltip =
  (pos: TooltipPositionType) => (o: TooltipProps<string, string | number>) => {
    if (!!o.position && o.position.x && o.position.x < 0) return;
    const { payload } = o;
    if (!payload || payload.length === 0) return;
    const stockInfo = payload[0].payload as StackBarChartItemDto;
    return (
      <StackBarTooltipWrap position={pos}>
        <FlexBox justify="center" alignItems="center" height="100%">
          <DateText variant="overline_title">{stockInfo.date}</DateText>
        </FlexBox>
      </StackBarTooltipWrap>
    );
  };
