import {
  ScButtonSizeProps,
  ScButtonVariantProps,
  ThemedComponentProps,
} from '@ui/model';
import { css } from 'styled-components';

export const getCssButtonVariantProps = ({
  theme,
  $variant,
}: ThemedComponentProps & ScButtonVariantProps) => {
  if ($variant === 'plain') {
    return css`
      background-color: ${theme.colorPalette.white};
      border: 1px solid ${theme.colorPalette.border.button};
    `;
  }

  if ($variant === 'general2') {
    return css`
      background-color: ${theme.colorPalette.background.general_button_2};
      border: 1px solid ${theme.colorPalette.border.chip};
    `;
  }

  return css`
    background-color: ${theme.colorPalette.kb.yellow};
  `;
};

export const getCssButtonSizeProps = ({ $size }: ScButtonSizeProps) => {
  if ($size === 'big') {
    return css`
      height: 48px;
    `;
  }
  if ($size === 'medium') {
    return css`
      height: 40px;
    `;
  }
  return css`
    height: 52px;
  `;
};

export const getCssButtonPressed = ({
  theme,
  $variant,
}: ThemedComponentProps & ScButtonVariantProps) => {
  if ($variant === 'plain') {
    return css`
      &:active:not(:disabled) {
        background-color: ${theme.colorPalette.black[3]};
      }
    `;
  }

  if ($variant === 'general2') {
    return css`
      &:active:not(:disabled) {
        background-color: ${theme.colorPalette.background
          .general_button_2_pressed};
      }
    `;
  }

  return css`
    &:active:not(:disabled) {
      background-color: ${theme.colorPalette.kb.yellow_pressed};
    }
  `;
};

export const getCssButtonDisabled = ({
  theme,
  $variant,
}: ThemedComponentProps & ScButtonVariantProps) => {
  if ($variant === 'plain') {
    return css`
      &:disabled {
        cursor: not-allowed;
        background-color: ${theme.colorPalette.white};
        opacity: 0.25;
      }
    `;
  }

  if ($variant === 'general2') {
    return css`
      &:disabled {
        cursor: not-allowed;
        background-color: ${theme.colorPalette.background.general_button_2};
        opacity: 0.25;
      }
    `;
  }

  return css`
    &:disabled {
      cursor: not-allowed;
      background-color: ${theme.colorPalette.kb.yellow};
      opacity: 0.25;
    }
  `;
};
