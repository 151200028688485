import { ScTableScrollProps } from '@ui/model';
import { css } from 'styled-components';

export const getTableDefaultCss = () => {
  return css`
    border-collapse: separate;
    border-top: 1px solid ${({ theme }) => theme.colorPalette.black[40]};
    position: relative;
  `;
};

export const getTableScrollCss = ({ $isScrollActive }: ScTableScrollProps) => {
  if ($isScrollActive) {
    return css`
      tr > th:first-child,
      tr > td:first-child {
        position: sticky;
        left: 0;

        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 11px;
          top: 0;
          bottom: -1px;
          right: -11px;
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.0001) 0.73%,
            rgba(0, 0, 0, 0.0565177) 99.84%
          );
          /* background-color: blue; */
        }
      }
    `;
  }
};

export const getTableRowDefaultCss = () => {
  return css`
    vertical-align: middle;
  `;
};

export const getTableRowBorder = () => {
  return css`
    & > th {
      font-weight: 500;
    }

    & > th:first-child,
    & > td:first-child {
      border-left: 0;
    }

    & > th:last-child,
    & > td:last-child {
      border-right: 0;
    }
  `;
};

export const getTableCellDefaultCss = () => {
  return css`
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
    border-left: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
  `;
};
