import { Badge, SelectButton } from '@components/common';
import {
  MyStrategyBasicInfoModel,
  StrategyCategoryType,
} from '@models/strategy';
import { FlexBox, ListTable, Typography } from '@ui/components';
import { FC, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

interface MyStrategyBasicInfoProps extends MyStrategyBasicInfoModel {
  category?: StrategyCategoryType;
}

const MyStrategyBasicInfoWrap = styled(FlexBox)``;

export const MyStrategyBasicInfo: FC<MyStrategyBasicInfoProps> = ({
  category,
  baseStrategyName,
  universePresetName,
  createdAt,
  sectorIndustrySummary,
  selectedCount,
}) => {
  const [open, setOpen] = useState(false);
  const tableContent = useMemo<Record<string, string | ReactNode>>(() => {
    const isBenchmark = category === 'DIY' || category === 'MASTER';
    const isSector = category === 'SECTOR';
    return {
      '전략 생성일': createdAt,
      [isBenchmark ? '벤치마크' : '프리셋(Pre-set)']: (
        <>
          <Typography variant="caption_medium" align="right">
            {category === 'MASTER'
              ? universePresetName || '없음'
              : baseStrategyName || '없음'}
          </Typography>
          {isSector && universePresetName && (
            <Typography variant="caption_medium" align="right">
              ({universePresetName})
            </Typography>
          )}
        </>
      ),
      '섹터/업종': (
        <SelectButton
          open={open}
          transparent
          color="secondary.text"
          fontWeight="medium"
          placeholder={String(selectedCount)}
          onClick={() => setOpen((o) => !o)}
        />
      ),
    };
  }, [
    createdAt,
    baseStrategyName,
    selectedCount,
    universePresetName,
    category,
    open,
  ]);
  const { sectors, industries } = sectorIndustrySummary;

  return (
    <MyStrategyBasicInfoWrap direction="column" spacing={16}>
      <ListTable tableContent={tableContent} />
      {open && (
        <FlexBox direction="column" spacing={8}>
          <FlexBox spacing={8} style={{ flexWrap: 'wrap' }}>
            {sectors.map((sector) => (
              <Badge key={`summary_sector_${sector}`}>{sector}</Badge>
            ))}
          </FlexBox>
          <FlexBox spacing={8} style={{ flexWrap: 'wrap' }}>
            {industries.map((industry) => (
              <Badge key={`summary_industry_${industry}`} active>
                {industry}
              </Badge>
            ))}
          </FlexBox>
        </FlexBox>
      )}
    </MyStrategyBasicInfoWrap>
  );
};
