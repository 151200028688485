import { EditHeadTab } from '@components/strategy-edit';
import { COUNTRY_MAPPER } from '@constants/common';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useEditController } from '@hooks/edit/useEditController';
import { CountryCodeType } from '@models/common';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { getFloaterModelByStep } from '@utils/strategy/strategy-edit.util';
import { FC, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { EditCancelBtn } from './DetailEditPageContainer';
import { SimpleFactorContainer } from './SimpleFactorContainer';
import { StockSelectorContainer } from './StockSelectorContainer';
import { StrategySettingContainer } from './StrategySettingContainer';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';

interface SimpleEditPageContainerProps {
  renew?: boolean;
  reEdit?: boolean;
  prevStrategyId?: string;
  countryCode?: CountryCodeType;
}

export const SimpleEditPageContainer: FC<SimpleEditPageContainerProps> = ({
  renew = false,
  reEdit = false,
  prevStrategyId = '',
  countryCode = COUNTRY_MAPPER.DOMESTIC,
}) => {
  const {
    holdings,
    updateRenewAndReEdit,
    fetchStyleFactors,
    fetchDefaultOptions,
    fetchMyStyleFactors,
    fetchMySimulationOption,
    setPrevSimulationOptionScreening,
  } = useStrategyEditStore();
  const { strategyId = '' } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const editStep = searchParams.get('step') || 1;

  const { handleClickComplete, handleCancel } = useEditController({
    isSimple: true,
    countryCode,
    renew,
    reEdit,
    prevStrategyId,
    investmentStrategyLevel:
      INVESTMENT_STRATEGY_LEVEL_MAPPER.INTERMEDIATE_LEVEL3,
  });

  const handleClickNext = () => {
    // Check 된 카드가 없으면 PopUp 띄우기
    // step 2번째로 이동시키기
    setSearchParams(`step=${+editStep + 1}`, { replace: true });
  };

  const handleClickPrev = () => {
    setSearchParams(`step=${+editStep - 1}`, { replace: true });
  };

  const handleClickTabButton = (index: number) => () => {
    setSearchParams(`step=${index + 1}`, { replace: true });
  };

  useEffect(() => {
    if (renew || reEdit) {
      Promise.all([
        fetchMyStyleFactors(strategyId),
        fetchMySimulationOption(strategyId),
      ]).then(() => {
        setPrevSimulationOptionScreening(true);
        updateRenewAndReEdit(renew, reEdit);
      });
    } else {
      fetchDefaultOptions(strategyId);
      fetchStyleFactors({ country: countryCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainer
      title={`전략편집 ${editStep}/3단계`}
      floater={getFloaterModelByStep({
        step: +editStep,
        len: 3,
        okDisabled: +editStep === 3 && holdings.length === 0, // 종목구성 데이터 없는 경우 버튼 비활성화
        onClickCancel: handleClickPrev,
        onClickOk: handleClickNext,
        onClickComplete: handleClickComplete,
      })}
      optionalButton={EditCancelBtn(handleCancel)}
      onClickPrev={handleCancel}
    >
      <EditHeadTab
        step={+editStep}
        type="simple"
        onClickTab={handleClickTabButton}
        enableDesc={holdings.length !== 0}
      />
      {+editStep === 1 && <SimpleFactorContainer />}
      {+editStep === 2 && (
        <StrategySettingContainer countryCode={countryCode} />
      )}
      {+editStep === 3 && <StockSelectorContainer simpleMode />}
    </LayoutContainer>
  );
};
