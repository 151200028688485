import { EditOptionType } from '@models/feedback';
import { StrategyCategoryType } from '@models/strategy';
import { useLayoutStore } from '@stores/ui';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

interface EditProgressTagProps {
  type: EditOptionType;
  category: StrategyCategoryType;
}

const EditProgressTagWrap = styled.div`
  margin-top: 2px;
  margin-bottom: 4px;
`;

export const EditProgressTag: FC<EditProgressTagProps> = ({
  type,
  category,
}) => {
  const { contentWidth } = useLayoutStore();
  const imageName: string = useMemo(() => {
    const isUnder360 = contentWidth < 360;
    if (type === 'pro-3') {
      if (category === 'DIY') {
        if (isUnder360) {
          return `${type}-diy-320`;
        }
        return `${type}-diy`;
      } else if (isUnder360) {
        return `${type}-320`;
      }
    } else if (isUnder360 && type === 'pro-2') {
      return `${type}-320`;
    }
    return type;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentWidth, type, category]);
  return (
    <EditProgressTagWrap>
      <img src={`/image/strategy-edit/${imageName}.svg`} alt="progress-tag" />
    </EditProgressTagWrap>
  );
};
