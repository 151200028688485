import { ProfitChartModel } from '@models/common';
import { theme } from '@styles/theme';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as RechartRadarChart,
  ResponsiveContainer,
} from 'recharts';
import { RadarChartLegendRender } from './ChartLegend';
import { RadarTick } from './ChartTick';

interface RadarChartProps {
  hasLegend?: boolean;
  chartData: ProfitChartModel;
}

export const RadarChart: React.FC<RadarChartProps> = ({
  chartData,
  hasLegend = true,
}) => {
  const { data, benchmarkName, strategyName, myStrategyName } = chartData;

  if (data.length <= 0) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartRadarChart data={data} reverseStackOrder cy="60%">
        <PolarGrid stroke={theme.colorPalette.divider.column} />
        <PolarAngleAxis dataKey="name" tick={RadarTick} />
        <PolarRadiusAxis axisLine={false} tick={false} domain={[0, 6]} />
        {hasLegend && (
          <Legend
            height={32}
            verticalAlign="top"
            content={RadarChartLegendRender}
          />
        )}
        <Radar
          name={benchmarkName}
          dataKey="benchmark"
          stroke={theme.colorPalette.divider.benchmark}
          fill={theme.colorPalette.divider.benchmark}
          strokeWidth={0}
          fillOpacity={0.5}
        />
        {strategyName && (
          <Radar
            name={strategyName}
            dataKey="base"
            stroke={theme.colorPalette.secondary.default}
            fill={theme.colorPalette.secondary.default}
            strokeWidth={0}
            fillOpacity={0.5}
          />
        )}
        {myStrategyName && (
          <Radar
            name={myStrategyName}
            dataKey="myStrategy"
            stroke={theme.colorPalette.kb.yellow}
            fill={theme.colorPalette.kb.yellow}
            strokeWidth={0}
            fillOpacity={0.5}
          />
        )}
      </RechartRadarChart>
    </ResponsiveContainer>
  );
};
