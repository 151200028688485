import { StrategyFactorType, StyleFactorPresetType } from '@models/strategy';
import { FactorEditSliceState } from './factor-edit.slice';

export const STRATEGY_FACTOR_TOGGLE_INIT: Record<StrategyFactorType, boolean> =
  {
    BETA: false,
    DIVIDENDS: false,
    LIQUIDITY: false,
    MOMENTUM: false,
    QUALITY: false,
    SCORE: false,
    SIZE: false,
    SURPRISE: false,
    VALUE: false,
    VOLATILITY: false,
  };

export const STRATEGY_PRESET_INIT: StyleFactorPresetType = {
  high: {
    max: 0,
    min: 0,
  },
  low: {
    max: 0,
    min: 0,
  },
  middle: {
    max: 0,
    min: 0,
  },
};

export const createInitFactorEditState = (): FactorEditSliceState => ({
  /** (상세 편집) 팩터 선택 단계 */
  factors: [],
  factorCheckObj: {},
  factorToggleObj: STRATEGY_FACTOR_TOGGLE_INIT,

  /** (상세 편집) 팩터 조정 단계 */
  inputFactors: [],
  rawFactors: [],
  histograms: [],

  /** (간편 편집) 간편 팩터 관련 */
  styleFactors: [],
  stylePresetObj: STRATEGY_PRESET_INIT,
});
