import { InfoButton } from '@components/common';
import { Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface SectionTemplateWrapProps {
  minHeight?: number;
}

interface SectionTemplateProps extends SectionTemplateWrapProps {
  children: React.ReactNode;
  description?: string;
  infoDescription?: string;
  subDescription?: string;
  title?: string;
  variant?: 'standard' | 'custom';
}

const Outer = styled.div`
  & + & {
    &::before {
      background-color: ${({ theme }) => theme.colorPalette.black[3]};
      content: '';
      display: block;
      height: 8px;
      margin: 32px -20px;
    }
  }
`;

const SectionTemplateWrap = styled.div<SectionTemplateWrapProps>`
  ${({ minHeight }) => {
    if (minHeight)
      return css`
        min-height: ${minHeight}px;
      `;
    return null;
  }}
`;

const Header = styled.header`
  margin-bottom: 24px;
`;

const Title = styled(FlexBox)`
  & > button {
    background-color: transparent;
  }
`;

export const SectionTemplate: FC<SectionTemplateProps> = ({
  children,
  description,
  minHeight,
  infoDescription,
  subDescription,
  title,
  variant = 'standard',
}) => {
  if (variant === 'custom') {
    return (
      <Outer>
        <SectionTemplateWrap minHeight={minHeight}>
          {children}
        </SectionTemplateWrap>
      </Outer>
    );
  }

  return (
    <Outer>
      <SectionTemplateWrap minHeight={minHeight}>
        <Header>
          {title && (
            <Title alignItems="center" spacing={6}>
              <Typography as="h1" variant="subTitle1_title" color="black.87">
                {title}
              </Typography>
              {infoDescription && (
                <InfoButton title={title} description={infoDescription} />
              )}
            </Title>
          )}
          {description && (
            <Typography variant="caption_medium" color="gray.blue" mt={8}>
              {description}
            </Typography>
          )}
          {subDescription && (
            <Typography variant="caption" color="gray.blue">
              {subDescription}
            </Typography>
          )}
        </Header>
        {children}
      </SectionTemplateWrap>
    </Outer>
  );
};
