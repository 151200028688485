import { SelectButton } from '@components/common';
import styled from 'styled-components';

export const MoreBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 4px;
  margin-top: 16px;
`;

export const SelectButtonWrap = styled(SelectButton)`
  white-space: nowrap;
`;
