import { StockSelectorSliceState } from './stock-selector.slice';

export const createStockSelectorState = (): StockSelectorSliceState => ({
  originHoldings: [],
  holdings: [],
  holdingsCnt: 0,
  excludeHoldings: [],
  whitelistObj: {},
  totalWeight: 0,
  searchedHoldings: [],
  baseHoldings: [],
});
