import {
  CashBox,
  ExceptListBox,
  SelectControlBox,
} from '@components/strategy-edit';
import { useStockSelectorEvent } from '@hooks/edit/stock-selector/useStockSelectorEvent';
import {
  useStockSelectorAction,
  useStockSelectorState,
} from '@stores/strategy-edit/strategy-edit.store';
import { FC } from 'react';
import { HoldingListContainer } from './HoldingListContainer';

interface StockSelectorContainerProps {
  simpleMode?: boolean;
}

export const StockSelectorContainer: FC<StockSelectorContainerProps> = ({
  simpleMode = false,
}) => {
  const { excludeHoldings, totalWeight, holdings } = useStockSelectorState();
  const { addStockItem, resetStockList } = useStockSelectorAction();
  const { handleClickInfo, handleClickInclude } = useStockSelectorEvent({
    simpleMode,
  });

  return (
    <div>
      {holdings.length > 0 && (
        <>
          <ExceptListBox
            excludeHoldings={excludeHoldings}
            onClickInclude={handleClickInclude}
            onClickInfo={handleClickInfo('exclude')}
          />
          <SelectControlBox
            onClickAddStock={addStockItem}
            onClickReset={resetStockList}
            onClickInfo={handleClickInfo('list')}
          />
        </>
      )}
      <HoldingListContainer />
      <CashBox totalWeight={totalWeight} />
    </div>
  );
};
