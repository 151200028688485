import { STRATEGY_CATEGORY_MAPPER } from '@constants/strategy';
import {
  GetStrategiesQueryVariables,
  StrategyStatusEnum,
  GetStrategiesQuery,
  useGetStrategiesQuery,
  useGetValueUpStrategiesQuery,
} from '@graphql/base';
import { useCountryCode } from '@stores/common/common.store';
import {
  convertStrategiesByCategory,
  useStrategyListStore,
} from '@stores/readymade-list';

interface useReadymadeListDataProps extends GetStrategiesQueryVariables {
  size: number;
}

export const useReadymadeListData = (queryObj: useReadymadeListDataProps) => {
  const {
    category: storeCategory,
    keyword: keywordObj,
    orderTypes,
    periodTypes,
    universe,
    themeTabIndex,
    selectedThemeGroups,
  } = useStrategyListStore();

  const countries = useCountryCode();
  const category = queryObj.category || STRATEGY_CATEGORY_MAPPER[storeCategory];
  const keyword = !!keywordObj[storeCategory]
    ? keywordObj[storeCategory]
    : undefined;
  const orderType = orderTypes[storeCategory];
  const periodType = periodTypes[storeCategory];
  const universePreset =
    storeCategory === 'SECTOR'
      ? queryObj.universePreset || universe
      : undefined;

  let themeParamObj:
    | {
        themeGroup: string[];
        isKbPick: boolean;
      }
    | undefined;

  themeParamObj = undefined;

  if (category === 'THEME') {
    themeParamObj = {
      themeGroup: [],
      isKbPick: false,
    };
    if (storeCategory === 'THEME') {
      if (themeTabIndex === 0) {
        themeParamObj.isKbPick = true;
      }
    }
    if (themeTabIndex === 2) {
      if (selectedThemeGroups.length > 0) {
        themeParamObj.themeGroup = selectedThemeGroups.map((theme) => theme.id);
      }
    }
  }

  const isValueUpStrategies = storeCategory === 'THEME' && themeTabIndex === 1;

  const { data: valueUpData, isFetching: isValueUpFetching } =
    useGetValueUpStrategiesQuery(
      {
        first: queryObj.size,
      },
      {
        keepPreviousData: true,
        staleTime: 1000 * 60 * 30,
        enabled: isValueUpStrategies,
      }
    );

  const valueUpStrategies = {
    strategies: valueUpData?.valueUpStrategies ?? [],
  };

  const { data, isFetching } = useGetStrategiesQuery(
    {
      ...queryObj,
      countries,
      category,
      keyword,
      orderMethod: orderType,
      orderPeriodReturn: periodType,
      universePreset,
      status: [
        StrategyStatusEnum.Activated,
        StrategyStatusEnum.ActivatedReservation,
        StrategyStatusEnum.ActivatedUpdating,
      ],
      first: queryObj.size,
      ...themeParamObj,
    },
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 30,
      enabled: !isValueUpStrategies,
    }
  );

  if (
    (!isValueUpStrategies && !data) ||
    (isValueUpStrategies && !valueUpData)
  ) {
    return {
      strategyList: [],
      pageObj: {
        hasNextPage: false,
        endCursor: '',
      },
    };
  }

  const isKbPick = themeParamObj?.isKbPick ?? false;
  const { list: strategyList, pageObj } = convertStrategiesByCategory(
    isValueUpStrategies
      ? (valueUpStrategies as GetStrategiesQuery)
      : (data as GetStrategiesQuery)
  );

  return {
    isFetching: isFetching || isValueUpFetching,
    strategyList,
    pageObj,
    isKbPick,
  };
};
