/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { getCssScroll } from '@ui/util';
import { getBeforeYear } from '@utils/common';
import _ from 'lodash';
import React, {
  FC,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

interface DatePickerProps {
  defaultDate?: Date | number;
  onScrollYear?: (year: number) => void;
  onScrollMonth?: (month: number) => void;
}

interface ItemProps {
  active?: boolean;
  hide?: boolean;
}

interface PanelButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const DatePickerWrap = styled.div``;

const SelectPeriodOptionWrap = styled(FlexBox)`
  background-color: ${({ theme }) => theme.colorPalette.white};
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
  border-top: 1px solid ${({ theme }) => theme.colorPalette.divider.list};
  height: 134px;
`;

const YearSelector = styled(FlexBox)`
  ${getCssScroll()}
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;

  width: 50px;
  padding-top: 53px;
  padding-bottom: 53px;
  position: relative;
`;

const ButtonPanel = styled(FlexBox)`
  flex-wrap: wrap;
`;

const PanelButtonWrap = styled.div`
  flex: 48%;
`;

const MonthSelector = styled(FlexBox)`
  ${getCssScroll()}
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;

  width: 25px;
  padding-top: 53px;
  padding-bottom: 53px;
`;

const Item = styled(Typography)<ItemProps>`
  color: #bbb;
  scroll-snap-align: center;

  ${({ active, hide, theme }) => {
    if (hide) {
      return css`
        color: transparent;
      `;
    }
    if (active) {
      return css`
        color: ${theme.colorPalette.secondary.text};
      `;
    }
  }}
`;

const createMonthList = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const createYearList = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 3; // 3년전부터 시작

  const yearList = Array(currentYear - startYear + 1)
    .fill(undefined)
    .map((_, index) => startYear + index);

  const currentMonth = new Date().getMonth();
  if (currentMonth === 0) {
    yearList.pop();
  }

  return yearList;
};

const createHideMonthSet = (yearIndex: number, yearList: number[]) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  if (yearList[yearIndex] === currentYear) {
    // 현재년도의 경우 (당월 - 1)개월까지 선택 가능
    return new Set(
      Array(12 - (currentMonth - 1))
        .fill(undefined)
        .map((_, index) => currentMonth + index)
    );
  } else if (yearIndex === 0) {
    // 3년전의 경우 당월부터 선택 가능
    return new Set(
      Array(currentMonth - 1)
        .fill(undefined)
        .map((_, index) => index + 1)
    );
  }
  return undefined;
};

const getItemIndex = (list: number[], target: number) => {
  return list.findIndex((i) => i === target);
};

const PanelButton: FC<PanelButtonProps> = ({ children, onClick }) => {
  return (
    <PanelButtonWrap>
      <Button size="medium" variant="plain" onClick={onClick}>
        {children}
      </Button>
    </PanelButtonWrap>
  );
};

// Date Element Height 값
const DATE_ELEMENT_HEIGHT = 31;

export const DatePicker: FC<DatePickerProps> = ({
  defaultDate = new Date(),
  onScrollYear,
  onScrollMonth,
}) => {
  const [yearList] = useState<number[]>(createYearList());
  const [monthList] = useState<number[]>(createMonthList());
  const [yearIndex, setYearIndex] = useState(0);
  const [monthIndex, setMonthIndex] = useState(0);
  const yearRef = useRef<HTMLElement>(null);
  const monthRef = useRef<HTMLElement>(null);

  const [hideMonthSet, setHideMonthSet] = useState<Set<number> | undefined>();

  const getScrollTop = (index: number) => DATE_ELEMENT_HEIGHT * index;

  const handleClickPanel = (prevYear: number) => () => {
    const prevDate = getBeforeYear(prevYear);
    const _prevYear = prevDate.getFullYear();
    const _prevMonth = prevDate.getMonth() + 1;
    const _prevYearIndex = getItemIndex(yearList, _prevYear);
    const _prevMonthIndex = getItemIndex(monthList, _prevMonth);
    yearRef.current?.scrollTo({ top: getScrollTop(_prevYearIndex) });
    monthRef.current?.scrollTo({ top: getScrollTop(_prevMonthIndex) });
    setYearIndex(_prevYearIndex);
    setMonthIndex(_prevMonthIndex);
  };

  const handleSetYearMonthIndex = (yearIndex: number, monthIndex: number) => {
    let mIndex = monthIndex;
    const currentYear = new Date().getFullYear();
    if (yearIndex === 0) {
      const limitMonth = new Date().getMonth();
      if (monthIndex < limitMonth) {
        mIndex = limitMonth;
      }
    } else if (currentYear === yearList[yearIndex]) {
      const limitMonth = new Date().getMonth() - 1;
      if (monthIndex > limitMonth) {
        mIndex = limitMonth;
      }
    }

    monthRef.current?.scrollTo({ top: getScrollTop(mIndex) });
    setMonthIndex(mIndex);

    yearRef.current?.scrollTo({ top: getScrollTop(yearIndex) });
    setYearIndex(yearIndex);

    return { monthIndex: mIndex };
  };

  const handlePartitialMonth = (index: number) => {
    setHideMonthSet(createHideMonthSet(index, yearList));
  };

  const handleClickItem = (type: 'year' | 'month', index: number) => () => {
    if (type === 'year') {
      handleSetYearMonthIndex(index, monthIndex);
    } else if (type === 'month') {
      handleSetYearMonthIndex(yearIndex, index);
    }
  };

  useEffect(() => {
    const createHandler = (
      ref: RefObject<HTMLElement>,
      callback: (index: number) => void
    ) =>
      _.debounce(() => {
        const scrollTop = ref.current?.scrollTop;
        if (scrollTop) {
          const index = Math.floor(scrollTop / DATE_ELEMENT_HEIGHT);
          callback(index);
        } else {
          callback(0);
        }
      }, 100);
    const yearTarget = yearRef.current;
    const monthTarget = monthRef.current;
    const handleYear = createHandler(yearRef, (index) => {
      setYearIndex(index);
      if (onScrollYear) {
        onScrollYear(yearList[index]);
      }
    });
    const handleMonth = createHandler(monthRef, (index) => {
      setMonthIndex(index);
      if (onScrollMonth) {
        onScrollMonth(monthList[index]);
      }
    });
    setTimeout(() => {
      yearTarget?.addEventListener('scroll', handleYear);
      monthTarget?.addEventListener('scroll', handleMonth);

      /** Scroll Behavior Smooth 옵션 적용 */
      if (yearTarget) {
        yearTarget.style.cssText = 'scroll-behavior: smooth;';
      }
      if (monthTarget) {
        monthTarget.style.cssText = 'scroll-behavior: smooth;';
      }
    });
    return () => {
      yearTarget?.removeEventListener('scroll', handleYear);
      monthTarget?.removeEventListener('scroll', handleMonth);
    };
  }, []);

  useLayoutEffect(() => {
    let date = defaultDate;
    if (typeof date === 'number') date = new Date(date);
    const _yearIndex = getItemIndex(yearList, date.getFullYear());
    const _monthIndex = getItemIndex(monthList, date.getMonth() + 1);
    setYearIndex(_yearIndex);
    setMonthIndex(_monthIndex);
    yearRef.current?.scrollTo({ top: getScrollTop(_yearIndex) });
    monthRef.current?.scrollTo({ top: getScrollTop(_monthIndex) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetYearMonthIndex(yearIndex, monthIndex);
    handlePartitialMonth(yearIndex);
  }, [yearIndex, monthIndex]);

  return (
    <DatePickerWrap>
      <ButtonPanel mb={24} spacing={8}>
        <PanelButton onClick={handleClickPanel(1)}>1년전</PanelButton>
        <PanelButton onClick={handleClickPanel(3)}>3년전</PanelButton>
      </ButtonPanel>
      <SelectPeriodOptionWrap justify="center">
        <YearSelector
          alignItems="center"
          direction="column"
          spacing={8}
          mr={20}
          ref={yearRef}
        >
          {yearList.map((year, index) => {
            const active = yearIndex === index;
            return (
              <Item
                key={`year_${index}`}
                variant="subTitle1_title"
                active={active}
                onClick={handleClickItem('year', index)}
              >
                {year}
              </Item>
            );
          })}
        </YearSelector>
        <MonthSelector
          alignItems="center"
          direction="column"
          spacing={8}
          ref={monthRef}
        >
          {monthList.map((month, index) => {
            const active = monthIndex === index;
            const hide = hideMonthSet?.has(month);
            return (
              <Item
                key={`month_${index}`}
                variant="subTitle1_title"
                active={active}
                hide={hide}
                onClick={handleClickItem('month', index)}
              >
                {String(month).padStart(2, '0')}
              </Item>
            );
          })}
        </MonthSelector>
      </SelectPeriodOptionWrap>
    </DatePickerWrap>
  );
};
