import { Tag } from '@components/common';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';
import { TagColor } from '@models/common';
import { LayoutModel } from '@models/layout.model';
import { FlexBox, Typography } from '@ui/components';
import { FC, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Floater } from './Floater';
import { Header } from './Header';
import { MIN_WIDTH } from '@constants/layout.const';
import { isDarkMode } from '@utils/common';

interface LayoutProps extends LayoutModel {}

interface ExtendedSpaceWrapProps {
  extended?: boolean;
}

interface ExtendedHeaderProps {
  tagColor?: TagColor;
  tagName?: string;
  title: string;
}

const LayoutWrap = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const LayoutInner = styled.div`
  position: absolute;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.layout};
`;

const Main = styled.main`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.layout.maxWidth};
  min-width: ${({ theme }) => theme.layout.minWidth};
  position: relative;
`;

const Content = styled.div<ExtendedSpaceWrapProps>`
  background-color: ${({ theme }) => theme.colorPalette.white};
  padding-top: 76px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 92px;
  min-height: 100vh;
  ${({ extended }) => {
    if (extended) {
      return css`
        padding-top: 68px;
      `;
    }
  }}
`;

const Dimmed = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.black[100]};
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

const ExtendedHeader = forwardRef<HTMLDivElement | null, ExtendedHeaderProps>(
  ({ tagColor, tagName, title }, ref) => {
    return (
      <div ref={ref}>
        <FlexBox direction="column" spacing={8} mb={32}>
          {tagName && title && (
            <div>
              <Tag color={tagColor} size="big">
                {tagName}
              </Tag>
            </div>
          )}
          <Typography
            variant="h5"
            wordBreak="break-all"
            style={{ lineBreak: 'anywhere' }}
          >
            {title}
          </Typography>
        </FlexBox>
      </div>
    );
  }
);

export const Layout: FC<LayoutProps> = ({
  children,
  contentWidth = MIN_WIDTH,
  extended = false,
  floater,
  navigation,
  optionalButton,
  pathname,
  tagColor,
  tagName,
  title = '',
  onClickPrev,
  onNavigation,
}) => {
  const [extendedHeaderView, setExtendedHeaderView] = useState(true);
  const target = useIntersectionObserver((isIntersecting) =>
    setExtendedHeaderView(isIntersecting)
  );

  return (
    <LayoutWrap>
      <LayoutInner>
        <Main>
          <Header
            contentWidth={contentWidth}
            extended={extended}
            extendedHeaderView={extendedHeaderView}
            navigation={navigation}
            optionalButton={optionalButton}
            pathname={pathname}
            tagColor={tagColor}
            tagName={tagName}
            title={title}
            onClickPrev={onClickPrev}
            onNavigation={onNavigation}
          />
          <Content extended={extended}>
            {extended && (
              <ExtendedHeader
                tagColor={tagColor}
                tagName={tagName}
                title={title}
                ref={target}
              />
            )}
            {children}
          </Content>
          {floater && (
            <Floater
              {...floater}
              contentWidth={contentWidth}
              isDarkMode={isDarkMode}
            />
          )}
        </Main>
      </LayoutInner>
      <Dimmed />
    </LayoutWrap>
  );
};
