import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface MySimulationOptionInfoProps {
  mySimulationOptionInfo: string[][];
}

const MySimulationOptionInfoWrap = styled.div``;

const OptionRow = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.contents};
  padding: 12px 0;
`;

export const MySimulationOptionInfo: FC<MySimulationOptionInfoProps> = ({
  mySimulationOptionInfo,
}) => {
  return (
    <MySimulationOptionInfoWrap>
      {mySimulationOptionInfo.map(([key, value]) => (
        <OptionRow key={`simulation_option_${key}`} justify="space-between">
          <Typography variant="body2_title">{key}</Typography>
          <Typography variant="body2_content" color="black.60">
            {value}
          </Typography>
        </OptionRow>
      ))}
    </MySimulationOptionInfoWrap>
  );
};
