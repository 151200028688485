import { HistogramChartModel } from '@models/common';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';
import { HistogramSingleChartItem } from '../slider-units/HistogramChartItem';

interface HistogramChartProps extends HistogramChartModel {}

const HistogramChartWrap = styled(FlexBox)`
  height: 50px;
`;

export const HistogramSingleChart: FC<HistogramChartProps> = ({
  histogramChartData = [],
  range = [0, 99],
}) => {
  return (
    <HistogramChartWrap spacing={1} alignItems="flex-end">
      {histogramChartData.map((item, index) => {
        const [start, end] = range;
        const selected = index >= start && index <= end;
        return (
          <HistogramSingleChartItem
            key={`histogram_chart_${index}`}
            height={item.height}
            selected={selected}
          />
        );
      })}
    </HistogramChartWrap>
  );
};
