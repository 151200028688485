import { ReturnRateText } from '@components/common';
import { MyStrategyCardDto } from '@models/strategy';
import { FlexBox, Typography } from '@ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface ForwardResultBoxProps {
  strategy: MyStrategyCardDto;
}

const ForwardResultBoxWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.colorPalette.background.box};
  padding: 8px 12px;
  border-radius: 4px;

  .return-rate-text {
    font-family: 'Spoqa Han Sans Neo';
  }
`;

/**
 * 전략보관함 카드
 * 모의투자 결과 박스 컴포넌트
 */
export const ForwardResultBox: FC<ForwardResultBoxProps> = ({ strategy }) => {
  const isCompareBenchmark =
    strategy.category === 'DIY' || strategy.category === 'MASTER';
  const returnRate = isCompareBenchmark
    ? strategy.benchmarkForwardReturn
    : strategy.baseForwardReturn;
  const title = `${isCompareBenchmark ? '벤치마크' : '프리셋(Pre-set)'} 수익률`;

  return (
    <FlexBox direction="column">
      <FlexBox mb={8} justify="space-between" alignItems="center">
        <FlexBox
          wrap="wrap"
          alignItems="center"
          justify="space-between"
          width="100%"
        >
          <FlexBox spacing={4} alignItems="center">
            <Typography color="black.87" variant="caption_medium">
              모의투자 성과
            </Typography>
            {strategy.isForwardAvailable && (
              <Typography color="black.40" variant="overline_title">
                {strategy.forwardDate}
              </Typography>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <ForwardResultBoxWrap>
        <FlexBox direction="column" justify="space-between" alignItems="center">
          <ReturnRateText value={strategy.myForwardReturn} type="body1_title" />
          <Typography color="black.60" variant="overline_content">
            나의 누적수익률
          </Typography>
        </FlexBox>
        <FlexBox direction="column" justify="space-between" alignItems="center">
          <ReturnRateText value={returnRate} type="body1_title" />
          <Typography color="black.60" variant="overline_content">
            {title}
          </Typography>
        </FlexBox>
      </ForwardResultBoxWrap>
    </FlexBox>
  );
};
