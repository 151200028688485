import { IndustryEnum, SectorEnum } from '@graphql/generates';
import { StrategyIndustryType, StrategySectorType } from '@models/strategy';

export const STRATEGY_SECTOR = [
  SectorEnum.Energy,
  SectorEnum.Materials,
  SectorEnum.Industrials,
  SectorEnum.ConsumerDiscretionary,
  SectorEnum.ConsumerStaples,
  SectorEnum.HealthCare,
  SectorEnum.Financials,
  SectorEnum.InformationTechnology,
  SectorEnum.Communications,
  SectorEnum.Utilities,
  SectorEnum.RealEstate,
] as const;

export const STRATEGY_INDUSTRY_ENUM = [
  IndustryEnum.AutomobilesComponents,
  IndustryEnum.Banks,
  IndustryEnum.CapitalGoods,
  IndustryEnum.CommercialProfessionalServices,
  IndustryEnum.ConsumerDurablesApparel,
  IndustryEnum.ConsumerServices,
  IndustryEnum.DiversifiedFinancials,
  IndustryEnum.Energy,
  IndustryEnum.FoodBeverageTobacco,
  IndustryEnum.FoodStaplesRetailing,
  IndustryEnum.HealthCareEquipmentServices,
  IndustryEnum.HouseholdPersonalProducts,
  IndustryEnum.Insurance,
  IndustryEnum.Materials,
  IndustryEnum.MediaEntertainment,
  IndustryEnum.PharmaceuticalsBiotechnologyLifeSciences,
  IndustryEnum.RealEstate,
  IndustryEnum.RealEstateManagementDevelopment,
  IndustryEnum.Retailing,
  IndustryEnum.SemiconductorsSemiconductorEquipment,
  IndustryEnum.SoftwareServices,
  IndustryEnum.TechnologyHardwareEquipment,
  IndustryEnum.TelecommunicationServices,
  IndustryEnum.Transportation,
  IndustryEnum.Utilities,
] as const;

export const STRATEGY_SECTOR_MAPPER: Record<StrategySectorType, SectorEnum> = {
  COMMUNICATIONS: SectorEnum.Communications,
  CONSUMER_DISCRETIONARY: SectorEnum.ConsumerDiscretionary,
  CONSUMER_STAPLES: SectorEnum.ConsumerStaples,
  ENERGY: SectorEnum.Energy,
  FINANCIALS: SectorEnum.Financials,
  HEALTH_CARE: SectorEnum.HealthCare,
  INDUSTRIALS: SectorEnum.Industrials,
  INFORMATION_TECHNOLOGY: SectorEnum.InformationTechnology,
  MATERIALS: SectorEnum.Materials,
  REAL_ESTATE: SectorEnum.RealEstate,
  UTILITIES: SectorEnum.Utilities,
};

export const SECTOR_NAME: Record<StrategySectorType, string> = {
  [SectorEnum.Communications]: '통신 서비스',
  [SectorEnum.ConsumerDiscretionary]: '임의소비재',
  [SectorEnum.ConsumerStaples]: '필수소비재',
  [SectorEnum.Energy]: '에너지',
  [SectorEnum.Financials]: '금융',
  [SectorEnum.HealthCare]: '헬스케어',
  [SectorEnum.Industrials]: '산업',
  [SectorEnum.InformationTechnology]: '정보기술',
  [SectorEnum.Materials]: '소재',
  [SectorEnum.RealEstate]: '부동산',
  [SectorEnum.Utilities]: '유틸리티',
};

export const INDUSTRY_NAME: Record<StrategyIndustryType, string> = {
  [IndustryEnum.AutomobilesComponents]: '자동차 및 부품',
  [IndustryEnum.Banks]: '은행',
  [IndustryEnum.CapitalGoods]: '자본재',
  [IndustryEnum.CommercialProfessionalServices]: '상업 및 전문 서비스',
  [IndustryEnum.ConsumerDurablesApparel]: '내구 소비재 및 의류',
  [IndustryEnum.ConsumerServices]: '소비자 서비스',
  [IndustryEnum.DiversifiedFinancials]: '금융 서비스',
  [IndustryEnum.Energy]: '에너지',
  [IndustryEnum.FoodBeverageTobacco]: '식품, 음료, 담배',
  [IndustryEnum.FoodStaplesRetailing]: '필수소비재 유통 및 소매',
  [IndustryEnum.HealthCareEquipmentServices]: '헬스케어 장비 및 서비스',
  [IndustryEnum.HouseholdPersonalProducts]: '가정용품 및 개인용품',
  [IndustryEnum.Insurance]: '보험',
  [IndustryEnum.Materials]: '소재',
  [IndustryEnum.MediaEntertainment]: '미디어 및 엔터테인먼트',
  [IndustryEnum.PharmaceuticalsBiotechnologyLifeSciences]:
    '제약, 생명공학 및 생명과학',
  [IndustryEnum.RealEstate]: '부동산리츠',
  [IndustryEnum.RealEstateManagementDevelopment]: '부동산 관리 및 개발',
  [IndustryEnum.Retailing]: '임의소비재 유통 및 소매',
  [IndustryEnum.SemiconductorsSemiconductorEquipment]: '반도체 및 반도체 장비',
  [IndustryEnum.SoftwareServices]: '소프트웨어 및 서비스',
  [IndustryEnum.TechnologyHardwareEquipment]: '기술 하드웨어 및 장비',
  [IndustryEnum.TelecommunicationServices]: '통신 서비스',
  [IndustryEnum.Transportation]: '운송',
  [IndustryEnum.Utilities]: '유틸리티',
};

export const SECTOR_INDUSTRY_STRUCTURE: Record<
  StrategySectorType,
  StrategyIndustryType[]
> = {
  [SectorEnum.Energy]: [],
  [SectorEnum.Materials]: [],
  [SectorEnum.Industrials]: [
    IndustryEnum.CapitalGoods,
    IndustryEnum.CommercialProfessionalServices,
    IndustryEnum.Transportation,
  ],
  [SectorEnum.ConsumerDiscretionary]: [
    IndustryEnum.AutomobilesComponents,
    IndustryEnum.ConsumerDurablesApparel,
    IndustryEnum.ConsumerServices,
    IndustryEnum.Retailing,
  ],
  [SectorEnum.ConsumerStaples]: [
    IndustryEnum.FoodBeverageTobacco,
    IndustryEnum.FoodStaplesRetailing,
    IndustryEnum.HouseholdPersonalProducts,
  ],
  [SectorEnum.HealthCare]: [
    IndustryEnum.HealthCareEquipmentServices,
    IndustryEnum.PharmaceuticalsBiotechnologyLifeSciences,
  ],
  [SectorEnum.Financials]: [
    IndustryEnum.Banks,
    IndustryEnum.DiversifiedFinancials,
    IndustryEnum.Insurance,
  ],
  [SectorEnum.InformationTechnology]: [
    IndustryEnum.SoftwareServices,
    IndustryEnum.TechnologyHardwareEquipment,
    IndustryEnum.SemiconductorsSemiconductorEquipment,
  ],
  [SectorEnum.Communications]: [
    IndustryEnum.TelecommunicationServices,
    IndustryEnum.MediaEntertainment,
  ],
  [SectorEnum.Utilities]: [],
  [SectorEnum.RealEstate]: [
    IndustryEnum.RealEstate,
    IndustryEnum.RealEstateManagementDevelopment,
  ],
};
