/**
 * 값이 undefined 인지 여부를 확인한다.
 * @param val 확인 할 값
 * @returns {boolean} val이 undefined인지의 여부를 반환한다.
 * @group Utils
 *
 * @example
 * isUndefined(undefined) => true
 * isUndefined(null) => false
 */
export function isUndefined(val: unknown): val is undefined {
  return typeof val === 'undefined';
}

/**
 * 값이 String 인지 여부를 확인한다.
 * @param val 확인 할 값
 * @returns {boolean} val이 String인지의 여부를 반환한다.
 * @group Utils
 *
 * @example
 * isString('텍스트') => true
 */
export function isString(val: unknown): val is string {
  return Object.prototype.toString.call(val) === '[object String]';
}

/**
 * 값이 숫자인지 확인한다.
 * @param val 확인 할 값
 * @returns {boolean}
 * @group Utils
 *
 * @example
 * isNumber(123) => true
 */
export function isNumber(val: unknown): val is number {
  return Object.prototype.toString.call(val) === '[object Number]';
}

/**
 * 숫자 또는 문자열 형태의 숫자가 정수인지의 여부를 반환한다.
 * @param val 확인 할 값
 * @returns {boolean} 숫자 또는 숫자형태의 문자열인지 여부를 반환한다.
 * @group Utils
 *
 * @example
 * isNumberLike(123) => true
 * isNumberLike('15') => true
 * isNumberLike('12.15') => false
 */
export function isNumberLike(val: unknown) {
  if (isNumber(val)) {
    return val % 1 === 0;
  }
  if (isString(val)) {
    return /^[0-9]+$/.test(val);
  }
  return false;
}

/**
 * 값이 소숫점이 포함되어 있는지 여부를 확인한다.
 * @param {(number | string)} val 확인 할 값
 * @returns {boolean} 소숫점이 포함되어 있는지 여부를 반환하는 Boolean 값
 * @group Utils
 *
 * @example
 * isDecimalNumber(12.34) => true
 * isDecimalNumber('12.34') => true
 * isDecimalNumber(12) => false
 */
export function isDecimalNumber(val: number | string) {
  if (isNumber(val)) {
    return val % 1 !== 0;
  }
  if (isString(val)) {
    return /^[+-]?[0-9]+\.[0-9]+$/.test(val);
  }
  return false;
}

/**
 * 값이 undefined 이거나 null 인지 여부를 확인한다.
 * 그리고 내용이 빈문자열('') | 문자열 'undefined' | 문자열 'null' 인지도 판단한다.
 * @param val 확인 할 값.
 * @returns {boolean} Nullable 여부를 반환하는 Boolean 값
 * @group Utils
 *
 * @example
 * isNullable('') => true
 * isNullable([]) => false
 */
export function isNullable(val: unknown): val is void {
  return (
    isUndefined(val) ||
    val === null ||
    val === 'undefined' ||
    val === 'null' ||
    val === ''
  );
}

/**
 * 한 배열이 다른 배열에 속하는지 여부를 확인한다.
 * @param {T[]} origin - 비교의 대상이 되는 배열 (다른 배열)
 * @param {T[]} target - 속하는지 알아볼 배열 (한 배열)
 * @returns {boolean} target이 origin에 속하는지 여부를 반환한다.
 * @group Utils
 *
 * @example
 * isContain([1,2,3,4], [1,2]) => true
 * isContain([1,3,5,7], [1,2]) => false
 */
export const isContain = <T>(origin: T[], target: T[]) => {
  for (const val of target) {
    if (!origin.includes(val)) {
      return false;
    }
  }
  return true;
};
