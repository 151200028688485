import { BooksizeContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { Typography } from '@ui/components';
import { TextField } from '@ui/components/TextField';
import { isNumberLike, numberInRange } from '@utils/common';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ConfirmTemplate } from './ConfirmTemplate';
import {
  AMOUNT_KR_DESCRIPTION,
  AMOUNT_US_DESCRIPTION,
  COUNTRY_MAPPER,
} from '@constants/common';

interface BooksizeConfirmProps
  extends BooksizeContentModel,
    ContentWidthModel {}

const BooksizeConfirmWrap = styled(ConfirmTemplate)``;

export const BooksizeConfirm: FC<BooksizeConfirmProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
  contentWidth,
  onCancel,
  onOk,
}) => {
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const [booksize, setBookisze] = useState<number | string>('');

  const descText =
    countryCode === COUNTRY_MAPPER.DOMESTIC
      ? AMOUNT_KR_DESCRIPTION
      : AMOUNT_US_DESCRIPTION;

  const handleChange = (value: number | string) => {
    const minValue = countryCode === COUNTRY_MAPPER.DOMESTIC ? 100 : 500;
    const _value = +String(value).split(',').join('');
    const _active = numberInRange(_value, { min: minValue, max: 100000 });
    setBookisze(_value);
    setError(!_active);
    setActive(_active);
  };

  const handleValidator = (value: number | string | readonly string[]) => {
    if (value === '0' || value === '') return true;
    const removeComma = String(value).split(',').join('');
    return isNumberLike(removeComma);
  };

  const handleOk = () => {
    if (!active || !onOk) return;
    onOk(+booksize);
  };

  return (
    <BooksizeConfirmWrap
      type="booksize"
      title="투자 금액 입력"
      contentWidth={contentWidth}
      disabled={!active}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <TextField
        align="right"
        unit="만원"
        type="number"
        placeholder="투자금액을 입력하세요."
        error={error}
        onChangeValue={handleChange}
        onChangeValidator={handleValidator}
      />
      <Typography
        variant="tag"
        color={error ? 'state.red' : 'gray.blue'}
        mt={4}
      >
        {descText}
      </Typography>
    </BooksizeConfirmWrap>
  );
};
