import { COUNTRY_MAPPER } from '@constants/common';
import { LevelOneContainer } from '@containers/strategy-edit/beginner/LevelOneContainer';
import { FC } from 'react';

interface UsStrategyBeginner1EditPageProps {
  reEdit?: boolean;
}

export const UsStrategyBeginner1EditPage: FC<
  UsStrategyBeginner1EditPageProps
> = ({ reEdit = false }) => {
  return <LevelOneContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />;
};
