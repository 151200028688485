import { MyStrategyReturnSummaryTypeInfoFragment } from '@graphql/generates';
import { MyStrategyCardReturnInfoDto } from '@models/strategy';
import { createPeriod } from '@stores/common';

/**
 * 내전략 카드 수익률 관련 정보를 넘겨받아 MyStrategyCardReturnInfoDto로 변환하는 함수.
 * @param {Object} data - 내전략 카드 수익률 관련 정보.
 * @param {Object} data.forward - 내전략 모의투자 정보.
 * @param {Object} data.forward.baseDate - 모의투자 기준일 정보.
 * @param {Object} data.forward.returnSummary - 모의투자 수익률 정보.
 * @param {Object} data.forward.returnSummary.cumulative - 모의투자 누적수익률.
 * @param {Object} data.backtest - 내전략 백테스트 정보.
 * @param {Object} data.backtest.baseDate - 백테스트 기준일 정보.
 * @param {Object} data.backtest.returnSummary - 백테스트 수익률 정보.
 * @param {Object} data.backtest.returnSummary.cumulative - 백테스트 누적수익률.
 * @param {Object} data.backtest.returnSummary.annualAvgReturn - 백테스트 연평균 수익률.
 * @param {Object} data.backtest.returnSummary.annualAvgVolatility - 백테스트 연평균 변동성.
 * @param {Object} data.backtest.returnSummary.mdd - 백테스트 MDD.
 * @returns {Object}
 */
export const createReturnSummary = (
  data: MyStrategyReturnSummaryTypeInfoFragment | null | undefined
): MyStrategyCardReturnInfoDto => {
  let forwardDate: string = '';
  let myForwardReturn: number | undefined;
  let benchmarkForwardReturn: number | undefined;
  let baseForwardReturn: number | undefined;
  let backtestDate: string = '';
  let backtestCumulativeReturn: number | undefined;
  let backtestAnnualAvgReturn: number | undefined;
  let backtestAnnualAvgVolatility: number | undefined;
  let backtestMdd: number | undefined;

  if (data) {
    const { backtest, baseStrategyCumulative, benchmarkCumulative, forward } =
      data;
    if (baseStrategyCumulative) {
      baseForwardReturn = baseStrategyCumulative;
    }
    if (benchmarkCumulative) {
      benchmarkForwardReturn = benchmarkCumulative;
    }
    if (forward) {
      forwardDate = `${createPeriod(forward.baseDate)}`;
      if (forward.returnSummary) {
        const { cumulative } = forward.returnSummary;
        if (cumulative) myForwardReturn = cumulative;
      }
    }
    if (backtest) {
      backtestDate = `${createPeriod(backtest.baseDate)}`;
      if (backtest.returnSummary) {
        const { cumulative, annualAvgReturn, annualAvgVolatility, mdd } =
          backtest.returnSummary;
        if (cumulative) backtestCumulativeReturn = cumulative;
        if (annualAvgReturn) backtestAnnualAvgReturn = annualAvgReturn;
        if (annualAvgVolatility)
          backtestAnnualAvgVolatility = annualAvgVolatility;
        if (mdd) backtestMdd = mdd;
      }
    }
  }

  return {
    forwardDate,
    myForwardReturn,
    benchmarkForwardReturn,
    baseForwardReturn,
    backtestDate,
    backtestCumulativeReturn,
    backtestAnnualAvgReturn,
    backtestAnnualAvgVolatility,
    backtestMdd,
  };
};
