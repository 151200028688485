import { PAGE_INFO, PAGE_KR_INFO, PAGE_US_INFO } from '@constants/router.const';
import env from '@core/env';
import { getCi, getToken, needCiUpdate } from '@core/token';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import {
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom';
import { MainRendingPage, MyStrategyListPage } from './pages/common';
import {
  BackTestPage,
  ForwardTestPage,
  MyStrategyBeginner1EditPage,
  MyStrategyBeginner2EditPage,
  MyStrategyDetailEditDiyPage,
  MyStrategyDetailEditPage,
  MyStrategyEditResultPage,
  MyStrategyMasterEditPage,
  MyStrategyPro1EditPage,
  MyStrategySimpleEditPage,
  ReadymadeDetailPage,
  ReadymadeListPage,
  ReadymadeMasterPage,
  StrategyBeginner1EditPage,
  StrategyBeginner2EditPage,
  StrategyDetailEditDiyPage,
  StrategyDetailEditPage,
  StrategyEditResultPage,
  StrategyMasterEditPage,
  StrategyPro1EditPage,
  StrategySimpleEditPage,
} from './pages/kr';
import {
  MyUsStrategyBeginner1EditPage,
  MyUsStrategyBeginner2EditPage,
  MyUsStrategyDetailEditDiyPage,
  MyUsStrategyDetailEditPage,
  MyUsStrategyEditResultPage,
  MyUsStrategyMasterEditPage,
  MyUsStrategyPro1EditPage,
  MyUsStrategySimpleEditPage,
  UsBackTestPage,
  UsForwardTestPage,
  UsReadymadeDetailPage,
  UsReadymadeListPage,
  UsReadymadeMasterPage,
  UsStrategyBeginner1EditPage,
  UsStrategyBeginner2EditPage,
  UsStrategyDetailEditDiyPage,
  UsStrategyDetailEditPage,
  UsStrategyEditResultPage,
  UsStrategyMasterEditPage,
  UsStrategyPro1EditPage,
  UsStrategySimpleEditPage,
} from './pages/us';

const Root = () => {
  useEffect(() => {
    const visibilitychangeCallback = () => {
      if (!document.hidden) {
        const token = Cookies.get(env.authTokenKey);
        if (!token) {
          getToken(getCi());
        }
      }
    };
    document.addEventListener('visibilitychange', visibilitychangeCallback);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        visibilitychangeCallback
      );
    };
  }, []);
  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  );
};

const rootLoader = async () => {
  await getToken(getCi());
  return '';
};

/**
 * 로더 실행 검증 함수
 *
 * 라우터 변경될 때마다 해당 함수 실행
 *
 * 함수 실행 결과로 true 값 반환 시, 할당된 loader 함수 실행. (이 경우엔 rootLoader 함수)
 * @returns {boolean}
 */
const rootShouldRevalidate = () => {
  const token = Cookies.get(env.authTokenKey);
  const revalidate = needCiUpdate().need || !token;
  return revalidate;
};

/**
 * 공통으로 사용되는 Router
 */
const commonFeatureRoutes = [
  {
    element: <MainRendingPage />,
    path: PAGE_INFO.home.page,
  },
  {
    element: <MyStrategyListPage />,
    path: `${PAGE_INFO['my-strategies'].page}`,
  },
  {
    element: <MyStrategyListPage />,
    path: `${PAGE_INFO['my-strategies'].page}`,
  },
];

/**
 * 국내 전략 관련 Router
 */
const krFeatureRoutes = [
  {
    element: <ReadymadeListPage />,
    path: PAGE_KR_INFO.readymades.page,
  },
  {
    element: <ReadymadeDetailPage />,
    path: PAGE_KR_INFO['readymade-detail'].page,
  },
  {
    element: <ReadymadeMasterPage />,
    path: PAGE_KR_INFO['readymade-detail-master'].page,
  },
  {
    element: <StrategyBeginner1EditPage />,
    path: PAGE_KR_INFO['strategy-beginner-1-edit'].page,
  },
  {
    element: <StrategyBeginner2EditPage />,
    path: PAGE_KR_INFO['strategy-beginner-2-edit'].page,
  },
  {
    element: <StrategyPro1EditPage />,
    path: PAGE_KR_INFO['strategy-pro-1-edit'].page,
  },
  {
    element: <StrategySimpleEditPage />,
    path: PAGE_KR_INFO['strategy-simple-edit'].page,
  },
  {
    element: <StrategyDetailEditPage />,
    path: PAGE_KR_INFO['strategy-detail-edit'].page,
  },
  {
    element: <StrategyDetailEditDiyPage />,
    path: PAGE_KR_INFO['strategy-diy-edit'].page,
  },
  {
    element: <StrategyMasterEditPage />,
    path: PAGE_KR_INFO['strategy-master-edit'].page,
  },
  {
    element: <StrategyBeginner1EditPage reEdit />,
    path: PAGE_KR_INFO['strategy-beginner-1-re-edit'].page,
  },
  {
    element: <StrategyBeginner2EditPage reEdit />,
    path: PAGE_KR_INFO['strategy-beginner-2-re-edit'].page,
  },
  {
    element: <StrategyPro1EditPage reEdit />,
    path: PAGE_KR_INFO['strategy-pro-1-re-edit'].page,
  },
  {
    element: <StrategySimpleEditPage reEdit />,
    path: PAGE_KR_INFO['strategy-simple-re-edit'].page,
  },
  {
    element: <StrategyDetailEditPage reEdit />,
    path: PAGE_KR_INFO['strategy-detail-re-edit'].page,
  },
  {
    element: <StrategyDetailEditDiyPage reEdit />,
    path: PAGE_KR_INFO['strategy-diy-re-edit'].page,
  },
  {
    element: <StrategyMasterEditPage reEdit />,
    path: PAGE_KR_INFO['strategy-master-re-edit'].page,
  },
  {
    element: <StrategyEditResultPage />,
    path: PAGE_KR_INFO['strategy-edit-result'].page,
  },
  {
    element: <MyStrategyEditResultPage />,
    path: PAGE_KR_INFO['my-strategy-edit-result'].page,
  },
  {
    element: <BackTestPage />,
    path: PAGE_KR_INFO['my-strategy-back-test'].page,
  },
  {
    element: <ForwardTestPage />,
    path: PAGE_KR_INFO['my-strategy-forward-test'].page,
  },

  {
    element: <MyStrategyBeginner1EditPage />,
    path: PAGE_KR_INFO['my-strategy-beginner-1-edit'].page,
  },
  {
    element: <MyStrategyBeginner2EditPage />,
    path: PAGE_KR_INFO['my-strategy-beginner-2-edit'].page,
  },
  {
    element: <MyStrategyPro1EditPage />,
    path: PAGE_KR_INFO['my-strategy-pro-1-edit'].page,
  },
  {
    element: <MyStrategySimpleEditPage />,
    path: PAGE_KR_INFO['my-strategy-simple-edit'].page,
  },
  {
    element: <MyStrategyMasterEditPage />,
    path: PAGE_KR_INFO['my-strategy-master-edit'].page,
  },
  {
    element: <MyStrategyDetailEditPage />,
    path: PAGE_KR_INFO['my-strategy-detail-edit'].page,
  },
  {
    element: <MyStrategyDetailEditDiyPage />,
    path: PAGE_KR_INFO['my-strategy-diy-edit'].page,
  },
];

/**
 * 미국 전략 관련 Router
 */
const usFeatureRoutes = [
  {
    element: <UsReadymadeListPage />,
    path: PAGE_US_INFO['us-readymades'].page,
  },
  {
    element: <UsReadymadeDetailPage />,
    path: PAGE_US_INFO['us-readymade-detail'].page,
  },
  {
    element: <UsReadymadeMasterPage />,
    path: PAGE_US_INFO['us-readymade-detail-master'].page,
  },
  {
    element: <UsStrategyBeginner1EditPage />,
    path: PAGE_US_INFO['us-strategy-beginner-1-edit'].page,
  },
  {
    element: <UsStrategyBeginner2EditPage />,
    path: PAGE_US_INFO['us-strategy-beginner-2-edit'].page,
  },
  {
    element: <UsStrategyPro1EditPage />,
    path: PAGE_US_INFO['us-strategy-pro-1-edit'].page,
  },
  {
    element: <UsStrategySimpleEditPage />,
    path: PAGE_US_INFO['us-strategy-simple-edit'].page,
  },
  {
    element: <UsStrategyDetailEditPage />,
    path: PAGE_US_INFO['us-strategy-detail-edit'].page,
  },
  {
    element: <UsStrategyDetailEditDiyPage />,
    path: PAGE_US_INFO['us-strategy-diy-edit'].page,
  },
  {
    element: <UsStrategyMasterEditPage />,
    path: PAGE_US_INFO['us-strategy-master-edit'].page,
  },

  {
    element: <UsStrategyBeginner1EditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-beginner-1-re-edit'].page,
  },
  {
    element: <UsStrategyBeginner2EditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-beginner-2-re-edit'].page,
  },
  {
    element: <UsStrategyPro1EditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-pro-1-re-edit'].page,
  },
  {
    element: <UsStrategySimpleEditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-simple-re-edit'].page,
  },
  {
    element: <UsStrategyDetailEditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-detail-re-edit'].page,
  },
  {
    element: <UsStrategyDetailEditDiyPage reEdit />,
    path: PAGE_US_INFO['us-strategy-diy-re-edit'].page,
  },
  {
    element: <UsStrategyMasterEditPage reEdit />,
    path: PAGE_US_INFO['us-strategy-master-re-edit'].page,
  },
  {
    element: <UsStrategyEditResultPage />,
    path: PAGE_US_INFO['us-strategy-edit-result'].page,
  },
  {
    element: <MyUsStrategyEditResultPage />,
    path: PAGE_US_INFO['my-us-strategy-edit-result'].page,
  },

  {
    element: <UsBackTestPage />,
    path: PAGE_US_INFO['my-us-strategy-back-test'].page,
  },
  {
    element: <UsForwardTestPage />,
    path: PAGE_US_INFO['my-us-strategy-forward-test'].page,
  },

  {
    element: <MyUsStrategyBeginner1EditPage />,
    path: PAGE_US_INFO['my-us-strategy-beginner-1-edit'].page,
  },
  {
    element: <MyUsStrategyBeginner2EditPage />,
    path: PAGE_US_INFO['my-us-strategy-beginner-2-edit'].page,
  },
  {
    element: <MyUsStrategyPro1EditPage />,
    path: PAGE_US_INFO['my-us-strategy-pro-1-edit'].page,
  },
  {
    element: <MyUsStrategySimpleEditPage />,
    path: PAGE_US_INFO['my-us-strategy-simple-edit'].page,
  },
  {
    element: <MyUsStrategyMasterEditPage />,
    path: PAGE_US_INFO['my-us-strategy-master-edit'].page,
  },
  {
    element: <MyUsStrategyDetailEditPage />,
    path: PAGE_US_INFO['my-us-strategy-detail-edit'].page,
  },
  {
    element: <MyUsStrategyDetailEditDiyPage />,
    path: PAGE_US_INFO['my-us-strategy-diy-edit'].page,
  },
];

const featureRoutes = [
  {
    element: <Root />,
    loader: rootLoader,
    shouldRevalidate: rootShouldRevalidate,
    children: [...krFeatureRoutes, ...usFeatureRoutes, ...commonFeatureRoutes],
  },
];

export const router = createBrowserRouter(featureRoutes);
