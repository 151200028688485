/**
 * IOS에서 스크롤을 막기 위해서 포함되어야 하는 CSS
 * @param offsetTop {number} position이 fixed일 때, top의 offset
 * @param position {boolean} true: fixed
 * @returns
 */
export const createScrollLockCss = (offsetTop?: number, position = true) => {
  const defaultCss = `
  -webkit-overflow-scrolling: none;
  overflow-y: hidden;
  overscroll-behavior: none;
  touch-action: none;
`;
  if (!position) {
    return defaultCss;
  }
  if (offsetTop === undefined) {
    return `
      ${defaultCss}
      width: 100%;
      position: absolute;
    `;
  }
  return `
    ${defaultCss}
    width: 100%;
    position: fixed;
    top: -${offsetTop}px;
  `;
};

export const getDimmedStack = () => {
  return document.body.getAttribute('dimmed-stack');
};

export const pushDimmedStack = () => {
  const stack = String(+(getDimmedStack() || 0) + 1);
  document.body.setAttribute('dimmed-stack', stack);
};

export const popDimmedStack = () => {
  const stack = +(getDimmedStack() || 0) - 1;
  if (stack <= 0) {
    document.body.removeAttribute('dimmed-stack');
    return stack;
  }
  document.body.setAttribute('dimmed-stack', String(stack));
  return stack;
};
