import { usePopupStore } from '@stores/feedback/popup.store';
import { useStrategyListStore } from '@stores/readymade-list';
import { useReadymadeStrategyStore } from '@stores/readymade-strategy';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { useRouterMove } from '..';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { UNIVERSE_PRESET_MAPPER } from '@constants/strategy';
import { handleBlockPopupState } from '@utils/common';
import { MyStrategyMasterResDto } from '@models/strategy';
import { MasterEditPageContainerProps } from '@containers/strategy-edit/MasterEditPageContainer';
import { COUNTRY_MAPPER } from '@constants/common';
import { useToastStore } from '@stores/feedback/toast.store';
import { STRATEGY_EDIT_MASTER_TOAST_MESSAGE } from '@constants/strategy/strategy-edit.const';
import { useConfirmStore } from '@stores/feedback';

interface useMasterEditControllerProps extends MasterEditPageContainerProps {}

export const useMasterEditController = ({
  countryCode,
  prevStrategyId,
  reEdit,
  renew,
}: useMasterEditControllerProps) => {
  const { strategyId = '' } = useParams<string>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const editStep = searchParams.get('step') || 1;
  const { openConfirm } = useConfirmStore();
  const {
    moveToReadyMades,
    moveToUSReadyMades,
    moveToMyStrategies,
    moveToMyEditResult,
    moveToEditResult,
    moveToUsEditResult,
    moveToMyUsEditResult,
  } = useRouterMove();
  const { universePresets, fetchUniversePreset } = useStrategyListStore();
  const { masterInfo, fetchMasterInfo, setMasterInfo } =
    useReadymadeStrategyStore();
  const {
    holdings,
    setStrategyId,
    editCancel,
    updateRenewAndReEdit,
    initEditState,
    setUniversePreset,
    fetchMasterFactors,
    createMyStrategy,
    fetchMyMasterFactors,
    fetchMyMasterInfo,
    setPrevSimulationOptionScreening,
  } = useStrategyEditStore();
  const openPopup = usePopupStore((state) => state.openPopup);
  const { openToast } = useToastStore();

  useEffect(() => {
    setStrategyId(strategyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyId]);

  useEffect(() => {
    if (+editStep === 1 && renew) {
      openToast(STRATEGY_EDIT_MASTER_TOAST_MESSAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renew, editStep]);

  useLayoutEffect(() => {
    if (renew || reEdit) {
      Promise.all([
        fetchMyMasterInfo({ id: strategyId }),
        fetchUniversePreset({ countries: countryCode }),
      ]).then(([masterInfoRes]) => {
        const { masterInfo, universePreset } =
          masterInfoRes as unknown as MyStrategyMasterResDto;
        fetchMyMasterFactors({ myStrategyId: strategyId, universePreset });
        setMasterInfo(masterInfo);
        setUniversePreset(universePreset);
        setPrevSimulationOptionScreening(true);
        updateRenewAndReEdit(!!renew, !!reEdit);
      });
    } else {
      const universe =
        countryCode === COUNTRY_MAPPER.DOMESTIC
          ? UNIVERSE_PRESET_MAPPER.KOSPI_KOSDAQ
          : UNIVERSE_PRESET_MAPPER.SNP500;
      fetchMasterInfo(strategyId);
      fetchMasterFactors({
        strategyId,
        universePreset: universe,
      });
      setUniversePreset(universe);
    }

    const handlePopState = handleBlockPopupState(handleCancel);
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopState);

    if (
      (countryCode === COUNTRY_MAPPER.DOMESTIC &&
        universePresets.PUBLIC.length === 0 &&
        universePresets.REPRESENT.length === 0) ||
      (countryCode === COUNTRY_MAPPER.USA && universePresets.USA.length === 0)
    ) {
      fetchUniversePreset({ countries: countryCode });
    }

    return () => {
      initEditState();
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickNext = () => {
    // Check 된 카드가 없으면 PopUp 띄우기
    // step 2번째로 이동시키기
    let query = `step=${+editStep + 1}`;
    if (reEdit) query += `&reEdit=${reEdit}`;
    setSearchParams(query, { replace: true });
  };

  const handleClickComplete = () => {
    if (holdings.length < 5) {
      openConfirm('alert')({
        title: '종목 구성',
        descriptions: [
          '기본 종목을 포함해 총 5개 이상의 종목을 구성해 주세요.',
        ],
        button: 'single',
      });
      return;
    }

    createMyStrategy(strategyId, false, !!(renew || reEdit)).then(
      (myStrategyId) => {
        if (myStrategyId) {
          if (renew) {
            if (countryCode === COUNTRY_MAPPER.USA) {
              moveToMyUsEditResult(myStrategyId, prevStrategyId || strategyId);
              return;
            } else {
              moveToMyEditResult(myStrategyId, prevStrategyId || strategyId);
              return;
            }
          }

          if (countryCode === COUNTRY_MAPPER.USA) {
            moveToUsEditResult(myStrategyId);
            return;
          } else {
            moveToEditResult(myStrategyId);
            return;
          }
        }
      }
    );
    // 백테스팅 Mutation 호출
    // 성공시 백테스팅 상세 화면으로 이동
  };

  const handleClickTabButton = (index: number) => () => {
    navigate(`?step=${index + 1}`, { replace: true });
  };

  const handleClickPrev = () => {
    let query = `step=${+editStep - 1}`;
    if (reEdit) query += `&reEdit=${reEdit}`;
    setSearchParams(query, { replace: true });
  };

  /** '편집 취소' 버튼 클릭시 실행되는 함수 */
  const handleCancel = () => {
    let moveMyStrategies =
      countryCode === COUNTRY_MAPPER.USA
        ? () => moveToMyStrategies(1)
        : () => moveToMyStrategies(0);
    const moveReadyMade =
      countryCode === COUNTRY_MAPPER.DOMESTIC
        ? moveToReadyMades
        : moveToUSReadyMades;
    editCancel(moveMyStrategies, moveReadyMade);
  };

  const handleClickShowDetail = () => {
    openPopup('masterStrategy')({
      factors: masterInfo.factors,
    });
  };

  return {
    strategyId,
    editStep,
    handleCancel,
    handleClickNext,
    handleClickComplete,
    handleClickTabButton,
    handleClickPrev,
    handleClickShowDetail,
  };
};
