import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';

interface QueryWithMeta extends Query {
  meta:
    | {
        errorMessage?: string;
      }
    | undefined;
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(_error, query: QueryWithMeta) {
      // TODO: useQuery에서 정의한 meta data 처리
      if (query.meta?.errorMessage) {
        // TODO: useQuery에서 정의한 meta.errorMessage 처리
      }
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
