import { TabItemDto } from '@components/layout';
import { StrategyCategoryEnum } from '@graphql/generates';
import { StrategyCategoryType } from '@models/strategy';

export const STRATEGY_CATEGORY_TAB: TabItemDto[] = [
  {
    text: '테마 전략',
    name: 'THEME',
    description: 'KB의 테마 전략을 선택 후 내 마음대로 편집하고 투자하세요.',
  },
  {
    text: '업종 전략',
    name: 'SECTOR',
    description: 'KB의 업종 전략을 선택 후 내 마음대로 편집하고 투자하세요.',
  },
  {
    text: '나만의 전략',
    name: 'DIY',
    description: '나만의 기준으로 전략을 마음대로 편집하고 투자하세요.',
  },
  {
    text: '대가들의 투자 전략',
    name: 'MASTER',
    description: '투자 대가의 전략을 선택 후 내 마음대로 편집하고 투자하세요.',
  },
];

/**
 * 전략 편집 탭
 */
export const STRATEGY_EDIT_TAB_INFO = {
  pro: {
    name: '중상급자',
    subName: '프로(Pro)투자',
  },
  beginner: {
    name: '초급자',
    subName: '프리셋(Pre-set)투자',
  },
};

export const STRATEGY_CATEGORY_ENUM = [
  'DIY',
  'EMP',
  'MASTER',
  'SECTOR',
  'THEME',
] as const;

export const STRATEGY_CATEGORY_LOCALE: Record<StrategyCategoryType, string> = {
  THEME: '테마 전략',
  DIY: '나만의 전략',
  EMP: 'EMP',
  MASTER: '대가들의 전략',
  SECTOR: '업종 전략',
} as const;

export const STRATEGY_CATEGORY_PLACEHOLDER: Record<
  StrategyCategoryType,
  string
> = {
  THEME: "'IT' 검색해보세요!",
  DIY: "'코스닥'을 검색해보세요!",
  EMP: '',
  MASTER: "'월가의 영웅'을 검색해보세요!",
  SECTOR: "'자본재'를 검색해보세요!",
};

export const US_STRATEGY_CATEGORY_PLACEHOLDER: Record<
  StrategyCategoryType,
  string
> = {
  THEME: "'IT' 검색해보세요!",
  DIY: "'기술주'를 검색해보세요!",
  EMP: '',
  MASTER: "'월가의 영웅'을 검색해보세요!",
  SECTOR: "'에너지'를 검색해보세요!",
};

export const STRATEGY_ORDER_CATEGORY_ENUM = ['diy', 'theme'] as const;

export const STRATEGY_CATEGORY_MAPPER: Record<
  StrategyCategoryType,
  StrategyCategoryEnum
> = {
  THEME: StrategyCategoryEnum.Theme,
  DIY: StrategyCategoryEnum.Diy,
  EMP: StrategyCategoryEnum.Emp,
  MASTER: StrategyCategoryEnum.Master,
  SECTOR: StrategyCategoryEnum.SectorIndustry,
} as const;

export const STRATEGY_CATEGORY_VIEW_MAPPER: Record<
  StrategyCategoryEnum,
  StrategyCategoryType
> = {
  THEME: StrategyCategoryEnum.Theme,
  DIY: StrategyCategoryEnum.Diy,
  EMP: StrategyCategoryEnum.Emp,
  MASTER: StrategyCategoryEnum.Master,
  [StrategyCategoryEnum.SectorIndustry]: 'SECTOR',
} as const;
