import {
  OPTIMIZATION_LOCALE,
  REBALANCING_OPTION_LOCALE,
  STRATEGY_CATEGORY_VIEW_MAPPER,
  UNIVERSE_LOCALE,
} from '@constants/strategy';
import {
  CashItemInfoFragment,
  ExchangeRateInfo,
  ForwardSummaryInfoFragment,
  MyReturnChartItem,
  MyStrategyInfoFragment,
  SectorIndustrySummaryTypeInfoFragment,
} from '@graphql/generates';
import { TickDto } from '@models/common';
import {
  CashDto,
  MyForwardSummaryExchangeModel,
  MyForwardSummaryModel,
  MyStrategyBasicInfoModel,
  SectorIndustrySummaryModel,
  StrategySettingDto,
  UniverseType,
  WhiteListStockObjectType,
} from '@models/strategy';
import { createPeriod } from '@stores/common';
import { STRATEGY_SETTING_INIT } from '@stores/strategy-edit/simulation-option';
import {
  filterNonDataOfArray,
  getDateFormattedString,
  numberToPercent,
  numberWithCommas,
  replaceNumberByTenThousandUnit,
} from '@utils/common';
import { createInitForwardSummary } from './my-strategy.init';

/**
 * 내전략 섹터/업종 정보를 만들어 반환한다.
 * 내전략 > 백테스트결과 > 전략상세 탭 > 상단 섹터/업종 정보.
 * @param {Object} data - 섹터/업종 정보.
 * @param {number} data.totalCnt - 업종 총 갯수.
 * @param {Object[]} data.info - 섹터/업종 정보들.
 * @param {string} data.info[].sector - 섹터 코드.
 * @param {string} data.info[].sectorName - 섹터명.
 * @param {string[]} data.info[].industries - 업종 코드 리스트.
 * @param {string[]} data.info[].industriesName - 업종명 리스트.
 * @param {boolean} data.info[].isSelectAll - 전체 선택 여부.
 * @returns {Object}
 */
export const createSectorIndustryInfo = (
  data: SectorIndustrySummaryTypeInfoFragment | null | undefined
): SectorIndustrySummaryModel => {
  let selectedCount = 0;
  const sectors: string[] = [];
  const industries: string[] = [];

  if (data) {
    const { totalCnt, info } = data;
    selectedCount = totalCnt || 0;
    if (info) {
      for (const infoItem of info) {
        if (!infoItem) continue;
        if (infoItem.sector) {
          const _industries = infoItem.industriesName || [];
          industries.push(...filterNonDataOfArray(_industries));
          const sectorName = `${infoItem.sectorName}(${
            infoItem.isSelectAll ? '전체' : _industries.length
          })`;
          sectors.push(sectorName);
        }
      }
    }
  }

  return {
    selectedCount,
    sectors,
    industries,
  };
};

/**
 * 내전략 차트 데이터를 만들어 반환한다.
 * @param {Object[]} chart - 차트 정보.
 * @param {Object} chart[].myStrategy - 내전략 정보.
 * @param {Object} chart[].strategy - 프리셋전략 정보.
 * @param {Object} chart[].benchmark - 벤치마크 정보.
 * @returns {Object}
 */
export const createMyChartData = (chart: Array<MyReturnChartItem>) => {
  let myStrategyName = '';
  let strategyName = '';
  let benchmarkName = '';

  if (chart[0]) {
    myStrategyName = chart[0].myStrategy?.name || '';
    strategyName = chart[0].strategy?.name || '';
    benchmarkName = chart[0].benchmark?.name || '';
    if (benchmarkName) {
      benchmarkName = UNIVERSE_LOCALE[benchmarkName as UniverseType];
    }
  }

  const _chart = chart.map((chartItem) => {
    const myStrategyValue = chartItem?.myStrategy?.value || 0;
    const strategyValue = chartItem?.strategy?.value || 0;
    const benchmarkValue = chartItem?.benchmark?.value || 0;
    const name = getDateFormattedString(chartItem?.ts, 'YYYY/mm/dd');

    const tick: TickDto = {
      myStrategy: myStrategyValue,
      base: strategyValue,
      benchmark: benchmarkValue,
      name,
    };

    return tick;
  });

  return {
    myStrategyName,
    strategyName,
    benchmarkName,
    data: _chart,
  };
};

/**
 * 모의투자 투자정보를 만들어 반환한다.
 * 내전략 > 모의투자 > 상단 투자정보.
 * @param {Object} data - 모의투자 상단 정보.
 * @param {number} data.investmentAmount - 투자금액.
 * @param {number} data.valuationAmount - 평가금액.
 * @param {number} data.gainOrLossValuation - 평가손익.
 * @param {number} data.gainOrLossExchange - 환손익.
 * @param {Object} data.period - 투자기간.
 * @returns {Object}
 */
export const createForwardSummary = (
  data: ForwardSummaryInfoFragment | null | undefined
): MyForwardSummaryModel => {
  if (!data) return createInitForwardSummary();
  const {
    gainOrLossExchange,
    gainOrLossValuation,
    investmentAmount,
    period,
    valuationAmount,
  } = data;

  const date = [...createPeriod(period, true)];

  const [
    _investmentAmount,
    _valuationAmount,
    _valuationProfit,
    _valuationExchange,
  ]: string[] = [
    investmentAmount,
    valuationAmount,
    gainOrLossValuation,
    gainOrLossExchange,
  ].map((amount) => {
    let resAmount = '';
    if (amount !== null && amount !== undefined) {
      resAmount = `${numberWithCommas(amount)}원`;
    } else {
      resAmount = '- 원';
    }

    return resAmount;
  });

  return {
    investmentPeriod: date,
    investmentAmount: _investmentAmount,
    valuationAmount: _valuationAmount,
    valuationProfit: _valuationProfit,
    valuationExchange: _valuationExchange,
  };
};

/**
 * 모의투자 환율정보를 만들어 반환한다.
 * 내전략 > 모의투자 > 상단 투자정보
 *
 * 미국전략에만 적용된다.
 * @param {Object} data - 모의투자 상단 정보.
 * @param {number} data.investmentAmount - 투자금액.
 * @param {number} data.valuationAmount - 평가금액.
 * @param {number} data.gainOrLossValuation - 평가손익.
 * @param {Object} data.period - 투자기간.
 * @returns {Object}
 */
export const createForwardSummaryExchange = (
  data: ForwardSummaryInfoFragment | null | undefined
): MyForwardSummaryExchangeModel => {
  if (!data) return createInitForwardSummary();
  const { gainOrLossValuation, investmentAmount, period, valuationAmount } =
    data;

  const date = [...createPeriod(period, true)];

  const [_investmentAmount, _valuationAmount, _valuationProfit]: string[] = [
    investmentAmount,
    valuationAmount,
    gainOrLossValuation,
  ].map((amount) => {
    let resAmount = '';
    if (amount !== null && amount !== undefined) {
      resAmount = `$ ${numberWithCommas(amount)}`;
    } else {
      resAmount = '-';
    }

    return resAmount;
  });

  return {
    investmentPeriod: date,
    investmentAmount: _investmentAmount,
    valuationAmount: _valuationAmount,
    valuationProfit: _valuationProfit,
  };
};

/**
 * 내전략 정보를 만들어 반환한다.
 * @param {Object} info - 내전략 정보.
 * @param {string} info.id - 전략 ID.
 * @param {string} info.portfolioId - 전략 PortfolioID.
 * @param {string} info.category - 전략 카테고리.
 * @param {string} info.baseStrategyName - 기초전략 이름.
 * @param {string} info.name - 내전략 이름.
 * @param {Object} info.sectorIndustrySummary - 섹터/업종 정보.
 * @param {Object} info.simulationOption - 투자기준 정보.
 * @param {number} info.createdAt - 내전략 생성일.
 * @param {boolean} info.isSimple - 간편전략 여부.
 * @param {boolean} info.isForwardAvailable - 모의투자 페이지 접근 가능 여부.
 * @param {boolean} info.isActiveKbContract - 자문계약 여부.
 * @param {Object} info.universePreset - 유니버스 정보.
 * @param {Object} info.investmentStrategyLevel - 전략 편집 레벨.
 * @returns {Object}
 */
export const createMyStrategyInfo = (info: MyStrategyInfoFragment) => {
  const {
    id,
    portfolioId,
    category,
    baseStrategyName,
    name,
    sectorIndustrySummary,
    simulationOption,
    createdAt,
    isSimple,
    isForwardAvailable,
    isActiveKbContract,
    universePreset,
    investmentStrategyLevel,
  } = info;

  const _baseStrategyName = baseStrategyName || '';
  const _sectorIndustrySummary = createSectorIndustryInfo(
    sectorIndustrySummary
  );
  const myStrategyBasicInfo: MyStrategyBasicInfoModel = {
    createdAt: getDateFormattedString(createdAt, 'YYYY/mm/dd'),
    baseStrategyName: _baseStrategyName,
    universePresetName: universePreset.name || '',
    selectedCount: _sectorIndustrySummary.selectedCount,
    sectorIndustrySummary: _sectorIndustrySummary,
  };
  const mySimulationOptionInfo: string[][] = [];
  const strategySettingObj: StrategySettingDto = {
    ...STRATEGY_SETTING_INIT,
  };

  const whitelistObj: WhiteListStockObjectType = {};
  if (info.whitelist && info.whitelist.length > 0) {
    info.whitelist.forEach((item) => {
      if (item?.ccid) {
        whitelistObj[item.ccid] = {
          ccid: item.ccid,
          isAdd: item.isAdd,
          weight: item.weight,
          isLastEdit: false,
        };
      }
    });
  }

  if (simulationOption) {
    const { booksize, optimization, startDate, rebalancing } = simulationOption;
    mySimulationOptionInfo.push([
      '투자 금액',
      replaceNumberByTenThousandUnit(booksize), // 투자금액 : 1원 -> 1만 단위로 환원
    ]);
    mySimulationOptionInfo.push([
      '최적화 옵션',
      optimization ? OPTIMIZATION_LOCALE[optimization] : '',
    ]);
    mySimulationOptionInfo.push([
      '리밸런싱 주기',
      REBALANCING_OPTION_LOCALE[rebalancing || 'NONE'],
    ]);
    mySimulationOptionInfo.push([
      '백테스트 시작일',
      getDateFormattedString(startDate, 'YYYY/mm/dd').slice(0, 7),
    ]);
    strategySettingObj.booksize = booksize;
    strategySettingObj.optimization = optimization;
    strategySettingObj.rebalancing = rebalancing || 'NONE';
    strategySettingObj.startDate = startDate;
  }

  return {
    myStrategyInfo: {
      id,
      portfolioId: portfolioId || '',
      category: STRATEGY_CATEGORY_VIEW_MAPPER[category],
      myStrategyBasicInfo,
      mySimulationOptionInfo,
      name,
      createdAt,
      isSimple,
      isForwardAvailable,
      isActiveKbContract,
      investmentStrategyLevel,
    },
    strategySettingObj,
    whitelistObj,
  };
};

/**
 * 현금 정보를 만들어 반환한다.
 * @param {Object} data - 현금 정보.
 * @param {number} data.weight - 현금 비중.
 * @param {number} data.price - 현금 가격.
 * @returns {object | undefined}
 */
export const createCash = (
  data: CashItemInfoFragment | null | undefined
): CashDto | undefined => {
  if (!data) return;
  return {
    name: '현금',
    percent: numberToPercent(data.weight || 0) + '%',
    price: numberWithCommas(data.price || 0),
  };
};

/**
 * 환율 정보를 문자열로 만들어 반환한다.
 * @param {Object} exchangeRateInfo - 환율 정보.
 * @param {number} exchangeRateInfo.baseDate - 환율 기준일.
 * @param {number} exchangeRateInfo.exchangeRate - 환율.
 * @returns {string}
 *
 * @example
 * createExchangeRateInfo({
 *  baseDate: 1707404400000,
 *  exchangeRate: 1328.2,
 * }); // '1,328.2 (2024/02/09)'
 */
export const createExchangeRateInfo = (
  exchangeRateInfo: ExchangeRateInfo | undefined | null
) => {
  let returnExchangeRateStr = '';

  if (exchangeRateInfo) {
    const { baseDate, exchangeRate } = exchangeRateInfo;
    if (exchangeRate && baseDate) {
      returnExchangeRateStr += numberWithCommas(exchangeRate);
      returnExchangeRateStr += ` (${getDateFormattedString(
        baseDate,
        'YYYY/mm/dd'
      )})`;
    }
  }

  return returnExchangeRateStr;
};
