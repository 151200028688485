import { ThemeType } from '@styles/theme';
import {
  BoxSpacingType,
  FlexBoxLayoutProps,
  FlexBoxWrapProps,
  FlexItemLayoutProps,
  ScBackgroundColorProps,
  ScBoxSpacingProps,
  ScHeightChangeProps,
  ScMinWidthProps,
  ScPositionProps,
  ScWidthChangeProps,
  ThemedComponentProps,
} from '@ui/model';
import { isNumber, isNumberLike, isUndefined } from '@utils/common';
import { css } from 'styled-components';
import { findColorByType } from './common.util';

export const getTextOverflow = (line: number) => () => {
  return css`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${line};
  `;
};

export const getFlexBoxCss = () => {
  return css`
    display: flex;
  `;
};

export const getWidthFromProps = ({ $width }: ScWidthChangeProps) => {
  if (!$width) {
    return null;
  }

  if (isNumberLike($width)) {
    return css`
      width: ${$width}px;
    `;
  }
  return css`
    width: ${$width};
  `;
};

export const getHeightFromProps = ({ $height }: ScHeightChangeProps) => {
  if (!$height) {
    return null;
  }
  if (isNumberLike($height)) {
    return css`
      height: ${$height}px;
    `;
  }
  return css`
    height: ${$height};
  `;
};

export const getBackgroundColorFromProps = ({
  $bgColor,
}: ScBackgroundColorProps) => {
  if (!$bgColor) {
    return null;
  }
  return css`
    background-color: ${findColorByType($bgColor)};
  `;
};

function getSpacing(spacing: ThemeType['spacing'], value: BoxSpacingType) {
  if (isNumber(value)) {
    return `${value}px`;
  }
  return spacing[value];
}

export const getFlexSpacingFromProps = ({
  theme,
  $spacing,
}: ThemedComponentProps & ScBoxSpacingProps) => {
  if ($spacing === '0' || $spacing === undefined) {
    return null;
  }
  return css`
    gap: ${getSpacing(theme.spacing, $spacing)};
  `;
};

export function getMinWidthFromProps({ $minWidth }: ScMinWidthProps) {
  if (!$minWidth) {
    return null;
  }
  if (isNumberLike($minWidth)) {
    return css`
      min-width: ${$minWidth}px;
    `;
  }
  return css`
    min-width: ${$minWidth};
  `;
}

export const getPositionFromProps = ({ $pos }: ScPositionProps) => {
  if ($pos) {
    return css`
      position: ${$pos};
    `;
  }
};

export const getFlexAttrFromProps = ({
  direction,
  justify,
  alignItems,
}: FlexBoxLayoutProps) => css`
  ${direction ? `flex-direction: ${direction};` : ''}
  ${justify ? `justify-content: ${justify};` : ''}
        ${alignItems ? `align-items: ${alignItems};` : ''}
`;

export const getFlexItemAttrFromProps = ({
  flexGrow,
  flexShrink,
  flexBasis,
}: FlexItemLayoutProps) => {
  let basis = '';

  if (isNumberLike(flexBasis)) {
    basis = `${flexBasis}px`;
  } else if (flexBasis) {
    basis = `${flexBasis}`;
  }

  return css`
    ${!isUndefined(flexGrow) ? `flex-grow: ${flexGrow};` : ''}
    ${!isUndefined(flexShrink) ? `flex-shrink: ${flexShrink};` : ''}
        ${!isUndefined(basis) ? `flex-basis: ${basis};` : ''}
  `;
};

export const getFlexWrapFromProps = ({ wrap }: FlexBoxWrapProps) => {
  if (wrap) {
    return css`
      flex-wrap: ${wrap};
    `;
  }
};
