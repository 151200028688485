import { ReturnRateText } from '@components/common';
import {
  STRATEGY_ORDER_PERIOD_LOCALE,
  STRATEGY_ORDER_PERIOD_MAPPER,
} from '@constants/strategy';
import {
  StrategyCardModel,
  StrategyCategoryType,
  StrategyOrderPeriodReturnType,
} from '@models/strategy';
import { Button, Typography } from '@ui/components';
import { FlexBox } from '@ui/components/Flexbox';
import { FC } from 'react';
import styled from 'styled-components';

interface StrategyCardProps {
  type: StrategyCategoryType;
  period: StrategyOrderPeriodReturnType;
  item: StrategyCardModel;
  onClickItemCurried: (id: string) => () => void;
  onClickSelectCurried: (id: string) => () => void;
  isKbPick?: boolean;
}

const CardButton = styled(Button)`
  border-color: ${({ theme }) => theme.colorPalette.border.chip};
`;

const ButtonGroup = styled.div`
  & > button {
    border-color: ${({ theme }) => theme.colorPalette.border.chip};
    width: 50%;

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-width: 1px 1px 1px 0px;
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colorPalette.black[7]};
  margin-top: 12px;
  margin-bottom: 8px;
`;

const SectorImgBox = styled.div`
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: ${({ theme }) =>
    theme.colorPalette.background.table_highlight};
`;

const CommonCardBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  padding: 20px;
  border-radius: 8px;
`;

const getReturnRate = (
  item: StrategyCardModel,
  period: StrategyOrderPeriodReturnType
) => {
  switch (period) {
    case STRATEGY_ORDER_PERIOD_MAPPER.MONTH_1:
      return item.returnSummary?.month1;
    case STRATEGY_ORDER_PERIOD_MAPPER.MONTH_3:
      return item.returnSummary?.month3;
    case STRATEGY_ORDER_PERIOD_MAPPER.MONTH_6:
      return item.returnSummary?.month6;
  }
};

export const StrategyCard: FC<StrategyCardProps> = ({
  type,
  period,
  item,
  onClickItemCurried,
  onClickSelectCurried,
  isKbPick,
}) => {
  const isAbleDetail = type === 'THEME' || type === 'MASTER';
  const returnRateLabel = isKbPick
    ? 'Pick 이후 수익률'
    : `${STRATEGY_ORDER_PERIOD_LOCALE[period]} 수익률`;

  const returnBaseDate = `(기준일: ${item.returnSummary?.kbPickCreatedTs})`;
  const returnRateValue = getReturnRate(item, period);

  const ButtonGroupElement = (
    <ButtonGroup>
      <Button
        variant="general2"
        height={40}
        onClick={onClickItemCurried(item.id)}
      >
        <Typography variant="body2_title" color="black.60">
          전략 상세
        </Typography>
      </Button>
      <Button
        variant="general2"
        height={40}
        onClick={onClickSelectCurried(item.id)}
      >
        <Typography variant="body2_title" color="black.60">
          전략 선택
        </Typography>
      </Button>
    </ButtonGroup>
  );

  const TitleWithDescElement = (
    <>
      <Typography
        variant="subTitle1_title"
        mb={8}
        style={{ lineBreak: 'anywhere' }}
      >
        {item.title}
      </Typography>
      <Typography color="black.47" variant="caption">
        {item.description}
      </Typography>
    </>
  );

  if (type === 'MASTER') {
    return (
      <CommonCardBox>
        <FlexBox spacing={16} mb={16}>
          <SectorImgBox>
            <img
              src={`/image/master/${item.img}.png`}
              alt={`${item.img} Icon`}
              width={60}
            />
          </SectorImgBox>
          <FlexBox direction="column">{TitleWithDescElement}</FlexBox>
        </FlexBox>
        {ButtonGroupElement}
      </CommonCardBox>
    );
  }

  if (type === 'SECTOR') {
    return (
      <CommonCardBox>
        <FlexBox spacing={16}>
          <SectorImgBox>
            <img
              src={`/image/sector/${item.img}.png`}
              alt={`${item.img} Icon`}
              width={60}
            />
          </SectorImgBox>
          <FlexBox direction="column" width="100%">
            {TitleWithDescElement}
            <Divider />
            <FlexBox py={6} justify="space-between" mb={16}>
              <Typography variant="caption" color="black.60">
                {returnRateLabel}
              </Typography>
              <ReturnRateText
                value={returnRateValue}
                type="body1_title"
                fontType="spoqa"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {isAbleDetail ? (
          ButtonGroupElement
        ) : (
          <CardButton
            variant="general2"
            height={40}
            onClick={onClickSelectCurried(item.id)}
          >
            <Typography variant="body2_title" color="black.60">
              전략 선택
            </Typography>
          </CardButton>
        )}
      </CommonCardBox>
    );
  }

  return (
    <CommonCardBox>
      {TitleWithDescElement}
      <Divider />
      {isKbPick ? (
        <FlexBox direction="row" justify="space-between" mb={16}>
          <FlexBox direction="column" py={6} spacing={4}>
            <FlexBox>
              <Typography variant="caption" color="black.60">
                {returnRateLabel}
              </Typography>
            </FlexBox>
            <FlexBox direction="column">
              <Typography variant="caption" color="black.60">
                {returnBaseDate}
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox alignItems="center">
            <ReturnRateText
              value={item.returnSummary?.kbPick}
              type="body1_title"
              fontType="spoqa"
            />
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox direction="column" mb={16}>
          <FlexBox py={6} justify="space-between">
            <Typography variant="caption" color="black.60">
              {returnRateLabel}
            </Typography>
            <ReturnRateText
              value={returnRateValue}
              type="body1_title"
              fontType="spoqa"
            />
          </FlexBox>
        </FlexBox>
      )}
      {isAbleDetail ? (
        ButtonGroupElement
      ) : (
        <CardButton
          variant="general2"
          height={40}
          onClick={onClickSelectCurried(item.id)}
        >
          <Typography variant="body2_title" color="black.60">
            전략 선택
          </Typography>
        </CardButton>
      )}
    </CommonCardBox>
  );
};
