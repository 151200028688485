import { Tag } from '@components/common';
import { EmptyStockList } from '@components/strategy/EmptyContent';
import { StockItemModel } from '@models/strategy';
import { FlexBox, Icon, Typography } from '@ui/components';
import { numberToPercent } from '@utils/common';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StockInfoBox } from './StockInfoBox';
import { WeightControlBox } from './WeightControlBox';
import { CountryCodeType } from '@models/common';
import { COUNTRY_MAPPER } from '@constants/common';

interface HoldingListBoxProps {
  countryCode: CountryCodeType;
  holdings: StockItemModel[];
  holdingsCnt: number;
  onClickExclude: (stock: StockItemModel) => () => void;
  onClickWeightMinus: (stock: StockItemModel) => () => void;
  onClickWeightPlus: (stock: StockItemModel) => () => void;
  onClickRefresh: (stock: StockItemModel) => () => void;
  onBlurWeight: (stock: StockItemModel) => (weight: string) => void;
  onFocusWeight: (stock: StockItemModel) => () => boolean;
}

const HoldingListBoxWrap = styled.div`
  padding-bottom: 32px;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
`;

const TableHeadGrid = styled(GridBox)`
  padding: 12px 0;
  border-top: 1px solid ${({ theme }) => theme.colorPalette.black[40]};
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
`;

const StockListGrid = styled(GridBox)`
  grid-template-rows: 90px;
`;

const StockItemWrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
`;

const ExceptBtn = styled.button`
  text-align: right;
`;

const TagWrapper = styled(FlexBox)`
  & > div {
    border-radius: 20px;
  }
`;

const RefreshBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colorPalette.border.chip};
  border-radius: 30px;
`;

export const HoldingListBox: FC<HoldingListBoxProps> = ({
  countryCode,
  holdings,
  holdingsCnt,
  onClickExclude,
  onClickWeightMinus,
  onClickWeightPlus,
  onClickRefresh,
  onBlurWeight,
  onFocusWeight,
}) => {
  const genTagWrapper = (stock: StockItemModel) => {
    const tagElement = [];
    if (stock.isWhitelist) {
      if (stock.isAdd) {
        tagElement.push(
          <Tag color="red" key={`${stock.ccid}-new-tag`}>
            +New
          </Tag>
        );

        if (stock.isAdd && stock.weight !== 0) {
          tagElement.push(
            <React.Fragment key={`${stock.ccid}-fixed-tag`}>
              <Tag color="green">비중고정</Tag>
              <RefreshBtn onClick={onClickRefresh(stock)}>
                <Icon type="refresh-gray" size={10} />
              </RefreshBtn>
            </React.Fragment>
          );
        }
      } else {
        tagElement.push(
          <React.Fragment key={`${stock.ccid}-fixed-tag`}>
            <Tag color="green">비중고정</Tag>
            <RefreshBtn onClick={onClickRefresh(stock)}>
              <Icon type="refresh-gray" size={10} />
            </RefreshBtn>
          </React.Fragment>
        );
      }
    }

    return (
      <TagWrapper spacing={4} key={`tag-wrapper-${stock.ccid}`}>
        {tagElement}
      </TagWrapper>
    );
  };

  return (
    <HoldingListBoxWrap>
      <FlexBox alignItems="center" mb={8} spacing={4}>
        <FlexBox alignItems="center">
          <Typography color="black.87" variant="caption_medium" mr={4}>
            총
          </Typography>
          <Typography color="secondary.text" variant="caption_medium">
            {holdingsCnt}
          </Typography>
          <Typography color="black.87" variant="caption_medium">
            개
          </Typography>
        </FlexBox>
        <Typography variant="tag" color="gray.blue">
          (비중 편집 시, 구성종목이 추가되거나 제외될 수 있습니다.)
        </Typography>
      </FlexBox>
      <TableHeadGrid>
        <FlexBox direction="column">
          <Typography color="black.87" variant="caption_medium">
            종목명
          </Typography>
          <Typography color="black.47" variant="overline_content">
            1주 당 비중
          </Typography>
        </FlexBox>
        <FlexBox direction="column" alignItems="center">
          <Typography color="black.87" variant="caption_medium">
            비중
          </Typography>
          <Typography color="black.47" variant="overline_content">
            (증감)
          </Typography>
        </FlexBox>
        <FlexBox direction="column" alignItems="flex-end">
          <Typography color="black.87" variant="caption_medium">
            제외
          </Typography>
        </FlexBox>
      </TableHeadGrid>

      {holdings.map((stock) => (
        <StockItemWrap key={stock.ccid}>
          <StockListGrid>
            <FlexBox>
              <FlexBox direction="column" justify="center" spacing={4}>
                {genTagWrapper(stock)}
                <Typography color="black.87" variant="caption_medium">
                  {stock.name}
                </Typography>
                <FlexBox>
                  {countryCode === COUNTRY_MAPPER.USA && (
                    <Typography
                      color="black.47"
                      variant="overline_content"
                      whiteSpace="pre"
                    >
                      {`${stock.code} | `}
                    </Typography>
                  )}
                  <Typography variant="overline_content" color="black.47">
                    {numberToPercent(stock.weightPerShare)}%
                  </Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox
              direction="column"
              spacing={6}
              alignItems="center"
              justify="center"
            >
              <WeightControlBox
                stock={stock}
                onClickMinus={onClickWeightMinus(stock)}
                onClickPlus={onClickWeightPlus(stock)}
                onWeightBlur={onBlurWeight(stock)}
                onFocusWeight={onFocusWeight(stock)}
              />
            </FlexBox>
            <ExceptBtn onClick={onClickExclude(stock)}>
              <Icon type="close-gray" size={22} />
            </ExceptBtn>
          </StockListGrid>
          <StockInfoBox code={stock.infoCode} />
        </StockItemWrap>
      ))}
      {holdings.length === 0 && <EmptyStockList />}
    </HoldingListBoxWrap>
  );
};
