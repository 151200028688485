import { Typography } from '@ui/components';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface BadgeProps {
  active?: boolean;
  children?: React.ReactNode;
}

const BadgeWrap = styled.div<BadgeProps>`
  ${({ active, theme }) => {
    if (active) {
      return css`
        background-color: ${theme.colorPalette.secondary.chip};
      `;
    }
    return css`
      background-color: ${theme.colorPalette.divider.sub_filter};
    `;
  }}
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
`;

export const Badge: FC<BadgeProps> = ({ active = false, children }) => {
  return (
    <BadgeWrap active={active}>
      <Typography
        variant="caption_medium"
        color={active ? 'secondary.text' : 'black.60'}
      >
        {children}
      </Typography>
    </BadgeWrap>
  );
};
