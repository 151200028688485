import { SelectButton } from '@components/common';
import {
  FlexBox,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@ui/components';
import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

interface MyStrategyStyleDetailInfoProps {
  strategyStyleDetailInfo: string[][];
}

const MyStrategyStyleDetailInfoWrap = styled(FlexBox)``;

const CustomRow = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorPalette.divider.tables};
  position: relative;
`;
const CustomColumn = styled(Typography)`
  flex: 1;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colorPalette.divider.tables};
  height: 100%;
  width: 1px;
  position: absolute;
  left: 50%;
`;

const WrapperComponent: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <MyStrategyStyleDetailInfoWrap
      direction="column"
      justify="flex-end"
      pt={16}
      spacing={24}
    >
      {children}
    </MyStrategyStyleDetailInfoWrap>
  );
};

export const MyStrategyStyleDetailInfo: FC<MyStrategyStyleDetailInfoProps> = ({
  strategyStyleDetailInfo,
}) => {
  const [open, setOpen] = useState(false);

  if (strategyStyleDetailInfo.length === 0) {
    return (
      <WrapperComponent>
        <Typography align="center" color="gray.blue" variant="caption_medium">
          편집된 팩터가 없습니다.
        </Typography>
      </WrapperComponent>
    );
  }

  return (
    <WrapperComponent>
      <SelectButton
        placeholder="투자 전략 상세"
        color="black.60"
        transparent
        open={open}
        onClick={() => setOpen((o) => !o)}
      />
      {open && (
        <div>
          <Table>
            <TableHead bgColor="background.table" height={32}>
              <TableRow>
                <TableCell py={6} width={160}>
                  투자지표
                </TableCell>
                <TableCell py={6} width={160}>
                  값
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div>
            {strategyStyleDetailInfo.map(([key, value]) => (
              <CustomRow key={`factor_${key}`} alignItems="center">
                <CustomColumn
                  px={8}
                  py={12}
                  variant="caption"
                  wordBreak="keep-all"
                >
                  {key}
                </CustomColumn>
                <Divider />
                <CustomColumn
                  align="center"
                  px={8}
                  py={12}
                  variant="caption"
                  wordBreak="keep-all"
                >
                  {value}
                </CustomColumn>
              </CustomRow>
            ))}
          </div>
        </div>
      )}
    </WrapperComponent>
  );
};
