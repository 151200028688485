import { COUNTRY_MAPPER } from '@constants/common';
import {
  LESS_THEN_FIVE_STOCKS_ALERT,
  NO_MATCHED_HOLDINGS_ALERT_CONTENT,
} from '@constants/feedback';
import { INVESTMENT_STRATEGY_LEVEL_MAPPER } from '@constants/strategy/strategy-edit.const';
import { LayoutContainer } from '@containers/layout/LayoutContainer';
import { useEditController } from '@hooks/edit/useEditController';
import { useRouterMove } from '@hooks/useRouterMove';
import { CountryCodeType } from '@models/common';
import { useConfirmStore } from '@stores/feedback';
import { useStrategyEditStore } from '@stores/strategy-edit/strategy-edit.store';
import { handleBlockPopupState } from '@utils/common';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BooksizeOptionContainer } from '../BooksizeOptionContainer';
import { EditCancelBtn } from '../DetailEditPageContainer';

interface LevelOneContainerProps {
  countryCode?: CountryCodeType;
  reEdit?: boolean;
  renew?: boolean;
  prevStrategyId?: string;
}

export const LevelOneContainer: FC<LevelOneContainerProps> = ({
  countryCode = COUNTRY_MAPPER.DOMESTIC,
  reEdit = false,
  renew = false,
  prevStrategyId = '',
}) => {
  const { strategyId = '' } = useParams<string>();
  const { openConfirm } = useConfirmStore();
  const { moveToUSReadyMades, moveToReadyMades } = useRouterMove();
  const {
    initEditState,
    setStrategyId,
    fetchDefaultOptions,
    fetchMySimulationOption,
    updateRenewAndReEdit,
    checkHoldingsValidate,
  } = useStrategyEditStore();

  const { handleCreateMyStrategy, handleCancel } = useEditController({
    isSimple: true,
    countryCode,
    reEdit,
    renew,
    prevStrategyId,
    investmentStrategyLevel: INVESTMENT_STRATEGY_LEVEL_MAPPER.NOVICE_LEVEL1,
  });

  const handleClickComplete = () => {
    checkHoldingsValidate(strategyId, true).then((holdingsCnt) => {
      if (holdingsCnt >= 5) {
        handleCreateMyStrategy();
      } else if (holdingsCnt === 0) {
        openConfirm('alert')({
          ...NO_MATCHED_HOLDINGS_ALERT_CONTENT,
        });
      } else {
        openConfirm('alert')({
          ...LESS_THEN_FIVE_STOCKS_ALERT,
        });
      }
    });
  };

  const handleClickPrev = () => {
    openConfirm('alert')({
      button: 'double',
      description: '편집한 내용을 저장하지 않고 나가시겠습니까?',
      title: '편집 취소',
      onOk: () => {
        if (countryCode === COUNTRY_MAPPER.USA) {
          moveToUSReadyMades();
          return;
        }
        if (countryCode === COUNTRY_MAPPER.DOMESTIC) {
          moveToReadyMades();
          return;
        }
      },
    });
    window.history.back();
  };

  useEffect(() => {
    const handlePopState = handleBlockPopupState(handleCancel);
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopState);

    return () => {
      initEditState();
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (renew || reEdit) {
      Promise.resolve(fetchMySimulationOption(strategyId)).then(() => {
        updateRenewAndReEdit(renew, reEdit);
      });
      fetchMySimulationOption(strategyId);
      return;
    }

    setStrategyId(strategyId);
    fetchDefaultOptions(strategyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainer
      title={`전략편집`}
      floater={{
        variant: 'single',
        okText: '백테스트 시작',
        onClickOk: handleClickComplete,
      }}
      optionalButton={EditCancelBtn(handleClickPrev)}
      onClickPrev={handleClickPrev}
    >
      <BooksizeOptionContainer />
    </LayoutContainer>
  );
};
