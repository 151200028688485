import { Typography } from '@ui/components';
import {
  ButtonUIHTMLComponentProps,
  FontWeightType,
  HeightChangeProps,
  MarginChangeProps,
  ScHeightChangeProps,
  SelectButtonVariantProps,
  ThemeColorType,
} from '@ui/model';
import { getHeightFromProps, getMarginFromProps } from '@ui/util';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { svgPath } from '@utils/common';

interface SelectButtonWrapProps {
  open?: boolean;
  transparent?: boolean;
}

interface StyleProps
  extends SelectButtonWrapProps,
    MarginChangeProps,
    SelectButtonVariantProps,
    ScHeightChangeProps {}

interface SelectButtonProps
  extends ButtonUIHTMLComponentProps,
    HeightChangeProps,
    StyleProps {
  color?: ThemeColorType;
  fontWeight?: FontWeightType;
  selectedValue?: string;
}

const SelectButtonWrap = styled.button<StyleProps>`
  border-radius: 4px;
  padding: 8px 4px 8px 8px;

  ${({ variant }) => {
    if (variant === 'gray') {
      return css`
        background-color: ${({ theme }) => theme.colorPalette.black[3]};
      `;
    }

    if (variant === 'white') {
      return css`
        background-color: ${({ theme }) =>
          theme.colorPalette.background.input_box};
        border: 1px solid ${({ theme }) => theme.colorPalette.border.select};
      `;
    }
  }}

  ${({ transparent }) => {
    if (transparent) {
      return css`
        background-color: transparent;
        padding: 0;
      `;
    }
  }}

  & > img {
    vertical-align: text-top;
    ${({ open }) => {
      if (open) {
        return css`
          transform: rotate(180deg);
        `;
      }
      return null;
    }}
  }

  ${getMarginFromProps}
  ${getHeightFromProps}
`;

export const SelectButton: FC<SelectButtonProps> = ({
  open = false,
  transparent = false,
  color,
  fontWeight,
  selectedValue,
  placeholder,
  height,
  ...props
}) => {
  return (
    <SelectButtonWrap
      open={open}
      transparent={transparent}
      {...props}
      $height={height}
    >
      <Typography
        as="span"
        color={color}
        fontWeight={fontWeight}
        variant={`caption${transparent ? '' : '_medium'}`}
        mr={2}
      >
        {selectedValue || placeholder}
      </Typography>
      <img src={svgPath('/icon', 'arrow-down')} alt="select" />
    </SelectButtonWrap>
  );
};
