export const STACK_BAR_CHART_BLUE_COLOR = [
  '#5B86D4',
  '#719ee1',
  '#88AEE6',
  '#AEC7EE',
  '#C8D9F4',
  '#375F9B',
];

export const STACK_BAR_CHART_ORANGE_COLOR = [
  '#FFDC00',
  '#FFE854',
  '#FFEF8A',
  '#FFF4B0',
  '#FFD766',
  '#FFAE00',
];

export const STACK_BARS_KEY_LIST = [0, 1, 2, 3, 4, 5].reverse();

export const LABEL_MAPPED_NAME: Record<string, string> = {
  myStrategy: '나의 전략',
  base: '프리셋',
  benchmark: '벤치마크',
};
