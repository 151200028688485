import * as Sentry from '@sentry/react';
import { InitializeDefStyle } from '@styles/InitializeDefStyle';
import { moveBackward } from '@utils/bridge';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { router } from './router';
import { NoMatchPage } from './pages/common/NoMatchPage';
import { isMable } from '@utils/common';
import { theme } from './styles';

Sentry.init({
  dsn: 'https://06e3b086a668477da90606d07edcfb84@o4505044715634688.ingest.sentry.io/4505044717076480',
  integrations: [new Sentry.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENV === 'production',
});

window.moveBackward = moveBackward;

function App() {
  return (
    <>
      <InitializeDefStyle />
      <ThemeProvider theme={theme}>
        {isMable() ? <RouterProvider router={router} /> : <NoMatchPage />}
      </ThemeProvider>
    </>
  );
}
export default App;
