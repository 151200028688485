import { SelectOption } from '@components/common';
import {
  MY_KB_STRATEGY_CARD_OPTIONS,
  MY_STRATEGY_CARD_OPTIONS,
} from '@constants/common';
import { MyStrategyOptionBottomsheetContentModel } from '@models/feedback';
import { ContentWidthModel } from '@models/layout.model';
import { FC } from 'react';
import styled from 'styled-components';
import { BottomsheetTemplate } from './BottomsheetTemplate';

interface MyStrategyOptionBottomsheetProps
  extends MyStrategyOptionBottomsheetContentModel,
    ContentWidthModel {}

const MyStrategyOptionBottomsheetWrap = styled(BottomsheetTemplate)``;

export const MyStrategyOptionBottomsheet: FC<
  MyStrategyOptionBottomsheetProps
> = ({
  contentWidth,
  isActiveKbContract,
  onClickOption,
  onClickInputOption,
}) => {
  return (
    <MyStrategyOptionBottomsheetWrap
      type="myStrategyOption"
      contentWidth={contentWidth}
    >
      <SelectOption
        checkVisible={false}
        options={
          isActiveKbContract
            ? MY_KB_STRATEGY_CARD_OPTIONS
            : MY_STRATEGY_CARD_OPTIONS
        }
        onClick={onClickOption}
        onClickInputOption={onClickInputOption}
      />
    </MyStrategyOptionBottomsheetWrap>
  );
};
