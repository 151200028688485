import { COUNTRY_MAPPER } from '@constants/common';
import { LevelTwoContainer } from '@containers/strategy-edit/beginner/LevelTwoContainer';
import { FC } from 'react';

interface UsStrategyBeginner2EditPageProps {
  reEdit?: boolean;
}

export const UsStrategyBeginner2EditPage: FC<
  UsStrategyBeginner2EditPageProps
> = ({ reEdit = false }) => {
  return <LevelTwoContainer reEdit={reEdit} countryCode={COUNTRY_MAPPER.USA} />;
};
