import { Layout } from '@components/layout';
import { LayoutModel } from '@models/layout.model';
import { useLayoutStore } from '@stores/ui/layout.store';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeedbackContainer } from '../feedback/FeedbackContainer';
import { EXTERNAL_PAGE_ENUM } from '@constants/router.const';
import { execute_gnb_bridge } from '@utils/bridge';
import { ExternalPageType } from '@models/router.model';

interface LayoutContainerProps extends LayoutModel {}

export const LayoutContainer: FC<LayoutContainerProps> = ({ ...props }) => {
  const { contentWidth, setContentWidth } = useLayoutStore();
  const nav = useNavigate();
  const { pathname } = useLocation();

  React.useLayoutEffect(() => {
    const resize = () => {
      setContentWidth(window.innerWidth);
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickHeaderPrev = () => {
    if (props.onClickPrev) {
      props.onClickPrev();
    } else {
      nav(-1);
    }
  };

  const handleNavigationMenu = (page: string) => {
    const externalPages = [...EXTERNAL_PAGE_ENUM] as string[];
    const pageName = page.replace('/', '') as ExternalPageType;

    if (externalPages.includes(pageName)) {
      execute_gnb_bridge(pageName);
    } else {
      nav(page);
    }
  };

  return (
    <>
      <Layout
        {...props}
        pathname={pathname}
        onClickPrev={handleClickHeaderPrev}
        onNavigation={handleNavigationMenu}
        contentWidth={contentWidth}
      />
      <FeedbackContainer />
    </>
  );
};
