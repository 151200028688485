import { HistogramSliderModel } from '@models/common';
import { FC, useState } from 'react';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import styled from 'styled-components';
import { Handle } from './slider-units/Handle';
import { SliderRail } from './slider-units/SliderRail';
import { Track } from './slider-units/Track';

interface HistogramSliderProps extends HistogramSliderModel {
  domain?: number[];
}

const HistogramSliderWrap = styled.div``;

export const HistogramSlider: FC<HistogramSliderProps> = ({
  domain = [0, 99],
  range = [0, 99],
  onChange,
  onUpdate,
}) => {
  const [type, setType] = useState<'min' | 'max'>('min');
  const handleSetType = (
    _: readonly number[],
    data: { activeHandleID: string }
  ) => {
    setType(data.activeHandleID === '$$-1' ? 'max' : 'min');
  };
  const handleChange = (
    values: readonly number[],
    data: { activeHandleID: string }
  ) => {
    if (onChange)
      onChange(values, data.activeHandleID === '$$-1' ? 'max' : 'min');
  };
  const handleUpdate = (values: readonly number[]) => {
    if (onUpdate) onUpdate(values, type);
  };

  return (
    <HistogramSliderWrap>
      <Slider
        mode={2}
        step={1}
        domain={domain}
        reversed={false}
        rootStyle={{
          position: 'relative' as 'relative',
          width: '100%',
        }}
        onUpdate={handleUpdate}
        onSlideStart={handleSetType}
        onSlideEnd={handleChange}
        values={range}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => {
            return (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            );
          }}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </HistogramSliderWrap>
  );
};
