import { ThemedSwitchButtonProps } from '@ui/model';
import { FC } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../Flexbox';
import { Typography } from '../Typography';

interface SwitchButtonDto {
  index: number;
  name: string;
  onClickBtn: () => void;
}

interface SwitchButtonProps extends ThemedSwitchButtonProps {
  activeIndex: number;
  buttonList: SwitchButtonDto[];
}

const SwitchButtonWrap = styled.button<{ size: 'small' | 'full' }>`
  background-color: ${({ theme }) => theme.colorPalette.white};
  border-style: solid;
  border-color: ${({ theme }) => theme.colorPalette.divider.title};
  border-width: 1px;

  width: ${({ size }) => (size === 'full' ? '100%' : '62px')};
  height: ${({ size }) => (size === 'full' ? '40px' : '32px')};

  margin-left: -1px;

  &:first-child {
    border-radius: 3px 0px 0px 3px;
  }

  &:last-child {
    border-radius: 0px 3px 3px 0px;
  }

  &.active {
    border: 1px solid ${({ theme }) => theme.colorPalette.secondary.default};
    background-color: ${({ theme }) => theme.colorPalette.secondary.default};
  }
`;

const OvalSwitchButton = styled.button`
  width: 59px;
  height: 30px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colorPalette.divider.appbar_tab};
  border-radius: 30px;
  &.active {
    background-color: ${({ theme }) => theme.colorPalette.secondary.default};
    border: 1px solid transparent;
  }
`;

type SwitchBtnClick = (index: number) => (e: React.MouseEvent) => void;

export const SwitchButton: FC<SwitchButtonProps> = ({
  variant = 'small',
  activeIndex = 0,
  buttonList,
}) => {
  const handleClick: SwitchBtnClick = (index) => (e) => {
    e.stopPropagation();
    buttonList[index].onClickBtn();
  };

  if (!buttonList || buttonList.length < 2) return null;

  if (variant === 'oval') {
    return (
      <FlexBox spacing={8}>
        {buttonList.map((item, index) => (
          <OvalSwitchButton
            className={activeIndex === item?.index ? 'active' : ''}
            onClick={handleClick(index)}
            key={item.name}
          >
            <Typography
              color={activeIndex === item?.index ? 'fixedwhite' : 'black.47'}
              variant="caption_medium"
            >
              {item.name}
            </Typography>
          </OvalSwitchButton>
        ))}
      </FlexBox>
    );
  }

  return (
    <FlexBox width="100%">
      {buttonList.map((item, index) => (
        <SwitchButtonWrap
          size={variant}
          className={activeIndex === item?.index ? 'active' : 'default'}
          onClick={handleClick(index)}
          key={item.name}
        >
          <Typography
            color={activeIndex === item?.index ? 'fixedwhite' : 'black.60'}
            variant="overline_title"
          >
            {item.name}
          </Typography>
        </SwitchButtonWrap>
      ))}
    </FlexBox>
  );
};
